import React from 'react';
import PropTypes from 'prop-types';
import { DL, DT, DD } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupDL = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data } = fields[fieldName];
  return (
    <DL mb={0}>
      {data.map((kv) => (
        <React.Fragment key={`${kv.key}${kv.value}`}>
          <DT>{kv.key}</DT>
          <DD>{kv.value}</DD>
        </React.Fragment>
      ))}
    </DL>
  );
};

MarkupDL.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupDL;
