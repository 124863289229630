import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Box, P, Text, VimeoModal, ShowMore,
} from '@galilee/lilee';
import CircleIcon from 'components/CircleIcon';
import Colin from './Colin';

const PlayVideo = styled(Text)`
  cursor: pointer;
  display: inline-block;
  &:hover {
    text-decoration: underline;
  }
`;

PlayVideo.defaultProps = {
  fontSize: 0,
  display: 'inline-block',
  uppercase: true,
  fontWeight: 'bold',
  mt: 6,
};

const ColinCardWrapper = styled(Box)`
  background: #0D2F46;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  margin-top: 90px;
`;

ColinCardWrapper.defaultProps = {
  p: [7],
  borderRadius: 3,
};

const ColinCard = ({
  image, title, body, videoId,
}) => {
  const [showVideo, setShowVideo] = useState(false);
  const bodyText = body.map((b) => <P key={b} color="base20" fontSize="2" fontWeight="light">{b}</P>);
  const firstBody = bodyText.shift();
  return (
    <ColinCardWrapper>
      <Colin image={image} />
      <Text fontSize="2" mb="4" uppercase fontWeight="medium">{title}</Text>
      {firstBody}
      <ShowMore>
        {bodyText}
      </ShowMore>
      { videoId && (
        <Box textAlign="right">
          <PlayVideo onClick={() => setShowVideo(true)}>
            <CircleIcon iconSize="xs" bg="tertiary" color="base" name="Play" mr="3" />
            Play Video
          </PlayVideo>
        </Box>
      )}
      { showVideo && videoId && <VimeoModal maxWidth="1024px" vimeoId={videoId} close={() => setShowVideo(false)} /> }
    </ColinCardWrapper>
  );
};

ColinCard.defaultProps = {
  videoId: null,
};

ColinCard.propTypes = {
  videoId: PropTypes.string,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  body: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default ColinCard;
