import { httpRequest, downloadFile } from 'utils';
import { errorDispatcher } from './actionUtils';

const fetchReportAction = async (dispatch, token) => {
  const date = new Date();
  const dateString = date.toISOString().split('T')[0];
  httpRequest('/api/report', 'get', null, { Authorization: `Bearer ${token}` })
    .then((blob) => {
      downloadFile(blob, `Report${dateString}.csv`);
    })
    .catch(() => errorDispatcher(dispatch, "We couldn't download the report, please try again later."));
};

export default fetchReportAction;
