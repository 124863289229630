import React, {
  createContext, useContext, useEffect, useRef, useState,
} from 'react';
import { toast } from '@galilee/lilee';
import { useApplication } from 'state/ApplicationProvider';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { getMatterAsync } from 'actions/Track';

import PropTypes from 'prop-types';

const TrackNTraceMatterContext = createContext();

function useTrackNTraceMatterHook(matterId) {
  const { state: applicationState } = useApplication();
  const { matters, users, actions: { getToken } } = useTrackNTrace();
  const [matterOwner, setMatterOwner] = useState(null);
  const [matter, setMatter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const { trackNTraceApiUrl } = applicationState.appSettings;

  const getTokenRef = useRef();

  useEffect(() => {
    if (!getTokenRef.current) {
      getTokenRef.current = getToken;
    }
  }, [getToken]);

  useEffect(() => {
    const owner = matters.find((m) => m.goldId === Number(matterId))?.owner || users.find((u) => u.role && u.role === 'Admin')?.email;
    setMatterOwner(owner);
  }, [matterId, matters, users]);

  useEffect(() => {
    async function getMatterDetails() {
      try {
        const token = await getTokenRef.current(matterOwner);
        if (!token) return;
        setIsLoading(true);
        const { matterDetail } = await getMatterAsync(matterId, token, trackNTraceApiUrl);
        if (!matterDetail) {
          toast.error(`Matter ${matterId} does not exist.`);
          return;
        }
        setMatter(matterDetail);
      } catch (err) {
        toast.error(`Failed to get matter ${matterId}`);
      } finally {
        setIsLoading(false);
      }
    }
    if (!matterId || !matterOwner || !trackNTraceApiUrl) return;
    getMatterDetails();
  }, [matterId, trackNTraceApiUrl, matterOwner]);

  return {
    matter,
    isLoading,
  };
}

export const useTrackNTraceMatter = () => useContext(TrackNTraceMatterContext);

export default function TrackNTraceMatterProvider({ children, matterId }) {
  const value = useTrackNTraceMatterHook(matterId);
  return (
    <TrackNTraceMatterContext.Provider value={value}>
      {children}
    </TrackNTraceMatterContext.Provider>
  );
}

TrackNTraceMatterProvider.propTypes = {
  matterId: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};
