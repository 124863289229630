import React, { useState } from 'react';
import styled from 'styled-components/macro';
import ReactRouterPropTypes from 'react-router-prop-types';
import TimeAgo from 'react-timeago';
import {
  PageLoading, Box, TR, TD,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import Container from 'components/Container';
import DataGrid from 'components/DataGrid';
import TitleContainer from 'components/TitleContainer';
import { useApplication } from 'state/ApplicationProvider';
import { fetchMatterSecurityAction } from 'actions/Matter';

const Time = styled(TimeAgo)`
  min-width: 150px;
  display: inline-block;
`;

const Security = ({ match }) => {
  const matterId = match.params.id;
  const { dispatch: applicationDispatch } = useApplication();
  const { authToken } = useAuth();
  const [sort, setSort] = useState({ sortId: 'actionTime', ascending: false });
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [results, setResults] = useState([]);

  if (!matterId) return <PageLoading />;

  const loadMore = async (page, newSort) => {
    const currentSort = newSort || sort;
    const newPage = await fetchMatterSecurityAction(applicationDispatch, matterId, authToken, page, currentSort.sortId, currentSort.ascending, 30);
    if (newPage) {
      setResults([...(page === 1 ? [] : results), ...newPage.actions]);
      if (page === 1) {
        setTotalPages(newPage.totalPages);
        setHasMore(page < newPage.totalPages);
      } else {
        setHasMore(page < totalPages);
      }
    } else {
      setHasMore(false); // Something went wrong so stop loading more
    }
  };

  const triggerLoadMore = async () => {
    const nextpageNumber = currentPage + 1;
    setCurrentPage(nextpageNumber);
    await loadMore(nextpageNumber, sort);
  };

  const triggerSort = async (newSort) => {
    const nextpageNumber = 1;
    setSort(newSort);
    setCurrentPage(nextpageNumber);
    await loadMore(nextpageNumber, newSort);
  };

  const items = results.map((i) => (
    <TR key={i.id}>
      <TD>{i.fullName}</TD>
      <TD>{i.ipAddress === '::1' ? 'Local host' : i.ipAddress}</TD>
      <TD>{i.action}</TD>
      <TD>
        <Time date={i.actionTime} />
      </TD>
    </TR>
  ));

  const headers = [
    {
      id: 'fullName', title: 'User', sortable: true, visible: true,
    },
    {
      id: 'ipAddress', title: 'IP Address', sortable: true, visible: true,
    },
    {
      id: 'action', title: 'Action', sortable: true, visible: true,
    },
    {
      id: 'actionTime', title: 'Time', sortable: true, visible: true,
    },
  ];

  return (
    <Container center>
      <Box p={[3, 0]}>
        <TitleContainer title="Security Tracking">
          <TitleContainer.WelcomeMessage>
            Kwil maintains a full audit of all actions carried out against a matter to ensure complete security and transparency.
          </TitleContainer.WelcomeMessage>
        </TitleContainer>
        <DataGrid
          loadMore={triggerLoadMore}
          hasMore={hasMore}
          headers={headers}
          onSort={triggerSort}
          sort={sort}
        >
          {items}
        </DataGrid>
      </Box>
    </Container>
  );
};

Security.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Security;
