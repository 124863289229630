import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Panel, AccordionRow, H6, Flex, Text, Button, Box, mediaQueries, ResponsiveGroup,
} from '@galilee/lilee';

import styled from 'styled-components/macro';
import { useTrackNTraceAdmin } from 'state/track/TrackNTraceAdminProvider';
import { humanReadableFormat } from 'utils';
import { useHistory } from 'react-router';
import format from 'date-fns/format';

const { greaterThan } = mediaQueries;

const getBorderStyle = (props) => {
  if (props.isSelected && props.errored) {
    return `4px solid ${props.theme.colors.warn}`;
  }

  if (props.isSelected) {
    return `4px solid ${props.theme.colors.primary}`;
  }

  if (props.isProcessingByOthers) {
    return `4px solid ${props.theme.colors.base40}`;
  }

  return 'none';
};

const Card = styled(Panel)`
  max-width: 760px;
  padding: ${(p) => p.theme.space[5]} ${(p) => p.theme.space[7]};
  margin-bottom: ${(p) => p.theme.space[3]};
  border-radius: ${(p) => p.theme.sizes[3]};
  cursor: pointer;
  border: ${getBorderStyle};
`;

const ActionWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  margin-top: ${(p) => p.theme.space[5]};
  border-top: 1px solid ${(p) => p.theme.colors.base10};
  padding: ${(p) => p.theme.space[6]} 0;
  flex-direction: column;
  ${greaterThan[0]`
    flex-direction: row;
    justify-content: space-between;
  `}
`;

const WarnButton = styled(Button)`
  background-color: ${(p) => p.theme.colors.warn};
  &:hover {
    background-color: ${(p) => p.theme.colors.warnDark};
  }
`;

const MatterLink = styled(H6)`
  text-decoration : underline;
  cursor: pointer;
`;

const CallbackStatus = {
  Queued: 'Queued',
  Active: 'Active',
  Finalised: 'Finalised',
};

export default function CallbackCard({
  callBackId, name, note, phoneNumber, processedBy, dateTimeAdded, processedDateTime, finalisedDateTime, attemptedCallBack, failedAttempt, status, matterURL, onSelect, isSelected, errored,
}) {
  const {
    me, actions: {
      pickupCallback, deleteCallback, finalizeCallback, releaseCallback, setCallbackUnanswered,
    },
  } = useTrackNTraceAdmin();

  const [open, setOpen] = useState(false);

  const history = useHistory();

  useEffect(() => {
    setOpen(processedBy === me.email);
  }, [processedBy, me]);

  const isFinalized = !!finalisedDateTime;
  const isProcessing = !!processedBy && !isFinalized;

  const isProcessingByMe = processedBy === me.email;
  const isProcessingByOthers = !!processedBy && processedBy !== me.email;

  const showActions = !isProcessingByOthers;

  const showStartCallButton = (status === CallbackStatus.Queued || attemptedCallBack <= failedAttempt) && attemptedCallBack !== 3;

  const showUnansweredButton = status === CallbackStatus.Active && attemptedCallBack !== failedAttempt && failedAttempt !== 3;

  const showCompleteButton = status === CallbackStatus.Active && (attemptedCallBack !== failedAttempt || failedAttempt === 3);

  const showReleaseButton = status === CallbackStatus.Active && attemptedCallBack !== 3 && attemptedCallBack === failedAttempt;

  const showDismissButton = failedAttempt === 3;

  const Heading = (
    <>
      <Text fontWeight="light" ellipsis>{name}</Text>
      <H6 ml="4">{isProcessing ? `Attempt ${attemptedCallBack}` : format(new Date(dateTimeAdded), 'dd/MM/yyyy')}</H6>
    </>
  );

  return (
    <Card
      mx="auto"
      isSelected={isSelected}
      isProcessingByOthers={isProcessingByOthers}
      errored={errored}
      onClick={() => onSelect(callBackId)}
    >
      <AccordionRow
        heading={Heading}
        headingFontSize="18px"
        headingFontWeight="medium"
        isOpen={open}
        onClick={() => setOpen(!open)}
        type="default"
        width="100%"
      >
        <Flex flexDirection="column" px="6" py="3">
          <Box>
            <H6>Note:</H6>
            <Text fontSize="1" my="4">{note}</Text>
            {!!matterURL && <MatterLink fontSize="0" color="base" onClick={() => history.push(matterURL)}>See User View</MatterLink>}
          </Box>
          {showActions && (
            <ActionWrapper>
              <Flex flexDirection="column">
                <Text fontWeight="medium">{ isProcessingByMe ? phoneNumber : phoneNumber.replace(/./g, (c, i) => (i >= 2 ? 'X' : c))}</Text>
                { failedAttempt > 0
                && (
                <Text fontSize={[0, 1]}>
                  {`Unanswered ${failedAttempt} time${failedAttempt > 1 ? 's' : ''} (last: ${humanReadableFormat(new Date(processedDateTime), { suffix: 'ago' })})`}
                </Text>
                )}
              </Flex>
              <ResponsiveGroup fullWidthBreakpointIndex={0} maxWidth="55%">
                {showUnansweredButton && <WarnButton fullWidth my={[4, 0]} onClick={() => setCallbackUnanswered(callBackId)}>Unanswered</WarnButton> }
                {showStartCallButton && <Button noWrap minWidth="120px" fullWidth ml={[0, 4]} mt={[2, 0]} onClick={() => pickupCallback(callBackId)}>Start Call</Button> }
                {showDismissButton && <WarnButton fullWidth ml={[0, 4]} onClick={() => deleteCallback(callBackId)}>Dismiss</WarnButton>}
                {showCompleteButton && <Button minWidth="110px" fullWidth ml={[0, 4]} onClick={() => finalizeCallback(callBackId)}>Complete</Button> }
                {showReleaseButton && <Button minWidth="100px" fullWidth ml={[0, 4]} mt={[2, 0]} onClick={() => releaseCallback(callBackId)}>Release</Button> }
              </ResponsiveGroup>
            </ActionWrapper>
          )}
        </Flex>
      </AccordionRow>
    </Card>
  );
}

CallbackCard.propTypes = {
  callBackId: PropTypes.string.isRequired,
  name: PropTypes.string,
  note: PropTypes.string,
  phoneNumber: PropTypes.string.isRequired,
  processedBy: PropTypes.string,
  dateTimeAdded: PropTypes.string.isRequired,
  processedDateTime: PropTypes.string,
  finalisedDateTime: PropTypes.string,
  attemptedCallBack: PropTypes.number,
  failedAttempt: PropTypes.number,
  matterURL: PropTypes.string,
  onSelect: PropTypes.func.isRequired,
  isSelected: PropTypes.bool,
  errored: PropTypes.bool,
  status: PropTypes.string.isRequired,
};

CallbackCard.defaultProps = {
  name: '',
  note: '',
  attemptedCallBack: 0,
  failedAttempt: 0,
  matterURL: null,
  processedBy: null,
  processedDateTime: null,
  finalisedDateTime: null,
  isSelected: false,
  errored: false,
};
