import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Box, H2, H3, Panel, Row, Column,
} from '@galilee/lilee';
import Container from 'components/Container';
import SummaryRow from 'components/SummaryRow';
import VideoPlayer from 'components/VideoPlayer';

const OverflowContainer = styled.div`
    box-shadow: inset 0px 100px 0 0 ${(p) => p.theme.colors.base};
`;

const SectionSummary = ({
  match, title, children, progress, sectionType, videoId, verificationProgress,
}) => (
  <Container>
    <Box pt={[6, 7]} bg="base">
      <Container center>
        <Row collapseIndex="0">
          <Column maxWidth="500px" px={[5, 0]}>
            <H2 px={[5, 0]} my="4" color="white">{title}</H2>
            {children}
          </Column>
          <Column flexDirection="row">
            <VideoPlayer videoId={videoId} sectionType={sectionType} />
          </Column>
        </Row>
      </Container>
    </Box>
    <OverflowContainer>
      <Container center>
        <Panel>
          <Box m={[5]}>
            <H3 pb="6">Documents in this section</H3>
            {progress && progress.map((p) => <SummaryRow sectionType={sectionType} routePath={match.url} progress={p} key={p.id} verificationProgress={verificationProgress} />)}
          </Box>
        </Panel>
      </Container>
    </OverflowContainer>
  </Container>
);
SectionSummary.defaultProps = {
  progress: [],
  verificationProgress: [],
};

SectionSummary.propTypes = {
  sectionType: PropTypes.string.isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  title: PropTypes.string.isRequired,
  videoId: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  progress: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.number })),
  verificationProgress: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      requireVerification: PropTypes.bool,
      verificationCompleted: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, voiCompleted: PropTypes.bool }),
      ),
    }),
  ),
};

export default SectionSummary;
