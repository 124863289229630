import React, {
  useState, useEffect, createContext, useContext,
} from 'react';
import UserType from 'enums/UserType';
import authService from '../components/api-authorization/AuthorizeService';
import { getBorrower, getMe } from '../actions/User';

const authContext = createContext();

function useAuthHook() {
  const [user, setUser] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [ready, setReady] = useState(false);
  const [tokenRefreshed, setTokenRefreshed] = useState(false);
  const isAdminOrTenantAdmin = user && [UserType.Admin, UserType.TenantAdmin].includes(user.userType);
  const [isTenantSupport, setIsTenantSupport] = useState(false);
  const [supportViewUserId, setSupportViewUserId] = useState(null);

  useEffect(() => {
    if (ready) return;
    async function initialiseState() {
      const newAuthToken = await authService.getAccessToken();
      let newUser = !newAuthToken ? null : await getMe(newAuthToken);

      if (newUser && [UserType.TenantSupport].includes(newUser.userType)) {
        setIsTenantSupport(true);
        if (supportViewUserId != null) {
          newUser = !newAuthToken ? null : await getBorrower(newAuthToken, supportViewUserId);
          newUser.userType = UserType.TenantSupport;
        }
      } else setIsTenantSupport(false);

      setAuthToken(newAuthToken);
      setUser(newUser);
      setReady(true);
    }
    initialiseState();
  }, [ready, supportViewUserId]);

  useEffect(() => {
    async function updateToken() {
      const newAuthToken = await authService.getAccessToken();
      setAuthToken(newAuthToken);
      setTokenRefreshed(true);
    }

    const subscription = authService.subscribe(() => updateToken());
    return () => {
      authService.unsubscribe(subscription);
    };
  }, []);

  const actions = {
    resetUser: async () => {
      const newAuthToken = await authService.getAccessToken();
      const newUser = !newAuthToken ? null : await getMe(newAuthToken);
      setSupportViewUserId(null);
      setAuthToken(newAuthToken);
      setUser(newUser);
    },
  };

  const setters = {
    setSupportViewUserId: (id) => {
      setSupportViewUserId(id);
      setReady(false);
    },
  };

  const support = {
    actions,
    setters,
    isTenantSupport,
    supportViewUserId,
  };

  return {
    user, support, authToken, ready, tokenRefreshed, isAdminOrTenantAdmin,
  };
}

export const useAuth = () => useContext(authContext);

export default function AuthProvider(props) {
  const auth = useAuthHook();
  return <authContext.Provider value={auth} {...props} />;
}
