import React, { useState, useEffect } from 'react';
import {
  Button,
} from '@galilee/lilee';
import { useMatterMaker } from 'state/matterMaker/MatterMakerProvider';

const SubmitBtn = () => {
  const { actions, state } = useMatterMaker();
  const [readyToSubmit, setReadyToSubmit] = useState(false);
  const {
    borrowerOneId, howManyMatters, lenderId,
  } = state.createMatter;
  useEffect(() => {
    setReadyToSubmit(
      borrowerOneId !== -1
      && howManyMatters
      && lenderId,
    );
  }, [borrowerOneId, howManyMatters, lenderId]);

  const onSubmit = () => {
    if (!readyToSubmit || state.settings.create) return;
    actions.updateSettings({ create: true });
  };
  return (
    <Button mb="9" disabled={!readyToSubmit} mt={[5, 6]} onClick={() => onSubmit()} color={readyToSubmit ? 'secondary' : 'base'}>Create</Button>
  );
};

export default SubmitBtn;
