import React from 'react';
import styled from 'styled-components/macro';
import {
  Flex, Panel, Box, H3, H5, HR, Tag, Icon, mediaQueries,
} from '@galilee/lilee';
import format from 'date-fns/format';
import { useTrackNTraceMatter } from '../../../state/track/TrackNTraceMatterProvider';

const { lessThan } = mediaQueries;

const SectionStatus = {
  NotStarted: 0,
  Warn: 1,
  Success: 2,
};

const getColor = (p) => {
  switch (p?.status) {
    case SectionStatus.NotStarted:
      return p.theme.colors.base10;
    case SectionStatus.Success:
      return p.theme.colors.success;
    case SectionStatus.Warn:
      return p.theme.colors.warn;
    default:
      return p.theme.colors.base10;
  }
};

const Duration = styled(Flex)`
  justify-content: flex-end;
  text-transform: uppercase;
  align-items: center;  
  ${lessThan[0]`
    display: none;  
  `}
`;

const StepRow = styled(Flex)`
  align-items: center;
  color: ${(p) => (p.archived ? p.theme.colors.base40 : p.theme.colors.base)};
  flex-direction: row;
  margin-bottom: ${(p) => p.theme.space[4]};
  &:last-child {
    margin-bottom: 0;
  }
`;

const Status = styled.span`
  display: inline-block;
  border: ${(p) => p.theme.space[3]} solid ${(p) => getColor(p)};
  border-radius: 19px;
  height: 38px;
  width: 38px;
  background-color: white;
`;

const StatusPath = styled(Flex)`
  background: ${(p) => (p.isLast ? 'none' : 'url(dots.png)')};
  background-repeat: repeat-y;
  background-position: center; 
  background-origin: content-box;
`;

StatusPath.defaultProps = {
  justifyContent: 'center',
  alignItems: 'stretch',
  mr: '6',
  width: '50px',
};

const SectionLink = styled(Flex)`
  align-self:start;
  text-decoration: underline;
  margin:0;
  color: ${(p) => p.theme.colors.secondary};
  cursor: pointer;
  font-weight: 500;
`;

const MatterHistory = () => {
  const { matter } = useTrackNTraceMatter();

  if (!matter) return null;

  return (
    <Panel width="100%" mx="auto" maxWidth="700px">
      <Box m={[5]}>
        <H5 mb="7">Matter history</H5>
        {matter.sections.sort((a, b) => a.order - b.order).map((section, index) => {
          const isFirst = index === 0;
          const isLast = (matter.sections.length - 1) === index;
          return (
            <Flex key={section.order}>
              <StatusPath isLast={isLast}>
                <Status status={section.status} />
              </StatusPath>
              <Flex flexDirection="column" width="100%">
                <Flex flexDirection="row">
                  <Flex alignItems="stretch" width="100%" flexDirection="column">
                    <Flex justifyContent="space-between" alignItems="baseline">
                      <H3 mb="5">{section.name}</H3>
                      <Duration>
                        {(!isFirst && !isLast) && <Icon size="16px" mr="4" name="Clock" color="base" />}
                        {section.duration}
                      </Duration>
                    </Flex>
                    {section.steps.sort((a, b) => a.order - b.order).map((step) => {
                      const delayFixed = step.delayEndDate !== null;
                      return (
                        <StepRow archived={step.isArchived} key={`${section.order}_${step.order}`}>
                          {step.isArchived && <Icon size="16px" mr="4" name="Retried" color="base20" />}
                          {step.isReinstruction && <Icon size="16px" mr="4" name="Retry" color="base20" />}
                          {step.isDelay && delayFixed && <Icon size="16px" mr="4" name="Clock" color="warn" />}
                          {step.isDelay && !delayFixed && <Tag scale="sm" bg="warn" mr="4">Delay</Tag>}
                          {`${format(new Date(step.date), 'dd/MM/yyyy')} - ${step.name}`}
                        </StepRow>
                      );
                    })}
                    {!!section.link && (
                      <SectionLink onClick={() => window.open(section.link, '_blank')}>
                        {section.link.toLowerCase().includes('upload') ? 'Upload Documents' : 'Cheque Directions'}
                      </SectionLink>
                    )}
                  </Flex>
                </Flex>
                {!isLast && <HR width="100%" my="7" />}
              </Flex>
            </Flex>
          );
        })}
      </Box>
    </Panel>
  );
};

export default MatterHistory;
