import React from 'react';
import PropTypes from 'prop-types';
import {
  DL, DT, DD, Label, Flex,
} from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupTL = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data, label } = fields[fieldName];
  return (
    <Flex flexDirection="column" flexBasis="100%">
      <Label>{label}</Label>
      <DL mb={0}>
        {data.map((kv) => (
          <React.Fragment key={kv.value}>
            <DT titleLabel={false}>{kv.key}</DT>
            <DD>{kv.value}</DD>
          </React.Fragment>
        ))}
      </DL>
    </Flex>
  );
};

MarkupTL.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupTL;
