/* eslint-disable max-len */
/* eslint-disable react-hooks/exhaustive-deps */

import { useState, useEffect, useCallback } from 'react';
import PROGRESS from 'enums/Progress';
import PROGRESS_STAGES from 'enums/ProgressStages';
import { validators } from '@galilee/lilee';

const getFriendlyErrorMessage = (status) => {
  if (status === 200) return 'OK';
  if (status === 201) return 'Created';
  if (status === 202) return 'Accepted';
  if (status === 203) return 'Non-Authoritative Information';
  if (status === 204) return 'No Content';
  if (status === 205) return 'Reset Content';
  if (status === 206) return 'Partial Content';
  if (status === 300) return 'Multiple Choices';
  if (status === 301) return 'Moved Permanently';
  if (status === 302) return 'Found';
  if (status === 303) return 'See Other';
  if (status === 304) return 'Not Modified';
  if (status === 305) return 'Use Proxy';
  if (status === 306) return 'Unused';
  if (status === 307) return 'Temporary Redirect';
  if (status === 400) return 'Bad Request';
  if (status === 401) return 'Unauthorized';
  if (status === 402) return 'Payment Required';
  if (status === 403) return 'Forbidden';
  if (status === 404) return 'Not Found';
  if (status === 405) return 'Method Not Allowed';
  if (status === 406) return 'Not Acceptable';
  if (status === 407) return 'Proxy Authentication Required';
  if (status === 408) return 'Request Timeout';
  if (status === 409) return 'Conflict';
  if (status === 410) return 'Gone';
  if (status === 411) return 'Length Required';
  if (status === 412) return 'Precondition Required';
  if (status === 413) return 'Request Entry Too Large';
  if (status === 414) return 'Request-URI Too Long';
  if (status === 415) return 'Unsupported Media Type';
  if (status === 416) return 'Requested Range Not Satisfiable';
  if (status === 417) return 'Expectation Failed';
  if (status === 418) return 'I\'m a teapot';
  if (status === 500) return 'Internal Server Error';
  if (status === 501) return 'Not Implemented';
  if (status === 502) return 'Bad Gateway';
  if (status === 503) return 'Service Unavailable';
  if (status === 504) return 'Gateway Timeout';
  if (status === 505) return 'HTTP Version Not Supported';
  return 'Indeterminable Error';
};

export const toStatusAction = (matterStatus, userStatus) => {
  switch (matterStatus) {
    case 'Draft':
      return 'Start';
    case 'NotStarted':
      return userStatus;
    case 'InProgress':
      return userStatus;
    case 'RequiresResign':
      return 'Resume';
    case 'Submitted':
    case 'SubmittedAndReady':
    case 'UnderReview':
      return 'Awaiting Review';
    case 'Errored':
      return 'Fix Error';
    case 'Finalised':
      return 'Finalised';
    default:
      return 'Resume';
  }
};

export const jsonSafeParse = (str) => {
  const response = { success: false, result: null };
  try {
    response.result = JSON.parse(str);
    response.success = true;
  } catch (e) {
    return response;
  }
  return response;
};

const getNextStage = (currentStage, completed) => {
  if (completed) return '';
  if (currentStage === PROGRESS_STAGES.READ) return `/${PROGRESS_STAGES.SIGN}`;
  if (currentStage === PROGRESS_STAGES.SIGN) return `/${PROGRESS_STAGES.UPLOAD}`;
  return '';
};

export const getPageMeta = (matter, matterProgress, progressStageName, currentStepId) => {
  if (!matter || !matterProgress || !currentStepId || !progressStageName) return null;

  const progressStage = matterProgress[`${progressStageName}Documents`];
  const currentStepIndex = progressStage.findIndex((p) => p.id === currentStepId);
  const currentStep = progressStage[currentStepIndex];
  const isCompletedByMe = currentStep.myProgress === PROGRESS.COMPLETED;
  const isCompletedByThem = currentStep.theirProgress === PROGRESS.COMPLETED;
  const submitted = currentStep.myProgress === PROGRESS.SUBMITTED;
  const finalised = currentStep.myProgress === PROGRESS.FINALISED;
  const locked = currentStep.myProgress === PROGRESS.LOCKED;

  const isNotEditable = submitted || finalised || locked;
  const isCompletedByAnyone = isCompletedByMe || isCompletedByThem;
  const isSignedByAnyone = currentStep.hasBeenSignedByAnyone;

  const isLastStep = (progressStage.length - 1) === currentStepIndex;
  const { allStagesJustCompleted } = matterProgress;

  const nextStageName = isLastStep || allStagesJustCompleted ? getNextStage(progressStageName, allStagesJustCompleted) : `/${progressStageName}`;
  const nextStepId = isLastStep || allStagesJustCompleted ? '' : `/${progressStage[currentStepIndex + 1].id}`;
  const nextRoute = `/matter/${matter.matterId}${nextStageName}${nextStepId}`;

  return {
    isCompletedByMe,
    isCompletedByAnyone,
    isNotEditable,
    isNotCompletedByMeOrSubmittedOrFinalised: !isCompletedByMe && !isNotEditable,
    nextRoute,
    currentStep,
    isSignedByAnyone,
  };
};

const englishIntegers = [
  '',
  'one',
  'two',
  'three',
  'four',
  'five',
  'six',
  'seven',
  'eight',
  'nine',
  'ten',
  'eleven',
  'twelve',
  'thirteen',
  'fourteen',
  'fifteen',
  'sixteen',
  'seventeen',
  'eighteen',
  'nineteen',
];

export function numToWords(n) {
  return englishIntegers[n];
}

export const feedbackScript = (user, matterId, senderRef, matterStage, lender, broker, originator) => {
  if (!matterStage || user.userType !== 1) return null;
  return `
    !function(e,t,r,n,a){if(!e[a]){for(var i=e[a]=[],c=0;c<r.length;c++){var s=r[c];i[s]=i[s]||function(e){return function(){var t=Array.prototype.slice.call(arguments);i.push([e,t])}}(s)}i.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+n+"/"+a+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],"YYHjBhxPwK4coy1c","delighted");

    delighted.survey({
      email: "${user.email}",
      name: "${user.firstName} ${user.lastName}",
      properties: {
        senderRef: "${senderRef}",
        matterId: "${matterId}",
        matterStage: "${matterStage}",
        lender: "${lender}",
        broker: "${broker || 'None'}",
        originator: "${originator || 'None'}"
      },
      initialDelay: 0,      
    });
  `;
};

export const validateField = (fieldName, allRules) => {
  const { getValue, rules } = allRules[fieldName];
  let errorMessage = null;
  Object.keys(rules).find((validatorName) => {
    errorMessage = validators[validatorName](rules[validatorName])(getValue());
    return typeof errorMessage === 'string';
  });
  return errorMessage;
};

export const validateForm = (validationRules) => {
  const validationErrors = {};
  Object.keys(validationRules).forEach((fieldName) => { validationErrors[fieldName] = validateField(fieldName, validationRules); });
  return validationErrors;
};

export const httpRequest = async (url, method, payload, headers, origin = '') => {
  const config = {
    method,
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  if (method.toLowerCase() === 'post' && payload) {
    config.body = JSON.stringify(payload);
  }
  if (headers && typeof headers === 'object' && Object.keys(headers).length > 0) {
    config.headers = { ...config.headers, ...headers };
  }

  const baseUrl = origin || window.location.origin;

  return fetch(`${baseUrl}${url}`, config)
    .then(async (response) => {
      if (response.ok) {
        if (response.headers.get('Content-Type').indexOf('application/json') > -1) {
          return response.json().then((json) => {
            if (json.succeeded) return json.result;
            const error = json.errors.length > 0 ? json.errors[0] : 'Data access error';
            return Promise.reject(error);
          });
        }
        return response.blob();
      }
      return Promise.reject(getFriendlyErrorMessage(response.status));
    });
};

export const downloadFile = (blob, fileName) => {
  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(new Blob([blob]));
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const doDownload = (progress, setDownloading, sectionType, docId, authToken, voiFile = false) => {
  setDownloading(true);
  let fileEndpoint = (progress.availableForDownload) ? 'finalisedFile' : 'file';
  if (voiFile) fileEndpoint = 'voi';
  httpRequest(`/api/${sectionType}documents/${docId}/${fileEndpoint}`, 'get', null, { Authorization: `Bearer ${authToken}` })
    .then((blob) => {
      const fileName = voiFile ? 'VOI Certificate.pdf' : (progress.fileName || `${progress.name}.pdf`);
      downloadFile(blob, fileName);
    })
    .catch(() => {
      throw new Error(`Unable to download file for document (${docId}).`);
    })
    .finally(() => {
      setDownloading(false);
    });
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / (k ** i)).toFixed(dm))} ${sizes[i]}`;
};

export function useWindowSize() {
  const isClient = typeof window === 'object';

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : 0,
      height: isClient ? window.innerHeight : 0,
    }),
    [isClient],
  );

  const [windowSize, setWindowSize] = useState(getSize);

  useEffect(() => {
    let debounceTimeoutId = null;
    if (!isClient) return false;

    const handleResize = () => {
      clearTimeout(debounceTimeoutId);
      debounceTimeoutId = setTimeout(() => {
        setWindowSize(getSize());
      }, 100);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [getSize, isClient]); // Empty array ensures that effect is only run on mount and unmount

  return windowSize;
}

export const base64EncodeUnicode = (str) => btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) => String.fromCharCode(`0x${p1}`)));

const countAllCompleted = (progress, progressField) => progress.filter((p) => (p[progressField] === PROGRESS.COMPLETED || p[progressField] === PROGRESS.SUBMITTED)).length;

export const calcNextDocPath = (progress, progressStage) => {
  const readDoc = progress.readDocuments.find(progressStage);
  if (readDoc) return `read/${readDoc.id}`;
  const signDoc = progress.signDocuments.find(progressStage);
  if (signDoc) return `sign/${signDoc.id}`;
  const uploadDoc = progress.uploadDocuments.find(progressStage);
  if (uploadDoc) return `upload/${uploadDoc.id}`;
  return null;
};

export const objectToKeyValue = (obj) => {
  const keyValueArray = [];
  Object.entries(obj).forEach(([key, value]) => {
    keyValueArray.push({ key, value });
  });
  return keyValueArray;
};

const calcNotStarted = (readCompleted, signDocuments, uploadDocuments, userId) => {
  // Not started if user has read any documents
  if (readCompleted > 0) return false;
  // Not started if user has signed any documents
  if (signDocuments.some((p) => (
    (p.myProgress === PROGRESS.SUBMITTED)
    || ((p.myProgress === PROGRESS.COMPLETED) && !p.requiresPrinting)
    || ((p.myProgress === PROGRESS.COMPLETED) && p.requiresPrinting && p.lastCompletedBy === userId)
  ))) return false;
  // Not started if user has completed any Upload documents.
  if (uploadDocuments.some((p) => (
    (p.myProgress === PROGRESS.SUBMITTED)
    || (p.myProgress === PROGRESS.COMPLETED && p.lastCompletedBy === userId)
  ))) return false;
  return true;
};

const locked = (progress) => progress.length === 0 || progress.every((d) => d.myProgress === PROGRESS.LOCKED);
const allSubmitted = (progress) => progress.length === 0 || progress.every((d) => d.myProgress === PROGRESS.SUBMITTED);

export const calcProgress = (progress, isMe, userId) => {
  const { readDocuments, signDocuments, uploadDocuments } = progress;
  const progressField = isMe ? 'myProgress' : 'theirProgress';
  const readCompleted = countAllCompleted(readDocuments, progressField);
  const signCompleted = countAllCompleted(signDocuments, progressField);
  const mandatoryUploadDocuments = uploadDocuments.filter((doc) => !doc.optional);
  const uploadCompleted = countAllCompleted(mandatoryUploadDocuments, progressField);
  const completed = readCompleted + signCompleted + uploadCompleted;
  const notStarted = isMe ? calcNotStarted(readCompleted, signDocuments, mandatoryUploadDocuments, userId) : null;
  const total = readDocuments.length + signDocuments.length + mandatoryUploadDocuments.length;
  const percentage = total === 0 ? 0 : Math.round((100 / total) * completed);
  const nextDocPath = isMe ? calcNextDocPath(progress, (p) => p.myProgress !== PROGRESS.COMPLETED) : null;
  const rejectedDocPath = isMe && percentage > 0 ? calcNextDocPath(progress, (p) => p.errors && p.errors.length) : null;
  const editedBySomeoneElseDocPath = isMe ? calcNextDocPath(progress, (p) => p.myProgress === PROGRESS.WARN) : null;
  const finalised = locked(readDocuments) && locked(signDocuments) && locked(mandatoryUploadDocuments);
  const submitted = isMe && allSubmitted(readDocuments) && allSubmitted(signDocuments) && allSubmitted(mandatoryUploadDocuments);
  return {
    percentage,
    completed,
    notStarted,
    total,
    nextDocPath,
    rejectedDocPath,
    editedBySomeoneElseDocPath,
    submitted,
    finalised,
  };
};

export const MATTER_STAGES = {
  REJECTED_DOCS: 'rejected',
  CHANGED_DOCS: 'changed',
  COMPLETE: 'complete',
  STARTED: 'started',
  SUBMIT_DOCS: 'submit',
  SUBMITTED_DOCS: 'submitted',
  NOT_STARTED: 'notStarted',
  FINALISED: 'finalised',
};

export const getSummaryStage = (progress, userId) => {
  const myProgress = calcProgress(progress, true, userId);
  const theirProgress = calcProgress(progress, false, userId);
  if (myProgress.finalised) return { stage: MATTER_STAGES.FINALISED, myProgress, theirProgress };
  if (myProgress.notStarted) return { stage: MATTER_STAGES.NOT_STARTED, myProgress, theirProgress };
  const isSubmitted = myProgress.submitted;
  if (isSubmitted) return { stage: MATTER_STAGES.SUBMITTED_DOCS, myProgress, theirProgress };
  if (myProgress.rejectedDocPath) {
    return {
      stage: MATTER_STAGES.REJECTED_DOCS,
      myProgress,
      theirProgress,
      rejectedDocPath: myProgress.rejectedDocPath,
    };
  }
  if (myProgress.editedBySomeoneElseDocPath) {
    return {
      stage: MATTER_STAGES.CHANGED_DOCS,
      myProgress,
      theirProgress,
      editedBySomeoneElseDocPath: myProgress.editedBySomeoneElseDocPath,
    };
  }
  if (myProgress.percentage < 100) return { stage: MATTER_STAGES.STARTED, myProgress, theirProgress };
  if (theirProgress.percentage === 100 && progress.signDocuments.every((sd) => sd.availableForDownload)) return { stage: MATTER_STAGES.SUBMIT_DOCS, myProgress, theirProgress };
  return { stage: MATTER_STAGES.COMPLETE, myProgress, theirProgress };
};

export const isValidDate = (date) => date instanceof Date
  && !Number.isNaN(Number.parseInt(date?.getTime()?.toString(), 10));

export const humanReadableFormat = (date, options) => {
  if (!isValidDate(date)) return 'Invalid date';

  const SECONDS = {
    IN_A_YEAR: 31536000,
    IN_A_MONTH: 2592000,
    IN_A_DAY: 86400,
    IN_AN_HOUR: 3600,
    IN_A_MINUTE: 60,
  };

  const {
    suffix = '', prefix = '', depth = 1, offset = { seconds: 60, label: 'Just now' },
  } = options;

  let remainingSeconds = Math.floor((+new Date() - +date) / 1000);

  const years = Math.floor(remainingSeconds / SECONDS.IN_A_YEAR);
  remainingSeconds -= years * SECONDS.IN_A_YEAR;

  const months = Math.floor(remainingSeconds / SECONDS.IN_A_MONTH);
  remainingSeconds -= months * SECONDS.IN_A_MONTH;

  const days = Math.floor(remainingSeconds / SECONDS.IN_A_DAY);
  remainingSeconds -= days * SECONDS.IN_A_DAY;

  const hours = Math.floor(remainingSeconds / SECONDS.IN_AN_HOUR);
  remainingSeconds -= hours * SECONDS.IN_AN_HOUR;

  const minutes = Math.floor(remainingSeconds / SECONDS.IN_A_MINUTE);
  remainingSeconds -= minutes * SECONDS.IN_A_MINUTE;

  if (offset.seconds) {
    const dateWeight = years * SECONDS.IN_A_YEAR + months * SECONDS.IN_A_MONTH + days * SECONDS.IN_A_DAY + minutes * SECONDS.IN_A_MINUTE;
    if (dateWeight <= offset.seconds) return offset.label;
  }

  const segments = {
    year: years,
    month: months,
    day: days,
    hour: hours,
    minute: minutes,
  };

  const humanFormat = Object.entries(segments)
    .filter(([, value]) => value)
    .slice(0, depth)
    .reduce((acc, [key, value]) => {
      const formatPlural = (singularStr, number) => (number && number > 1 ? `${singularStr}s` : `${singularStr}`);
      const text = `${acc} ${value} ${formatPlural(key, value)}`;
      return text.trim();
    }, '');

  return `${prefix} ${humanFormat} ${suffix}`.trim();
};
