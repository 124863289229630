import React from 'react';
import {
  PageLoading, Box, H3, H4, Select, Icon,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import TitleContainer from 'components/TitleContainer';
import Container from 'components/Container';
import Layout from 'components/Layout';
import { useMatterMaker } from 'state/matterMaker/MatterMakerProvider';
import UserType from 'enums/UserType';
import styled from 'styled-components/macro';
import { BorrowerSelect, BorrowersDisplay } from './components/Borrowers';
import { GuarantorSelect, GuarantorsDisplay } from './components/Guarantors';
import OptionalSettings from './components/OptionalSettings';
import Submit from './components/SubmitBtn';
import SubmitPopup from './components/SubmitPopup';
import AdminSelect from '../components/AdminSelect';

const SelectContainer = styled.div`
  position: relative;
`;

const CreateMatter = () => {
  const { user, authToken } = useAuth();
  const { state, actions } = useMatterMaker();
  const isAdmin = user.userType === UserType.Admin;

  const {
    howManyMatters: howManyOptions,
    enabledLenders: lenderOptions,
    jurisdictions: jurisdictionOptions,
    securities: securityOptions,
    tenants: tenantOptions,
  } = state?.settings?.options;
  const {
    howManyMatters, lenderId, securities, numberOfSecurities,
  } = state?.createMatter;

  const howManyOnChange = (value) => {
    if (!value || value === howManyMatters) return;
    actions.updatePayload({ howManyMatters: value });
  };

  const lenderOnChange = (value) => {
    if (!value || value === lenderId) return;
    actions.updatePayload({ lenderId: value });
  };

  const securitiesOnChange = (value) => {
    if (!value || value === numberOfSecurities) return;
    actions.updatePayload({ numberOfSecurities: value });
  };

  const jurisdictionOnChange = (value, index) => {
    if (!value || value === securities[index]) return;
    securities[index] = value;
    actions.updatePayload({ securities });
  };

  const handleTenantChange = (tenantId) => {
    if (!tenantId) return;
    actions.updatePayload({ tenantId });
  };

  if (!authToken) return <PageLoading />;

  return (
    <Layout>
      <Container mt={[6, 7, 8]} center>
        <Box>
          <TitleContainer title="Matter maker">
            <TitleContainer.WelcomeMessage>
              <H3>
                Borrower / Guarantor mobile numbers must NOT match
              </H3>
            </TitleContainer.WelcomeMessage>
          </TitleContainer>
          {isAdmin && (
          <AdminSelect
            title="Tenant"
            options={tenantOptions}
            onChange={handleTenantChange}
          />
          )}
          <AdminSelect
            title="How Many Matters?"
            options={howManyOptions}
            onChange={howManyOnChange}
          />
          <BorrowerSelect />
          <BorrowersDisplay />
          <GuarantorSelect />
          <GuarantorsDisplay />
          <AdminSelect
            title="Lender"
            options={lenderOptions}
            onChange={lenderOnChange}
          />
          <AdminSelect
            title="How many Securities?"
            options={securityOptions}
            onChange={securitiesOnChange}
          />

          {[...Array(numberOfSecurities)].map((x, i) => {
            const key = i;
            return (

              <SelectContainer>
                <H4 mt="5" mb="6">
                  {`Jurisdiction of property ${key + 1}`}
                </H4>
                <Select
                  defaultOption={jurisdictionOptions[0]}
                  enableSearch
                  options={jurisdictionOptions}
                  onChange={(option) => jurisdictionOnChange(option.value || null, i)}
                  rightIcon={<Icon color="base40" name="NavDown" size="14px" />}
                  type="default"
                />
              </SelectContainer>
            );
          })}
          <OptionalSettings />
          <Submit />
          <SubmitPopup />
        </Box>
      </Container>
    </Layout>
  );
};

export default CreateMatter;
