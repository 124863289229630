import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { PageLoading } from '@galilee/lilee';
import { fetchUploadData } from 'actions/Upload';
import { useAuth } from 'state/AuthProvider';
import UploadProvider from 'state/UploadProvider';
import { useApplication } from 'state/ApplicationProvider';

const Upload = ({ children }) => {
  const { user, authToken } = useAuth();
  const { dispatch } = useApplication();
  const { id: docId } = useParams();
  const [data, setData] = useState(null);

  useEffect(() => {
    async function fetchData() {
      if (dispatch && docId && authToken) {
        const uploadData = await fetchUploadData(dispatch, docId, authToken);
        setData({ ...uploadData, docId });
      }
    }
    if (data && data.docId === docId) return;
    setData(null);
    fetchData();
  }, [authToken, data, dispatch, docId]);

  if (!authToken || !user || !data) return <PageLoading />;

  return (
    <UploadProvider data={data} authToken={authToken} applicationDispatch={dispatch}>
      {children}
    </UploadProvider>
  );
};

Upload.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Upload;
