import React, { useState } from 'react';
import {
  Box, H4, Input, Button, Modal, Listing, Row, Icon,
} from '@galilee/lilee';
import Layout from 'components/Layout';
import TitleContainer from 'components/TitleContainer';
import styled from 'styled-components/macro';
import { useCollaborate } from 'state/collaborate/CollaborateProvider';
import { OuterContainer, ShadowContainer } from '../components/Containers';

const List = styled(Box)`
  & > hr  {
    clear: both;
    visibility: hidden;
  }
`;

const Collaborate = () => {
  const { state, actions } = useCollaborate();
  const {
    collaborators, collaboratingWithMe, user, loading,
  } = state;

  const {
    email, firstName, lastName, id, alert,
  } = user;
  const validName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  const validEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const readyToSubmit = validEmail.exec(email) && validName.exec(firstName) && validName.exec(lastName);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pageState, setPageState] = useState({
    addUser: false,
    removeUser: false,
  });
  const { addNewUser, removeUser } = pageState;

  const closeModal = () => {
    setIsModalOpen(false);
    setPageState({
      ...pageState, addUser: false, removeUser: false,
    });
    state.clearUser();
  };

  const onAddUser = () => {
    if (!readyToSubmit) return;
    actions.addCollaborator(state.user);
    closeModal();
  };

  const onRemoveUser = () => {
    if (!id) return;
    actions.removeCollaborator(id);
    closeModal();
  };

  const onAlertUser = () => {
    if (!id) return;
    actions.alertCollaborator(id, !alert);
  };

  const openAddUser = () => {
    setPageState({ ...pageState, addNewUser: true });
    setIsModalOpen(true);
  };

  const openEditUser = (details) => {
    state.setUser(details);
    setPageState({ ...pageState, addNewUser: false });
    setIsModalOpen(true);
  };

  const onUserChange = (value, type) => {
    if (value === state.user[type]) return;
    state.setUser({ [type]: value });
  };

  return (
    <OuterContainer>
      <Layout>
        <ShadowContainer>
          <TitleContainer title="Share Documents" sectionTitle="Settings" mt={7}>
            <TitleContainer.WelcomeMessage>
              Give users access to your documents.
              Adding a user to this list will give them broker permissions over your documents.
            </TitleContainer.WelcomeMessage>
          </TitleContainer>

          <List mx={[1, 5, 6, 7]} mb={[6, 7]}>
            <H4 mb={[6, 7]}>Add or remove users</H4>
            <Listing.Card
              borderColor="base10"
              border="2px solid #E6E9EB"
              borderRadius="5px"
              onClick={() => openAddUser()}
              style={{ display: 'block' }}
            >
              <Listing.Card.Title>
                <Row ml={[0, null, 5]} alignItems="center">
                  <Icon iconSize="sm" name="AddCircle" color="#395060" />
                  <H4 ml={7} color="#072438">Add New User</H4>
                </Row>
              </Listing.Card.Title>
            </Listing.Card>

            { !loading ? (
              <>
                {Object.keys(collaborators).map(((key) => {
                  const {
                    id: collaboratorId,
                    firstName: collaboratorFirstName,
                    lastName: collaboratorLastName,
                    email: collaboratorEmail,
                    alert: collaboratorAlert,
                  } = collaborators[key];

                  return (
                    <Listing.Card
                      borderColor="base10"
                      border="2px solid #E6E9EB"
                      borderRadius="5px"
                      key={key}
                      onClick={() => openEditUser({
                        id: collaboratorId, firstName: collaboratorFirstName, lastName: collaboratorLastName, email: collaboratorEmail, alert: collaboratorAlert,
                      })}
                      backgroundColor="#F3F4F5"
                      style={{ gridTemplateColumns: 'auto 2fr 2fr' }}
                    >
                      <Listing.Card.Title>
                        <Row alignItems="center">
                          <Icon iconSize="sm" name="UserCircle" color="#395060" />
                          <H4 ml={7} color="#072438">
                            {collaboratorFirstName}
                            {' '}
                            {collaboratorLastName}
                          </H4>
                        </Row>
                      </Listing.Card.Title>
                      <Listing.Card.Status style={{ wordWrap: 'anywhere', textAlign: 'left', justifyContent: 'space-between' }}>
                        {collaboratorEmail}
                      </Listing.Card.Status>
                    </Listing.Card>
                  );
                }))}
                {Object.keys(collaboratingWithMe).length > 0 && <H4 mt={[6, 7]} mb={[6, 7]}>Brokers sharing with you</H4>}
                {Object.keys(collaboratingWithMe).map(((key) => {
                  const {
                    firstName: collaboratorFirstName,
                    lastName: collaboratorLastName,
                    email: collaboratorEmail,
                  } = collaboratingWithMe[key];

                  return (
                    <Listing.Card
                      borderColor="base10"
                      border="2px solid #E6E9EB"
                      borderRadius="5px"
                      key={key}
                      backgroundColor="#F3F4F5"
                      style={{ gridTemplateColumns: 'auto 2fr 2fr' }}
                    >
                      <Listing.Card.Title>
                        <Row alignItems="center">
                          <Icon iconSize="sm" name="UserCircle" color="#395060" />
                          <H4 ml={7} color="#072438">
                            {collaboratorFirstName}
                            {' '}
                            {collaboratorLastName}
                          </H4>
                        </Row>
                      </Listing.Card.Title>
                      <Listing.Card.Status style={{ wordWrap: 'anywhere', textAlign: 'left', justifyContent: 'space-between' }}>
                        {collaboratorEmail}
                      </Listing.Card.Status>
                    </Listing.Card>
                  );
                }))}
              </>
            ) : null}
          </List>

          { isModalOpen && (
          <Modal>
            <Modal.Close onClose={() => closeModal()} />
            <ShadowContainer>
              {addNewUser ? (
                <>
                  <TitleContainer title="Add a user" sectionTitle="Users" mt={7}>
                    <TitleContainer.WelcomeMessage>
                      Please complete all fields to add a new user.
                    </TitleContainer.WelcomeMessage>
                  </TitleContainer>
                  <Box mx={[1, 5, 6, 7]}>
                    <Box mb={[6, 7]}>
                      <H4 color="#072438">First Name</H4>
                      <Input mt={[4, 4, 4]} id="firstName" name="firstName" onChange={(val) => onUserChange(val.target.value, 'firstName')} />
                    </Box>
                    <Box mb={[6, 7]}>
                      <H4 color="#072438">Last name</H4>
                      <Input mt={[4, 4, 4]} id="lastName" name="lastName" onChange={(val) => onUserChange(val.target.value, 'lastName')} />
                    </Box>
                    <Box mb={[6, 7]}>
                      <H4 mb={[2]} color="#072438">Email</H4>
                      <Input mt={[4, 4, 4]} id="email" name="email" onChange={(val) => onUserChange(val.target.value, 'email')} />
                    </Box>
                    <Button mt={[4, 4, 4]} color={readyToSubmit ? 'secondary' : 'base'} onClick={() => onAddUser()} disabled={!readyToSubmit}>
                      Add User
                    </Button>
                  </Box>
                </>
              ) : (
                <>
                  <TitleContainer title="User details" mt={7} />
                  <Box mx={[1, 5, 6, 7]}>
                    <Box mb={[6, 7]}>
                      <H4 color="#072438">First Name</H4>
                      <Input mt={[4, 4, 4]} id="firstName" name="firstName" disabled value={firstName ?? ''} />
                    </Box>
                    <Box mb={[6, 7]}>
                      <H4 color="#072438">Last name</H4>
                      <Input mt={[4, 4, 4]} id="lastName" name="lastName" disabled value={lastName ?? ''} />
                    </Box>
                    <Box mb={[6, 7]}>
                      <H4 mb={[2]} color="#072438">Email</H4>
                      <Input mt={[4, 4, 4]} id="email" name="email" disabled value={email ?? ''} />
                    </Box>
                    <Row>
                      <Button
                        mt={[4, 4, 4]}
                        color={alert ? 'secondary' : 'base'}
                        onClick={() => (onAlertUser())}
                        leftIcon={alert ? <Icon iconSize="sm" name="CheckedBox" color="#395060" /> : <Icon iconSize="sm" name="EmptyBox" color="#395060" />}
                      >
                        Notifications
                      </Button>

                      <Button
                        ml="5"
                        mt={[4, 4, 4]}
                        color={removeUser ? 'primary' : 'secondary'}
                        onClick={() => (removeUser ? onRemoveUser() : setPageState({ ...pageState, removeUser: true }))}
                      >
                        {removeUser ? 'Are You Sure?' : 'Remove'}
                      </Button>
                    </Row>
                  </Box>
                </>
              ) }
            </ShadowContainer>
          </Modal>
          )}
        </ShadowContainer>
      </Layout>
    </OuterContainer>
  );
};

export default Collaborate;
