import React from 'react';
import PropTypes from 'prop-types';
import {
  H6, Text, Flex, H2, H3,
} from '@galilee/lilee';

const DidYouKnow = ({ summary, title, body }) => (
  <Flex flexDirection="column" pl={[4, 5, 6, 7]} pr={[4, 5, 6, 7]} mt="8">
    <H6 color="secondary" mb="4">Did you know...</H6>
    <H2 fontSize="32px" lineHeight="compact" color="base20">{title}</H2>
    <Text color="base40" mb="6" fontSize="2">{body}</Text>
    <H3 fontSize="18px" color="base20">{summary}</H3>
  </Flex>
);

DidYouKnow.propTypes = {
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  summary: PropTypes.string.isRequired,
};

export default DidYouKnow;
