import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Flex, Image, Row, Column, H2, P, mediaQueries,
} from '@galilee/lilee';
import Container from './Container';

const { lessThan } = mediaQueries;

const ErrorContainer = styled(Flex)`
  height: 100%;
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.base};
  padding: ${(p) => p.theme.space[4]};
`;

const TextColumn = styled(Column)`
  flex-direction: column;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.base};

  ${H2} {
    color: white;
    margin-bottom: ${(p) => p.theme.space[6]};
  }

  ${P} {
    color: white;
    max-width: 510px;
  }

  ${lessThan[1]`
    align-items: center;
    margin-top: ${(p) => p.theme.space[9]};

    ${P} {
      text-align: center;
    }

    ${H2} {
    text-align: center;
  `}
`;

const ImageColumn = styled(Column)`
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${(p) => p.theme.colors.base};

  ${P} {
    color: white;
  }
`;

const ErrorPage = ({ image, title, children }) => (
  <ErrorContainer>
    <Container center>
      <Row collapseIndex="1">
        <ImageColumn colspan="1">
          <Image src={image} width="215px" />
        </ImageColumn>
        <TextColumn colspan="2">
          <H2>{title}</H2>
          <Flex flexDirection="column" justifyContent="center">
            {children}
          </Flex>
        </TextColumn>
      </Row>
    </Container>
  </ErrorContainer>
);

ErrorPage.propTypes = {
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default ErrorPage;
