import { NavLink } from 'react-router-dom';
import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components/macro';
import {
  layout,
  fontSize,
  fontFamily,
  fontWeight,
  textAlign,
  lineHeight,
  letterSpacing,
  space,
  alignItems,
} from 'styled-system';

const StyledLink = styled(NavLink)`
  display: inline-block;
  color: ${(p) => themeGet(`colors.${p.color}`)(p)};
  text-decoration-color: ${(p) => themeGet(`colors.${p.color}`)(p)};
  cursor: pointer;
  &:hover {
    color: ${(p) => themeGet(`colors.${p.color}Dark`)(p)};
  }
  &:active, &:visited {
    color: ${(p) => themeGet(`colors.${p.color}`)(p)};
  }
  ${layout}
  ${fontSize}
  ${fontFamily}
  ${fontWeight}
  ${textAlign}
  ${lineHeight}
  ${letterSpacing}
  ${space}
  ${alignItems}
`;

StyledLink.propTypes = {
  ...layout.propTypes,
  ...fontSize.propTypes,
  ...fontFamily.propTypes,
  ...fontWeight.propTypes,
  ...textAlign.propTypes,
  ...lineHeight.propTypes,
  ...letterSpacing.propTypes,
};

export default StyledLink;
