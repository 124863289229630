import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Box, Flex, Icon } from '@galilee/lilee';

const CircleWrapper = styled(Flex)`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  display: inline-flex;
  border-radius: 24px;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;
  background-color: ${(p) => (p.isMe ? p.theme.colors.primary40 : p.theme.colors.white)};
`;

const IconWrapper = styled(Box)`
  justify-content: center;
  align-items: center;
  width: 36px;
  height: 36px;
  display: inline-flex;
  border-radius: 24px;
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;
  background-color: ${(p) => (p.isMe ? p.theme.colors.white : p.theme.colors.primary40)};
`;

const PersonIcon = styled(Icon)`
  color: ${(p) => (p.isMe ? p.theme.colors.primary : p.theme.colors.white)};
  width: 16px;
  height: 16px;
`;

const Active = styled(Box)`
  position: absolute;
  top: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  background-color: ${(p) => (p.active ? p.theme.colors.secondary : p.theme.colors.white)};
  border: 1px solid white;
`;

const UserIcon = ({
  isMe, active, ...rest
}) => (
  <CircleWrapper isMe={isMe} {...rest}>
    <Active active={active} />
    <IconWrapper size="16px" isMe={isMe} name="User">
      <PersonIcon isMe={isMe} name="User" />
    </IconWrapper>
  </CircleWrapper>
);

UserIcon.propTypes = {
  isMe: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
};

export default UserIcon;
