import React from 'react';
import {
  H4, Input, Box, H1,
} from '@galilee/lilee';
import { useUpdateBorrower } from 'state/updateBorrower/UpdateBorrowerProvider';
import UserType from 'enums/UserType';

const DisplayBorrower = () => {
  const { state } = useUpdateBorrower();
  const {
    id, firstName, middleName, lastName, email, mobile, phoneNumber, userType,
  } = state?.user;

  const newValueOnChange = (value, attribute) => {
    if (value === state.user[attribute]) return;
    state.setBorrower({ [attribute]: value });
  };

  const inputs = [
    {
      id: 1,
      title: 'First name',
      label: 'firstName',
      value: firstName,
    },
    {
      id: 2,
      title: 'Middle name',
      label: 'middleName',
      value: middleName,
    },
    {
      id: 3,
      title: 'Last name',
      label: 'lastName',
      value: lastName,
    },
    {
      id: 4,
      title: 'Email',
      label: 'email',
      value: email,
    },
    {
      id: 5,
      title: 'Mobile',
      label: 'phoneNumber',
      value: phoneNumber || mobile.number,
    },
  ];

  return id && (
  <>
    <H1>
      {userType === UserType.Broker ? 'Broker' : 'Borrower'}
    </H1>
    {inputs.map((input) => (
      <Box key={input.id}>
        <H4 mb="5" mt="5">
          {input.title}
        </H4>
        <Input
          maxWidth="100%"
          id={input.label}
          name={input.label}
          type="text"
          value={input.value ?? ''}
          onChange={(val) => newValueOnChange(val.target.value, input.label)}
        />
      </Box>
    ))}
  </>
  );
};

export default DisplayBorrower;
