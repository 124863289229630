import React from 'react';
import {
  Box, H4, Input, Button,
} from '@galilee/lilee';
import Layout from 'components/Layout';
import TitleContainer from 'components/TitleContainer';
import { useBrokerProfile } from 'state/brokerprofile/BrokerProfileProvider';
import { OuterContainer, ShadowContainer } from '../components/Containers';

const BrokerProfile = () => {
  const { state, actions } = useBrokerProfile();
  const { user, loading } = state;
  const {
    firstName, lastName,
  } = user;
  const validName = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;
  const readyToSubmit = validName.exec(firstName) && validName.exec(lastName);

  const renameUser = () => {
    if (!readyToSubmit) return;
    actions.renameBroker(state.user);
  };

  const userOnChange = (value, type) => {
    if (value === state.user[type]) return;
    actions.setUser({ [type]: value });
  };

  return (
    <OuterContainer>
      <Layout>
        <ShadowContainer>
          <TitleContainer title="Profile" sectionTitle="Settings" mt={7}>
            <TitleContainer.WelcomeMessage />
          </TitleContainer>
          <Box mx={[1, 5, 6, 7]}>
            <H4 mb={[6, 7]}>
              Edit profile details
            </H4>
            <>
              <Box mb={[6, 7]}>
                <H4 color="#072438">First Name</H4>
                <Input disabled={loading} mt={[4, 4, 4]} id="firstName" name="firstName" onChange={(val) => userOnChange(val.target.value, 'firstName')} value={firstName ?? ''} />
              </Box>
              <Box mb={[6, 7]}>
                <H4 color="#072438">Last name</H4>
                <Input disabled={loading} mt={[4, 4, 4]} id="lastName" name="lastName" onChange={(val) => userOnChange(val.target.value, 'lastName')} value={lastName ?? ''} />
              </Box>
              <Button mt={[4, 4, 4]} color={readyToSubmit ? 'secondary' : 'base'} onClick={() => renameUser()} disabled={!readyToSubmit}>
                Save
              </Button>
            </>
          </Box>
        </ShadowContainer>
      </Layout>
    </OuterContainer>
  );
};

export default BrokerProfile;
