import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Button } from '@galilee/lilee';

const LinkButtonWrapper = styled(Link)`
  text-decoration: none;
  width: ${(p) => p.width};
  max-width: ${(p) => p.maxWidth};
`;

const LinkButton = ({
  to, children, color, ...rest
}) => (
  <LinkButtonWrapper to={to} width={rest.width} maxWidth={rest.maxWidth}>
    <Button noBorder color={color} {...rest}>
      {children}
    </Button>
  </LinkButtonWrapper>
);

LinkButton.defaultProps = {
  children: null,
  color: 'link',
  width: 'initial',
  maxWidth: 'initial',
};

LinkButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  to: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
  color: PropTypes.string,
  width: PropTypes.string,
  maxWidth: PropTypes.string,
};

export default LinkButton;
