import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import App from 'App';
import RootProvider from 'state/RootProvider';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import ImagePreloader from 'components/ImagePreloader';

function registerInsights(key) {
  if (key && key !== 'NA') {
    const appInsights = new ApplicationInsights({ config: { instrumentationKey: key } });
    appInsights.loadAppInsights();
    appInsights.trackPageView();
  }
}

async function fetchConfig() {
  const response = await fetch('api/config');
  const config = await response.json();
  if (config.result) {
    localStorage.setItem('kwil_config', JSON.stringify(config.result));
    registerInsights(config.result.applicationInsightsKey);
  }
}

const kwilConfig = localStorage.getItem('kwil_config');

if (kwilConfig) {
  const config = JSON.parse(kwilConfig);
  registerInsights(config.applicationInsightsKey);
} else {
  fetchConfig();
}

ReactDOM.render(
  <RootProvider>
    <ImagePreloader />
    <App />
  </RootProvider>,
  document.getElementById('root'),
);
