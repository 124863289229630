import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { P, PageLoading, utils } from '@galilee/lilee';
import { useMatter } from 'state/MatterProvider';
import SectionSummary from 'components/SectionSummary';

const { useScrollToTop } = utils;

const ReadSummary = ({ match }) => {
  const { state } = useMatter();
  useScrollToTop(true);

  if (!state.matterProgress) return <PageLoading />;
  return (
    <SectionSummary videoId="390661391" sectionType="upload" match={match} title="Upload Documents" progress={state.matterProgress.uploadDocuments}>
      <P fontSize={2} color="white">
        You are almost done, we just need one or two other documents for settlement.
      </P>
      <P fontSize={2} color="white">
        Please follow the prompts to upload the documents we have requested.
      </P>
      <P fontSize={2} color="white">
        Be sure to take note of any guidelines or special requirements listed for these documents, if they do not meet your&nbsp;
        {state.matter.isSharia ? ' financier\'s ' : ' lender\'s '}
        requirements then settlement may be delayed.
      </P>
      <P fontSize={2} color="white">
        This is the final section so you&apos;re probably a pro by now, but if you&apos;d like a heads up about uploading you can watch the video tutorial.
      </P>
    </SectionSummary>
  );
};

ReadSummary.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default ReadSummary;
