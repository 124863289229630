import React, { useState } from 'react';
import { Icon, Input, Flex } from '@galilee/lilee';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';

const Wrapper = styled(Flex)`
  flex-direction: row;
  align-items: center;
  border-bottom: 1px solid ${(p) => p.theme.colors.base20};
  padding: 0 ${(p) => p.theme.space[4]};
`;

const StyledInput = styled(Input)`
  background: transparent;
  border: none;
  box-shadow: none;
  margin-right: ${(p) => p.theme.space[4]};
  color: white;
  &:focus {
      outline: 0;
      border: none;
      border-color: transparent;
      box-shadow: none;
    },
`;

export default function CallbackSearchBar({ onSubmit }) {
  const [search, setSearch] = useState('');
  return (
    <Wrapper>
      <StyledInput onChange={(e) => setSearch(e.target.value)} />
      <Icon
        name="Search"
        size="16px"
        color="white"
        cursor="pointer"
        onClick={() => onSubmit(search)}
      />
    </Wrapper>
  );
}

CallbackSearchBar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};
