import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { PageLoading } from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { ApplicationPaths, QueryParameterNames } from './ApiAuthorizationConstants';

const AuthorizeRoute = ({ component: Component, ...rest }) => {
  const { user, ready } = useAuth();
  const authenticated = !!user;
  const redirectUrl = `${ApplicationPaths.Login}?${QueryParameterNames.ReturnUrl}=${encodeURI(window.location.href)}`;
  if (!ready) return <PageLoading />;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (authenticated) {
          return <Component {...props} />;
        }
        return <Redirect to={redirectUrl} />;
      }}
    />
  );
};

AuthorizeRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default AuthorizeRoute;
