import React from 'react';
import PropTypes from 'prop-types';
import { Table, TR, TD } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupTable = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data } = fields[fieldName];
  return (
    <Table striped>
      <tbody>
        {data.map((row) => (
          <TR key={row + row[0]}>
            {row.map((column) => <TD key={column}>{column}</TD>)}
          </TR>
        ))}
      </tbody>
    </Table>
  );
};

MarkupTable.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupTable;
