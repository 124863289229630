/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { pdfjs, Document, Page } from 'react-pdf';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  ActionBar, Button, ConfirmAction, PageButton, Panel, ResponsiveGroup, PageLoading, Modal, P, Show, utils, ZoomButton,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { useApplication } from 'state/ApplicationProvider';
import Container from 'components/Container';
import {
  PDFNavWrapper, PDFNav, PDFNavIcon, PDFPageNumber,
} from 'components/PDFNav';
import { setDocumentReadAction } from 'actions/Matter';
import { getPageMeta } from 'utils';
import PROGRESS_STAGES from 'enums/ProgressStages';
import PdfLoading from 'components/PdfLoading';
import PDFDocumentWrapper from 'components/PDFDocumentWrapper';
import Incomplete from 'images/Incomplete.png';
import DownloadButton from '../components/DownloadButton';

const { useScrollToTop } = utils;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Read = ({ match }) => {
  const docId = Number(match.params.id);
  const { authToken, support } = useAuth();
  const { state, dispatch } = useMatter();
  const history = useHistory();
  const { dispatch: applicationDispatch } = useApplication();
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [pdfPath, setPDFPath] = useState(null);
  const [lastPageHit, setlastPageHit] = useState(false);
  const [pageReminder, setPageReminder] = useState(false);
  const [shownFinalPage, setShownFinalPage] = useState(false);
  const [confirmClicked, setConfirmClicked] = useState(false);
  const [zoomed, setZoomed] = useState(false);
  useScrollToTop(true);

  // Reset everything when react router redirects to the same page with a different docId
  useEffect(() => {
    setShownFinalPage(false);
    setPageCount(0);
    setPageNumber(1);
    setPDFPath(null);
    setlastPageHit(false);
    setPageReminder(false);
  }, [docId]);

  useEffect(() => {
    if (pageNumber && pageCount && pageNumber === pageCount) setlastPageHit(true);
  }, [pageNumber, pageCount, setlastPageHit]);

  useEffect(() => {
    if (!authToken || !docId || pdfPath) return;
    setPDFPath({ url: `${window.location.origin}/api/readdocuments/${docId}/file`, httpHeaders: { Authorization: `Bearer ${authToken}` }, withCredentials: false });
  }, [authToken, docId, pdfPath]);

  useEffect(() => {
    if (lastPageHit) {
      const timer = setTimeout(() => {
        setShownFinalPage(true);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [lastPageHit]);

  if (!state.matter || !state.matterProgress || !authToken || !docId || !pdfPath) return <PageLoading />;

  const {
    isCompletedByMe, isNotEditable, isNotCompletedByMeOrSubmittedOrFinalised, currentStep, nextRoute,
  } = getPageMeta(state.matter, state.matterProgress, PROGRESS_STAGES.READ, docId);

  const AcceptTerms = () => {
    if (support.isTenantSupport) return;
    if (lastPageHit) {
      setConfirmClicked(false);
      setDocumentReadAction(applicationDispatch, dispatch, docId, authToken);
    } else {
      setPageReminder(true);
    }
  };

  const pager = (
    <PageButton
      width="300px"
      actionComplete={isCompletedByMe || lastPageHit}
      totalPages={pageCount}
      currentPage={pageNumber}
      nextPageClick={() => setPageNumber(pageNumber + 1)}
      previousPageClick={() => setPageNumber(pageNumber - 1)}
    />
  );

  const Accept = () => {
    if (isCompletedByMe || isNotEditable) return null;

    if (!isCompletedByMe && (!lastPageHit || !shownFinalPage)) {
      return pager;
    }

    if (lastPageHit && !confirmClicked) {
      return (
        <ConfirmAction
          message="By clicking accept you confirm you have read the document in full."
          onConfirm={() => {
            setConfirmClicked(true);
          }}
        >
          {pager}
        </ConfirmAction>
      );
    }

    if (confirmClicked) {
      return (
        <Button
          width="100%"
          color={lastPageHit && isNotCompletedByMeOrSubmittedOrFinalised ? 'secondary' : 'base'}
          onClick={() => AcceptTerms()}
          disabled={support.isTenantSupport}
        >
          Accept
        </Button>
      );
    }
  };

  return (
    <Container>
      <Container center>
        <Panel mb="0">
          <Panel.Subtitle>Read &amp; Accept</Panel.Subtitle>
          <Panel.Title pb={[0]}>
            {currentStep.name}
            <br />
            <DownloadButton
              progress={state.matterProgress.readDocuments.find((doc) => doc.id === docId)}
              sectionType="read"
              docId={docId}
              authToken={authToken}
              verificationProgress={state.verificationProgress}
            />
          </Panel.Title>
          <Panel.Section userSelect={false} p="0">
            <PDFPageNumber pageNumber={pageNumber} pageCount={pageCount} />
            <PDFDocumentWrapper zoomed={zoomed}>
              <PDFNavWrapper show={pageNumber > 1}>
                <PDFNav leftNav onClick={() => setPageNumber((pageNumber - 1))}><PDFNavIcon name="NavLeft" /></PDFNav>
              </PDFNavWrapper>
              <Document
                loading={<PdfLoading />}
                file={pdfPath}
                onLoadSuccess={({ numPages }) => setPageCount(numPages)}
              >
                <Page
                  width={1034}
                  loading={<PdfLoading />}
                  pageNumber={pageNumber}
                />
              </Document>
              <PDFNavWrapper show={pageCount && pageNumber !== pageCount}>
                <PDFNav pulse={pageNumber === 1} onClick={() => setPageNumber((pageNumber + 1))}><PDFNavIcon name="NavRight" /></PDFNav>
              </PDFNavWrapper>
              <Show breakpoints={[0, 1]}>
                <ZoomButton.Wrapper bottom={zoomed ? '16px' : '80px'}>
                  <ZoomButton zoomed={zoomed} onClick={() => setZoomed(!zoomed)} />
                </ZoomButton.Wrapper>
              </Show>
            </PDFDocumentWrapper>
          </Panel.Section>
        </Panel>
        <ActionBar
          showNext={isCompletedByMe || isNotEditable}
          onNextClick={() => history.push(nextRoute)}
        >
          {Accept()}
        </ActionBar>
        {
          pageReminder && (
            <Modal>
              <Modal.IllustrationBody image={Incomplete}>
                <Modal.Subtitle>Read Document</Modal.Subtitle>
                <Modal.Title lineHeight="compact">You must read all the pages before accepting</Modal.Title>
                <P>You’ve not read all the pages in this document.</P>
                <P>Please go back and click through all the available pages to the end. Then you may accept the document terms.</P>
                <Modal.ActionContainer>
                  <ResponsiveGroup>
                    <Button color="secondary" onClick={() => setPageReminder(false)}>Got it</Button>
                  </ResponsiveGroup>
                </Modal.ActionContainer>
              </Modal.IllustrationBody>
            </Modal>
          )
        }
      </Container>
    </Container>
  );
};

Read.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Read;
