import React from 'react';
import { Route } from 'react-router';
import TopNav from 'components/TopNav';
import ReactRouterPropTypes from 'react-router-prop-types';
import Read from './read';
import ReadSummary from './readSummary';

const ReadRouter = ({ match }) => (
  <>
    <TopNav />
    <Route path={`${match.path}/:id`} component={Read} />
    <Route exact path={match.path} component={ReadSummary} />
  </>
);

ReadRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default ReadRouter;
