import {
  Column, H2, H4, Row, Text,
} from '@galilee/lilee';
import React from 'react';

const WhatIsLiveSign = () => (
  <Column>
    <H4 color="white" lineHeight="1em" mb="6">Powered by livesign - coming soon</H4>
    <Column>
      <Row collapseIndex="0" mb={[7, 7, 0]}>
        <Column mr={[0, 7, 8, 9]}>
          <H2 lineHeight="1em" mb="6" fontSize={['42px', null, null, '48px']}>What is livesign?</H2>
          <Text mb="7" maxWidth="300px" color="base20" fontSize="18px">
            A digital signing platform that biometrically &amp; cryptographically binds the person behind the device at the point of signing, to their government issued photo ID.
          </Text>
        </Column>
        <Column>
          <H2 lineHeight="1em" mb="6" fontSize={['42px', null, null, '48px']}>Who is livesign for?</H2>
          <Text mb="7" maxWidth="300px" color="base20" fontSize="18px">
            Anyone who needs absolute certainty about who is digital signing a document.
          </Text>
        </Column>
      </Row>
      <Row collapseIndex="0" mb="0" alignItems="flex-start">
        <Column mr={[0, 7, 8, 9]}>
          <H2 lineHeight="1em" mb="6" fontSize={['42px', null, null, '48px']}>Why use livesign?</H2>
          <Text mb="7" maxWidth="300px" color="base20" fontSize="18px">
            Increase compliance and reduce fraud, and transition to digital signing without losing the benefits of paper witnessing.
            livesign is the only digital signing platform that conducts an identity check at the exact moment of digital signing.
          </Text>
        </Column>
        <Column>
          <H2 lineHeight="1em" mb="6" fontSize={['42px', null, null, '48px']}>How does livesign work?</H2>
          <Text mb="7" maxWidth="300px" color="base20" fontSize="18px">
            When a signer clicks to sign the digital document, they are prompted to produce their government issued ID.
            The signing process is only allowed to complete if livesign can biometrically &amp; cryptographically match the person behind the device to their photo ID.
            The completed document and a certificate of completion, including the ID check results, is then returned to the user who requested the document signature.
          </Text>
        </Column>
      </Row>
    </Column>
  </Column>
);

export default WhatIsLiveSign;

WhatIsLiveSign.defaultProps = {};

WhatIsLiveSign.propTypes = {};
