import React from 'react';
import PropTypes from 'prop-types';
import { H4 } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupTitle = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data } = fields[fieldName];
  return <H4>{data}</H4>;
};

MarkupTitle.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupTitle;
