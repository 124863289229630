import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { withRouter } from 'react-router-dom';
import {
  Box, Text, H4, utils, mediaQueries, Flex, Icon, Column, H2,
} from '@galilee/lilee';
import UserType from '../enums/UserType';
import PartyType from '../enums/PartyType';

const { toAUD } = utils;
const { lessThan } = mediaQueries;

const Container = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: auto auto; 
  grid-template-areas:
  "MatterRef Borrowers LoanAmount "    
  "Property Lender LenderRef"
  "Broker Tenant";
  ${lessThan[1]`
    grid-template-columns: 1fr;
    grid-auto-rows auto;
  `}
`;

const SummaryBlock = styled(Flex)`
  width: 33%;
  flex-direction: row;
  margin-bottom: 30px;
`;

const MatterSummary = ({ matter, user }) => {
  const borrowers = matter.borrowers.filter((b) => b.borrowerType === PartyType.Borrower || b.borrowerType === PartyType.BorrowerAndGuarantor);
  const guarantors = matter.borrowers.filter((b) => b.borrowerType === PartyType.Guarantor || b.borrowerType === PartyType.BorrowerAndGuarantor);

  return (
    <>
      <H2 mb={[5, 6, 7]}>
        {matter.isSharia ? 'Finance' : 'Loan'}
        &nbsp;Details
      </H2>
      <Container collapseIndex="0" flexWrap="wrap">
        <SummaryBlock gridArea="MatterRef">
          <Icon name="Badge" color="primaryDark" marginRight="20px" />
          <Column>
            <H4>Matter&nbsp;Ref</H4>
            {matter.senderRef}
          </Column>
        </SummaryBlock>
        <SummaryBlock gridArea="Borrowers">
          <Icon name="UserCircle" color="primaryDark" marginRight="20px" />
          <Column>
            <H4>
              {matter.isSharia ? 'CUSTOMERS' : 'BORROWERS'}
            </H4>
            {borrowers.map((b) => (
              <Text key={b.id}>
                {b.firstName}
                &nbsp;
                {b.middleName ? `${b.middleName} ` : ''}
                {b.lastName}
                <Text style={{ fontSize: 14, fontStyle: 'italic' }}>
                  {((user?.userType === UserType.Admin
                    || user?.userType === UserType.TenantAdmin)
                    && b.twoFactorCode)
                    && `SMS: ${b.twoFactorCode}`}
                </Text>
              </Text>
            ))}
          </Column>
        </SummaryBlock>
        <SummaryBlock gridArea="LoanAmount">
          <Icon name="MoneyCircle" color="primaryDark" marginRight="20px" />
          <Column>
            <H4>
              {matter.isSharia ? 'FINANCE' : 'LOAN'}
              &nbsp;Amount
            </H4>
            <Text>{toAUD(matter.loanAmount)}</Text>
          </Column>
        </SummaryBlock>
        <SummaryBlock gridArea="Property">
          <Icon name="Home" color="primaryDark" marginRight="20px" />
          <Column>
            <H4>Property</H4>
            {matter.securityProperties.map((sp) => (
              <Text key={sp.id}>
                {sp.addressOne && `${sp.addressOne} `}
                {sp.addressTwo && `${sp.addressTwo} `}
                {sp.suburb && `${sp.suburb} `}
                {sp.state && `${sp.state} `}
                {sp.postcode && `${sp.postcode} `}
              </Text>
            ))}
          </Column>
        </SummaryBlock>
        {
          guarantors.length > 0 && (
            <SummaryBlock>
              <Icon name="UserCircle" color="primaryDark" marginRight="20px" />
              <Column>
                <H4>Guarantors</H4>
                {guarantors.map((b) => (
                  <Text key={b.id}>
                    {b.firstName}
                    {b.middleName ? ` ${b.middleName} ` : ' '}
                    {b.lastName}
                  </Text>
                ))}
              </Column>
            </SummaryBlock>
          )
        }
        <SummaryBlock gridArea="Lender">
          <Icon name="UserCircle" color="primaryDark" marginRight="20px" />
          <Column>
            <H4>
              {matter.isSharia ? 'FINANCIER' : 'LENDER'}
            </H4>
            <Text>{matter.lender}</Text>
          </Column>
        </SummaryBlock>
        <SummaryBlock gridArea="LenderRef">
          <Icon name="Badge" color="primaryDark" marginRight="20px" />
          <Column>
            <H4>
              {matter.isSharia ? 'FINANCIER' : 'LENDER'}
              &nbsp;Ref
            </H4>
            <Text>{matter.lenderRef}</Text>
          </Column>
        </SummaryBlock>
        {
          matter.broker && (
            <SummaryBlock gridArea="Broker">
              <Icon name="UserCircle" color="primaryDark" marginRight="20px" />
              <Column>
                <H4>Broker</H4>
                <Text>{matter.broker}</Text>
              </Column>
            </SummaryBlock>
          )
        }
        {
          user?.userType === UserType.Admin && (
            <SummaryBlock gridArea="Tenant">
              <Icon name="User" color="primaryDark" marginRight="20px" />
              <Column>
                <H4>Tenant</H4>
                <Text>{matter.tenant}</Text>
              </Column>
            </SummaryBlock>
          )
        }
      </Container>
    </>
  );
};

MatterSummary.defaultProps = {
  user: null,
};

MatterSummary.propTypes = {
  matter: PropTypes.shape({
    borrowers: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    ),
    guarantors: PropTypes.arrayOf(
      PropTypes.shape({
        firstName: PropTypes.string,
        middleName: PropTypes.string,
        lastName: PropTypes.string,
      }),
    ),
    loanAmount: PropTypes.number,
    securityProperties: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        addressOne: PropTypes.string,
        addressTwo: PropTypes.string,
        suburb: PropTypes.string,
        state: PropTypes.string,
        postcode: PropTypes.number,
      }),
    ),
    lender: PropTypes.string,
    lenderRef: PropTypes.string,
    broker: PropTypes.string,
    tenant: PropTypes.string,
    senderRef: PropTypes.string,
    isSharia: PropTypes.bool,
  }).isRequired,
  user: PropTypes.shape({
    userType: PropTypes.number,
  }),
};

export default withRouter(MatterSummary);
