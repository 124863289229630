import React from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Icon, H6, PageLoading,
} from '@galilee/lilee';
import TrackNTraceMatterProvider, { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import { matchPath } from 'react-router-dom';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { useHistory } from 'react-router';
import styled from 'styled-components/macro';
import MatterHistory from '../components/MatterHistory';
import MatterCurrentStep from '../components/MatterCurrentStep';
import Layout from '../components/Layout';
import PhoneSupport from '../components/PhoneSupport';

const EligibleLenders = [
  'pepper finance corporation limited', // GOLD can only return lender name at the moment. we need to find a better solution for this.
];

const NavWrapper = styled(Flex)`
  align-items: center;
  margin: ${(p) => p.theme.space[6]} 0;
  cursor: pointer;
`;

const MatterDetails = ({ matterId }) => {
  const { isAdmin, actions: { setReload } } = useTrackNTrace();
  const { matter, isLoading } = useTrackNTraceMatter();

  const history = useHistory();

  const title = matter?.names.join(' & ');
  const subtitle = matter?.suburbs.join(' & ');

  const handleClick = () => {
    if (isAdmin) {
      history.push('/track/admin');
      return;
    }

    setReload((prev) => !prev); // we want to reload matters when we go back to matter list page.
    history.push('/track');
  };

  const Nav = (
    <NavWrapper onClick={handleClick}>
      <Icon name={isAdmin ? 'ArrowLeft' : 'Search'} size="12px" mr="5" color="tertiary" />
      {isAdmin ? <H6 color="tertiary" fontWeight="medium">Go Back To Callback List</H6> : <H6 color="tertiary" fontWeight="medium">Find Another Matter</H6>}
    </NavWrapper>
  );

  if (isLoading) return <PageLoading />;

  const showPhoneSupport = !isAdmin && EligibleLenders.includes((matter?.lender || '').toLowerCase());

  return (
    <Layout kicker={matterId} title={title} subtitle={subtitle} top={Nav} bottom={showPhoneSupport ? <PhoneSupport matterId={matterId} /> : null}>
      <MatterCurrentStep />
      <MatterHistory />
    </Layout>
  );
};

const Matter = () => {
  const pathMatch = matchPath(
    window.location.pathname,
    { path: '/track/:id', exact: true },
  );

  const matterId = pathMatch?.params?.id;

  return (
    <TrackNTraceMatterProvider matterId={matterId}>
      <MatterDetails matterId={matterId} />
    </TrackNTraceMatterProvider>
  );
};

MatterDetails.propTypes = {
  matterId: PropTypes.string.isRequired,
};

export default Matter;
