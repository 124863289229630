import { Flex } from '@galilee/lilee';
import styled from 'styled-components/macro';

const PDFDocumentWrapper = styled(Flex)`
  background-color: white;
  overscroll-behavior: contain;
  position: relative;
  ${
  (p) => (p.zoomed
    ? `
      bottom: 0;
      left: 0;
      overflow: auto;
      position: fixed;
      right: 0;
      top: 0;
      z-index: 990;
    ` : null)
}
`;

export default PDFDocumentWrapper;
