import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Box, Flex, mediaQueries } from '@galilee/lilee';
import logo from '../../images/logo-trans-light.png';
import NavItems from './components/NavItems';

export const mobileNav = '80px';
export const desktopNav = '70px';
const { lessThan } = mediaQueries;

const StyledNav = styled(Flex)`
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: ${desktopNav};
  z-index: 200;
  ${lessThan[1]`
    width: 100%;
    height: ${mobileNav};
    bottom: unset;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
  `}
`;

const LogoContainer = styled(Box)`
  position: relative;
  height: ${desktopNav};
  width: ${desktopNav};
  align-self: flex-start;
  ${lessThan[1]`
    width: 110px;
    position: initial;
  `}
`;

const LogoSwitcher = styled(Flex)`
  position: fixed;
  width: 95px;
  height: 53px;
  background-color: ${(p) => p.theme.colors.base};
  margin-top: 13px;
  border-radius: 0 25px 25px 0;
  align-items: center;
  ${lessThan[1]`
    margin-top: 13px;
    position: initial;
  `}
`;

const Logo = styled.img`
  width: 50px;
  margin-left: 16px;
  margin-top: 8px;
`;

const NavBar = ({ matter, matterProgress, theirProgresses }) => (
  <StyledNav bg="primary">
    <LogoContainer>
      <LogoSwitcher>
        <Link to="/">
          <Logo src={logo} />
        </Link>
      </LogoSwitcher>
    </LogoContainer>
    <NavItems matter={matter} matterProgress={matterProgress} theirProgresses={theirProgresses} />
  </StyledNav>
);

NavBar.defaultProps = {
  matter: null,
  matterProgress: null,
  theirProgresses: [],
};

NavBar.propTypes = {
  matter: PropTypes.shape({}),
  matterProgress: PropTypes.shape({}),
  theirProgresses: PropTypes.arrayOf(PropTypes.shape({})),
};

export default NavBar;
