import React, {
  useState, useRef, useEffect, useLayoutEffect,
} from 'react';
import styled from 'styled-components/macro';
import {
  Box, Flex, Button, Show, Checkbox, H4, Icon, mediaQueries, ResponsiveSearchBar, utils, Listing, H2, Header, Panel,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useApplication } from 'state/ApplicationProvider';
import DataList from 'components/DataList';
import Layout from 'components/Layout';
import { useManage } from 'state/manage/ManageProvider';
import fetchReportAction from 'actions/Report';
import useOnScreen from 'hooks/useOnScreen';
import MatterItem from '../components/MatterItem';

const { useDebounce } = utils;
const { lessThan, greaterThan } = mediaQueries;

const MatterItemList = styled(Listing)`
  background-color: white;
  margin: 0;
  padding: ${(p) => p.theme.space[5]} ${(p) => p.theme.space[6]};

  ${greaterThan[1]`
     padding: ${(p) => p.theme.space[6]} ${(p) => p.theme.space[9]};
  `}
`;

const ColumnContainer = styled(Flex)`
  flex-direction: row;
  ${lessThan[3]`
    flex-direction: column;
  `}
`;

const StyledHeader = styled(Header)`
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 14px 34px rgb(7 36 56 / 10%);
  padding: 72px;
  ${lessThan[1]`
    padding: 20px;
  `}
`;

const FilterActionContainer = styled(Panel)`
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 14px 34px rgb(7 36 56 / 10%);
  height: auto;
  padding-left: 72px;
  width: auto;
  margin-right: 24px;
  box-shadow: none;
  background-color: transparent;
  ${lessThan[3]`
    background-color: white;
    box-shadow: 0px 14px 34px rgb(7 36 56 / 10%);
    margin-right: 0px;
  `}
  ${lessThan[1]`
    padding-left: 20px;
  `}
`;

const FilterContainer = styled(Flex)`
  max-height: initial;
  padding-top: 0;
  transition: max-height .3s, padding-top .3s;
  overflow: hidden;
  flex-direction: column;
  ${lessThan[3]`
    max-height: ${(p) => (p.showFilter ? 'auto' : '0')}; 
    flex-direction: row;
    padding-top: ${(p) => (p.showFilter ? '50px;' : '0')}; 
    margin-left: ${(p) => p.theme.space[6]};
  `}
  ${lessThan[1]`
    flex-direction: column;
    border-bottom-width: 0;
  `}
`;

const ListContainer = styled(Panel)`
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 14px 34px rgb(7 36 56 / 10%);
  width: 80%;
  ${lessThan[3]`
    width: 100%;
  `}
`;

const FilterItem = styled(Flex)`
  flex: initial;  
  ${lessThan[3]`
    flex: 1;
  `}
  ${lessThan[1]`
    flex: initial;
  `}
`;

const NoResultsWrapper = styled(Flex)`
  padding: ${(p) => p.theme.space[9]} 0;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NoResultsText = styled(Box)`
  max-width: 440px;
`;

FilterItem.defaultProps = {
  pb: [6],
  flexDirection: 'column',
};

const initFilters = {
  matterStatuses: [],
  lenders: [],
  tenants: [],
  brokers: [],
};

const PageSize = 30;

const headers = [
  {
    id: 'borrowers', title: 'Show By:', sortable: false, width: 3, mobileWidth: 1, desktopTitle: '',
  },
  {
    id: 'loanAmount', title: 'Amount', sortable: true, width: 1, mobileWidth: 1,
  },
  {
    id: 'creationDate', title: 'Started', sortable: true, width: 1, mobileWidth: 1,
  },
  {
    id: 'borrowerLastActionDate', title: 'Updated', sortable: true, width: 1, mobileWidth: 1,
  },
];

const MatterList = () => {
  const {
    user, authToken, support,
  } = useAuth();
  const { isTenantSupport } = support;
  const { dispatch: applicationDispatch } = useApplication();
  const { state, actions } = useManage();
  const [isInitiated, setIsInitiated] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [keyword, setKeyword] = useState('');
  const [checkedFilters, setCheckedFilters] = useState(initFilters);
  const [sort, setSort] = useState({ sortId: 'borrowerLastActionDate', ascending: false });

  const endOfScrollRef = useRef(null);
  const isVisible = useOnScreen(endOfScrollRef);

  const actionsRef = useRef();
  const debouncedKeyword = useDebounce(keyword, 500);

  useEffect(() => {
    support.actions.resetUser();
  }, []);

  useEffect(() => {
    if (isInitiated) return;
    actionsRef.current = actions;
    setIsInitiated(true);
  }, [actions, isInitiated]);

  useEffect(() => {
    if (!isInitiated) return;
    actionsRef.current?.setSearch({
      page: 1,
      pageSize: PageSize,
      filters: checkedFilters,
      keyword: debouncedKeyword,
      orderBy: sort.sortId,
      ascending: sort.ascending,
    });
  }, [isInitiated, debouncedKeyword, sort, checkedFilters]);

  useLayoutEffect(() => {
    if (!isVisible || !isInitiated) return;
    actions.fetchMoreMatters();
  }, [isInitiated, isVisible, actions]);

  const { matters } = state;
  const {
    matterStatuses, lenders, tenants, brokers,
  } = state.filters;
  const { sortId, ascending } = sort;

  const handleFilterChange = (name, filter) => {
    const existing = checkedFilters[name];
    const newFilters = existing.includes(filter) ? existing.filter((i) => i !== filter) : [...existing, filter];
    setCheckedFilters({ ...checkedFilters, [name]: newFilters });
  };

  return (
    <Layout>
      <Box>
        <StyledHeader color="#ffffff" mb={24}>
          <StyledHeader.Title>
            {isTenantSupport && 'Hi Support Staff'}
            {(user && !isTenantSupport) ? `Hi ${user.firstName}` : ''}
          </StyledHeader.Title>
          <Box mt={[5, 6, 7]}>
            Welcome to the Kwil management platform. You can filter and sort matters, read documents and track borrower progress.
            <br />
            {user.userType === 3 && (<Button mt={7} noBorder onClick={() => fetchReportAction(applicationDispatch, authToken)} leftIcon={<Icon name="Document" color="secondary" />}>Report</Button>)}
            {isTenantSupport && '\nAs a Tenant Support Staff member you can view matters with the same view as a borrower.'}
          </Box>
        </StyledHeader>
        <ColumnContainer>
          <FilterActionContainer showFilter={showFilter}>
            <Show breakpoints={[0, 1, 2, 3]}>
              <Button noBorder onClick={() => setShowFilter(!showFilter)} leftIcon={<Icon name={showFilter ? 'NavDown' : 'NavUp'} color="secondary" />}>Filters</Button>
            </Show>
            <FilterContainer showFilter={showFilter}>
              <FilterItem>
                <H4 color="base" mb={5}>Filter by Status</H4>
                {matterStatuses.map((l) => (
                  <Checkbox
                    key={l.key}
                    label={l.value}
                    checked={checkedFilters.matterStatuses.includes(l.key)}
                    onChange={() => handleFilterChange('matterStatuses', l.key)}
                  />
                ))}
              </FilterItem>
              { lenders?.length > 1 && (
              <FilterItem>
                <H4 color="base" mb={5}>Filter by Lenders</H4>
                {lenders.map((l) => (
                  <Checkbox
                    key={l.key}
                    label={l.value}
                    checked={checkedFilters.lenders.includes(l.key)}
                    onChange={() => handleFilterChange('lenders', l.key)}
                  />
                ))}
              </FilterItem>
              ) }
              { tenants?.length > 0 && (
              <FilterItem>
                <H4 color="base" mb={5}>Filter by Tenants</H4>
                {tenants.map((l) => (
                  <Checkbox
                    key={l.key}
                    label={l.value}
                    checked={checkedFilters.tenants.includes(l.key)}
                    onChange={() => handleFilterChange('tenants', l.key)}
                  />
                ))}
              </FilterItem>
              ) }
              { brokers?.length > 0 && (
              <FilterItem>
                <H4 color="base" mb={5}>Filter by Brokers</H4>
                {brokers.map((b) => (
                  <Checkbox
                    key={b.key}
                    label={b.value}
                    checked={checkedFilters.brokers.includes(b.key)}
                    onChange={() => handleFilterChange('brokers', b.key)}
                  />
                ))}
              </FilterItem>
              ) }
            </FilterContainer>
          </FilterActionContainer>
          <ListContainer>
            <ResponsiveSearchBar
              withButton={false}
              placeHolder="Search by keyword"
              onChange={(val) => setKeyword(val?.trim())}
            />
            <DataList.SortableHeader headers={headers} onSort={(val) => setSort({ ...sort, ...val })} sort={{ sortId, ascending }} />
            <MatterItemList>
              {!state.isLoading && matters.map((m) => <MatterItem key={m.matterId} matter={m} orderBy={sort.sortId} />)}
              {(!state.isLoading && !matters.length) && (
              <NoResultsWrapper>
                <H2 mb="7">Nothing to see here!</H2>
                <NoResultsText>Try removing any filters you might have applied, or contact us if you think you should be able to see some results here.</NoResultsText>
              </NoResultsWrapper>
              )}
              {(state.isLoading || state.isLoadingMore) && <Flex mx="auto" my="7" justifyContent="center"><Icon size="40px" name="Spinner" stepping /></Flex>}
            </MatterItemList>
          </ListContainer>
        </ColumnContainer>
        <div ref={endOfScrollRef} id="sentinel" />
      </Box>
    </Layout>
  );
};

export default MatterList;
