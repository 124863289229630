import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Box, Label, FieldError,
} from '@galilee/lilee';
import useDynamicFieldProps from './useDynamicFieldProps';

const TextBoxLabel = styled(Label)`
  min-height: 18px;
`;

const TextBox = ({ fieldName, inputType, readOnly }) => {
  const { fieldProps, errors, field } = useDynamicFieldProps(fieldName);
  return (
    <Box width="100%">
      { field.labelText !== null && <TextBoxLabel htmlfor={fieldName}>{field.labelText}</TextBoxLabel> }
      <Input
        maxWidth="450px"
        disabled={readOnly}
        placeholder={field.placeholder}
        type={inputType}
        {...fieldProps}
      />
      <FieldError error={errors[fieldName]} />
    </Box>
  );
};

TextBox.defaultProps = {
  inputType: 'text',
};

TextBox.propTypes = {
  inputType: PropTypes.string,
  fieldName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default TextBox;
