import React from 'react';
import { Route, Switch } from 'react-router';
import Home from 'screens/home';
import ManageHome from 'screens/manage/home';
import ManageMatter from 'screens/manage/matter';
import MatterMaker from 'screens/manage/matterMaker/index';
import UpdateBorrowerRoute from 'screens/manage/updateBorrower';
import CollaborateRoute from 'screens/manage/collaborate';
import BrokerProfileRoute from 'screens/manage/brokerProfile';
import Marketing from 'screens/marketing';
import Track from 'screens/track';
import Matter from 'screens/matter';
import BorrowerList from 'screens/manage/components/BorrowerList';
import AuthorizeRoute from 'components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from 'components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';
import { ToastNotifications } from '@galilee/lilee';
import ConnectionError from 'components/ConnectionError';
import { useApplication } from 'state/ApplicationProvider';
import { useAuth } from 'state/AuthProvider';
import IdleLogout from 'components/IdleLogout';
import HeartbeatLogout from 'components/HeartbeatLogout';
import ThirdPartyScripts from 'components/ThirdPartyScripts';
import UserType from 'enums/UserType';
import SignInError from './screens/signInError';
import fourOhFour from './screens/fourOhFour';

const App = () => {
  const { state } = useApplication();
  const { user, support } = useAuth();
  const { isTenantSupport } = support;
  const isBrokerOrAdmin = user && [UserType.Broker, UserType.Lender, UserType.Admin, UserType.TenantAdmin].includes(user.userType);
  const isBroker = user && [UserType.Broker].includes(user.userType);
  const isAdmin = user && [UserType.Admin].includes(user.userType);
  const homeComponent = (isBrokerOrAdmin || isTenantSupport) ? ManageHome : Home;
  const matterComponent = isBrokerOrAdmin ? ManageMatter : Matter;
  const MatterMakerComponent = isAdmin ? MatterMaker : fourOhFour;
  const UpdateBorrowerComponent = isAdmin ? UpdateBorrowerRoute : fourOhFour;
  const CollaborateComponent = isBroker ? CollaborateRoute : fourOhFour;
  const BrokerProfileComponent = isBroker ? BrokerProfileRoute : fourOhFour;
  const SupportUserSelectComponent = isTenantSupport ? BorrowerList : fourOhFour;
  const authenticated = !!user;

  return (
    <>
      <ToastNotifications autoCloseDuration={5} />
      <IdleLogout isAuthenticated={authenticated} isPaused={state.logoutPaused}>
        <HeartbeatLogout isAuthenticated={authenticated}>
          <ConnectionError disconnectionErrors={state.disconnectionErrors} socketConnectionErrors={state.socketConnectionErrors} />
          <ThirdPartyScripts />
          <Switch>
            <Route path="/track" component={Track} />
            <AuthorizeRoute exact path="/home" component={homeComponent} />
            <AuthorizeRoute path="/matter/:id" component={matterComponent} />
            <AuthorizeRoute exact path="/matter-maker" component={MatterMakerComponent} />
            <AuthorizeRoute exact path="/update-user" component={UpdateBorrowerComponent} />
            <AuthorizeRoute exact path="/collaborate" component={CollaborateComponent} />
            <AuthorizeRoute exact path="/broker-profile" component={BrokerProfileComponent} />
            <AuthorizeRoute exact path="/support/:id" component={SupportUserSelectComponent} />
            <Route exact path="/sign-in-error" component={SignInError} />
            <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            <Route component={Marketing} />
          </Switch>
        </HeartbeatLogout>
      </IdleLogout>
    </>
  );
};

App.displayName = App.name;

export default App;
