import React, { } from 'react';
import {
  Flex, Text, Image, Box, mediaQueries,
} from '@galilee/lilee';
import styled from 'styled-components/macro';
import LinkButton from 'components/LinkButton';
import { toStatusAction } from 'utils/index';
import PropTypes from 'prop-types';

const { lessThan } = mediaQueries;

const MatterPanel = styled(Box)`
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${lessThan[0]`
    flex-direction: column;
  `}
`;

const MatterContainer = styled(Flex)`
  ${lessThan[0]`
    flex-direction: column;
    width: auto;
  `}
`;

const StyledImage = styled(Image)`
  width: 84px;
  object-fit: cover;
`;

const MatterCard = ({ matter }) => {
  const {
    lenderName, lenderLogoPath, senderRef, lenderRef, matterId, status, userStatus,
  } = matter;
  return (
    <MatterPanel mb={[6, 4, 5, 6]} p={[6, 4, 5]}>
      <MatterContainer alignItems="center">
        <StyledImage alt={lenderName} src={process.env.PUBLIC_URL + lenderLogoPath} mr={[4, 5, 6]} />
        <Flex flexDirection="column" mb={[6, 0, 0, 0]} mt={[6, 0, 0, 0]}>
          <Text color="primary" fontSize={[2, 3]} mr={[5, 6, 7]}>
            Matter Ref:
            {senderRef}
          </Text>
          <Text color="primary" fontSize={[2, 3]} mr={[5, 6, 7]}>
            Lender Ref:
            {lenderRef}
          </Text>
        </Flex>
      </MatterContainer>
      <LinkButton mb={[3, 0, 0, 0]} color="secondary" to={`./matter/${matterId}`}>
        {toStatusAction(status, userStatus)}
      </LinkButton>
    </MatterPanel>
  );
};

MatterCard.propTypes = {
  matter: PropTypes.shape({
    lenderName: PropTypes.string,
    lenderLogoPath: PropTypes.string,
    senderRef: PropTypes.string,
    lenderRef: PropTypes.string,
    matterId: PropTypes.number,
    status: PropTypes.string,
    userStatus: PropTypes.string,
  }).isRequired,
};

export default MatterCard;
