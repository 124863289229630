import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Icon, Box } from '@galilee/lilee';
import PROGRESS from 'enums/Progress';

const IconStack = styled(Box)`
  position: relative;
  width: ${(p) => (p.iconSize === 'md' ? '24px' : '16px')};
  height: ${(p) => (p.iconSize === 'md' ? '24px' : '16px')};
  display: inline-table;
`;

const StackedIcon = styled(Icon)`
  position: absolute;
  left: ${(p) => (p.iconSize === 'md' ? '6px' : '3px')};
  width: 50%;
`;

export const All = (props) => <Icon color="white" {...props} name="Circle" />;

export const NotStarted = (props) => <Icon color="primary10" {...props} name="Circle" />;

export const InProgress = (props) => <Icon color="primary40" {...props} name="Circle" />;

export const Completed = (props) => <Icon color="primary" {...props} name="CheckCircle" />;

export const UnderReview = (props) => <Icon color="primary" {...props} name="Time" />;

export const Finalised = (props) => <Icon color="secondary" {...props} name="CheckCircle" />;

export const Error = (props) => <Icon color="error" {...props} name="WarningCircle" />;

export const Warn = (props) => <Icon color="warn" {...props} name="WarningCircle" />;

export const Locked = ({ iconSize, ...props }) => (
  <IconStack iconSize={iconSize} {...props}>
    <Icon color="primary40" name="Circle" />
    <StackedIcon iconSize={iconSize} color="white" name="Lock" />
  </IconStack>
);

export const Started = ({
  me, them, iconSize, ...props
}) => {
  const calcColor = (progress, isMe) => {
    if (isMe && progress === PROGRESS.NOTSTARTED) return 'white';
    if (!isMe && (progress === PROGRESS.NOTSTARTED || progress === PROGRESS.WARN)) return 'primary10';
    return 'primary';
  };
  const innerColor = calcColor(me, true);
  const outerColor = calcColor(them, false);
  return (
    <IconStack iconSize={iconSize} {...props}>
      <Icon color={outerColor} name="Circle" />
      <StackedIcon iconSize={iconSize} color={innerColor} name="Circle" />
    </IconStack>
  );
};

Started.defaultProps = { iconSize: 'md' };

Started.propTypes = {
  me: PropTypes.string.isRequired,
  them: PropTypes.string.isRequired,
  iconSize: PropTypes.oneOf(['sm', 'md']),
};

Warn.defaultProps = { iconSize: 'md' };
Warn.propTypes = { iconSize: PropTypes.string };

Error.defaultProps = { iconSize: 'md' };
Error.propTypes = { iconSize: PropTypes.string };

Completed.defaultProps = { iconSize: 'md' };
Completed.propTypes = { iconSize: PropTypes.string };

Locked.defaultProps = { iconSize: 'md' };
Locked.propTypes = { iconSize: PropTypes.string };
