import React from 'react';
import PropTypes from 'prop-types';
import ProgressIcon from 'components/ProgressIcons';
import ActiveNavLink from 'components/ActiveNavLink';

const ProgressLink = ({ to, progress, ...props }) => <ActiveNavLink to={to}><ProgressIcon progress={progress} {...props} /></ActiveNavLink>;

ProgressLink.defaultProps = {
  progress: null,
};

ProgressLink.propTypes = {
  progress: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    myProgress: PropTypes.string,
    theirProgress: PropTypes.string,
  }),
  to: PropTypes.string.isRequired,
};

export default ProgressLink;
