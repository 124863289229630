import React from 'react';
import { Route } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import TopNav from 'components/TopNav';
import Sign from './sign';
import SignSummary from './signSummary';

const SignRouter = ({ match }) => (
  <>
    <TopNav />
    <Route path={`${match.path}/:id`} component={Sign} />
    <Route exact path={match.path} component={SignSummary} />
  </>
);

SignRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default SignRouter;
