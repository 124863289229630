import React, { useState, useEffect } from 'react';
import { pdfjs } from 'react-pdf';
import ReactRouterPropTypes from 'react-router-prop-types';
import { PageLoading, utils } from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useManageMatter } from 'state/ManageMatterProvider';
import PDFViewer from '../components/PDFViewer';

const { useScrollToTop } = utils;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Read = ({ match }) => {
  const docId = Number(match.params.id);
  const { authToken } = useAuth();
  const { state } = useManageMatter();
  const [pdfPath, setPDFPath] = useState(null);
  useScrollToTop(true);

  useEffect(() => {
    setPDFPath(null);
  }, [docId]);

  useEffect(() => {
    if (!authToken || !docId || pdfPath) return;
    setPDFPath({ url: `${window.location.origin}/api/readdocuments/${docId}/file`, httpHeaders: { Authorization: `Bearer ${authToken}` }, withCredentials: false });
  }, [authToken, docId, pdfPath]);

  if (!pdfPath || !state || !state.matterProgress) return <PageLoading />;

  const { name } = state.matterProgress.readDocuments.find((p) => p.id === docId);

  return (
    <PDFViewer pdfPath={pdfPath} title={name} subtitle="Read &amp; Accept" />
  );
};

Read.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Read;
