import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Flex, mediaQueries, H3, Text,
} from '@galilee/lilee';
import styled, { keyframes } from 'styled-components/macro';

const { lessThan } = mediaQueries;

const shake = keyframes`
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
`;

const Notice = styled(Flex)`
  margin: auto;
  width: 60%;
  max-width: ${(p) => p.theme.breakpoints[1]};
  background-color:#072438e8;
  border-radius: 15px;
  flex: 4;

  padding: ${(p) => p.theme.space[7]};
  margin-bottom: ${(p) => p.theme.space[5]};

  position: fixed;
  right: 0;
  left: 70px;
  ${lessThan[1]`
      left: 0px;
  `}

  animation:  ${shake} 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
  animation-delay: 1s;
`;

const NoticeTextContainer = styled(Flex)`
  margin: auto;
  flex-direction: column;
  align-items: flex-start;
`;

const NoticeTitle = styled(H3)`
  width: 100%;
  display: block;
  color: white;
  margin-bottom: ${(p) => p.theme.space[5]};
  ${lessThan[1]`
    text-align: center;
  `}
`;

const NoticeSubTitle = styled(Text)`
  display: block;
  color: white;
  ${lessThan[1]`
    text-align: center;
  `}
`;

const PdfNotice = ({ isWebformThenSign, status }) => {
  const [Hide, setHide] = useState(false);
  useEffect(() => {
    const timeOut = setTimeout(() => setHide(true), 5000);
    return () => clearTimeout(timeOut);
  }, []);
  return (
    !Hide && !isWebformThenSign && (status !== 'finalised' ? status !== 'submitted' : false)
    && (
      <Notice style={{ zIndex: 1000 }}>
        <NoticeTextContainer>
          <NoticeTitle>
            This document is not interactive
          </NoticeTitle>
          <NoticeSubTitle>
            Use the navigation bar at the bottom of the page to proceed.
          </NoticeSubTitle>
        </NoticeTextContainer>
      </Notice>
    )
  );
};

PdfNotice.propTypes = {
  isWebformThenSign: PropTypes.bool.isRequired,
  status: PropTypes.string.isRequired,
};

export default PdfNotice;
