import React from 'react';
import { P } from '@galilee/lilee';
import { Link } from 'react-router-dom';
import ColinWentWrong from 'images/ColinWentWrong.png';
import ErrorPage from 'components/ErrorPage';

const SignInError = () => (
  <ErrorPage image={ColinWentWrong} title="There was an error authenticating you">
    <P>Please check your browser is up to date</P>
    <P>Are your cookies enabled?</P>
    <P>- try a different browser</P>
    <P>- try clearing your cookie cache</P>
    <P>
      Wait at least 1 minute before logging back in
      {' '}
      <Link to="/authentication/login">Login Page</Link>
      {' '}
    </P>
    <P>
      To learn more about Kwil
      {' '}
      <Link to="/">Home page</Link>
      {' '}
    </P>
  </ErrorPage>
);

export default SignInError;
