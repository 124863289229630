import React, { useState } from 'react';
import {
  Flex, Button, Text, Panel, Input, Box, H6, toast, PageLoading,
} from '@galilee/lilee';

import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import styled from 'styled-components/macro';

const AccessCodePanel = styled(Panel)`
  max-width: 700px;
  margin-bottom: ${(p) => p.theme.space[8]};
  border-radius:  ${(p) => p.theme.sizes[3]};
`;

const AccessCodeForm = () => {
  const {
    loginPendingUser, isLoading, actions,
  } = useTrackNTrace();

  const { login, requireAccessCode } = actions;

  const [code, setCode] = useState('');

  const handleButtonClick = async () => {
    if (!code || !loginPendingUser) return;
    await login(loginPendingUser, code);
  };

  const handleEmailResent = async () => {
    if (!loginPendingUser) return;
    await requireAccessCode(loginPendingUser);
    toast.success('Verification code sent.');
  };

  return (
    <>
      {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
      <AccessCodePanel mx="auto">
        <Box px={[1, 8]} py="6">
          <Text mt="5" mb="5" textAlign="center" fontSize="3" fontWeight="medium">
            We&#8217;ve sent you an email with a unique code!
          </Text>
          <Text mb="8" mx="auto" textAlign="center">Please find the code and enter it below to continue.</Text>
          <H6 mt="5" mb="5">
            6 Digit code
          </H6>
          <form>
            <Input type="password" fontSize="2" onChange={(e) => setCode(e.target.value)} />
          </form>
          <Button mt="8" onClick={handleButtonClick}>Continue</Button>
          <Flex flexDirection="column" alignItems="center" mt="10">
            <Text fontSize="3" mb="6" fontWeight="medium" textAlign="center">
              Can&#8217;t find the Email?
            </Text>
            <Text>Either resend the email or go back and check the email you entered</Text>
            <Button mt="6" color="link" fontSize="2" onClick={handleEmailResent}>Resend Email</Button>
          </Flex>
        </Box>
      </AccessCodePanel>
    </>
  );
};

export default AccessCodeForm;
