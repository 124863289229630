import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  Flex, Icon, utils, Box, Button, mediaQueries, Text,
} from '@galilee/lilee';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { useHistory } from 'react-router';

const { useOnClickOutside } = utils;
const { greaterThan } = mediaQueries;

const Wrapper = styled(Flex)`
  flex-direction: row;
  max-width:100%;
`;

const DropDownWrapper = styled(Box)`
  position: relative;
  margin-right: ${(p) => p.theme.space[4]};
  max-width:200px;
  min-width:200px;
  ${greaterThan[0]`
    min-width: 300px;
    max-width:500px;
  `}
`;

const DropDown = styled(Flex)`
  cursor: pointer;
  flex-direction: row;
  align-items: center;
  position: relative;
  height:50px;
  border-width: 0px;
  border-style: solid;
  border-radius: ${(p) => p.theme.radii[2]};
  padding: ${(p) => p.theme.space[5]};
  background: ${(p) => (p.isFocus ? p.theme.colors.base10 : p.theme.colors.white)};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Menu = styled(Flex)`
  flex-direction: column;
  border-radius: ${(p) => p.theme.radii[2]};
  box-shadow: 0 8px 10px 1px rgb(0 0 0 / 14%);
  width: 100%;
  height: auto;
  overflow: hidden;
  position: absolute;
  z-index: 1;
  background: white;
  padding: ${(p) => p.theme.space[3]};
`;

const Option = styled(Text)`
  cursor: pointer;
  :hover {
    background-color: ${(p) => p.theme.colors.base10};
  }
  border-bottom: 1px solid;
  border-color: ${(p) => p.theme.colors.base05};
  padding: ${(p) => p.theme.space[4]};
  &:last-child {
    font-weight: 500;
    border-bottom: none;
    text-transform: uppercase;
    text-align: center;
  }
`;

const IconWrapper = styled(Box)`
  position: absolute;
  right: 10px;
`;

const ButtonWrapper = styled(Button)`
  min-width: 90px;
  &:hover {
    padding: 0;
  }
`;

const userActions = {
  NO_FILTER: 'All email addresses',
  DELETE_ALL: 'Delete All',
};

const getUserSelectOptions = (users) => {
  const options = [...users.map((u) => u.email)];
  if (users.length > 1) {
    options.unshift(userActions.NO_FILTER);
  }
  options.push(userActions.DELETE_ALL);
  return options;
};

export default function UserSelect() {
  const history = useHistory();
  const [showMenu, setShowMenu] = useState(false);
  const [isFocus, setIsFocused] = useState(false);
  const selectRef = useRef();

  const { users, selectedUser, actions: { setSelectedUser, logout } } = useTrackNTrace();
  useOnClickOutside(selectRef, () => {
    setShowMenu(false);
    setIsFocused(false);
  });

  const handleChange = (value) => {
    setIsFocused(false);
    setShowMenu(false);

    if (value === userActions.NO_FILTER) {
      setSelectedUser(null);
      return;
    }

    if (value === userActions.DELETE_ALL) {
      setSelectedUser(null);
      logout();
      return;
    }

    setSelectedUser(value);
  };

  if (!users || !users.length) return null;

  const options = getUserSelectOptions(users);
  const defaultOption = selectedUser || options[0];

  return (
    <Wrapper onClick={() => setIsFocused(true)}>
      <DropDownWrapper ref={selectRef}>
        <DropDown isFocus={isFocus} onClick={() => setShowMenu(!showMenu)}>
          <Text ellipsis width="95%">{defaultOption}</Text>
          <IconWrapper>
            <Icon name="NavDown" size="14px" />
          </IconWrapper>
        </DropDown>
        {showMenu && (
        <Menu>
          {
            options.map((o) => (
              <Option
                ellipsis
                key={o}
                onClick={(e) => {
                  e.stopPropagation();
                  handleChange(o);
                }}
              >
                {o}
              </Option>
            ))
        }
        </Menu>
        )}
      </DropDownWrapper>
      <ButtonWrapper
        color="secondary"
        fontSize="1"
        iconSmall
        px="2"
        leftIcon={<Icon name="Plus" ml="3" />}
        onClick={() => history.push('/track/login')}
      >
        ADD
      </ButtonWrapper>
    </Wrapper>
  );
}
