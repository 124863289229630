import styled from 'styled-components/macro';
import { themeGet } from '@styled-system/theme-get';
import { Box } from '@galilee/lilee';

const Gradient = styled(Box)`
  position: ${(p) => p.position};
  width: 100%;
  background: rgb(7,36,56);
  min-height: ${(p) => themeGet(`sizes[${p.MinHeight}]`, p.minHeight)(p)};
  background: linear-gradient(174deg, rgba(7,36,56,1) 34%, rgba(63,115,204,1) 100%); 
`;

Gradient.defaultProps = {
  position: 'initial',
  minHeight: '350px',
};

export default Gradient;
