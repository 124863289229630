import React from 'react';
import PropTypes from 'prop-types';
import { space, layout } from 'styled-system';
import styled from 'styled-components/macro';
import {
  Box, Flex, Text, Image, Hide, Row, Column, H3, P, H2, Absolute, Relative, mediaQueries,
} from '@galilee/lilee';
import Container from 'components/Container';
import HowLink1 from 'images/HowLink1.png';
import HowLink2 from 'images/HowLink2.png';
import HowLink3 from 'images/HowLink3.png';
import HowLink4 from 'images/HowLink4.png';
import HowLink5 from 'images/HowLink5.png';
import HowLink6 from 'images/HowLink6.png';
import HowWitnessing from 'images/HowWitnessing.png';
import HowWorkflow from 'images/HowWorkflow.png';
import HowIntegrate from 'images/HowIntegrate.png';
import HowAccept from 'images/HowAccept.png';
import HowTrack from 'images/HowTrack.png';
import HowSettlement from 'images/HowSettlement.png';
import HowFallover from 'images/HowFallover.png';

const { lessThan } = mediaQueries;

const InfoColumn = styled(Flex)`
  flex: 4;
`;

const IconColumn = styled(Box)`
  width: 64px;
  position: relative;
  top: ${(p) => p.top};
  ${space};
  ${layout};
`;

IconColumn.defaultProps = {
  top: '0',
  mr: 7,
  mb: 8,
};

IconColumn.propTypes = {
  top: PropTypes.string,
  ...space.propTypes,
  ...layout.propTypes,
};

const LinkColumn = styled(Hide)`
  position: relative;
  top: ${(p) => p.top};
  ${space};
  ${layout};
`;

LinkColumn.defaultProps = {
  ...IconColumn.defaultProps,
  breakpoints: [0, 1, 2],
  ml: 4,
  mr: 4,
};

LinkColumn.propTypes = {
  ...IconColumn.propTypes,
};

const WitnessingColumn = styled(Hide)`
    display: flex;
    min-width: 179px;
    max-height: 179px;
    margin-bottom: 64px;
`;

WitnessingColumn.defaultProps = {
  breakpoints: [0, 1, 2],
};

const LargeIconColumn = styled(IconColumn)`
  width: 94px;
  ${lessThan[2]`
    order: 2;
    margin-right: 0;
    margin-left: ${(p) => p.theme.sizes[5]};  
  `}
`;

const TextColumn = styled(Column)``;

TextColumn.defaultProps = {
  mt: [0, null, null, 5],
  mr: [0, null, null, 5],
  mb: [0, null, null, 8],
};

const HowItWorksText = styled(Text)`
  max-width: 470px;
`;

HowItWorksText.defaultProps = {
  color: 'base20',
  fontSize: '18px',
  mb: [8, null, null, 9],
};

const HowItWorks = () => (
  <Container maxWidth="1241px" center>
    <H2 fontSize={['36px', null, '48px']} mb={6}>How it works</H2>
    <HowItWorksText>
      We’ve made converting to a digital mortgage workflow process much easier than you think. We’re here to guide you through it every step of the way.
    </HowItWorksText>
    <Row collapseIndex="2">
      <InfoColumn>
        <IconColumn>
          <Image src={HowWorkflow} />
        </IconColumn>
        <TextColumn>
          <H3>Maintain your workflow</H3>
          <P color="base20" fontSize="2" fontWeight="light">
            Create your documents exactly as you normally would, but instead of licking the stamp, simply upload to KWIL.
          </P>
        </TextColumn>
      </InfoColumn>
      <LinkColumn minWidth="115px" top="50px">
        <Image width="115px" src={HowLink1} />
      </LinkColumn>
      <InfoColumn>
        <IconColumn>
          <Image src={HowIntegrate} />
        </IconColumn>
        <TextColumn>
          <H3>Integrate with our systems</H3>
          <P color="base20" fontSize="2" fontWeight="light">
            We will use your existing forms, our guys will talk to your guys just to make sure everything works as expected.
          </P>
        </TextColumn>
      </InfoColumn>
      <LinkColumn minWidth="143px" mr={[0, null, null, 9]} top="70px">
        <Image width="143px" src={HowLink2} />
      </LinkColumn>
    </Row>
    <Row collapseIndex="2">
      <InfoColumn>
        <IconColumn>
          <Image src={HowTrack} />
        </IconColumn>
        <TextColumn>
          <H3>Track & collaborate  </H3>
          <P color="base20" fontSize="2" fontWeight="light">
            Brokers & intermediaries can keep track of and assist throughout the document delivery, signing and return process. No more black holes, waiting for the post to come in.
          </P>
        </TextColumn>
      </InfoColumn>
      <LinkColumn mr="0" minWidth="101px">
        <Image src={HowLink4} />
      </LinkColumn>
      <WitnessingColumn>
        <Image src={HowWitnessing} />
      </WitnessingColumn>
      <LinkColumn top="60px" minWidth="101px">
        <Image src={HowLink3} />
      </LinkColumn>
      <InfoColumn>
        <IconColumn>
          <Image src={HowAccept} />
        </IconColumn>
        <TextColumn>
          <H3>Accept, sign & upload </H3>
          <P color="base20" fontSize="2" fontWeight="light">
            Once the initial setup is done, your customers simply receive an SMS, login securely, digitally sign their documents and upload any extra docs you require. No more waiting by the post box.
          </P>
        </TextColumn>
      </InfoColumn>
    </Row>
    <Row mt={[8, 8, 8, 0]} collapseIndex="2">
      <Absolute>
        <Hide breakpoints={[0, 1, 2]}>
          <Relative top="-100px">
            <Image src={HowLink5} />
          </Relative>
        </Hide>
      </Absolute>
      <InfoColumn>
        <LargeIconColumn>
          <Image src={HowSettlement} />
        </LargeIconColumn>
        <TextColumn>
          <H3 fontSize={['32px', null, null, '36px']}>80% reduction in settlement timeframe</H3>
          <P color="base20" fontSize="2" fontWeight="light">
            No more signing and doc return errors, and no more waiting to see what the postman delivers.
          </P>
        </TextColumn>
      </InfoColumn>
      <LinkColumn minWidth="101px" top="90px">
        <Image src={HowLink6} />
      </LinkColumn>
      <InfoColumn>
        <LargeIconColumn>
          <Image src={HowFallover} />
        </LargeIconColumn>
        <TextColumn>
          <H3 fontSize={['32px', null, null, '36px']}>75% decrease in fallover rate</H3>
          <P color="base20" fontSize="2" fontWeight="light">
            Once credit has approved the deal, almost all fallovers occur prior to signing of the loan documents.
            Faster and easier digitally signing almost entirely removes this last obstacle to settlement
          </P>
        </TextColumn>
      </InfoColumn>
    </Row>
  </Container>
);

export default HowItWorks;
