/* eslint-disable import/prefer-default-export */
import { httpRequest } from 'utils';
import { errorDispatcher } from './actionUtils';

export const fetchMattersAction = async (dispatch, token, currentPage, orderBy, ascending = true, pageSize = 20, filterObj = null, keyword = '') => {
  let querystringFilter = '';
  if (filterObj) {
    Object.keys(filterObj).forEach((groupName) => {
      const groupArr = filterObj[groupName];
      if (groupArr) {
        groupArr.forEach((filter) => { querystringFilter += `&${groupName}=${encodeURIComponent(filter)}`; });
      }
    });
  }
  const response = await httpRequest(
    `/api/matters/matterfilter?page=${currentPage}&pageSize=${pageSize}&OrderBy=${orderBy}&Ascending=${ascending}${querystringFilter}&query=${keyword}`,
    'get',
    null,
    { Authorization: `Bearer ${token}` },
  )
    .then((data) => data)
    .catch(() => errorDispatcher(dispatch, "We couldn't load your matters at the moment, please reload the page to try again."));
  return response;
};

export const fetchMatterFiltersAction = async (dispatch, token) => {
  const response = await httpRequest('/api/matters/filtercriteria', 'get', null, { Authorization: `Bearer ${token}` })
    .then((data) => data)
    .catch(() => errorDispatcher(dispatch, "We couldn't load the filters at the moment, please reload the page to try again."));
  return response;
};

export const fetchManageMatterAction = async (dispatch, applicationDispatch, matterId, token) => {
  httpRequest(`/api/matters/broker/${matterId}`, 'get', null, { Authorization: `Bearer ${token}` })
    .then((data) => dispatch({ type: 'SET_MATTER', payload: { ...data, matterId: Number(matterId) } }))
    .catch(() => errorDispatcher(applicationDispatch, "We couldn't load your matters at the moment, please reload the page to try again."));
};

export const deleteSignDocument = async (signDocumentId, applicationDispatch, token) => {
  const response = httpRequest(`/api/signDocuments/${signDocumentId}/delete`, 'post', null, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(applicationDispatch, "We couldn't delete the document at the moment, please reload the page to try again."));
  return response;
};
