import styled from 'styled-components/macro';
import { genericHashLink } from 'react-router-hash-link';
import {
  Box, Button, Flex, Column, H1, H5, Image, Text, mediaQueries,
} from '@galilee/lilee';

const { lessThan } = mediaQueries;

export const HeroContainer = styled(Box)`
  background: linear-gradient(360deg, #072438 75.04%, rgba(7, 36, 56, 0.88) 96.57%);
  color: white;
`;

export const PartnerContainer = styled(Box)`
  background: #ffffff0d;
`;

PartnerContainer.defaultProps = {
  width: '100%',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  p: 6,
  py: 7,
  color: 'white',
};

export const WhyUseKwilContainer = styled(Box)``;

WhyUseKwilContainer.defaultProps = {
  p: 5,
  width: '100%',
  color: 'white',
};

export const WhySwitchContainer = styled(Box)``;

WhySwitchContainer.defaultProps = {
  pt: [5, 5, 8],
  pb: 5,
  width: '100%',
  color: 'white',
};

export const HowItWorksContainer = styled(Box)`
  background: linear-gradient(180deg, #072438 0%, #047DD4 100%);
`;

HowItWorksContainer.defaultProps = {
  p: 5,
  width: '100%',
  color: 'white',
};

export const ContactUsContainer = styled(Box)`
  background-color: ${(p) => p.theme.colors.base};
  color: white;
`;

export const HeroTitleContainer = styled(Flex)`
  flex-direction: column;
  align-items: flex-start;
  flex: 4;
  ${lessThan[1]`
    margin: 30px;
    padding-top: ${(p) => p.theme.space[5]};
    order: 2;
  `}
`;

HeroTitleContainer.defaultProps = {
  mr: [0, 0, 6, 7],
};

export const HeroImageContainer = styled(Flex)`
  max-width: 730px;
  ${lessThan[1]`
    margin-bottom: ${(p) => p.theme.space[4]};
    order: 1;
  `}
`;

HeroImageContainer.defaultProps = {
  flex: '6',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
};

export const HeroSection = styled(Flex)`
  flex-direction: row;
  padding-bottom: ${(p) => p.theme.space[9]};
  ${lessThan[1]`
    flex-direction: column;
    padding: ${(p) => p.theme.space[9]} ${(p) => p.theme.space[5]};
  `}
`;

export const ColinColumn = styled(Column)`
  ${lessThan[1]`
    &:last-child {
      margin-right: ${(p) => p.theme.space[6]};
    }
  `}
`;

ColinColumn.defaultProps = {
  mr: [6, 7, 5, 7, 8],
  ml: [6, 7, 0],
};

export const HeroImage = styled(Image)`
  flex: 6;
  cursor: ${(p) => (p.onClick ? 'pointer' : 'default')};
`;

export const HeroTitle = styled(H1)`
  margin-bottom: ${(p) => p.theme.space[7]};
  width: 100%;
  display: block;
  ${lessThan[1]`
    text-align: center;
  `}
`;

export const HeroText = styled(Text)`
  display: block;
  ${lessThan[1]`
    text-align: center;
  `}
`;

HeroText.defaultProps = {
  color: 'base20',
  mb: '7',
  fontSize: ['18px', null, null, '21px'],
};

export const PartnerImage = styled(Image)`
  height: auto;
`;

export const PartnerTitle = styled(H5)`
  font-weight: ${(p) => p.theme.fontWeights.medium};
  margin-bottom: ${(p) => p.theme.space[7]};
  text-align: center;
  color: white;
  ${lessThan[1]`
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
  `}
`;

// HashLink components

export const StyledLink = styled(Text)`
  display: inline-block;
  cursor: pointer;
  line-height: 62px;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledAnchor = styled.a`
  cursor: pointer;
  text-decoration: underline;
`;

export const NavLink = genericHashLink(StyledLink);

export const HashLinkButton = genericHashLink(Button);

export const ScrollAnchor = genericHashLink(StyledAnchor);

NavLink.defaultProps = {
  mr: 7,
};
