import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { NavLink } from 'react-router-dom';

const activeClassName = 'nav-item-active';

const ActiveNavLink = styled(NavLink)`
  &.${activeClassName} {
      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        width: 50%;
        border-bottom: 2px solid ${(p) => p.theme.colors.success};
    }
  }
`;

ActiveNavLink.defaultProps = {
  activeClassName,
  exact: true,
};

ActiveNavLink.propTypes = {
  to: PropTypes.string.isRequired,
  activeClassName: PropTypes.string.isRequired,
};

export default ActiveNavLink;
