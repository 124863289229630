export const ManageAction = {
  SET_SEARCH: 'SET_SEARCH',
  SET_FILTERS: 'SET_FILTERS',
  FETCH_MATTERS: 'FETCH_MATTERS',
  FETCH_MORE_MATTERS: 'FETCH_MORE_MATTERS',
};

export default function manageReducer(state, action) {
  switch (action.type) {
    case ManageAction.SET_SEARCH:
      return {
        ...state,
        search: { ...state.search, ...action.payload },
      };
    case ManageAction.SET_FILTERS:
      return {
        ...state,
        filters: { ...state.filters, ...action.payload },
      };
    case ManageAction.FETCH_MATTERS:
      return {
        ...state,
        totalPages: action.payload?.totalPages || 0,
        currentPage: 1,
        matters: [...(action.payload?.matters || [])],
      };
    case ManageAction.FETCH_MORE_MATTERS:
      return {
        ...state,
        currentPage: action.payload.currentPage,
        totalPages: action.payload.totalPages,
        matters: [...new Map([...state.matters, ...action.payload.matters].map((item) => [item.matterId, item]))].map(([, matter]) => matter),
      };
    default:
      return state;
  }
}
