import React from 'react';
import styled from 'styled-components/macro';
import {
  Flex, mediaQueries, Popover, Text, H6, Box, H2, Row, Column,
} from '@galilee/lilee';
import { useMatter } from 'state/MatterProvider';
import { useHistory } from 'react-router-dom';
import ProgressLink from 'components/ProgressLink';
import GroupSummaryLink from 'components/GroupSummaryLink';
import PROGRESS from 'enums/Progress';
import PropTypes from 'prop-types';

const { lessThan } = mediaQueries;

const StyledContainer = styled(Flex)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; 
  grid-template-rows: auto; 
  gap: 50px;
  ${lessThan[1]`
    grid-template-columns: 1fr;
  `}
`;

const DocumentTitle = styled(H6)`
  font-weight:"normal";
  color:${(p) => p.theme.colors.base80};
  &:hover {
    color:${(p) => p.theme.colors.primaryDark};
  }
`;

const ProgressContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  user-select: none;
`;

const Title = styled(H6)`
  letter-spacing: ${(p) => p.theme.letterSpacings.small};
  text-transform: uppercase;
  color: ${(p) => p.theme.colors.primaryDark};
`;

Title.defaultProps = {
  fontWeight: 'medium',
};

const ProgressIconContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const StepsToCompletion = () => {
  const { state } = useMatter();
  if (!state.matterProgress || !state.matter) return null;
  const { matter, matterProgress } = state;

  return (
    <>
      <H2 mb={[5, 6, 7]}>Steps To Complete</H2>
      <StyledContainer>
        <ProgressGroup matterId={matter.matterId} title="Read & Accept" groupName="read" progress={matterProgress.readDocuments} first />
        <ProgressGroup matterId={matter.matterId} title="Complete & Sign" groupName="sign" group progress={matterProgress.signDocuments} />
        <ProgressGroup matterId={matter.matterId} title="Upload Docs" groupName="upload" progress={matterProgress.uploadDocuments} />
      </StyledContainer>
    </>
  );
};

const getProgressMessage = (p) => {
  if (p.errors && p.errors.length) {
    return "Uh Oh! There's a problem with this document. Please correct any issues and then re-submit.";
  }
  if ((p.myProgress === PROGRESS.COMPLETED || p.myProgress === PROGRESS.SUBMITTED) && (p.theirProgress === PROGRESS.COMPLETED || p.theirProgress === PROGRESS.SUBMITTED)) {
    return 'Everyone has completed this document';
  }
  if (p.myProgress === PROGRESS.WARN) {
    return 'Someone has edited this document that you already signed. Please re-sign to complete your application.';
  }
  if (p.myProgress !== PROGRESS.COMPLETED && p.myProgress !== PROGRESS.SUBMITTED) {
    return p.optional ? 'This document is optional' : 'You need to complete this document';
  }
  return "You're all done, we're just waiting for someone else to complete this document";
};

const ProgressGroup = ({
  title, progress, groupName, first, noBorder, matterId,
}) => {
  const routePath = `/matter/${matterId}/${groupName}`;

  const history = useHistory();

  return (
    <ProgressContainer noBorder={noBorder}>
      <Row>
        <GroupSummaryLink to={routePath} marginRight="20px" />
        <Title marginTop="12px">{title}</Title>
      </Row>
      <ProgressIconContainer first={first} noBorder={noBorder}>
        {progress && progress.map((p) => (
          <Popover
            key={p.id}
            position="bottom"
            bg="white"
            offsetPosition={{ left: '-105px' }}
            contentMargin="0"
            borderTop="0.5px solid #E7EAEC"
            onClick={() => { history.push(`${routePath}/${p.id}`); }}
          >
            <ProgressLink pt={3} m="3" to={`${routePath}/${p.id}`} progress={p} key={p.id} />
            <Column pt={5}>
              <DocumentTitle>{p.name}</DocumentTitle>
              <Text fontSize="1">{getProgressMessage(p)}</Text>
            </Column>
          </Popover>
        ))}
      </ProgressIconContainer>
    </ProgressContainer>
  );
};

ProgressGroup.defaultProps = {
  noBorder: false,
  progress: null,
  title: '',
  first: false,
};

ProgressGroup.propTypes = {
  noBorder: PropTypes.bool,
  progress: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    myProgress: PropTypes.string,
    theirProgress: PropTypes.string,
  })),
  title: PropTypes.string,
  first: PropTypes.bool,
  groupName: PropTypes.string.isRequired,
  matterId: PropTypes.number.isRequired,
};

StepsToCompletion.propTypes = {
};

export default StepsToCompletion;
