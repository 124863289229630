import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  PageLoading, Box, Header,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import Container from 'components/Container';
import Layout from 'components/Layout';
import Error from 'components/Error';
import MatterCard from './components/MatterCard';

const MatterList = () => {
  const [matterData, setMatterData] = useState({ matters: [], loading: true, loadingError: false });
  const { user, authToken } = useAuth();
  const history = useHistory();

  useEffect(() => {
    async function fetchData() {
      const response = await fetch('api/matters', { headers: { Authorization: `Bearer ${authToken}` } });
      const data = await response.json();
      setMatterData({
        matters: data.succeeded ? data.result.matters : [],
        loading: false,
        loadingError: !data.succeeded,
      });
    }
    if (authToken && matterData.loading) fetchData();
  }, [authToken, history, matterData.loading, user]);

  if (matterData.loading) return <PageLoading />;
  if (matterData.loadingError) return <Error />;

  return (
    <Layout>
      <Container center>
        <Box p={[3, 0]}>
          <Header color="#ffffff" mb={24}>
            <Header.Title>{`Hi ${user.firstName}`}</Header.Title>
            { (matterData.matters.length < 0)
              ? (
                <Box mt={[5, 6, 7]}>
                  Your documents will be available soon. Please wait for an email from us, or check back later.
                </Box>
              )
              : (
                <Box mt={[5, 6, 7]}>
                  Welcome to Kwil
                </Box>
              )}
          </Header>
          {matterData.matters && matterData.matters.map((matter) => (
            <MatterCard key={matter.matterId} matter={matter} />
          ))}
        </Box>
      </Container>
    </Layout>
  );
};

export default MatterList;
