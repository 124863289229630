import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  PageLoading, Box, Header,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import Container from 'components/Container';
import Layout from 'components/Layout';
import Error from 'components/Error';
import BorrowerCard from 'screens/components/BorrowerCard';

const BorrowerList = ({ match }) => {
  const [borrowerData, setBorrowerData] = useState({
    borrowers: [], loading: true, loadingError: false,
  });
  const {
    user, authToken, support,
  } = useAuth();
  const { isTenantSupport, setters } = support;
  const history = useHistory();
  const matterId = match.params.id;

  useEffect(() => {
    async function fetchBorrowersToEmulate() {
      const response = await fetch(`api/matters/${matterId}/borrowers`, { headers: { Authorization: `Bearer ${authToken}` } });
      const data = await response.json();
      setBorrowerData({
        borrowers: data.succeeded ? data.result.borrowers : [],
        loading: false,
        loadingError: !data.succeeded,
      });
    }
    if (authToken && borrowerData.loading) fetchBorrowersToEmulate();
  }, [authToken, history, borrowerData.loading, user, matterId]);

  useEffect(() => {
    if (!borrowerData.borrowers.length || borrowerData.borrowers.length > 1) return;
    if (borrowerData.borrowers.length === 1) {
      setters.setSupportViewUserId(borrowerData.borrowers[0].id);
      history.goBack();
    }
  }, [borrowerData, setters, history]);

  if (borrowerData.loading) return <PageLoading />;
  if (borrowerData.loadingError) return <Error />;

  return (
    <Layout>
      <Container center width="30%">
        <Box p={[3, 0]}>
          <Header color="#ffffff" mb={24}>
            <Header.Title>
              {isTenantSupport && 'Hi Support Staff'}
              {(user && !isTenantSupport) ? `Hi ${user.firstName}` : ''}
            </Header.Title>
            { (borrowerData.borrowers.length === 0)
              ? (
                <Box mt={[5, 6, 7]}>
                  There are no borrowers on this matter or you do not have access
                </Box>
              )
              : (
                <Box mt={[5, 6, 7]}>
                  Please pick a borrower to view as
                </Box>
              )}
          </Header>
          {borrowerData.borrowers && borrowerData.borrowers.map((borrower) => (
            <BorrowerCard key={borrower.id} borrower={borrower} />
          ))}
        </Box>
      </Container>
    </Layout>
  );
};

BorrowerList.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default BorrowerList;
