import { httpRequest } from '../utils';

export const getMe = async (token) => httpRequest('/api/users/me', 'get', null, { Authorization: `Bearer ${token}` });

export const getBorrower = async (token, borrowerId) => httpRequest(`/api/users/borrower/${borrowerId}`, 'get', null, { Authorization: `Bearer ${token}` });

export const getUserByEmail = async (token, email) => httpRequest(`/api/users/${email}`, 'get', null, { Authorization: `Bearer ${token}` });

export const unVerifyBroker = async (token, id) => httpRequest(`/api/users/${id}/unverify`, 'post', null, { Authorization: `Bearer ${token}` });

export const updateUser = async (token, borrowerId, payload) => {
  const config = {
    method: 'post',
    credentials: 'omit',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  config.body = JSON.stringify(payload);
  config.headers = { ...config.headers, Authorization: `Bearer ${token}` };
  return fetch(`${window.location.origin}/api/users/${borrowerId}`, config)
    .then((response) => response.json());
};

export const resendWelcomeEmail = async (token, payload) => httpRequest('/api/users/resendwelcome', 'post', payload, { Authorization: `Bearer ${token}` });

export const renameBroker = async (token, payload) => httpRequest('/api/brokers/rename', 'post', payload, { Authorization: `Bearer ${token}` });
