import React from 'react';
import CollaborateProvider from 'state/collaborate/CollaborateProvider';
import Collaborate from './Collaborate';

export default function CollaborateRoute() {
  return (
    <CollaborateProvider>
      <Collaborate />
    </CollaborateProvider>
  );
}
