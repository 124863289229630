import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Button, Row, Box, Text, IconSection, ErrorSummary,
} from '@galilee/lilee';
import { setContactUsAction } from 'actions/Marketing';
import TextBoxField from 'components/TextBoxField';
import { validateField, validateForm } from 'utils';

const marketingThemeProps = {
  mb: '3px',
  pt: '18px',
  pb: '18px',
  boxShadow: 'none',
  bg: 'base80',
  fontSize: 2,
};

const FormRow = styled(Row)``;

const ContactUs = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [company, setCompany] = useState('');
  const [sent, setSent] = useState(null);

  const [errors, setErrors] = useState({});
  const errorList = Object.values(errors).filter((error) => error !== null);

  const fields = {
    name: {
      name: 'name',
      displayName: 'Name',
      getValue: () => name,
      setValue: (e) => setName(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your full name' },
      },
    },
    email: {
      name: 'email',
      displayName: 'Email',
      getValue: () => email,
      setValue: (e) => setEmail(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your email address' },
      },
    },
    phoneNumber: {
      name: 'phoneNumber',
      displayName: 'Phone Number',
      getValue: () => phoneNumber,
      setValue: (e) => setPhoneNumber(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your Phone Number' },
      },
    },
    company: {
      name: 'company',
      displayName: 'Company',
      getValue: () => company,
      setValue: (e) => setCompany(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your company' },
      },
    },
  };

  const submitContactUs = async () => {
    const validationErrors = validateForm(fields);
    const validationIsValid = Object.values(validationErrors).every((error) => error === null);
    setErrors(validationErrors);
    if (validationIsValid) {
      const contactUs = {
        name,
        email,
        phoneNumber,
        company,
      };
      await setContactUsAction(contactUs);
      setSent(true);
      setName('');
      setEmail('');
      setPhoneNumber('');
      setCompany('');
    }
  };

  const validateElement = (fieldName) => {
    const error = validateField(fieldName, fields);
    setErrors({ ...errors, [fieldName]: error });
  };

  if (sent) {
    return (
      <IconSection
        size="9"
        iconMargin="6"
        width="100%"
        iconColor="base80"
        titleColor="white"
        titleSize="21px"
        titleCase="initial"
        p="4"
        mb="4"
        iconName="Send"
        title="Sent!"
      >
        <Text color="base20">
          Thanks for your interest, we&apos;ll be in touch soon.
        </Text>
      </IconSection>
    );
  }

  return (
    <>
      <ErrorSummary errors={errorList} title="There were errors" mb={[5, 6]} />

      <FormRow>
        <TextBoxField
          noLabel
          {...marketingThemeProps}
          placeholder={fields.name.displayName}
          fieldConfig={fields.name}
          validate={() => validateElement(fields.name.name)}
          fieldError={errors[fields.name.name]}
        />
      </FormRow>

      <FormRow>
        <TextBoxField
          noLabel
          {...marketingThemeProps}
          placeholder={fields.email.displayName}
          fieldConfig={fields.email}
          validate={() => validateElement(fields.email.name)}
          fieldError={errors[fields.email.name]}
        />
      </FormRow>

      <FormRow>
        <TextBoxField
          noLabel
          {...marketingThemeProps}
          placeholder={fields.phoneNumber.displayName}
          fieldConfig={fields.phoneNumber}
          validate={() => validateElement(fields.phoneNumber.name)}
          fieldError={errors[fields.phoneNumber.name]}
        />
      </FormRow>

      <FormRow>
        <TextBoxField
          noLabel
          {...marketingThemeProps}
          placeholder={fields.company.displayName}
          fieldConfig={fields.company}
          validate={() => validateElement(fields.company.name)}
          fieldError={errors[fields.company.name]}
        />
      </FormRow>

      <Box width="100%">
        <Button
          fullWidthBreakpointIndex="0"
          color="tertiary"
          onClick={() => submitContactUs()}
        >
          Register
        </Button>
        { sent === false && <Text>Hmmm, something went wrong, please try again.</Text> }
      </Box>
    </>
  );
};

export default ContactUs;
