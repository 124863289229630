import React, { useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import styled from 'styled-components/macro';
import {
  Select, Icon, H7, H4, Text, Flex, Checkbox, mediaQueries,
} from '@galilee/lilee';
import { useMatterMaker } from 'state/matterMaker/MatterMakerProvider';

const { lessThan } = mediaQueries;

const BorrowerContainer = styled(Flex)`
  background-color: white;
  width: 100%;
  padding:${(p) => p.theme.space[7]};
  border-radius: 15px;
  justify-content: space-evenly;
  ${lessThan[0]`
    flex-direction: column;
  `}
`;

const ColumnContainer = styled(Flex)`
  flex-direction: column;
`;

export const GuarantorSelect = () => {
  const { state, actions } = useMatterMaker();
  const { borrowerOneId, borrowerTwoId } = state.createMatter;
  const [checked, setChecked] = useState(false);
  const borrowerOptions = state.settings.options.borrowers;
  const [selectedGuarantorOne, setSelectedGuarantorOne] = useState(-1);
  const [selectedGuarantorTwo, setSelectedGuarantorTwo] = useState(-1);

  const guarantorOnChange = async (guarantorSelection) => {
    if (!guarantorSelection) return;

    const request = {
      createMatter: guarantorSelection,
    };
    actions.setBorrower(request);
  };

  const guarantor1Options = state.createMatter.isCommercial
    ? borrowerOptions.filter((o) => o.value !== selectedGuarantorTwo.value) : borrowerOptions.filter((o) => o.value !== selectedGuarantorTwo.value
    && o.mobile !== selectedGuarantorTwo.mobile
    && o.value !== borrowerOneId && o.value !== borrowerTwoId);

  const guarantor2Options = state.createMatter.isCommercial
    ? borrowerOptions.filter((o) => o.value !== selectedGuarantorOne.value) : borrowerOptions.filter((o) => o.value !== selectedGuarantorOne.value
    && o.mobile !== selectedGuarantorOne.mobile
    && o.value !== borrowerOneId && o.value !== borrowerTwoId);

  return (
    <ColumnContainer
      mb="5"
      mt="5"
    >
      <H4 mt="5">Select Guarantors (optional)</H4>
      <H7
        mb="5"
        mt="5"
      >
        Guarantor one
      </H7>
      <Select
        mb="2"
        enableSearch
        options={guarantor1Options}
        onChange={(borrower) => {
          setSelectedGuarantorOne({ value: borrower.value, mobile: borrower.mobile });
          guarantorOnChange({ guarantorOneId: borrower.value });
        }}
        rightIcon={<Icon color="base40" name="NavDown" size="14px" />}
        type="default"
      />
      <Checkbox
        mt="5"
        mb="2"
        label="(Optional) Second guarantor?"
        checked={checked}
        onChange={() => {
          if (checked) guarantorOnChange({ guarantorTwoId: -1 });
          setChecked(!checked);
        }}
      />
      {(checked)
        && (
          <>
            <H7
              mb="5"
              mt="5"
            >
              Guarantor two
            </H7>
            <Select
              mb="5"
              enableSearch
              options={guarantor2Options}
              onChange={(borrower) => {
                setSelectedGuarantorTwo({ value: borrower.value, mobile: borrower.mobile });
                guarantorOnChange({ guarantorTwoId: borrower.value });
              }}
              rightIcon={<Icon color="base40" name="NavDown" size="14px" />}
              type="default"
            />
          </>
        )}
    </ColumnContainer>
  );
};

export const GuarantorsDisplay = () => {
  const { state } = useMatterMaker();
  const { guarantorOneId, guarantorTwoId } = state.createMatter;
  const { borrowers } = state.settings;

  const borrowerOne = borrowers.find((b) => b.id === guarantorOneId);
  const borrowerTwo = borrowers.find((b) => b.id === guarantorTwoId);

  const toggle = borrowerOne || borrowerTwo;

  return toggle ? (
    <BorrowerContainer>
      {borrowerOne && <Guarantor guarantor={borrowerOne} />}
      {borrowerTwo && <Guarantor guarantor={borrowerTwo} />}
    </BorrowerContainer>
  ) : null;
};

const Guarantor = ({ guarantor }) => {
  if (!guarantor) return null;
  const {
    email, mobile, firstName, lastName,
  } = guarantor;
  return (
    <ColumnContainer>
      <H4 mb="5" mt="5">
        {`${firstName}`}
        &nbsp;
        {`${lastName}`}
      </H4>
      <Text>{email}</Text>
      <Text>{mobile}</Text>
    </ColumnContainer>
  );
};

Guarantor.propTypes = {
  guarantor: shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
