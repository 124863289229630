import React from 'react';
import BrokerProfileProvider from 'state/brokerprofile/BrokerProfileProvider';
import BrokerProfile from './BrokerProfile';

export default function BrokerProfileRoute() {
  return (
    <BrokerProfileProvider>
      <BrokerProfile />
    </BrokerProfileProvider>
  );
}
