import React, {
  createContext, useContext, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { HubConnectionBuilder } from '@microsoft/signalr';

const uploadContext = createContext('');

function getInitialState(data) {
  return {
    ...data,
    uploadDocumentMeta: (data.uploadDocumentMeta ? {
      documentsUploaded: { ...data.uploadDocumentMeta },
      uploadDocumentId: data.uploadDocumentId,
    } : null),
  };
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_EMPTY_ATTACHMENT':
      return {
        ...state,
        ...action.payload,
      };
    case 'SET_REJECTION_REASON':
      return {
        ...state,
        uploadDocumentRejectionReasons: state.uploadDocumentRejectionReasons.map((r) => ({ ...r, chosen: r.id === action.payload })),
        uploadDocumentMeta: {
          documentsUploaded: null,
          uploadDocumentId: state.uploadDocumentId,
        },
      };
    case 'SET_DOC_UPDATE':
    {
      if (action.payload == null) return { ...state };
      const { documentsUploaded, rejectionReasons } = action.payload;
      const uploadDocumentMeta = { documentsUploaded, uploadDocumentId: state.uploadDocumentId };
      return {
        ...state,
        uploadDocumentRejectionReasons: rejectionReasons,
        uploadDocumentMeta,
      };
    }
    case 'SET_UPLOAD_DOCUMENTS':
      return {
        ...state,
        uploadDocumentRejectionReasons: state.uploadDocumentRejectionReasons.map((r) => ({ ...r, chosen: false })),
        uploadDocumentMeta: action.payload,
      };
    default:
      return state;
  }
};

const createHubConnection = (uploadDocumentId, authToken) => new HubConnectionBuilder()
  .withUrl(`/uploaddocshub?uploadDocId=${uploadDocumentId}`, { accessTokenFactory: () => authToken })
  .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 120000, 180000])
  .build();

const useUploadHook = (authToken, data, applicationDispatch) => {
  const [state, dispatch] = useReducer(reducer, getInitialState(data));
  const { uploadDocumentId } = data;

  useEffect(() => {
    const connection = createHubConnection(uploadDocumentId, authToken);

    connection.onreconnecting(() => applicationDispatch({ type: 'SET_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter' }));
    connection.onreconnected(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter' }));

    connection
      .on('UploadDocUpdate', (uploadDocUpdate) => {
        dispatch({
          type: 'SET_DOC_UPDATE',
          payload: uploadDocUpdate,
        });
      });

    connection.start()
      .then(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_ERROR', payload: 'Matter' }))
      .catch(() => applicationDispatch({ type: 'SET_WEBSOCKET_ERROR', payload: 'Matter' }));

    return () => {
      if (connection) connection.stop();
    };
  }, [dispatch, authToken, uploadDocumentId, applicationDispatch]);
  return { state, dispatch };
};

export const useUploadDoc = () => useContext(uploadContext);

const UploadProvider = ({
  authToken, data, applicationDispatch, ...rest
}) => {
  const value = useUploadHook(authToken, data, applicationDispatch);
  return <uploadContext.Provider value={value} {...rest} />;
};

UploadProvider.propTypes = {
  authToken: PropTypes.string.isRequired,
  data: PropTypes.shape({
    name: PropTypes.string.isRequired,
    summary: PropTypes.string.isRequired,
    uploadDocumentRejectionReasons: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        chosen: PropTypes.bool.isRequired,
      }),
    ),
    uploadDocumentInstructions: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
      }),
    ),
  }).isRequired,
  applicationDispatch: PropTypes.func.isRequired,
};

export default UploadProvider;
