import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, NavLink } from 'react-router-dom';
import styled, { withTheme } from 'styled-components/macro';
import {
  Flex, H4, Popover, Text, themeProps, Hide, Show, Icon, SideBar, utils, mediaQueries, Row,
} from '@galilee/lilee';
import UserType from 'enums/UserType';
import { useAuth } from '../../../state/AuthProvider';
import { ApplicationPaths } from '../../api-authorization/ApiAuthorizationConstants';
import UserIcon from '../../UserIcon';
import CircleIcon from '../../CircleIcon';
import { useWindowSize, calcProgress } from '../../../utils';
import StyledLink from '../../Link';

const { useOnClickOutside } = utils;
const { lessThan } = mediaQueries;

const NavItemContainer = styled(Flex)`
  flex: 1;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  ${lessThan[1]`
    flex-direction: row-reverse;  
  `}
`;

const NavBar = ({
  theme, matter, matterProgress, theirProgresses,
}) => {
  const [openMobileMenu, setOpenMobileMenu] = useState(false);
  const { user } = useAuth();
  const size = useWindowSize();
  const hamburgerMenuRef = useRef();

  useOnClickOutside(hamburgerMenuRef, () => setOpenMobileMenu(false));

  if (!user || !theme) return null;
  const isInMatter = matter && matter.matterId;
  const isMobile = size.width <= Number(theme.breakpoints[1].replace('px', ''));
  const isAdmin = user.userType === UserType.Admin;
  const isBroker = user.userType === UserType.Broker;

  return (
    <NavItemContainer pr={[4, 0]}>
      <Hide breakpoints={[0, 1]}>
        <NavLink exact activeClassName="is-active-nav-link" to="/home">
          <CircleIcon iconSize="sm" name="BulletList" mt={6} mx={6} mb={7} />
        </NavLink>

        {isAdmin && (
        <NavLink exact activeClassName="is-active-nav-link" to="/matter-maker">
          <CircleIcon iconSize="sm" name="Stack" mx={6} mb={7} />
        </NavLink>
        )}

        {isAdmin && (
        <NavLink exact activeClassName="is-active-nav-link" to="/update-user">
          <CircleIcon iconSize="sm" name="Badge" mx={6} mb={7} />
        </NavLink>
        )}

        {isBroker && (
          <>
            <NavLink exact activeClassName="is-active-nav-link" to="/broker-profile">
              <CircleIcon iconSize="sm" name="User" mx={6} mb={7} />
            </NavLink>
            <NavLink exact activeClassName="is-active-nav-link" to="/collaborate">
              <CircleIcon iconSize="sm" name="Send" mx={6} mb={7} />
            </NavLink>
          </>
        )}

        {isInMatter && (
          <NavLink exact activeClassName="is-active-nav-link" to={`/matter/${matter.matterId}`}>
            <CircleIcon iconSize="sm" name="Home" mx={6} mb={7} />
          </NavLink>
        )}
      </Hide>
      <Show breakpoints={[0, 1]}>
        <div ref={hamburgerMenuRef}>
          <CircleIcon bg="primary" color="white" iconSize="sm" name="Hamburger" ml={4} onClick={() => setOpenMobileMenu(true)} />
          <SideBar open={openMobileMenu} setOpen={setOpenMobileMenu} positioning={{ position: 'right', distance: '0' }} translateX={{ from: '0px', to: '500px' }}>
            <StyledLink to="/home" onClick={() => setOpenMobileMenu(false)} mb={7}>
              <Row>
                <Icon name="BulletList" size="16px" mr="4" />
                <H4 color="base">All Matters</H4>
              </Row>
            </StyledLink>

            {isInMatter && (
              <StyledLink to={`/matter/${matter.matterId}`} onClick={() => setOpenMobileMenu(false)} mb={7}>
                <Row>
                  <Icon name="Home" size="16px" mr="4" />
                  <H4 color="base">Summary</H4>
                </Row>
              </StyledLink>
            )}

            {isAdmin && (
            <StyledLink to="/matter-maker" onClick={() => setOpenMobileMenu(false)} mb={7}>
              <Row>
                <Icon name="Stack" size="16px" mr="4" />
                <H4 color="base">Matter Maker</H4>
              </Row>
            </StyledLink>
            )}

            {isAdmin && (
            <StyledLink to="/update-user" onClick={() => setOpenMobileMenu(false)} mb={7}>
              <Row>
                <Icon name="Badge" size="16px" mr="4" />
                <H4 color="base">Update borrower</H4>
              </Row>
            </StyledLink>
            )}

            {isBroker && (
              <>
                <StyledLink to="/collaborate" onClick={() => setOpenMobileMenu(false)} mb={7}>
                  <Row>
                    <Icon name="User" size="16px" mr="4" />
                    <H4 color="base">Collaborate</H4>
                  </Row>
                </StyledLink>
                <StyledLink to="/broker-profile" onClick={() => setOpenMobileMenu(false)} mb={7}>
                  <Row>
                    <Icon name="User" size="16px" mr="4" />
                    <H4 color="base">Profile</H4>
                  </Row>
                </StyledLink>
              </>
            )}

            <StyledLink to={{ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } }} mb={7}>
              <Row>
                <Icon name="Logout" size="16px" mr="4" />
                <H4 color="base">Logout</H4>
              </Row>
            </StyledLink>
          </SideBar>
        </div>
      </Show>
      {isInMatter && (
        <Show breakpoints={[0, 1]}>
          <NavLink exact activeClassName="is-active-nav-link" to={`/matter/${matter.matterId}`}>
            <CircleIcon iconSize="sm" name="Home" ml={4} />
          </NavLink>
        </Show>
      )}
      {isInMatter && matterProgress && (
        matter.borrowers.map((borrower) => {
          const isMe = user.id === borrower.id;
          let status = 'Not started';
          const progress = isMe ? matterProgress : theirProgresses.find((mp) => mp.userId === borrower.id);

          if (progress) {
            const { percentage } = calcProgress(progress, true, borrower.id);
            if (percentage > 0) status = `${percentage}% Complete`;
          }

          return (
            <Popover
              key={borrower.id}
              position={isMobile ? 'bottom' : 'right'}
              bg="white"
              offsetPosition={isMobile ? { right: 0 } : null}
              contentMargin="14px"
              arrowPosition={isMobile ? '229px' : '21px'}
            >
              <UserIcon isMe={isMe} active={isMe} mb={isMobile ? 0 : 6} ml={isMobile ? 4 : 0} />
              <Popover.Content bg="white" width="250px" p="6">
                <H4>
                  {borrower.firstName}
                  {' '}
                  {borrower.lastName}
                </H4>
                <Text fontSize="1">{status}</Text>
              </Popover.Content>
            </Popover>
          );
        })
      )}
      <Hide breakpoints={[0, 1]}>
        <Link to={{ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } }}>
          <CircleIcon iconSize="sm" name="Logout" ml={isMobile ? 4 : 0} />
        </Link>
      </Hide>

    </NavItemContainer>
  );
};

NavBar.defaultProps = {
  theirProgresses: null,
  matter: null,
  matterProgress: null,
};

NavBar.propTypes = {
  theme: themeProps.isRequired,
  matter: PropTypes.shape({
    matterId: PropTypes.number,
    borrowers: PropTypes.arrayOf(PropTypes.shape({
    })),
  }),
  matterProgress: PropTypes.shape({
  }),
  theirProgresses: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.string.isRequired,
    readDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    signDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    uploadDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  })),
};

export default withTheme(NavBar);
