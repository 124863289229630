import { httpRequest } from 'utils';
import PROGRESS from 'enums/Progress';
import { errorDispatcher } from './actionUtils';

export const fetchMatterAction = async (dispatch, matterId, token) => {
  try {
    const data = await httpRequest(`/api/matters/${matterId}`, 'get', null, { Authorization: `Bearer ${token}` });
    dispatch({ type: 'SET_MATTER', payload: data });
  } catch (error) {
    dispatch({ type: 'SET_MATTER', payload: { succeeded: false } });
  }
};

export const fetchMatterSecurityAction = async (dispatch, matterId, token, currentPage, orderBy, ascending = true, pageSize = 20) => {
  try {
    const response = await httpRequest(
      `/api/matters/${matterId}/tracking?page=${currentPage}&pageSize=${pageSize}&OrderBy=${orderBy}&Ascending=${ascending}`,
      'get',
      null,
      { Authorization: `Bearer ${token}` },
    );
    return response;
  } catch (error) {
    throw errorDispatcher(dispatch, "We couldn't load the security tracking info at the moment, please reload the page to try again.");
  }
};

export const fetchMatterProgressAction = async (dispatch, applicationDispatch, matterId, userId, token) => {
  const data = await httpRequest(`/api/matters/${matterId}/progressperuser`, 'get', null, { Authorization: `Bearer ${token}` });
  if (data && data.matterProgressPerUser && data.matterProgressPerUser.length) {
    const currentUserProgress = data.matterProgressPerUser.find((mp) => mp.userId === userId);
    const theirProgresses = data.matterProgressPerUser.filter((mp) => mp.userId !== userId);
    dispatch({
      type: 'SET_MATTER_PROGRESS',
      payload: {
        matterProgress: currentUserProgress,
        theirProgresses,
      },
    });
  }
  // Ignore matter progress errors because if the matter doesn't load then the matter progress won't either.
};

export const unSignAction = async (dispatch, matterDispatch, docId, token) => {
  matterDispatch({
    type: 'SET_OPTIMISTIC_PROGRESS',
    payload: {
      theirProgress: PROGRESS.NOTSTARTED,
      documentId: docId,
      stage: 'sign',
      progress: {
        myProgress: PROGRESS.NOTSTARTED,
      },
    },
  });
  httpRequest(`/api/signdocuments/${docId}/unsign`, 'post', null, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(dispatch, "We couldn't edit this document, please reload the page to try again."));
};

export const setDocumentPrintAction = async (dispatch, matterDispatch, docId, token) => {
  matterDispatch({
    type: 'SET_OPTIMISTIC_PROGRESS',
    payload: {
      theirProgress: PROGRESS.COMPLETED,
      documentId: docId,
      stage: 'sign',
      progress: {
        myProgress: PROGRESS.COMPLETED,
      },
    },
  });
  httpRequest(`/api/signdocuments/${docId}/print`, 'post', null, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(dispatch, "We couldn't mark this document as printed at the moment, please reload the page to try again."));
};

export const setDocumentSignAction = async (dispatch, matterDispatch, docId, token) => {
  matterDispatch({
    type: 'SET_OPTIMISTIC_PROGRESS',
    payload: {
      documentId: docId,
      stage: 'sign',
      progress: {
        myProgress: PROGRESS.COMPLETED,
      },
    },
  });
  httpRequest(`/api/signdocuments/${docId}/sign`, 'post', null, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(dispatch, "We couldn't sign this document right now, please reload the page to try again."));
};

export const setSignDocumentReadAction = async (dispatch, matterDispatch, docId, token) => {
  matterDispatch({
    type: 'SET_OPTIMISTIC_PROGRESS',
    payload: {
      documentId: docId,
      stage: 'sign',
      progress: {
        myProgress: PROGRESS.COMPLETED,
      },
    },
  });
  httpRequest(`/api/signdocuments/${docId}/read`, 'post', null, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(dispatch, "We couldn't mark this document as read right now, please reload the page to try again."));
};

export const setDocumentSignWitnessAction = async (dispatch, docId, witness, token) => {
  httpRequest(`/api/signdocuments/${docId}/signwitness`, 'post', witness, { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })
    .catch(() => errorDispatcher(dispatch, "We couldn't witness this document right now, please reload the page to try again."));
};

export const setDocumentSignatureImageUpload = async (dispatch, applicationDispatch, matterId, userId, base64image, signatureType, token) => {
  httpRequest(`/api/matters/${matterId}/signature`, 'post', { base64image, signatureType }, { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })
    .then(() => dispatch({ type: 'SET_BORROWER_SIGNATURE', payload: { userId, hasSignature: true } }))
    .catch(() => errorDispatcher(applicationDispatch, "We couldn't upload your signature, please reload the page to try again."));
};

export const setDocumentReadAction = async (dispatch, matterDispatch, docId, token) => {
  matterDispatch({
    type: 'SET_OPTIMISTIC_PROGRESS',
    payload: {
      documentId: docId,
      stage: 'read',
      progress: {
        myProgress: PROGRESS.COMPLETED,
      },
    },
  });
  httpRequest(`/api/readdocuments/${docId}`, 'post', null, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(dispatch, "We couldn't mark this document as read at the moment, please reload the page to try again."));
};

export const setSubmitMatterAction = async (matterId, token) => httpRequest(`/api/matters/${matterId}/submit`, 'post', null, { Authorization: `Bearer ${token}` });

export const consentAction = async (dispatch, applicationDispatch, matterId, token, userId, consented) => {
  httpRequest(`/api/matters/${matterId}/consent`, 'post', { consented }, { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })
    .then(() => dispatch({ type: 'SET_CONSENT', payload: { consented, userId } }))
    .catch(() => errorDispatcher(applicationDispatch, "We couldn't save your consent status, please reload the page to try again."));
};

export const welcomeReadAction = async (dispatch, applicationDispatch, matterId, token, userId) => {
  httpRequest(`/api/matters/${matterId}/welcomescreen`, 'post', null, { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })
    .then(() => dispatch({ type: 'SET_WELCOME_SCREEN', payload: { shownWelcomeScreen: true, userId } }))
    .catch(() => errorDispatcher(applicationDispatch, "Well this is strange, we couldn't dismiss the welcome screen, please reload the page to try again."));
};

export const acknowledgeNotesAction = async (dispatch, applicationDispatch, matterId, token, userId) => {
  httpRequest(`/api/matters/${matterId}/acknowledgenotes`, 'post', null, { Authorization: `Bearer ${token}`, 'Content-Type': 'application/json' })
    .then(() => dispatch({ type: 'ACKNOWLEDGE_NOTES', payload: { matterNotesAcknowledged: true, userId } }))
    .catch(() => errorDispatcher(applicationDispatch, "Well this is strange, we couldn't let you acknowledge these terms, please reload the page to try again."));
};
