import React from 'react';
import MatterList from 'screens/manage/matterList';
import ManageProvider from 'state/manage/ManageProvider';

export default function ManageHome() {
  return (
    <ManageProvider>
      <MatterList />
    </ManageProvider>
  );
}
