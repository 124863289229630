import React, { useState } from 'react';
import { useTrackNTraceAdmin } from 'state/track/TrackNTraceAdminProvider';
import { toast } from '@galilee/lilee';
import { useHistory } from 'react-router';
import Layout from './Layout';
import CallbackCard from './CallbackCard';
import CallbackSearchBar from './CallbackSearchBar';

const CallbackList = () => {
  const { callbacks } = useTrackNTraceAdmin();
  const [selected, setSelected] = useState('');
  const [error, setError] = useState(null);

  const history = useHistory();

  const handleSelect = (value) => {
    if (error) {
      setError(null);
    }
    setSelected(value);
  };

  const handleSubmit = (value) => {
    try {
      history.push(`/track/${value}`);
    } catch (err) {
      toast.error('Matter ID does not exist');
      setError(err);
    }
  };

  return (
    <Layout title="Callback Priority List" top={<CallbackSearchBar onSubmit={handleSubmit} />}>
      {
        callbacks.map((c) => (
          <CallbackCard
            key={c.callBackId}
            onSelect={() => handleSelect(c.callBackId)}
            isSelected={c.callBackId === selected}
            errored={!!error}
            {...c}
          />
        ))
      }
    </Layout>
  );
};

export default CallbackList;
