import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon, Popover, H4, Text,
} from '@galilee/lilee';
import ActiveNavLink from 'components/ActiveNavLink';

const GroupSummaryLink = ({ to }) => (
  <ActiveNavLink to={to}>
    <Popover
      position="bottom"
      bg="white"
      offsetPosition={{ left: '-105px' }}
      contentMargin="0"
      cursor="pointer"
    >
      <Icon m="3" color="primary" name="InfoCircle" />
      <Popover.Content bg="white" width="250px" p="6" boxShadow="0px 0px 40px rgba(7,36,56,0.15)">
        <H4>
          Section Summary
        </H4>
        <Text fontSize="1">
          Click here to download a copy of your documents
        </Text>
      </Popover.Content>
    </Popover>
  </ActiveNavLink>
);

GroupSummaryLink.propTypes = {
  to: PropTypes.string.isRequired,
};

export default GroupSummaryLink;
