import React from 'react';
import { Route, Switch } from 'react-router';
import FourOhFour from 'screens/fourOhFour';
import Conference from './Conference';
import Home from './home';

const Marketing = () => (
  <Switch>
    <Route path="/conference" component={Conference} />
    <Route exact path="/" component={Home} />
    <Route component={FourOhFour} />
  </Switch>
);

export default Marketing;

Marketing.propTypes = {
};
