import React from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Column, FieldError } from '@galilee/lilee';

const CheckBoxField = ({ fieldConfig, fieldError }) => {
  const {
    name, displayName, getValue, setValue,
  } = fieldConfig;
  return (
    <Column>
      <Checkbox
        name={name}
        label={displayName}
        checked={getValue()}
        onChange={setValue}
      />
      <FieldError error={fieldError} />
    </Column>
  );
};

CheckBoxField.defaultProps = {
  fieldError: null,
};

CheckBoxField.propTypes = {
  fieldConfig: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    getValue: PropTypes.func,
    setValue: PropTypes.func,
  }).isRequired,
  fieldError: PropTypes.string,
};

export default CheckBoxField;
