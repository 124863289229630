import React from 'react';
import PropTypes from 'prop-types';
import {
  Radio, Box, Label, Flex, FieldError, utils,
} from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';
import useDynamicFieldProps from './useDynamicFieldProps';

const { isNumeric } = utils;

const RadioList = ({ fieldName, readOnly }) => {
  const { errors, field, fieldProps } = useDynamicFieldProps(fieldName);
  const { fieldValues } = useDynamicForm();
  const {
    id, name, value, ...props
  } = fieldProps;

  const shouldHideField = (condition, conditionValue, fieldRef, values) => {
    let fieldValue = values[fieldRef];
    switch (condition) {
      case 'eq':
        return fieldValue?.toString() !== conditionValue.toString();
      case 'gt':
      {
        let convertedConditionValue = conditionValue;
        if (isNumeric(fieldValue)) fieldValue = Number(fieldValue);
        if (isNumeric(conditionValue)) convertedConditionValue = Number(conditionValue);
        return !(fieldValue > convertedConditionValue);
      }
      case 'lt':
      {
        let convertedConditionValue = conditionValue;
        if (isNumeric(fieldValue)) fieldValue = Number(fieldValue);
        if (isNumeric(conditionValue)) convertedConditionValue = Number(conditionValue);
        return !(fieldValue < convertedConditionValue);
      }
      default:
        return false;
    }
  };

  return (
    <Box width="100%">
      <Label htmlfor={fieldName}>{field.labelText}</Label>
      <Flex flexDirection="column">
        { field.options.map((option) => {
          const { key, value: optionValue, showIf } = option;
          let isHidden = false;
          if (showIf) {
            const { condition, fieldRef, conditionValue } = showIf;
            isHidden = shouldHideField(condition, conditionValue, fieldRef, fieldValues);
          }

          return (isHidden ? null : <Radio disabled={readOnly} key={key} mt="3" mb="3" label={optionValue} checked={value === key} value={key} {...props} />);
        })}
      </Flex>
      <FieldError error={errors[fieldName]} />
    </Box>
  );
};

RadioList.propTypes = {
  fieldName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default RadioList;
