import React from 'react';
import Helmet from 'react-helmet';
import googlePlay from 'images/googlePlay.png';
import appStore from 'images/appStore.png';
import digIdDownload from 'images/DigIDDownload.png';
import digIdSetup from 'images/DigIDSetup.png';
import digIdVerify from 'images/DigIdVerify.png';
import ColinWentWrong from 'images/ColinWentWrong.png';
import logo from 'images/logo-trans-light.png';
import Concurrency from 'images/Concurrency.png';
import ColinNotFound from 'images/ColinNotAvailable.png';
import hero from 'images/HeroImage.png';
import heroChristmas from 'images/HeroChristmas.png';
import gateway from 'images/Gateway.png';
import mortgageDirect from 'images/MortgageDirect.png';
import origin from 'images/Origin.png';
import columbus from 'images/ColumbusCapital.png';
import adelaide from 'images/AdelaideBank.png';
import homeStar from 'images/HomeStar.png';
import crownMoney from 'images/CrownMoney.png';
import ezy from 'images/MortgageEzy.png';
import colinTheCop from 'images/ColinTheCop.png';
import colinDoctor from 'images/ColinDoctor.png';
import colinAstro from 'images/ColinAstro.png';
import colinEnviro from 'images/ColinEnviro.png';
import colinVisibility from 'images/ColinVisibility.png';
import HowLink1 from 'images/HowLink1.png';
import HowLink2 from 'images/HowLink2.png';
import HowLink3 from 'images/HowLink3.png';
import HowLink4 from 'images/HowLink4.png';
import HowLink5 from 'images/HowLink5.png';
import HowLink6 from 'images/HowLink6.png';
import HowWitnessing from 'images/HowWitnessing.png';
import HowWorkflow from 'images/HowWorkflow.png';
import HowIntegrate from 'images/HowIntegrate.png';
import HowAccept from 'images/HowAccept.png';
import HowTrack from 'images/HowTrack.png';
import HowSettlement from 'images/HowSettlement.png';
import HowFallover from 'images/HowFallover.png';
import ConsentImg from 'images/Consent.png';
import changeDocument from 'images/ChangedDocument.png';
import complete from 'images/Completed.png';
import rejectedDocument from 'images/RejectedDocument.png';
import finalised from 'images/finalised.png';
import started from 'images/Started.png';
import submitDocuments from 'images/SubmitDocuments.png';
import submittedDocuments from 'images/SubmittedDocuments.png';
import zeroPercent from 'images/ZeroPercent.png';
import Incomplete from 'images/Incomplete.png';
import LiveSign from 'images/LiveSign.png';
import Verify from 'images/Verify.svg';
import VerifySuccess from 'images/VerifySuccess.svg';
import VerifyError from 'images/VerifyError.svg';
import Unsign from 'images/Unsign.png';
import read from 'images/read.png';
import sign from 'images/sign.png';
import upload from 'images/upload.png';
import LandingChart from 'images/LandingChart.png';

const ImagePreloader = () => (
  <Helmet>
    <link rel="preload" href={googlePlay} as="image" />
    <link rel="preload" href={appStore} as="image" />
    <link rel="preload" href={digIdDownload} as="image" />
    <link rel="preload" href={digIdSetup} as="image" />
    <link rel="preload" href={digIdVerify} as="image" />
    <link rel="preload" href={ColinWentWrong} as="image" />
    <link rel="preload" href={logo} as="image" />
    <link rel="preload" href={Concurrency} as="image" />
    <link rel="preload" href={ColinNotFound} as="image" />
    <link rel="preload" href={hero} as="image" />
    <link rel="preload" href={heroChristmas} as="image" />
    <link rel="preload" href={gateway} as="image" />
    <link rel="preload" href={mortgageDirect} as="image" />
    <link rel="preload" href={origin} as="image" />
    <link rel="preload" href={columbus} as="image" />
    <link rel="preload" href={adelaide} as="image" />
    <link rel="preload" href={homeStar} as="image" />
    <link rel="preload" href={crownMoney} as="image" />
    <link rel="preload" href={ezy} as="image" />
    <link rel="preload" href={colinTheCop} as="image" />
    <link rel="preload" href={colinDoctor} as="image" />
    <link rel="preload" href={colinAstro} as="image" />
    <link rel="preload" href={colinEnviro} as="image" />
    <link rel="preload" href={colinVisibility} as="image" />
    <link rel="preload" href={HowLink1} as="image" />
    <link rel="preload" href={HowLink2} as="image" />
    <link rel="preload" href={HowLink3} as="image" />
    <link rel="preload" href={HowLink4} as="image" />
    <link rel="preload" href={HowLink5} as="image" />
    <link rel="preload" href={HowLink6} as="image" />
    <link rel="preload" href={HowWitnessing} as="image" />
    <link rel="preload" href={HowWorkflow} as="image" />
    <link rel="preload" href={HowIntegrate} as="image" />
    <link rel="preload" href={HowAccept} as="image" />
    <link rel="preload" href={HowTrack} as="image" />
    <link rel="preload" href={HowSettlement} as="image" />
    <link rel="preload" href={HowFallover} as="image" />
    <link rel="preload" href={ConsentImg} as="image" />
    <link rel="preload" href={changeDocument} as="image" />
    <link rel="preload" href={complete} as="image" />
    <link rel="preload" href={rejectedDocument} as="image" />
    <link rel="preload" href={finalised} as="image" />
    <link rel="preload" href={started} as="image" />
    <link rel="preload" href={submitDocuments} as="image" />
    <link rel="preload" href={submittedDocuments} as="image" />
    <link rel="preload" href={zeroPercent} as="image" />
    <link rel="preload" href={Incomplete} as="image" />
    <link rel="preload" href={LiveSign} as="image" />
    <link rel="preload" href={Verify} as="image" />
    <link rel="preload" href={VerifySuccess} as="image" />
    <link rel="preload" href={VerifyError} as="image" />
    <link rel="preload" href={Unsign} as="image" />
    <link rel="preload" href={read} as="image" />
    <link rel="preload" href={sign} as="image" />
    <link rel="preload" href={upload} as="image" />
    <link rel="preload" href={LandingChart} as="image" />
  </Helmet>
);

export default ImagePreloader;
