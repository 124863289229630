import React from 'react';
import PropTypes from 'prop-types';
import { UL, LI } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupUL = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data } = fields[fieldName];
  return <UL m={0}>{data.map((value) => (<LI key={fieldName + value}>{value}</LI>))}</UL>;
};

MarkupUL.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupUL;
