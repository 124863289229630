import React from 'react';
import Layout from 'components/Layout';
import styled from 'styled-components/macro';
import {
  PageLoading, Listing, mediaQueries, Hide, Button, H4, Input, Row, Flex, UL, LI,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import TitleContainer from 'components/TitleContainer';
import { useUpdateBorrower } from 'state/updateBorrower/UpdateBorrowerProvider';
import FourOhFour from 'screens/fourOhFour';
import DisplayBorrower from './components/EditUser';
import SubmitPopup from './components/SubmitPopup';

const { greaterThan } = mediaQueries;

const ItemList = styled(Listing)`
  margin: 0;
  padding: ${(p) => p.theme.space[5]} ${(p) => p.theme.space[6]};
  ${greaterThan[1]`
     padding: ${(p) => p.theme.space[6]} ${(p) => p.theme.space[9]};
  `}
`;

const BorrowerContainer = styled(Flex)`
  background-color: white;
  padding:${(p) => p.theme.space[7]};
  border-radius: 15px;
  width: 100%;
  flex-direction: column;
`;

const UpdateBorrower = () => {
  const { authToken, isAdminOrTenantAdmin } = useAuth();
  const { actions, state } = useUpdateBorrower();

  const {
    id, email,
  } = state.user;

  const borrowerOnChange = (value) => {
    if (!value || value === email) return;
    state.setBorrower({ email: value });
  };

  const onSearch = async () => {
    if (!email) return;
    await actions.getBorrower(email);
  };

  const onSubmit = () => {
    if (!id || state.settings.readyToSubmit) return;
    state.setSettings({ readyToSubmit: true });
  };

  const onUnVerify = async () => {
    if (!id) return;
    await actions.unVerifyUser(id);
  };

  const resendWelcome = async () => {
    if (!email) return;
    await actions.resendWelcome(email);
  };

  const onClear = () => {
    state.setBorrower({
      id: null,
      mobile: null,
      phoneNumber: null,
      email: null,
      firstName: null,
      middleName: null,
      lastName: null,
      userType: null,
    });
  };

  if (!authToken) return <PageLoading />;

  if (!isAdminOrTenantAdmin) return <FourOhFour />;

  return (
    <Layout>
      <BorrowerContainer mt={[6, 7, 8]}>
        <Hide breakpoints={[0]}>
          <TitleContainer title="Manage users">
            <TitleContainer.WelcomeMessage>
              Please make sure you update users with accurate data.
              <br />
              <UL>
                <LI>Update user details</LI>
                <LI>Un-verify mobiles</LI>
                <LI>Resend welcome emails</LI>
              </UL>
            </TitleContainer.WelcomeMessage>
          </TitleContainer>
        </Hide>
        <Listing>
          {!id ? (
            <>
              <H4> Email </H4>
              <Input mt={[4, 4, 4]} id="email" name="email" onChange={(val) => borrowerOnChange(val.target.value)} disabled={!!id} />
              <Button mt={[4, 4, 4]} disabled={!!id} color="primary" onClick={() => onSearch()}>
                Search
              </Button>
            </>
          ) : (
            <>
              <DisplayBorrower />
              <Row>
                <Button disabled={!id} mt={[5, 6]} mr={[5, 6]} onClick={() => onSubmit()} color="secondary">Save</Button>
                <Button disabled={!id} mt={[5, 6]} onClick={() => onClear()} color="primary">Change user</Button>
              </Row>
              <H4 mt={[5, 6]}> Other options </H4>
              <Button disabled={!id} mt={[5, 6]} onClick={() => onUnVerify()} color="primary">Un-verify mobile</Button>
              <Button disabled={!id} mt={[5, 6]} onClick={() => resendWelcome()} color="primary">Resend welcome email</Button>
            </>
          )}
        </Listing>
        <ItemList />
      </BorrowerContainer>
      <SubmitPopup />
    </Layout>
  );
};

export default UpdateBorrower;
