import React, { useEffect } from 'react';
import { Route } from 'react-router';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Layout from 'components/Layout';
import { PageLoading } from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useApplication } from 'state/ApplicationProvider';
import MatterProvider, { useMatter } from 'state/MatterProvider';
import FourOhFour from 'screens/fourOhFour';

import { fetchMatterAction, fetchMatterProgressAction } from 'actions/Matter';
import MatterSummary from './summary';
import Read from './read';
import Security from './security';
import Sign from './sign';
import Upload from './upload';
import Consent from './components/Consent';
import NotConsented from './components/NotConsented';

const Matter = ({ match, ...props }) => {
  const history = useHistory();
  const {
    user, authToken, support,
  } = useAuth();
  const { isTenantSupport, supportViewUserId } = support;

  const { dispatch } = useApplication();

  if (!authToken || !user) return <PageLoading />;

  if (isTenantSupport && supportViewUserId == null) {
    history.push(`/support/${match.params.id}`);
    return null;
  }

  return (
    <MatterProvider matterId={match.params.id} authToken={authToken} userId={user.id} applicationDispatch={dispatch}>
      <MatterRouter path={match.path} matterId={match.params.id} user={user} isTenantSupport={isTenantSupport} authToken={authToken} {...props} />
    </MatterProvider>
  );
};

Matter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Matter;

const mustConsent = (user, matter) => matter.borrowers.some((b) => (b.consented === null && user.id === b.id));
const iNotConsented = (user, matter) => matter.borrowers.some((b) => (b.consented === false && user.id === b.id));
const someoneNotConsented = (matter) => matter.borrowers.some((b) => (b.consented === false));

const MatterRouter = ({
  matterId, user, authToken, path, isTenantSupport,
}) => {
  const { state, dispatch } = useMatter();
  const { dispatch: applicationDispatch } = useApplication();
  const matterLoaded = !!state && !!state.matter;

  useEffect(() => {
    if (dispatch && !matterLoaded) {
      fetchMatterAction(dispatch, matterId, authToken);
      fetchMatterProgressAction(dispatch, applicationDispatch, matterId, user.id, authToken);
    }
  }, [authToken, matterId, dispatch, applicationDispatch, matterLoaded, user.id]);

  if (state.matter && state.matter.succeeded === false) return <FourOhFour />;
  if (!isTenantSupport) {
    if (matterLoaded && mustConsent(user, state.matter)) return <Consent />;
    if (matterLoaded && someoneNotConsented(state.matter)) return <NotConsented iNotConsented={iNotConsented(user, state.matter)} />;
  }

  return (
    <Layout matter={state.matter} matterProgress={state.matterProgress} theirProgresses={state.theirProgresses}>
      {!matterLoaded && <PageLoading />}
      {matterLoaded && (
        <>
          <Route exact path={path} component={MatterSummary} />
          <Route path={`${path}/read`} component={Read} />
          <Route path={`${path}/sign`} component={Sign} />
          <Route path={`${path}/upload`} component={Upload} />
          <Route path={`${path}/security`} component={Security} />
        </>
      )}
    </Layout>
  );
};

MatterRouter.propTypes = {
  matterId: PropTypes.string.isRequired,
  user: PropTypes.shape({
    sub: PropTypes.string,
    id: PropTypes.string.isRequired,
  }).isRequired,
  authToken: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  isTenantSupport: PropTypes.bool.isRequired,
};
