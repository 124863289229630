import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { Flex, Image, Icon } from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { httpRequest } from 'utils';

const SigBox = styled(Flex)`
  padding: 10px;
  width: 100%;
  max-width: 500px;
  border: 1px solid ${(p) => p.theme.colors.base20};
  min-height: 150px;
  align-items: center;
  justify-content: center;
`;

const DisplaySignature = () => {
  const { state } = useMatter();
  const { authToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [sigUrl, setSigUrl] = useState(null);
  const { matterId } = state.matter;

  useEffect(() => {
    if (!loading && !sigUrl && authToken) {
      setLoading(true);
      httpRequest(`/api/matters/${matterId}/signature`, 'get', null, { Authorization: `Bearer ${authToken}` })
        .then((blob) => {
          setSigUrl(URL.createObjectURL(blob));
          setLoading(false);
        })
        .catch(() => {
          throw new Error('Unable to download signature.');
        });
    }
  }, [authToken, loading, matterId, sigUrl]);

  return (
    <SigBox>
      {loading && <Icon size="30" name="Spinner" stepping spin={false} />}
      {sigUrl && <Image src={sigUrl} alt="Signature" />}
    </SigBox>
  );
};

export default DisplaySignature;
