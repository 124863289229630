import { httpRequest } from 'utils';
import { errorDispatcher } from './actionUtils';

const getDataSectionPostfixId = (fieldName) => {
  const fieldSections = fieldName.split('_');
  if (fieldSections.length < 2) return '';
  const lastSection = fieldSections.pop();
  if (Number.isNaN(Number(lastSection))) return '';
  return `_${lastSection}`;
};

export const fetchSignData = async (applicationDispatch, docId, token) => {
  try {
    const result = await httpRequest(`/api/signdocuments/${docId}`, 'get', null, { Authorization: `Bearer ${token}` });

    const { signDocumentElements, ...rest } = result;

    const fieldNames = [];
    const validationConfig = { fields: {} };
    const deselectionConfig = {};
    const formConfig = {};
    const formValues = {};
    const fieldIds = {};

    signDocumentElements.forEach((element) => {
      const {
        componentMeta, name, signDocumentField, ...elementFormConfig
      } = element;

      const dataSectionPostFixId = getDataSectionPostfixId(name);

      fieldNames.push(name);

      formConfig[name] = { ...elementFormConfig };

      if (componentMeta) {
        let meta = null;

        meta = JSON.parse(componentMeta);
        // Add dynamic index to all fieldRef's if the element is a data section.
        if (dataSectionPostFixId) {
          if (meta.showIf) meta.showIf.fieldRef += dataSectionPostFixId;
          if (meta.showIfAny) {
            meta = {
              ...meta,
              showIfAny: {
                ...meta.showIfAny,
                fields: meta.showIfAny.fields.map((f) => {
                  const newField = f + dataSectionPostFixId;
                  return newField;
                }),
              },
            };
          }

          if (meta.showIfAll) {
            meta = {
              ...meta,
              showIfAll: {
                ...meta.showIfAll,
                fields: meta.showIfAll.fields.map((f) => ({ ...f, fieldRef: f.fieldRef + dataSectionPostFixId })),
              },
            };
          }

          if (meta.deselectionRules) {
            meta = {
              ...meta,
              deselectionRules: {
                ...meta.deselectionRules,
                fields: meta.deselectionRules.fields.map((dr) => ({ ...dr, fieldRef: dr.fieldRef + dataSectionPostFixId })),
              },
            };
          }
          if (meta.validationRules) {
            meta = {
              ...meta,
              validationRules: Object.fromEntries(Object.keys(meta.validationRules).map((key) => {
                const validationRule = meta.validationRules[key];
                if (validationRule.fieldRef) {
                  return [key, { ...validationRule, fieldRef: validationRule.fieldRef + dataSectionPostFixId }];
                }

                if (validationRule.fields) {
                  return [key, { ...validationRule, fields: validationRule.fields.map((f) => (f.fieldRef ? { ...f, fieldRef: f.fieldRef + dataSectionPostFixId } : (f + dataSectionPostFixId))) }];
                }

                return [key, validationRule];
              })),
            };
          }
        }

        const { validationRules, deselectionRules, ...componentMetaConfig } = meta;
        if (validationRules) validationConfig.fields[name] = validationRules;
        if (deselectionRules) deselectionConfig[name] = deselectionRules;
        formConfig[name] = { ...formConfig[name], ...componentMetaConfig };
      }

      if (signDocumentField) {
        let { value } = signDocumentField;
        if (element.componentType === 'CheckBox') value = value === 'true' ? 'true' : 'false';
        formValues[name] = value === null ? '' : value;
        fieldIds[name] = signDocumentField.id;
      }
    });

    return {
      ...rest,
      formConfig,
      validationConfig,
      deselectionConfig,
      formValues,
      fieldIds,
      docId,
      showErrors: 'blur',
    };
  } catch (error) {
    throw errorDispatcher(applicationDispatch, "We couldn't retrieve these sign document details from the server, please reload the page to try again.");
  }
};

export const setDocumentSignField = async (applicationDispatch, docId, fieldId, token, value) => {
  httpRequest(`/api/signDocuments/${docId}/field/${fieldId}`, 'post', { value }, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(applicationDispatch, "We couldn't contact the server to change this field, please reload the page to try again."));
};

export const clearDocumentSignFields = async (applicationDispatch, docId, fieldIds, token) => {
  httpRequest(`/api/signDocuments/${docId}/fields/clear`, 'post', { fieldIds }, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(applicationDispatch, "We couldn't contact the server, please reload the page to try again."));
};

export const preVerifySignDocument = async (token, signDocumentId) => {
  const result = await httpRequest(`/api/Verification/${signDocumentId}`, 'post', null, { Authorization: `Bearer ${token}` });
  return result;
};

export const removeSignDocumentVOI = async (token, signDocumentId) => httpRequest(`/api/Verification/${signDocumentId}`, 'delete', null, { Authorization: `Bearer ${token}` });
