import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Box, Text } from '@galilee/lilee';

const StyledBanner = styled(Box)`
  text-align: center;
  && a {
    color: white;
  }
`;

StyledBanner.defaultProps = {
  width: '100%',
  backgroundColor: 'primary',
  color: 'white',
};

const Banner = ({ children }) => (
  <StyledBanner>
    <Text color="white" p="4" fontSize="1" inline>
      {children}
    </Text>
  </StyledBanner>
);

Banner.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Banner;
