import React, { useState } from 'react';
import {
  Box, Panel, PageLoading, H3, P, Header, Show, Hide, Button, Icon, toast,
} from '@galilee/lilee';
import Container from 'components/Container';
import { useManageMatter } from 'state/ManageMatterProvider';
import MatterSummary from 'components/MatterSummary';
import LastAction from 'components/LastAction';
import { useAuth } from 'state/AuthProvider';
import MatterStatus from 'enums/MatterStatus';
import TitleContainer from 'components/TitleContainer';
import styled from 'styled-components/macro';
import { setSubmitMatterAction } from 'actions/Matter';
import ManageProgressGroup from '../components/ManageProgressGroup';

const StyledPanel = styled(Panel)`
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 14px 34px rgb(7 36 56 / 10%);
  padding: 0;
`;

const getSummaryMessage = (matter) => {
  if (!matter || !matter.matterStatus) return null;
  switch (matter.matterStatus) {
    case MatterStatus.Draft:
    case MatterStatus.NotStarted:
      return (
        <>
          <H3 m="0" mb="5">New matter created</H3>
          <P fontSize="2">You and your borrower need to check the details of the loan.</P>
          <P fontSize="2">If they are incorrect then please contact us immediately to have them amended.</P>
        </>
      );
    case MatterStatus.InProgress:
      return (
        <>
          <H3 m="0" mb="5">Matter in progress</H3>
          <P fontSize="2">The matter has been started, you can track it&apos;s progress below.</P>
        </>
      );
    case MatterStatus.Submitted:
    case MatterStatus.SubmittedAndReady:
      return (
        <>
          <H3 m="0" mb="5">Your Borrower has submitted their documents</H3>
          <P fontSize="2">All parties have completed all the necessary documents and have been submitted for review.</P>
        </>
      );
    case MatterStatus.UnderReview:
      return (
        <>
          <H3 m="0" mb="5">Under review</H3>
          <P fontSize="2">The submitted documents are under review, if there are any issues then the Borrowers will be notified.</P>
        </>
      );
    case MatterStatus.RequiresResign:
      return (
        <>
          <H3 m="0" mb="5">A document requires re-signing</H3>
          <P fontSize="2">A document was modified and needs re-signing, please ensure your borrower re-signs the document highlighted below.</P>
        </>
      );
    case MatterStatus.Errored:
      return (
        <>
          <H3 m="0" mb="5">A problem was identified</H3>
          <P fontSize="2">
            The document reviewer identified a problem with one or more documents,
            please review the feedback and ensure your borrowers fix up and re-submit their documents as soon as possible.
          </P>
        </>
      );
    case MatterStatus.Finalised:
      return (
        <>
          <H3 m="0" mb="5">Matter finalised</H3>
          <P fontSize="2">Great! Your all done here, everything is in order and has been submitted successfully..</P>
        </>
      );
    default:
      return null;
  }
};

const Summary = () => {
  const { state } = useManageMatter();
  const { matter, matterProgress } = state;
  const summaryMessage = getSummaryMessage(matter);
  const { user, authToken, isAdminOrTenantAdmin } = useAuth();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const readyToSubmit = state.dateToAutoSubmit != null;
  const statusNotAllowDeleteDocuments = [MatterStatus.Finalised, MatterStatus.Submitted, MatterStatus.SubmittedAndReady, MatterStatus.UnderReview];

  const submit = async () => {
    if (!readyToSubmit || !isAdminOrTenantAdmin || isSubmitting) return;
    setIsSubmitting(true);
    try {
      await setSubmitMatterAction(matter.matterId, authToken);
      toast.success('Matter submitted successfully.');
      matter.matterStatus = MatterStatus.Submitted;
    } catch (error) {
      toast.error(`${error}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!matter || !matterProgress) return <PageLoading />;

  return (
    <Container center>
      <Box p={[3, 0]}>
        <Show breakpoints={[1, 0]}>
          <TitleContainer title={`#${matter.senderRef}`}>
            { summaryMessage && <Box mt={[5, 6, 7]}>{ summaryMessage }</Box> }
            { readyToSubmit && isAdminOrTenantAdmin && !statusNotAllowDeleteDocuments.includes(matter.matterStatus) && (
            <Button
              width="200px"
              mt={7}
              noBorder
              onClick={() => submit()}
              leftIcon={isSubmitting ? <Icon name="loading" color="base80" spin /> : <Icon name="Document" color="secondary" />}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Now'}
            </Button>
            )}
          </TitleContainer>
        </Show>
        <Hide breakpoints={[0, 1]}>
          <Header color="#ffffff" mb={24}>
            <Header.Title>{`#${matter.senderRef}`}</Header.Title>
            { summaryMessage && <Box mt={[5, 6, 7]}>{ summaryMessage }</Box> }
            { readyToSubmit && isAdminOrTenantAdmin && !statusNotAllowDeleteDocuments.includes(matter.matterStatus) && (
            <Button
              width="200px"
              mt={7}
              noBorder
              onClick={() => submit()}
              leftIcon={isSubmitting ? <Icon name="loading" color="base80" spin /> : <Icon name="Document" color="secondary" />}
            >
              {isSubmitting ? 'Submitting...' : 'Submit Now'}
            </Button>
            )}
          </Header>
        </Hide>
        <StyledPanel p={[5, 6, 7]}>
          <Box p={[7, 7, 8]}>
            <MatterSummary matter={state.matter} user={user} />
          </Box>
        </StyledPanel>
        <StyledPanel p={[5, 6, 7]}>
          <Box p={[7, 7, 8]}>
            <ManageProgressGroup
              matterId={matter.matterId}
              title="Read &amp; Accept"
              groupName="read"
              progress={matterProgress.readDocuments}
              allowDelete={false}
            />
            <ManageProgressGroup
              matterId={matter.matterId}
              title="Complete &amp; Sign"
              groupName="sign"
              progress={matterProgress.signDocuments}
              allowDelete={isAdminOrTenantAdmin && !statusNotAllowDeleteDocuments.includes(matter.matterStatus)}
            />
            <ManageProgressGroup
              matterId={matter.matterId}
              title="Upload Documents"
              groupName="upload"
              progress={matterProgress.uploadDocuments}
              isLast
              allowDelete={false}
            />
          </Box>
        </StyledPanel>
        <LastAction lastAction={state.matter.lastAction} path={`/matter/${state.matter.matterId}/security`} />
      </Box>
    </Container>
  );
};

export default Summary;
