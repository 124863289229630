import React, {
  createContext, useContext, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import { HubConnectionBuilder } from '@microsoft/signalr';
import { useAuth } from 'state/AuthProvider';
import { deleteSignDocument, fetchManageMatterAction } from 'actions/Manage';
import { removeSignDocumentVOI } from 'actions/Sign';
import { toast } from '@galilee/lilee';

const manageMatterContext = createContext('');

const initialState = {
  matter: null,
  matterProgress: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MATTER':
    {
      const {
        readDocuments, signDocuments, uploadDocuments, dateToAutoSubmit, ...rest
      } = action.payload;
      return {
        ...state,
        matter: rest,
        matterProgress: { readDocuments, signDocuments, uploadDocuments },
        dateToAutoSubmit,
      };
    }
    case 'SET_MATTER_PROGRESS':
    {
      const {
        readDocuments, signDocuments, uploadDocuments, dateToAutoSubmit,
      } = action.payload;
      return {
        ...state,
        matterProgress: { readDocuments, signDocuments, uploadDocuments },
        dateToAutoSubmit,
      };
    }
    default:
      return state;
  }
};

const createHubConnection = (matterId, authToken) => new HubConnectionBuilder()
  .withUrl(`/brokermatterhub?matterId=${matterId}`, { accessTokenFactory: () => authToken })
  .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 120000, 180000])
  .build();

const useManageMatterHook = (matterId, applicationDispatch) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user, authToken } = useAuth();
  const userId = user.id;

  useEffect(() => {
    const connection = createHubConnection(matterId, authToken);

    connection.onreconnecting(() => applicationDispatch({ type: 'SET_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter Progress' }));
    connection.onreconnected(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter Progress' }));

    connection
      .on('BrokerMatterProgress', (updatedMatterProgressResponse) => {
        const {
          readDocuments, signDocuments, uploadDocuments, dateToAutoSubmit,
        } = updatedMatterProgressResponse;
        dispatch({
          type: 'SET_MATTER_PROGRESS',
          payload: {
            readDocuments,
            signDocuments,
            uploadDocuments,
            dateToAutoSubmit,
          },
        });
      });

    connection.start()
      .then(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_ERROR', payload: 'Matter Progress' }))
      .catch(() => applicationDispatch({ type: 'SET_WEBSOCKET_ERROR', payload: 'Matter Progress' }));

    return () => {
      connection.stop();
    };
  }, [dispatch, applicationDispatch, authToken, matterId, userId]);

  const actions = {
    deleteSignDocument: async (id) => {
      await deleteSignDocument(id, applicationDispatch, authToken);
      await fetchManageMatterAction(dispatch, applicationDispatch, matterId, authToken);
    },
    removeSignDocumentVOI: async (id) => {
      try {
        await removeSignDocumentVOI(authToken, id);
        toast.success('VOS has been removed.');
      } catch (error) {
        toast.error('We couldn\'t contact the server, please reload the page to try again.');
      }
      await fetchManageMatterAction(dispatch, applicationDispatch, matterId, authToken);
    },
  };

  return { state, actions, dispatch };
};

export const useManageMatter = () => useContext(manageMatterContext);

const ManageMatterProvider = ({
  matterId, applicationDispatch, ...rest
}) => {
  const value = useManageMatterHook(matterId, applicationDispatch);
  return <manageMatterContext.Provider value={value} {...rest} />;
};

ManageMatterProvider.propTypes = {
  matterId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  applicationDispatch: PropTypes.func.isRequired,
};

export default ManageMatterProvider;
