import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Flex, Icon, VimeoModal, Box, mediaQueries,
} from '@galilee/lilee';

const { lessThan } = mediaQueries;

const StyledIcon = styled(Icon)`
  background-color: #ffffffe8;
  width: 90px;
  height: 90px;
  color: ${(p) => p.theme.colors.baseOpacity60};
  border-radius: ${(p) => p.theme.radii[3]};
`;

const StyledVideoPlayer = styled(Flex)`
  cursor: pointer;
  box-shadow: ${(p) => p.theme.shadows.xl};
  background-color: ${(p) => p.theme.colors.base80};
  background: url(${(p) => `${p.sectionType}.png`});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: ${(p) => p.theme.radii[3]};
  justify-content: center;
  align-items: center;
  min-height: 200px;
  margin: ${(p) => p.theme.space[5]};
  max-width: 100%;
  ${lessThan[1]`
    min-height: 250px;
    margin-left: ${(p) => p.theme.space[6]};
  `}
  ${lessThan[2]`
    min-height: 250px;
    margin-left: ${(p) => p.theme.space[7]};
  `}
  ${lessThan[3]`
    min-height: 280px;
    margin-left: ${(p) => p.theme.space[9]};
  `}
  &:hover {
    ${StyledIcon} {
      background-color: #ffffffe8;
      color: ${(p) => p.theme.colors.baseOpacity80};
    }
  }
`;

const VideoPlayer = ({ videoId, sectionType, ...rest }) => {
  const [playVideo, setPlayVideo] = useState(false);

  return (
    <>
      { playVideo && <VimeoModal maxWidth="1024px" vimeoId={videoId} close={() => setPlayVideo(false)} /> }
      <Box width="100%" onClick={() => setPlayVideo(true)}>
        <StyledVideoPlayer sectionType={sectionType} {...rest}>
          <StyledIcon name="Play" />
        </StyledVideoPlayer>
      </Box>
    </>
  );
};

VideoPlayer.propTypes = {
  videoId: PropTypes.string.isRequired,
  sectionType: PropTypes.string.isRequired,
};

export default VideoPlayer;
