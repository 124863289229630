import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Icon as LileeIcon, Flex } from '@galilee/lilee';

const calcSize = (p) => {
  switch (p.iconSize) {
    case 'xs':
      return '24px';
    case 'sm':
      return '42px';
    case 'md':
      return '42px';
    default:
      return '48px';
  }
};

const calcIconSize = (p) => {
  switch (p.iconSize) {
    case 'xs':
      return '12px';
    case 'sm':
      return '16px';
    case 'md':
      return '24px';
    default:
      return '24px';
  }
};

const CircleWrapper = styled(Flex)`
  justify-content: center;
  align-items: center;
  width: ${calcSize};
  height: ${calcSize};
  display: inline-flex;
  border-radius: 24px;
  flex-shrink: 0;
  user-select: none;
  opacity: ${(p) => (p.disabled ? '0.2' : '1')};
  cursor: ${(p) => (p.disabled ? 'default' : 'pointer')};
  visibility: ${(p) => (p.hideInPlace ? 'hidden' : 'visible')};
`;

const Icon = styled(LileeIcon)`
  && {
    width: ${calcIconSize};
    height: ${calcIconSize};
    position: relative;
    color: ${(p) => p.theme.colors[p.color]}
  }
`;

const CircleIcon = ({
  iconSize, color, name, hideInPlace, ...rest
}) => (
  <CircleWrapper hideInPlace={hideInPlace} iconSize={iconSize} {...rest}>
    <Icon iconSize={iconSize} color={color} name={name} />
  </CircleWrapper>
);

CircleIcon.defaultProps = {
  bg: 'white',
  color: 'primary',
  iconSize: 'md',
  hideInPlace: false,
  disabled: false,
};

CircleIcon.propTypes = {
  iconSize: PropTypes.oneOf(['xs', 'sm', 'md']),
  bg: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  hideInPlace: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default CircleIcon;
