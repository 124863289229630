const formatResponse = (response) => {
  const accHowManyMatters = [...new Array(response.matterLimit)].map((_, index) => ({ value: index + 1, label: (index + 1).toString() }));

  const borrowerOptions = response.borrowers.map((b) => ({ value: b.id, label: b.email, mobile: b.mobile }));

  const enabledLendersOptions = response.enabledLenders.map((l) => ({ value: l.id, label: l.name }));

  const tenantOptions = response.tenants
    .sort((a) => (a.name.toLowerCase().includes('matter') ? -1 : 0))
    .map((t) => ({ value: t.id, label: t.name }));

  const options = {
    options: {
      howManyMatters: accHowManyMatters,
      borrowers: borrowerOptions,
      enabledLenders: enabledLendersOptions,
      tenants: tenantOptions,
    },
  };
  const createMatter = {
    createMatter: {
      howManyMatters: accHowManyMatters[0].value,
      lenderId: enabledLendersOptions[0]?.value,
      tenantId: tenantOptions[0]?.value,
    },
  };
  const matterMaker = {
    ...response,
    ...options,
    ...createMatter,
  };

  return (matterMaker);
};

export default function applicationReducer(state, action) {
  switch (action.type) {
    case 'SET_MATTER_MAKER': {
      const response = formatResponse(action.payload);
      return {
        ...state,
        settings: {
          ...state.settings,
          ...response,
          options: {
            ...state.settings.options,
            ...response.options,
          },
        },
        createMatter: {
          ...state.createMatter,
          ...response.createMatter,
        },
      };
    }
    case 'UPDATE_PAYLOAD':
      return {
        ...state,
        createMatter: {
          ...state.createMatter,
          ...action.payload,
        },
      };
    case 'SET_BORROWER':
      return {
        ...state,
        createMatter: {
          ...state.createMatter,
          ...action.payload.createMatter,
        },
      };
    case 'UPDATE_SETTINGS':
      return {
        ...state,
        settings: {
          ...state.settings,
          ...action.settings,
        },
      };
    default:
      return state;
  }
}
