import { httpRequest } from 'utils/index';

export const fetchMatterMakerData = async (token) => {
  const response = httpRequest('/api/matterMaker', 'get', null, { Authorization: `Bearer ${token}` });
  return response;
};

export const createMatterAction = async (token, payload) => {
  const response = httpRequest('/api/matterMaker/create', 'post', payload, { Authorization: `Bearer ${token}` });
  return response;
};
