import React from 'react';
import PropTypes from 'prop-types';
import {
  Absolute, Panel, P,
} from '@galilee/lilee';
import Container from 'components/Container';
import styled from 'styled-components/macro';
import LinkButton from 'components/LinkButton';

const FullScreen = styled(Absolute)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.base05};
`;

const NotConsented = ({ iNotConsented }) => (
  <FullScreen top="0" bottom="0" left="0" right="0">
    <Container center>
      <Panel>
        <Panel.Title>Electronic Document Signing Rejected</Panel.Title>
        <Panel.Section>
          <P>
            {iNotConsented ? 'You have ' : 'Another Borrower on the matter has '}
            requested paper documents, you will receive them in the post shortly.
          </P>
          <P>
            If you think this was done in error please use the Live Chat to contact our support team and they can help you out.
          </P>
        </Panel.Section>
        <Panel.ActionContainer justifyContent="flex-end">
          <LinkButton color="secondary" to="/home">Finish</LinkButton>
        </Panel.ActionContainer>
      </Panel>
    </Container>
  </FullScreen>
);

NotConsented.propTypes = {
  iNotConsented: PropTypes.bool.isRequired,
};

export default NotConsented;
