import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'state/AuthProvider';
import TimeAgo from 'react-timeago';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Flex, H6, Box, mediaQueries, Button,
} from '@galilee/lilee';
import { DocStatusIcon } from 'components/ProgressIcons';
import { useManageMatter } from 'state/ManageMatterProvider';

const { lessThan, greaterThan } = mediaQueries;

const ProgressContainer = styled(Box)`
  padding-bottom: ${(p) => (p.isLast ? '0' : p.theme.space[5])};
  margin-bottom: ${(p) => (p.isLast ? '0' : p.theme.space[6])};
  border-bottom: 2px solid ${(p) => (p.isLast ? 'transparent' : '#E7EAEC')};
`;

const ProgressRow = styled(Flex)`
  cursor: pointer;
  ${lessThan[0]`
    flex-direction: column;  
  `}
  border-bottom: 1px solid #E7EAEC;
  &:hover {
    background-color: ${(p) => p.theme.colors.baseOpacity04};
  }
  &:last-child {
    border-color: transparent;
  }
`;

ProgressRow.defaultProps = {
  py: 4,
  px: 2,
};

const DocumentSummaryWrapper = styled(Flex)`
  flex-direction: column;  
  ${greaterThan[0]`
    flex-direction: row;  
  `}
`;

const ManageProgressGroup = ({
  title, progress, groupName, matterId, isLast, allowDelete,
}) => {
  const history = useHistory();
  const { isAdminOrTenantAdmin } = useAuth();
  const { actions } = useManageMatter();
  const routePath = `/matter/${matterId}/${groupName}`;
  const getProgressMessage = (p) => {
    switch (p.documentStatus) {
      case 1:
        return '';
      case 2:
        return `Completed by ${p.completedBy.join(' and ')}`;
      case 3:
        return (
          <>
            Completed by everyone&nbsp;
            <TimeAgo date={p.completed} />
          </>
        );
      default:
        return '';
    }
  };

  if (!progress || !progress.length) return null;
  return (
    <ProgressContainer isLast={isLast}>
      <H6 mb="4">{title}</H6>
      {progress && progress.map((p) => (
        <ProgressRow>
          <DocumentSummaryWrapper flex="9" onClick={() => history.push(`${routePath}/${p.id}`)} key={p.id}>
            <Flex flex="3">
              <DocStatusIcon documentStatus={p.documentStatus} mr="4" />
              {p.name}
            </Flex>
            <Flex flex="5">{getProgressMessage(p)}</Flex>
          </DocumentSummaryWrapper>
          <Flex flex="1">
            {(p.requiresVerification && isAdminOrTenantAdmin) && (
            <Button
              size="small"
              color="secondary"
              mr="4"
              mt={['4', null]}
              onClick={() => actions.removeSignDocumentVOI(p.id)}
              width="150px"
            >
              Remove VOS
            </Button>
            )}
            {allowDelete && <Button size="small" color="primary" mr="4" mt={['4', null]} onClick={() => actions.deleteSignDocument(p.id)}>Delete</Button>}
          </Flex>
        </ProgressRow>
      ))}
    </ProgressContainer>
  );
};

ManageProgressGroup.defaultProps = {
  progress: null,
  title: '',
  isLast: false,
  allowDelete: false,
};

ManageProgressGroup.propTypes = {
  progress: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    myProgress: PropTypes.string,
    theirProgress: PropTypes.string,
  })),
  title: PropTypes.string,
  groupName: PropTypes.string.isRequired,
  matterId: PropTypes.number.isRequired,
  isLast: PropTypes.bool,
  allowDelete: PropTypes.bool,
};

export default ManageProgressGroup;
