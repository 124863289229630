import React, { useState } from 'react';
import styled from 'styled-components/macro';
import {
  Box, Flex, Text, Image, Hide, Row, Column, H2, VimeoModal, utils,
} from '@galilee/lilee';
import Container from 'components/Container';
import logo from 'images/logo-trans-light.png';
import hero from 'images/HeroImage.png';
import heroChristmas from 'images/HeroChristmas.png';
import gateway from 'images/Gateway.png';
import pepper from 'images/Pepper.png';
import mortgageDirect from 'images/MortgageDirect.png';
import origin from 'images/Origin.png';
import columbus from 'images/ColumbusCapital.png';
import adelaide from 'images/AdelaideBank.png';
import homeStar from 'images/HomeStar.png';
import mebank from 'images/MEBank.png';
import crownMoney from 'images/CrownMoney.png';
import ezy from 'images/MortgageEzy.png';
import colinTheCop from 'images/ColinTheCop.png';
import colinDoctor from 'images/ColinDoctor.png';
import colinAstro from 'images/ColinAstro.png';
import colinEnviro from 'images/ColinEnviro.png';
import colinVisibility from 'images/ColinVisibility.png';
import LinkButton from 'components/LinkButton';
import ColinCard from './components/ColinCard';
import DidYouKnow from './components/DidYouKnow';
import ContactUs from './components/ContactUs';
import HowItWorks from './components/HowItWorks';
import {
  HeroContainer,
  PartnerContainer,
  WhySwitchContainer,
  ContactUsContainer,
  HeroTitleContainer,
  HeroSection,
  HeroImageContainer,
  HeroTitle,
  HeroText,
  ColinColumn,
  HeroImage,
  PartnerTitle,
  PartnerImage,
  NavLink,
  HowItWorksContainer,
} from './components/Containers';

const { useScrollToTop } = utils;

const PartnerColumn = styled(Column)`
  flex: ${(p) => p.flex};
`;

PartnerColumn.defaultProps = {
  flexDirection: 'row',
  flex: '3',
};

const Marketing = () => {
  useScrollToTop(true);
  const isChristmas = (new Date()).getMonth() === 11;
  const heroImage = isChristmas ? heroChristmas : hero;
  const defaultVideoId = isChristmas ? '377416577' : '371758282';
  const [showVideoId, setShowVideoId] = useState(null);
  return (
    <>
      {showVideoId && <VimeoModal maxWidth="1024px" vimeoId={showVideoId} close={() => setShowVideoId(null)} />}
      <HeroContainer>
        <Flex justifyContent="space-between" p={[7, null, null, '44px']} pb={[7, 7, 9]}>
          <Image height="58px" src={logo} alt="Logo" />
          <Flex>
            <Hide breakpoints={[0, 1]}>
              <NavLink smooth to="#whyDigital">Why digital mortgage document signing?</NavLink>
              <NavLink smooth to="#howItWorks">How it works</NavLink>
            </Hide>
            <LinkButton color="tertiary" to="./home">Log in</LinkButton>
          </Flex>
        </Flex>
        <Container maxWidth="1241px" center>
          <HeroSection>
            <HeroTitleContainer>
              <HeroTitle fontSize={['42px', null, null, '58px']}>Digital mortgage document signing gets it done fast.</HeroTitle>
              <HeroText>
                Forget sending paper forms in the  post. Switching to a digital mortgage document signing solution that gets the job done faster, more efficiently and with less errors
              </HeroText>
              <LinkButton fullWidthBreakpointIndex="1" color="tertiary" onClick={() => window.open('https://share.hsforms.com/1o1DOT9xWRPq-nenXtGqxzA4sdxg', '_blank')}>Book a Demo</LinkButton>
            </HeroTitleContainer>
            <HeroImageContainer>
              <HeroImage onClick={() => setShowVideoId(defaultVideoId)} src={heroImage} />
            </HeroImageContainer>
          </HeroSection>
        </Container>
      </HeroContainer>
      <Box bg="base">
        <PartnerContainer>
          <Container maxWidth="1241px" center>
            <PartnerTitle>Empowering our financial institutions</PartnerTitle>
            <Row collapseIndex="3" flexDirection="row">
              <PartnerColumn flex="4">
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={pepper} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={gateway} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={ezy} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={columbus} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={crownMoney} />
                </Flex>
              </PartnerColumn>
              <PartnerColumn flex="4">
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={adelaide} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={mortgageDirect} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={origin} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={homeStar} />
                </Flex>
                <Flex flex="1 1 auto" justifyContent="center">
                  <PartnerImage src={mebank} />
                </Flex>
              </PartnerColumn>
            </Row>
          </Container>
        </PartnerContainer>
        <WhySwitchContainer id="whyDigital">
          <Container maxWidth="1241px" center>
            <Row collapseIndex="1">
              <ColinColumn>
                <Box p={[4, 0]} mt="50px">
                  <H2 fontSize={['36px', null, '48px']}>Why switch to digital mortgage document signing?</H2>
                  <Text color="base20" fontSize="18px"> Eliminate security risks, ensure a smoother settlement process and reduce your environmental footprint.</Text>
                </Box>
                <ColinCard
                  videoId="361960037"
                  image={colinTheCop}
                  title="Security"
                  body={[
                    'Delivering documents containing personally identifiable information often brings up concerns about security.',
                    'When they are sent in the post there are many opportunities for information to fall into the wrong hands, opening the door to identity theft.',
                    'KWIL integrates with livesign to securely verify a client at the precise point of signing, removing the risk of identity theft.',
                  ]}
                />
                <DidYouKnow
                  title="Paper documents take 7 days "
                  body="on average to be returned to you."
                  summary="60% of online documents are returned within the first 3 days."
                />
              </ColinColumn>
              <ColinColumn>
                <ColinCard
                  videoId="366895049"
                  image={colinAstro}
                  title="Speed"
                  body={[
                    'Receiving loan documents back quickly makes a big difference to streamlining your settlement process.',
                    `Sending documents by post adds delays to every step of the settlement process. They can easily be damaged, lost, stolen or simply forgotten about by your clients. 
                    In the event of a signing error, you’ll have to start the process all over again – risking a settlement delay.`,
                    'With digital signing, your documents will arrive instantly and securely in your client’s inbox, eliminating the risk of accidents or mishaps.',
                  ]}
                />
                <DidYouKnow
                  title="Paper documents take 3 days "
                  body="on average to arrive with your clients."
                  summary="Online documents are delivered instantly!"
                />
                <ColinCard
                  videoId="371256523"
                  image={colinVisibility}
                  title="Visibility"
                  body={[
                    'It’s often difficult to predict if or when loan documentation is going to be completed by your client.',
                    'After you’ve sent your documents in the post, there is no way to see what happens to them or where they go. You’re completely left in the dark.',
                    `With a digital signing process, you know exactly where your clients are up to in the process,
                         eliminating any concerns with when you will receive them back error free and in a timely manner.`,
                  ]}
                />
              </ColinColumn>
              <ColinColumn>
                <DidYouKnow
                  title="50% of paper documents"
                  body="are returned with an error of some kind."
                  summary="Whereas online documents are rarely returned with errors"
                />
                <ColinCard
                  videoId="371256483"
                  image={colinDoctor}
                  title="Fall overs"
                  body={[
                    'More often than not, your clients aren’t familiar with the requirements or process to complete their contracts.',
                    `When documents are returned in the post, it’s all too common to find various mistakes and
                         missing information. This can cause delays, or even end up in the "too hard basket" so your client decides not to proceed.`,
                    `When your documents are completed online,
                    you can assist in correcting any mistakes as soon
                    as they happen to avoid settlement delays and
                    ultimately your overall fall over rate.`,
                  ]}
                />
                <ColinCard
                  videoId="366897786"
                  image={colinEnviro}
                  title="Environment"
                  body={[
                    'We don’t need to lecture anybody on the need to reduce our environmental footprint and what impact paper use has on this.',
                    `When printed, loan documentation can be upwards of 50 pages, not to mention envelopes. 
                    If you’re completing 200 - 300 contracts per year you don’t need to do the maths to know that’s an awful lot of paper`,
                    'Digital signing minimises paper production and waste, which is critical to our environment’s welfare.',
                  ]}
                />
              </ColinColumn>
            </Row>
          </Container>
        </WhySwitchContainer>
      </Box>
      <HowItWorksContainer id="howItWorks">
        <HowItWorks />
      </HowItWorksContainer>
      <ContactUsContainer px={[7, 0]} py={[8, 8, 9]} id="ContactUs">
        <Container maxWidth="917px" center>
          <Row collapseIndex="0">
            <Column mb={[7, 7, 0]} mr={[0, 7, 8, 9]}>
              <H2 lineHeight="1em" mb="6" fontSize="48px">Interested?</H2>
              <Text mb="7" maxWidth="300px" color="base20" fontSize="18px">
                Register your interest and someone from the KWIL Team will get in contact to let you know how our solution can save your business time and money.
              </Text>
            </Column>
            <Column mb="0" alignItems="flex-start">
              <ContactUs />
            </Column>
          </Row>
        </Container>
      </ContactUsContainer>
    </>
  );
};

Marketing.propTypes = {
};

export default Marketing;
