import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Flex, mediaQueries } from '@galilee/lilee';
import Container from 'components/Container';
import NavBar, { desktopNav, mobileNav } from './NavBar';

const { lessThan } = mediaQueries;

const LayoutContainer = styled(Flex)`
    padding-left: ${desktopNav};
    flex-direction: column;
    min-height: 100%;
    ${lessThan[1]`
        flex-direction: row;
        padding-top: ${mobileNav};
        padding-left: 0;    
    `}
    overflow: hidden;
`;

const Center = styled(Flex)`
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

const Layout = ({
  children, matter, matterProgress, theirProgresses,
}) => (
  <>
    <NavBar matter={matter} matterProgress={matterProgress} theirProgresses={theirProgresses} />
    <LayoutContainer>
      <Center>
        <Container mt={[0, 7, 8]}>
          <Container center>
            {children}
          </Container>
        </Container>
      </Center>
    </LayoutContainer>
  </>
);

Layout.defaultProps = {
  children: null,
  matter: null,
  matterProgress: null,
  theirProgresses: null,
};

Layout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  matter: PropTypes.shape({}),
  matterProgress: PropTypes.shape({}),
  theirProgresses: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.string.isRequired,
    readDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    signDocuments: PropTypes.arrayOf(PropTypes.shape({})),
    uploadDocuments: PropTypes.arrayOf(PropTypes.shape({})),
  })),
};

export default Layout;
