import React from 'react';
import { H7 } from '@galilee/lilee';

// Maps to kwil.common.Entities.Enums.ProgressType

const PROGRESS = {
  NOTSTARTED: 'NotStarted',
  COMPLETED: 'Completed',
  LOCKED: 'Locked',
  WARN: 'Warn',
  SUBMITTED: 'Submitted',
};

export default PROGRESS;

export const toProgressName = (progress, ...rest) => {
  switch (progress) {
    case PROGRESS.NOTSTARTED:
      return <H7 color="base40" {...rest}>Not Started</H7>;
    case PROGRESS.COMPLETED:
      return <H7 fontWeight="medium" color="primary" {...rest}>Complete</H7>;
    case PROGRESS.LOCKED:
      return <H7 fontWeight="medium" color="primary" {...rest}>Finalised</H7>;
    case PROGRESS.WARN:
      return <H7 fontWeight="medium" color="warn" {...rest}>Needs Resigning</H7>;
    case PROGRESS.SUBMITTED:
      return <H7 fontWeight="medium" color="primary" {...rest}>Submitted</H7>;
    default:
      return <H7 fontWeight="medium" color="primary" {...rest}>{progress}</H7>;
  }
};
