import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Flex, H3, Tag, Icon, Text,
} from '@galilee/lilee';
import format from 'date-fns/format';

const MatterStepStatus = {
  Delayed: 'Delayed',
  Reinstructed: 'Reinstructed',
  InProgress: 'InProgress',
};

const StatusBadge = styled(Tag)`
  border-radius: ${(p) => p.theme.radii[2]};
  padding: ${(p) => p.theme.space[3]} ${(p) => p.theme.space[5]};
  font-weight: ${(p) => p.theme.fontWeights.medium};
  text-align: center;
`;

const UploadLink = styled(Flex)`
  align-self:start;
  text-decoration: underline;
  margin-top: ${(p) => p.theme.space[6]};
  color: ${(p) => p.theme.colors.secondary};
  cursor: pointer;
  font-weight: 500;
`;

const getStatus = (s) => {
  if (s.isDelay && !s.delayEndDate) {
    return {
      status: MatterStepStatus.Delayed, displayTag: 'Delayed', background: 'warn', color: 'white',
    };
  }

  if (s.isReinstruction) {
    return {
      status: MatterStepStatus.Reinstructed, displayTag: 'Re-instruction', background: 'base10', color: 'base60',
    };
  }

  return {
    status: MatterStepStatus.InProgress, displayTag: 'In Progress', background: 'success', color: 'white',
  };
};

const StepHeader = ({ step }) => {
  const { background, color, displayTag } = getStatus(step);
  return (
    <Flex mb="5" flexDirection="row" justifyContent="space-between" alignItems="center" minHeight="34px">
      { step.hasOutstandingIssues && (
        <Flex flexDirection="column">
          <Text fontSize="18px">{step.description}</Text>
          {!!step.link && (
          <UploadLink onClick={() => window.open(step.link, '_blank')}>
            Upload Documents
          </UploadLink>
          )}
        </Flex>
      )}
      { !step.hasOutstandingIssues && (
        <>
          <H3>{`${format(new Date(step.date), 'dd/MM/yyyy')} - ${step.name}`}</H3>
          <StatusBadge
            bg={background}
            leftIcon={step.isReinstruction ? <Icon name="Retry" color={color} size="16px" mr="4" /> : null}
            color={color}
          >
            {displayTag}
          </StatusBadge>
        </>
      )}
    </Flex>
  );
};

StepHeader.propTypes = {
  step: PropTypes.shape({
    date: PropTypes.string,
    name: PropTypes.string,
    isDelay: PropTypes.bool,
    isReinstruction: PropTypes.bool,
    hasOutstandingIssues: PropTypes.bool,
    description: PropTypes.string,
    link: PropTypes.string,
  }).isRequired,
};

export default StepHeader;
