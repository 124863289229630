import React from 'react';
import PropTypes from 'prop-types';
import { Box, P } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupP = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data, width } = fields[fieldName];
  return <Box width={width}><P mt="0" mb="0" fontSize={2} ellipsis={!!width}>{data}</P></Box>; // if width is specified, display an ellipsis for overflowed content
};

MarkupP.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupP;
