import { createGlobalStyle } from 'styled-components/macro';
import { mediaQueries } from '@galilee/lilee';

const { lessThan } = mediaQueries;

const GlobalStyle = createGlobalStyle`
html {
  box-sizing: border-box;
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.light};
}

*:focus {
  outline: none;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0) !important; 
  -webkit-focus-ring-color: rgba(255, 255, 255, 0) !important; 
  outline: none !important;
}

*, *:before, *:after {
  box-sizing: inherit;
  font-family: inherit;
}

body {
  border: 0;
  margin: 0;
  background-color: ${(p) => p.theme.colors.base05};
  overflow-y: scroll;
  font-family: ${(p) => p.theme.fonts.body};
  font-weight: ${(p) => p.theme.fontWeights.light};
  color: ${(p) => p.theme.colors.base};
  overflow-x: hidden;
}

html, body, #root, #root > div {
  height: 100%;
}

h1 { margin: 0 0 ${(p) => p.theme.sizes[5]} 0; }
h2 { margin: 0 0 ${(p) => p.theme.sizes[4]} 0; }
h3 { margin: 0 0 ${(p) => p.theme.sizes[3]} 0; }
h4 { margin: 0 0 ${(p) => p.theme.sizes[2]} 0; }
h5 { margin: 0 0 ${(p) => p.theme.sizes[1]} 0; }
h6 { margin: 0 0 ${(p) => p.theme.sizes[0]} 0; }

a {
  color: ${(p) => p.theme.colors.primary};
}

a:active, a:visited {
  color: ${(p) => p.theme.colors.primary};
}

a:hover {
  color: ${(p) => p.theme.colors.primaryDark};
}

.react-pdf__Document {
    height: 100%;
    width: 100%;
}

.react-pdf__Page__canvas {
  height: 100% !important;
  width: 100% !important;
}

.react-pdf__Page__textContent {
  display: none;
  user-select: none;
}

.is-active-nav-link > div {
  background-color: ${(p) => p.theme.colors.primaryDark};
  > svg {
    color: white !important;
  }
}
${lessThan[1]`
  .LPMcontainer {
    display: none !important; 
  }
`}

`;

export default GlobalStyle;
