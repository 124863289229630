import React from 'react';
import PropTypes from 'prop-types';
import { THead, TR, TH } from '@galilee/lilee';
import Header, { headerPropTypes, sortPropTypes } from './Header';

const SortableHeader = ({ onSort, headers, sort }) => {
  if (!headers) return null;
  return (
    <THead>
      <TR>
        {headers.map((h) => <TH key={h.id}><Header onSort={onSort} header={h} sort={sort} /></TH>)}
      </TR>
    </THead>
  );
};

SortableHeader.defaultProps = {
  headers: null,
};

SortableHeader.propTypes = {
  headers: PropTypes.arrayOf(headerPropTypes),
  sort: sortPropTypes.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default SortableHeader;
