import React, {
  createContext, useContext, useState, useEffect, useCallback, useReducer,
} from 'react';
import { useApplication } from 'state/ApplicationProvider';
import { useAuth } from 'state/AuthProvider';
import PropTypes from 'prop-types';
import { fetchMatterFiltersAction, fetchMattersAction } from 'actions/Manage';
import { objectToKeyValue } from 'utils';
import PageLoading from '@galilee/lilee/dist/components/PageLoading';
import reducer, { ManageAction } from './reducer';

const initState = {
  matters: [],
  filters: {
    matterStatuses: [],
    lenders: [],
    tenants: [],
    brokers: [],
  },
  search: null,
  currentPage: 1,
  totalPages: 0,
  ready: false,
};

const ManageContext = createContext(null);

function useManageHook() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [state, dispatch] = useReducer(reducer, initState);
  const { authToken, ready } = useAuth();
  const { dispatch: applicationDispatch } = useApplication();

  const fetchFilters = useCallback(async () => {
    const response = await fetchMatterFiltersAction(applicationDispatch, authToken);
    const filterObj = {
      lenders: objectToKeyValue(response?.lenders),
      matterStatuses: objectToKeyValue(response?.matterStatuses),
      tenants: response?.tenants ? objectToKeyValue(response.tenants) : [],
      brokers: response?.brokers ? objectToKeyValue(response.brokers) : [],
    };
    dispatch({ type: ManageAction.SET_FILTERS, payload: filterObj });
  }, [applicationDispatch, authToken]);

  const fetchMattersAsync = useCallback(async (search) => {
    try {
      setIsLoading(true);
      const response = await fetchMattersAction(applicationDispatch, authToken, search.page, search.orderBy, search.ascending, search.pageSize, search.filters, search.keyword);
      dispatch({ type: ManageAction.FETCH_MATTERS, payload: response });
    } finally {
      setIsLoading(false);
    }
  }, [applicationDispatch, authToken]);

  useEffect(() => {
    if (!ready) return;
    fetchFilters();
  }, [fetchFilters, ready]);

  useEffect(() => {
    if (!state.search || !ready) return;
    fetchMattersAsync(state.search);
  }, [ready, state.search, fetchMattersAsync]);

  const actions = {
    fetchMatters: fetchMattersAsync,
    fetchMoreMatters: async () => {
      const nextPage = state.currentPage + 1;
      if (isLoading || isLoadingMore || nextPage > state.totalPages) return;
      setIsLoadingMore(true);
      const {
        orderBy, ascending, pageSize, filters, keyword,
      } = state.search;
      const response = await fetchMattersAction(applicationDispatch, authToken, nextPage, orderBy, ascending, pageSize, filters, keyword);
      setIsLoadingMore(false);
      dispatch({
        type: ManageAction.FETCH_MORE_MATTERS,
        payload:
        {
          currentPage: nextPage,
          totalPages: response?.totalPages,
          matters: response?.matters,
        },
      });
    },
    setSearch: (search) => {
      dispatch({ type: ManageAction.SET_SEARCH, payload: search });
    },
  };

  return {
    state: {
      ...state,
      isLoading,
      isLoadingMore,
      ready,
    },
    actions,
  };
}

export const useManage = () => {
  const contextValue = useContext(ManageContext);
  if (contextValue === null) throw Error('ManageContext has not been Provided!');
  return contextValue;
};

export default function ManageProvider({ children }) {
  const value = useManageHook();

  return (
    <ManageContext.Provider value={value}>
      {!value.state.ready ? <PageLoading /> : children}
    </ManageContext.Provider>
  );
}

ManageProvider.defaultProps = {
  children: null,
};

ManageProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};
