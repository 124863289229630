import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useEffect,
} from 'react';
import { useAuth } from 'state/AuthProvider';
import PropTypes from 'prop-types';
import {
  addCollaborator, removeCollaborator, getCollaborators, alertCollaborator,
} from 'actions/Collaborate';
import { toast } from '@galilee/lilee';
import reducer from './reducer';

const CollaborateContext = createContext(null);

const initialState = {
  loading: false,
  collaborators: {},
  collaboratingWithMe: {},
  user: {
    email: null,
    firstName: null,
    lastName: null,
    id: null,
    alert: false,
  },
};

function useCollaborateHook() {
  const { authToken } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const getAuthTokenRef = useRef(authToken);

  useEffect(() => {
    async function fetch() {
      try {
        dispatch({ type: 'SET_LOADING', payload: true });
        const payload = await getCollaborators(getAuthTokenRef.current);
        dispatch({ type: 'UPDATE_COLLABORATORS', payload });
      } catch (error) {
        toast.error('We couldn\'t get your collaborators, please reload the page and try again');
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }
    fetch();
  }, []);

  const stateSetters = {
    setUser: (payload) => {
      dispatch({ type: 'UPDATE_USER', payload });
    },
    clearUser: () => {
      dispatch({ type: 'UPDATE_USER', payload: { email: null, firstName: null, lastName: null } });
    },
  };

  const actions = {
    addCollaborator: async (payload) => {
      try {
        const response = await addCollaborator(getAuthTokenRef.current, payload);
        dispatch({ type: 'SET_COLLABORATORS', payload: response });
        stateSetters.clearUser();
        toast.success('New collaborator has been added');
      } catch (error) {
        toast.error("We couldn't add the new collaborator, please reload the page and try again");
      }
    },
    removeCollaborator: async (id) => {
      try {
        const response = await removeCollaborator(getAuthTokenRef.current, id);
        dispatch({ type: 'SET_COLLABORATORS', payload: response });
        stateSetters.clearUser();
        toast.success('Collaborator has been removed');
      } catch (error) {
        toast.error("We couldn't remove the collaborator, please reload the page and try again");
      }
    },
    alertCollaborator: async (id, alert) => {
      try {
        const response = await alertCollaborator(getAuthTokenRef.current, id, alert);
        dispatch({ type: 'SET_COLLABORATORS', payload: response });
      } catch (error) {
        toast.error("We couldn't remove the collaborator, please reload the page and try again");
      } finally {
        dispatch({ type: 'UPDATE_USER', payload: { alert } });
      }
    },
  };

  return {
    state: { ...state, ...stateSetters },
    actions,
  };
}

export const useCollaborate = () => {
  const contextValue = useContext(CollaborateContext);
  if (contextValue === null) throw Error('CollaborateContext has not been Provided!');
  return contextValue;
};

export default function CollaborateProvider({ children }) {
  const hook = useCollaborateHook();
  const value = useMemo(() => ({ ...hook }), [hook]);
  return (
    <CollaborateContext.Provider value={value}>
      {children}
    </CollaborateContext.Provider>
  );
}

CollaborateProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
