import React from 'react';
import styled from 'styled-components/macro';
import { Flex, Icon } from '@galilee/lilee';

const PdfOuter = styled(Flex)`
  position: relative;
  &:before {
    display: block;
    content: "";
    width: 100%;
    padding-top: calc((877 / 620) * 100%);
  }
`;

const PdfInner = styled(Flex)`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const PdfLoading = () => (
  <PdfOuter>
    <PdfInner>
      <Icon size="40px" name="Spinner" stepping />
    </PdfInner>
  </PdfOuter>
);

export default PdfLoading;
