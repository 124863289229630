import {
  Box, Column, Flex, H2, LI, Row, Text, UL,
} from '@galilee/lilee';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components/macro';
import preloaderGif from 'images/landingChartPreloader.gif';
import init from 'utils/landingChartInit';

const AnimationWrapper = styled(Box)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 250px;
  width: 100%;
`;

const AnimationContainer = styled.div`
  background-color: transparent; 
  height: 100%;
  max-height:410px;
  max-width: 600px; 
  width: 100%;
`;

const Canvas = styled.canvas`
  display: none;
  position: absolute;
  background-color: transparent;
  max-width: 600px;
  width: 100%;
  max-height: 410px;
  height: 100%;
`;

const Image = styled.img`
  vertical-align: middle; 
  max-height: 100%;
`;

const DomOverLayContainer = styled.div`
  pointer-events:none; 
  overflow:hidden; 
  width: 100%;
  max-width: 600px; 
  height:410px; 
  position: absolute; 
  left: 0px; 
  top: 0px; 
  display: none;
`;

const ListWrapper = styled(Box)``;

const PreLoadDiv = styled(Flex)`
  height: 100%; 
  width: 100%;
`;

const CustomRow = styled(Row)`
  @media (max-width: ${(p) => p.collapseAt}) {
    flex-direction: column;
  }
`;

const WhyUseKwil = ({ loadAnimation }) => {
  const [createJsLoaded, setCreateJsLoaded] = useState(false);
  const [landingChartScriptLoaded, setLandingChartScriptLoaded] = useState(false);
  const [animationInitialised, setAnimationInitialised] = useState(false);

  useEffect(() => {
    if (createJsLoaded && landingChartScriptLoaded && loadAnimation && !animationInitialised) {
      init();
      setAnimationInitialised(true);
    }
  }, [animationInitialised, createJsLoaded, landingChartScriptLoaded, loadAnimation]);

  const createJsOnLoad = () => setCreateJsLoaded(true);
  const landingChartScriptOnLoad = () => setLandingChartScriptLoaded(true);

  useEffect(() => {
    const createJsScript = document.getElementById('createJsScript');
    const landingChartScript = document.getElementById('landingChartScript');
    if (createJsScript) createJsScript.addEventListener('load', createJsOnLoad);
    if (landingChartScript) landingChartScript.addEventListener('load', landingChartScriptOnLoad);

    return () => {
      if (createJsScript) createJsScript.removeEventListener('load', createJsOnLoad);
      if (landingChartScript) landingChartScript.removeEventListener('load', landingChartScriptOnLoad);
    };
  });

  return (
    <>
      <Helmet>
        <script id="createJsScript" src="https://code.createjs.com/1.0.0/createjs.min.js" />
        <script id="landingChartScript" src="./landingChartAssets/landingChart.js" />
      </Helmet>
      <CustomRow collapseAt="1050px" justifyContent="space-evenly">
        <Column mb="0" mr={[0, 7, 8, 9]}>
          <Column maxWidth={[null, null, '400px']}>
            <Text mb="4" color="base20" fontSize="18px">
              Secure digital mortgages are the future.
            </Text>
            <ListWrapper mb="5" color="base20" fontSize="18px">
              <UL>
                <LI mb={[5, null, null, 2, 5]}>
                  Eliminate rework
                </LI>
                <LI mb={[5, null, null, 2, 5]}>
                  Improve accuracy
                </LI>
                <LI mb={[5, null, null, 2, 5]}>
                  Reduce risk
                </LI>
                <LI mb={[5, null, null, 2, 5]}>
                  Reduce settlement timeframes
                </LI>
                <LI mb={[5, null, null, 2, 5]}>
                  Convenient
                </LI>
                <LI mb={[5, null, null, 2, 5]}>
                  Simple to use
                </LI>
              </UL>
            </ListWrapper>
            <Text mb="7" color="base20" fontSize="18px">
              With Kwil, errors are almost entirely eliminated thanks to our intuitive customer focused design.
            </Text>
          </Column>
        </Column>
        <Column mb={[7, 7, 0]} mr={[0, 7, 8, 9]} width="100%">
          <Text mb="7" maxWidth="300px" color="base20" fontSize="18px">
            Did you know, with KWIL, the average number of days from documents issued to documents returned is just 3? That makes the average settlement timeframe 35 days as opposed to 41.
          </Text>
          <H2 fontSize={['36px', null, null, '42px']} mb="7">84% Customer Satisfaction</H2>
          <AnimationWrapper id="animationWrapper">
            <AnimationContainer id="animation_container">
              <Canvas id="canvas" />
              <DomOverLayContainer id="dom_overlay_container" />
              <PreLoadDiv id="_preload_div_" alignItems="center" justifyContent="center">
                <Image src={preloaderGif} />
              </PreLoadDiv>
            </AnimationContainer>
          </AnimationWrapper>
        </Column>
      </CustomRow>
    </>
  );
};
export default WhyUseKwil;

WhyUseKwil.defaultProps = {
  loadAnimation: true,
};

WhyUseKwil.propTypes = {
  loadAnimation: PropTypes.bool,
};
