import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import Countdown from 'react-countdown-now';
import {
  Fixed, H2, Text, Button, Flex, mediaQueries,
} from '@galilee/lilee';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

const { lessThan } = mediaQueries;

const LogoutWrapper = styled(Fixed)`
    border-radius: ${(p) => p.theme.radii[4]};
    box-shadow: ${(p) => p.theme.shadows.xl};
    margin-top: 50px;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
    ${lessThan[3]`
      left: 20%;
      transform: translateX(-10%);   
    `}
    ${lessThan[2]`
      left: 10%;
    transform: translateX(-5%);
    `}
    ${lessThan[1]`
      left: 0;
      transform: none;
    `}
    ${lessThan[0]`
      left: 0;
      transform: none;
    `}
    ${lessThan[1]`
      width: 100%;
    `}
`;

const TextCenter = styled(Text)`
  ${lessThan[1]`
    text-align: center;
  `}
`;

const LogoutContainer = styled(Flex)`
  ${lessThan[1]`
    flex-direction: column;
  `}
`;

LogoutContainer.defaultProps = {
  bg: 'base',
  borderRadius: '4',
  color: 'white',
  maxWidth: '850px',
  margin: 'auto',
};

export default function LogoutTimer({ onReset }) {
  const history = useHistory();

  return (
    <LogoutWrapper>
      <LogoutContainer>
        <Flex minWidth="135px" justifyContent="center" alignItems="center" m="5">
          <H2 textAlign="center" mb="0">Are you still there?</H2>
        </Flex>
        <Flex justifyContent="center" m="5">
          <TextCenter>
            You’ve not taken any action on the page recently. You will be logged out of Kwil in:
            <Countdown
              date={Date.now() + 1000 * 60 * 5}
              onComplete={() => history.push({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } })}
              renderer={({ minutes, seconds }) => {
                const minuteString = minutes > 0 ? `${minutes} minute${minutes === 1 ? '' : 's'} and ` : '';
                const secondString = `${seconds} second${seconds === 1 ? '' : 's'}`;
                return (
                  <>
                    <br />
                    {`${minuteString}${secondString}`}
                  </>
                );
              }}
            />
          </TextCenter>
        </Flex>
        <Flex justifyContent="center" alignItems="center" m="5">
          <Button noBorder color="secondary" onClick={() => onReset()}>Continue</Button>
        </Flex>
      </LogoutContainer>
    </LogoutWrapper>
  );
}

LogoutTimer.defaultProps = {
};

LogoutTimer.propTypes = {
  onReset: PropTypes.func.isRequired,
};
