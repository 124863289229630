import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { Flex, Icon } from '@galilee/lilee';

export const sortPropTypes = PropTypes.shape({
  sortId: PropTypes.string,
  ascending: PropTypes.bool,
  filter: PropTypes.number,
});

export const headerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  sortable: PropTypes.bool,
  visible: PropTypes.visible,
  custom: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
});

const HeaderContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  cursor: ${(p) => (p.header.sortable ? 'pointer' : 'default')};
`;

const HeaderIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  margin-left: 8px;
  position: relative;
  top: 1px;
`;

const Header = ({ header, sort, onSort }) => {
  if (!header.visible) return null;
  if (header.custom) return <>{header.custom}</>;
  const isSelected = sort.sortId === header.id;

  const headerClick = async () => {
    if (header.sortable) {
      const newSort = { sortId: header.id, ascending: isSelected ? !sort.ascending : false, filter: sort.filter };
      onSort(newSort);
    }
  };

  return (
    <HeaderContainer
      header={header}
      selected={isSelected}
      onClick={headerClick}
    >
      {header.title}
      {header.sortable && (
        <HeaderIcon
          color={isSelected ? 'secondary' : 'base20'}
          name={isSelected && sort.ascending ? 'NavUp' : 'NavDown'}
        />
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  header: headerPropTypes.isRequired,
  sort: sortPropTypes.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default Header;
