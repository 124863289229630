import React, { useRef, useState } from 'react';
import styled from 'styled-components/macro';
import {
  Box, Flex, Text, Image, Hide, Row, Column, H2, VimeoModal, utils,
} from '@galilee/lilee';
import Container from 'components/Container';
import LinkButton from 'components/LinkButton';
import logo from 'images/logo-trans-light.png';
import hero from 'images/HeroImage.png';
import heroChristmas from 'images/HeroChristmas.png';
import useOnScreen from 'utils/useOnScreen';
import ContactUs from './components/ContactUs';
import Banner from './components/Banner';
import WhyUseKwil from './components/WhyUseKwil';
import WhatIsLiveSign from './components/WhatIsLiveSign';
import {
  HeroContainer,
  ContactUsContainer,
  HeroTitleContainer,
  HeroSection,
  HeroImageContainer,
  HeroTitle,
  HeroText,
  HeroImage,
  HowItWorksContainer,
  NavLink,
  HashLinkButton,
  ScrollAnchor,
  WhyUseKwilContainer,
} from './components/Containers';

const { useScrollToTop } = utils;

const PartnerColumn = styled(Column)`
  flex: ${(p) => p.flex};
`;

PartnerColumn.defaultProps = {
  flexDirection: 'row',
  flex: '3',
};

const Conference = () => {
  useScrollToTop(true);
  const isChristmas = (new Date()).getMonth() === 11;
  const heroImage = isChristmas ? heroChristmas : hero;
  const defaultVideoId = isChristmas ? '377416577' : '371758282';
  const [showVideoId, setShowVideoId] = useState(null);
  const whyUseKwilContainerRef = useRef();
  const containerIsVisible = useOnScreen(whyUseKwilContainerRef);

  return (
    <>
      {showVideoId && <VimeoModal maxWidth="1024px" vimeoId={showVideoId} close={() => setShowVideoId(null)} />}
      <Banner>
        {'Hi, we’re new. If you’d like to find out more about what Kwil can do for you then please '}
        <ScrollAnchor smooth to="/conference#ContactUs">register your interest</ScrollAnchor>
        .
      </Banner>
      <HeroContainer mb="-1px">
        <Flex justifyContent="space-between" p={[7, null, null, '44px']} pb={[7, 7, 9]}>
          <Image height="58px" src={logo} />
          <Flex>
            <Hide breakpoints={[0, 1]}>
              <Flex alignItems="center" height="100%">
                <NavLink smooth to="/conference#whyKwil">Why use Kwil</NavLink>
                <NavLink smooth to="/conference#whatIsLivesign">What is livesign?</NavLink>
              </Flex>
            </Hide>
            <LinkButton color="tertiary" to="./home">Log in</LinkButton>
          </Flex>
        </Flex>
        <Container maxWidth="1241px" center>
          <HeroSection>
            <HeroTitleContainer>
              <HeroTitle fontSize={['42px', null, null, '58px']}>A safer, faster and more efficient digital signing platform</HeroTitle>
              <HeroText>
                Most secure when powered by livesign
              </HeroText>
              <HashLinkButton fullWidthBreakpointIndex="1" color="tertiary" smooth to="/conference#ContactUs">Register Interest</HashLinkButton>
            </HeroTitleContainer>
            <HeroImageContainer>
              <HeroImage onClick={() => setShowVideoId(defaultVideoId)} src={heroImage} />
            </HeroImageContainer>
          </HeroSection>
        </Container>
      </HeroContainer>
      <Box bg="base">
        <WhyUseKwilContainer mb="-1px" id="whyKwil" ref={whyUseKwilContainerRef}>
          <Container maxWidth="1241px" center>
            <Column>
              <H2 lineHeight="1em" mb="7" fontSize={['42px', null, null, '48px']}>Why use KWIL?</H2>
              <WhyUseKwil loadAnimation={containerIsVisible} />
            </Column>
          </Container>
        </WhyUseKwilContainer>
      </Box>
      <HowItWorksContainer id="whatIsLivesign">
        <Container maxWidth="1241px" center>
          <WhatIsLiveSign />
        </Container>
      </HowItWorksContainer>
      <ContactUsContainer px={[7, 0]} py={[8, 8, 9]} id="ContactUs">
        <Container maxWidth="917px" center>
          <Row collapseIndex="0">
            <Column mb={[7, 7, 0]} mr={[0, 7, 8, 9]}>
              <H2 lineHeight="1em" mb="6" fontSize="48px">Interested?</H2>
              <Text mb="7" maxWidth="300px" color="base20" fontSize="18px">
                You can register your interest and someone from the Kwil Team will get in contact to let you know how Kwil can save your business time and money.
              </Text>
            </Column>
            <Column mb="0" alignItems="flex-start">
              <ContactUs />
            </Column>
          </Row>
        </Container>
      </ContactUsContainer>
    </>
  );
};

Conference.propTypes = {
};

export default Conference;
