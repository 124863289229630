import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef,
} from 'react';
import { useAuth } from 'state/AuthProvider';
import PropTypes from 'prop-types';
import {
  getUserByEmail, updateUser, unVerifyBroker, resendWelcomeEmail,
} from 'actions/User';
import { toast } from '@galilee/lilee';
import reducer from './reducer';

const UpdateBorrowerContext = createContext(null);

const initialState = {
  settings: {
    readyToSubmit: false,
    errors: null,
  },
  user: {
    id: null,
    mobile: null,
    phoneNumber: null,
    email: null,
    firstName: null,
    middleName: null,
    lastName: null,
    userType: null,
  },
};

function useUpdateBorrowerHook() {
  const { authToken } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const getAuthTokenRef = useRef(authToken);

  const stateSetters = {
    setBorrower: (payload) => {
      try {
        dispatch({ type: 'UPDATE_USER', payload });
      } catch (error) {
        toast.error('We couldn\'t update the user, please reload the page and try again');
      }
    },
    setSettings: (settings) => {
      try {
        dispatch({ type: 'UPDATE_SETTINGS', payload: settings });
      } catch (error) {
        toast.error('We couldn\'t update the user settings, please reload the page and try again');
      }
    },
  };

  const actions = {
    getBorrower: async (payload) => {
      try {
        const response = await getUserByEmail(getAuthTokenRef.current, payload);
        dispatch({ type: 'UPDATE_USER', payload: { ...response.user } });
      } catch (error) {
        toast.error("We couldn't get the user details, please reload the page and try again");
      }
    },
    unVerifyUser: async (id) => {
      try {
        const response = await unVerifyBroker(getAuthTokenRef.current, id);
        if (response.id) toast.success('User mobile successfully un-verified');
        else toast.error("We couldn't un-verify user mobile");
      } catch (error) {
        toast.error("We couldn't un-verify user mobile, please reload the page and try again");
      }
    },
    updateBorrower: async (data) => {
      try {
        const { id, payload } = data;
        const response = await updateUser(authToken, id, payload);
        dispatch({
          type: 'UPDATE_SETTINGS',
          payload: {
            errors: response.errors
              ? response.errors.toString()
              : Object.values(response)[0].toString(),
          },
        });
      } catch (error) {
        toast.error('We couldn\'t update the user, please reload the page and try again');
      }
    },
    resendWelcome: async (email) => {
      try {
        await resendWelcomeEmail(getAuthTokenRef.current, { EmailAddress: email });
        toast.success('Successfully sent welcome email');
      } catch (error) {
        toast.error("We couldn't resend the welcome email, please reload the page and try again");
      }
    },
  };

  return {
    state: { ...state, ...stateSetters },
    actions,
  };
}

export const useUpdateBorrower = () => {
  const contextValue = useContext(UpdateBorrowerContext);
  if (contextValue === null) throw Error('UpdateBorrowerContext has not been Provided!');
  return contextValue;
};

export default function UpdateBorrowerProvider({ children }) {
  const hook = useUpdateBorrowerHook();
  const value = useMemo(() => ({ ...hook }), [hook]);
  return (
    <UpdateBorrowerContext.Provider value={value}>
      {children}
    </UpdateBorrowerContext.Provider>
  );
}

UpdateBorrowerProvider.propTypes = {
  children: PropTypes.shape().isRequired,
};
