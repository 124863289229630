import { httpRequest, jsonSafeParse } from '../utils';

export const getFavourites = () => {
  const favourites = localStorage.getItem('kwil_favourites');
  if (favourites) {
    const response = jsonSafeParse(favourites);
    return response.success ? response.result : [];
  }
  return [];
};

export const setFavourite = (favourite) => {
  const favourites = getFavourites();
  if (favourites.length === 0 || favourites.every((f) => f.id !== favourite.id)) {
    localStorage.setItem('kwil_favourites', JSON.stringify([{ id: favourite.id, names: favourite.names, suburbs: favourite.suburbs }, ...favourites]));
  }
};

export const deleteFavourite = (id) => {
  const favourites = getFavourites().filter((f) => f.id !== id);
  localStorage.setItem('kwil_favourites', JSON.stringify(favourites));
};

export const requireAccessCodeAsync = async (emailAddress, trackNTraceApiUrl) => {
  await httpRequest('/auth/2fa', 'post', { emailAddress }, null, trackNTraceApiUrl);
};

export const getTokenAsync = async (email, code, trackNTraceApiUrl) => {
  const response = await httpRequest('/auth/token', 'post', { email, twoFactorCode: code }, null, trackNTraceApiUrl);
  return response;
};

export const refreshTokenAsync = async (token, refreshToken, trackNTraceApiUrl) => {
  const response = await httpRequest('/auth/refresh', 'post', { token, refreshToken }, null, trackNTraceApiUrl);
  return response;
};

export const getMe = async (token, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/users/me', 'get', null, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};

export const createCallbackAsync = async (phoneNumber, note, name, matterURL, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/createCallback', 'post', {
    phoneNumber, note, name, matterURL,
  }, null, trackNTraceApiUrl);
  return response;
};

export const getCallbacksAsync = async (token, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/getCallbacks', 'get', null, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};

export const getCallbackStatusAsync = async (callbackId, trackNTraceApiUrl) => {
  const response = await httpRequest(`/api/getCallbackStatus/${callbackId}`, 'get', null, null, trackNTraceApiUrl);
  return response;
};

export const pickupCallbacksAsync = async (emailAddress, callBackId, token, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/pickupCallback', 'post', { emailAddress, callBackId }, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};

export const deleteCallbacksAsync = async (emailAddress, callBackId, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/deleteCallback', 'post', { emailAddress, callBackId }, null, trackNTraceApiUrl);
  return response;
};

export const finalizeCallbacksAsync = async (emailAddress, callBackId, token, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/finaliseCallback', 'post', { emailAddress, callBackId }, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};

export const releaseCallbacksAsync = async (emailAddress, callBackId, token, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/releaseCallback', 'post', { emailAddress, callBackId }, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};

export const setCallbackUnansweredAsync = async (emailAddress, callBackId, token, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/setCallbackUnanswered', 'post', { emailAddress, callBackId }, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};

export const getMattersAsync = async (token, trackNTraceApiUrl) => {
  const response = await httpRequest('/api/trackNTrace', 'get', null, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};

export const getMatterAsync = async (matterId, token, trackNTraceApiUrl) => {
  const response = await httpRequest(`/api/trackNTrace/${matterId}`, 'get', null, { Authorization: `Bearer ${token}` }, trackNTraceApiUrl);
  return response;
};
