import React, { useState } from 'react';
import {
  Absolute, OL, LI, Panel, ResponsiveGroup, Radio, Button, PageLoading, Modal, P, H4,
} from '@galilee/lilee';
import { useMatter } from 'state/MatterProvider';
import { useApplication } from 'state/ApplicationProvider';
import Container from 'components/Container';
import styled from 'styled-components/macro';
import { useAuth } from 'state/AuthProvider';
import { consentAction } from 'actions/Matter';
import ConsentImg from 'images/Consent.png';

const FullScreen = styled(Absolute)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.colors.base05};
`;

const Consent = () => {
  const { state, dispatch } = useMatter();
  const { dispatch: applicationDispatch } = useApplication();
  const { user, authToken, support } = useAuth();
  const [option, setOption] = useState('digital');
  const [confirmPaper, setConfirmPaper] = useState(false);

  const dispatchConsent = (consent) => {
    consentAction(dispatch, applicationDispatch, state.matter.matterId, authToken, user.id, consent);
  };

  const handleConsentSubmit = () => {
    if (option === 'digital') {
      dispatchConsent(true);
    }
    if (option === 'paper') {
      setConfirmPaper(true);
    }
  };

  if (!state || !state.matter) return <PageLoading />;

  return (
    <FullScreen top="0" bottom="0" left="0" right="0">
      <Container center>

        <Panel>
          <Panel.Title>Kwil Consent</Panel.Title>
          <Panel.Section>
            <H4>By Using this site you confirm:</H4>
            <OL>
              <LI>
                You consent to receiving your
                {state.matter.isSharia ? ' finance ' : ' loan '}
                documents (including precontractual documents and the
                {state.matter.isSharia ? " financier's " : " lender's "}
                Credit Guide) electronically through this KWIL system;
              </LI>
              <LI>
                You understand that you should regularly check for  electronic communications from the
                {state.matter.isSharia ? ' financier, ' : ' lender, '}
                including about precontractual documents;
              </LI>
              <LI>
                You understand that the
                {state.matter.isSharia ? ' financier ' : ' lender '}
                might not give precontractual documents to you in paper form in the future; and
              </LI>
              <LI>
                You understand that you can withdraw this consent at any time.
              </LI>
            </OL>
          </Panel.Section>
          <Panel.ActionContainer justifyContent="flex-end">
            <ResponsiveGroup fullWidthBreakpointIndex={1} pl="4">
              <Radio disabled={support.isTenantSupport} mt="4" mb="4" label="I accept these terms" checked={option === 'digital'} onChange={(e) => setOption(e.target.value)} value="digital" />
              <Radio disabled={support.isTenantSupport} mt="4" mb="4" label="No I'd prefer paper forms" checked={option === 'paper'} onChange={(e) => setOption(e.target.value)} value="paper" />
              <Button disabled={support.isTenantSupport} onClick={() => handleConsentSubmit()} color="secondary">Next</Button>
            </ResponsiveGroup>
          </Panel.ActionContainer>
        </Panel>

        {
          confirmPaper && (
            <Modal>
              <Modal.IllustrationBody image={ConsentImg}>
                <Modal.Subtitle>Paper forms</Modal.Subtitle>
                <Modal.Title>Are you sure?</Modal.Title>
                <P>
                  You have chosen to receive your documents in paper form. Documents will be dispatched shortly by mail. This may delay the settlement of your loan.
                </P>
                <Modal.ActionContainer>
                  <ResponsiveGroup>
                    <Button color="base" onClick={() => setConfirmPaper(false)}>Back</Button>
                    <Button color="secondary" onClick={() => dispatchConsent(false)}>Confirm</Button>
                  </ResponsiveGroup>
                </Modal.ActionContainer>
              </Modal.IllustrationBody>
            </Modal>
          )
        }

      </Container>
    </FullScreen>
  );
};

Consent.propTypes = {
};

export default Consent;
