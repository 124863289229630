import React from 'react';
import PropTypes from 'prop-types';
import {
  Button, Modal, ResponsiveGroup, Text,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import VerifyError from 'images/VerifyError.svg';

const VerificationErrorModal = ({ errorType, invalidUserName, close }) => {
  const { user } = useAuth();
  let verificationErrorMessage = null;

  switch (errorType) {
    case 'verification_cancelled':
      verificationErrorMessage = {
        title: 'Oh, looks like you cancelled.',
        body: "Don't worry you can complete your Live Sign verification at any time. If you have questions please contact our customer support team via the Live Chat tab.",
      };
      break;
    case 'verification_failed':
      verificationErrorMessage = {
        title: 'Uh oh, looks like your verification failed.',
        body: 'You should probably contact our customer support team via the Live Chat tab to request some assistance with your Live Sign verification.',
      };
      break;
    case 'invalid_identity':
      verificationErrorMessage = {
        title: "Uh oh, we couldn't verify your identity",
        body: (
          <>
            <Text>Your name in Live Sign Web:</Text>
            <Text capitalize fontWeight="bold">{`${user.firstName} ${user.lastName}`}</Text>
            <Text mt={6}>The name Live Sign Verify provided:</Text>
            <Text capitalize fontWeight="bold">{invalidUserName}</Text>
            <Text mt={6}>
              These names must match exactly. If you&apos;ve used the wrong Live Sign then please try again using the correct one,
              if not then please contact us and we&apos;ll help you sort it out.
            </Text>
          </>),
      };
      break;
    default:
      verificationErrorMessage = {
        title: 'Live Sign is not available right now',
        body: "Please complete any other documents and come back later. In the mean time we'll try and get Live Sign back up and running.",
      };
  }

  return (
    <Modal>
      <Modal.IllustrationBody image={VerifyError}>
        <Modal.Close onClose={() => { close(); }} />
        <Modal.Body>
          <Modal.Subtitle mb={[7, null, 8]}>Live Sign Verify</Modal.Subtitle>
          <Modal.Title lineHeight="compact">{verificationErrorMessage.title}</Modal.Title>
          <Text mb={[6, null, 7]}>
            {verificationErrorMessage.body}
          </Text>
        </Modal.Body>
        <Modal.ActionContainer>
          <ResponsiveGroup>
            <Button color="secondary" onClick={() => close()}>Close</Button>
          </ResponsiveGroup>
        </Modal.ActionContainer>
      </Modal.IllustrationBody>
    </Modal>
  );
};
VerificationErrorModal.defaultProps = {
  errorType: null,
  invalidUserName: null,
};

VerificationErrorModal.propTypes = {
  errorType: PropTypes.string,
  close: PropTypes.func.isRequired,
  invalidUserName: PropTypes.string,
};

export default VerificationErrorModal;
