import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { Input, Box, Label } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const TextBoxLabel = styled(Label)`
  min-height: 18px;
`;

const ReadOnlyInput = styled(Input)`
  box-shadow: none;
  border-color: transparent;
  background-color: transparent;
  cursor: text;
`;

const ReadOnlyText = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data, label } = fields[fieldName];
  return (
    <Box width="100%">
      { label !== null && <TextBoxLabel htmlfor={fieldName}>{label}</TextBoxLabel> }
      <ReadOnlyInput
        disabled
        type="text"
        defaultValue={data}
      />
    </Box>
  );
};

ReadOnlyText.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default ReadOnlyText;
