import React, { useState } from 'react';
import {
  Flex, Modal, Text, Button, H6,
} from '@galilee/lilee';
import styled from 'styled-components/macro';
import { useApplication } from 'state/ApplicationProvider';
import { useAuth } from 'state/AuthProvider';
import { useMatterMaker } from 'state/matterMaker/MatterMakerProvider';
import submitImg from 'images/SubmitPopup.png';
import { errorDispatcher } from 'actions/actionUtils';
import { createMatterAction } from 'actions/matterMaker';

const Popup = styled(Flex)`
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(7, 36, 56, 0.75);
`;

const SubmitBox = styled(Flex)`
  margin: auto;
  background-color: white;
  border-radius: 10px;
`;

SubmitBox.defaultProps = { maxWidth: ['80%', '80%', '750px'] };

const SubmitPopup = () => {
  const { dispatch: applicationDispatch } = useApplication();
  const { authToken } = useAuth();
  const { actions, state } = useMatterMaker();
  const [submitted, setSubmitted] = useState(false);
  const [matterIds, setMatterIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const { howManyMatters } = state.createMatter;

  async function submitNow() {
    setLoading(true);
    try {
      const response = await createMatterAction(authToken, state.createMatter);
      setSubmitted(true);
      if (response !== undefined) {
        setMatterIds(response.matterIds);
      }
    } catch (err) {
      errorDispatcher(applicationDispatch, "We couldn't create your matter, please reload the page and try again");
    }
  }
  async function close() {
    setSubmitted(false);
    setLoading(false);
    setMatterIds([]);
    actions.updateSettings({ create: false });
  }

  return (state.settings.create) ? (
    <Popup style={{ zIndex: 1000 }}>
      <SubmitBox>
        <Modal.IllustrationBody image={submitImg}>
          <Modal.Body>
            <Modal.Subtitle mb={[6, null, 2]}>Matter Maker</Modal.Subtitle>
            {submitted && (
            <Text fontSize="2" mb={[6, null, 3]}>
              {(matterIds.length > 0)
                ? (
                  <>
                    <H6
                      mb="5"
                      mt="5"
                    >
                      Your created matter Id(s):
                    </H6>
                    <H6
                      mb="5"
                    >
                      {matterIds.map((id) => `${id.toString()} `)}
                    </H6>
                  </>
                )
                : (
                  <H6
                    mb="5"
                    mt="5"
                  >
                    Matter Maker Failed
                  </H6>
                )}
            </Text>
            )}
          </Modal.Body>
          {!submitted
            ? (
              <Modal.ActionContainer width="100%" justifyContent="space-evenly">
                {(!loading) && <Button mr="5" disabled={loading} mb={6} color="secondary" onClick={() => submitNow()}>Create Now</Button>}
                {(!loading) ? <Button mb={20} color="base" onClick={() => close()}>Make changes</Button>
                  : (
                    <H6
                      mb="5"
                      mt="5"
                    >
                      Matter(s) will be Ready in
                      {' '}
                      {howManyMatters * 6}
                      {' '}
                      seconds...
                    </H6>
                  )}
              </Modal.ActionContainer>
            )
            : (
              <Modal.ActionContainer width="100%" justifyContent="space-evenly">
                <Button mb={6} color="secondary" onClick={() => close()}>Exit</Button>
              </Modal.ActionContainer>
            ) }
        </Modal.IllustrationBody>
      </SubmitBox>
    </Popup>
  ) : null;
};

export default SubmitPopup;
