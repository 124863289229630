import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import styled from 'styled-components/macro';
import {
  P, H3, Column, Button, Image, Header, Icon, Box, Hide, Show,
} from '@galilee/lilee';
import Helmet from 'react-helmet';
import LinkButton from 'components/LinkButton';
import changeDocument from 'images/ChangedDocument.png';
import complete from 'images/Completed.png';
import rejectedDocument from 'images/RejectedDocument.png';
import finalised from 'images/finalised.png';
import started from 'images/Started.png';
import submitDocuments from 'images/SubmitDocuments.png';
import submittedDocuments from 'images/SubmittedDocuments.png';
import zeroPercent from 'images/ZeroPercent.png';
import {
  MATTER_STAGES, getSummaryStage, feedbackScript, numToWords,
} from 'utils';
import { Error } from 'components/ProgressIcons/components/Icons';
import TitleContainer from 'components/TitleContainer';
import { useAuth } from 'state/AuthProvider';
import AutoSubmitCountdown from './AutoSubmitCountdown';

const TitleColumn = styled(Column)`
  flex: 5;
`;

const SummaryStatus = ({
  matter, match, user, onSubmitDocuments, onDownloadDocuments,
}) => {
  const {
    stage, myProgress, rejectedDocPath, editedBySomeoneElseDocPath,
  } = getSummaryStage(matter.matterProgress, user.id);
  const { dateToAutoSubmit } = matter;
  const {
    matterId, senderRef, lender, broker, originator,
  } = matter.matter;
  const { support } = useAuth();
  const multipleBorrowers = matter.matter.borrowers && matter.matter.borrowers.length > 1;
  let summaryData = null;

  const [isCountdownFinished, setIsCountdownFinished] = useState(false);

  useEffect(() => {
    if (!dateToAutoSubmit) return;
    const isPastDue = new Date(dateToAutoSubmit) < new Date();
    setIsCountdownFinished(isPastDue);
  }, [dateToAutoSubmit]);

  switch (stage) {
    case MATTER_STAGES.NOT_STARTED:
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">
              Please check the details of your&nbsp;
              {matter.matter.isSharia ? 'finance' : 'loan'}
            </H3>
            <P fontSize="2">
              If they are incorrect please contact us via live chat so we can amend before continuing.
            </P>
            <P fontSize="2">
              By clicking Start, you acknowledge that the&nbsp;
              {matter.matter.isSharia ? 'finance' : 'loan'}
                      &nbsp;details below are correct.
            </P>
            <LinkButton to={`${match.url}/read`} color="secondary">Start</LinkButton>
          </>
        ),
        image: (
          <Image src={zeroPercent} alt="Not Started" />
        ),
      };
      break;
    case MATTER_STAGES.SUBMITTED_DOCS:
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">Your documents have been submitted and will be checked soon. </H3>
            <P fontSize="2">Once your documents have been reviewed and accepted we will notify you and you will be able to download a complete set for your records.</P>
          </>
        ),
        image: (
          <Image src={submittedDocuments} />
        ),
      };
      break;
    case MATTER_STAGES.SUBMIT_DOCS:
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">You need to submit your documents</H3>
            <P fontSize="2">
              {multipleBorrowers ? 'All parties ' : 'You '}
              have completed all the necessary documents which will be automatically submitted
              {
                !isCountdownFinished
                && (
                <>
                  {' in '}
                  <AutoSubmitCountdown dateToAutoSubmit={dateToAutoSubmit} onComplete={() => setIsCountdownFinished(true)} />
                </>
                )
              }
              <P fontSize="2">To speed things up you can submit now.</P>
            </P>
            <Button onClick={onSubmitDocuments} color="secondary">Submit</Button>
          </>
        ),
        image: (
          <Image src={submitDocuments} alt="Submit Documents" />
        ),
      };
      break;
    case MATTER_STAGES.STARTED:
    {
      const remaining = myProgress.total - myProgress.completed;
      let message = "You've made a good start";
      if (myProgress.percentage > 25) message = "Keep going, you're doing great";
      if (myProgress.percentage > 75) message = "You're almost there";
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">{message}</H3>
            <P fontSize="2">
              { `You only have ${numToWords(remaining)} more document${remaining > 1 ? 's' : ''} to complete your loan. You can resume where you last left off here:` }
            </P>
            <LinkButton to={`${match.url}/${myProgress.nextDocPath}`} color="secondary">Resume</LinkButton>
          </>
        ),
        image: (
          <Image src={started} alt="Started" />
        ),
      };
      break;
    }
    case MATTER_STAGES.COMPLETE:
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">Great, you&apos;ve completed your part</H3>
            <P fontSize="2">You&apos;ve done everything you can do for now. Other parties of the loan still need to complete their part.</P>
            <P fontSize="2">We may alert you to issues or changes that require your attention, so please keep an eye out for notifications.</P>
          </>
        ),
        image: (
          <Image src={complete} alt="Complete" />
        ),
      };
      break;
    case MATTER_STAGES.CHANGED_DOCS:
    {
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">
              Someone made changes to a document you signed.
            </H3>
            <P fontSize="2">
              Please review the document that was changed, re-sign it and then submit the document to us.
            </P>
            <LinkButton to={`${match.url}/${editedBySomeoneElseDocPath}`} color="secondary">Review</LinkButton>
          </>
        ),
        image: (
          <Image src={changeDocument} alt="Documents changed" />
        ),
      };
      break;
    }
    case MATTER_STAGES.REJECTED_DOCS:
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">A document was rejected</H3>
            <P fontSize="2">To fix problems please read the comments made by our settlement experts.</P>
            <LinkButton to={`${match.url}/${rejectedDocPath}`} color="secondary">Fix It</LinkButton>
          </>
        ),
        image: (
          <Image src={rejectedDocument} alt="Documents rejected" />
        ),
      };
      break;
    case MATTER_STAGES.FINALISED:
      summaryData = {
        body: (
          <>
            <H3 m="0" mb="5">Your loan application is complete</H3>
            <P fontSize="2">Our agents have reviewed your documents and they have passed successfully. Your matter is now complete and your transaction is now ready for settlement.</P>
            <P fontSize="2">You can download a copy of all your completed documents below.</P>
            <Button onClick={onDownloadDocuments} leftIcon={<Icon name="Download" color="white" />} color="secondary">Download Documents</Button>
          </>
        ),
        image: (
          <Image src={finalised} />
        ),
      };
      break;
    default:
      throw new Error('Invalid Summary Stage');
  }

  const showFeedback = (stage === MATTER_STAGES.FINALISED || stage === MATTER_STAGES.SUBMITTED_DOCS);

  return (
    <>
      <Helmet>
        { showFeedback && (
          <script>
            {feedbackScript(user, matterId, senderRef, stage, lender, broker, originator)}
          </script>
        )}
      </Helmet>
      <TitleColumn>
        <Show breakpoints={[1, 0]}>
          <TitleContainer title={user ? `Hi ${user.firstName}` : ''}>
            <Box mt={[4, 5, 6]}>
              {summaryData.body}
            </Box>
          </TitleContainer>
        </Show>
        <Hide breakpoints={[0, 1]}>
          <Header color="#ffffff">
            {support.isTenantSupport ? <Header.Title>{user ? `Hi ${user.firstName} (Support View)` : ''}</Header.Title> : <Header.Title>{user ? `Hi ${user.firstName}` : ''}</Header.Title>}
            <Box mt={[4, 5, 6]}>
              {summaryData.body}
            </Box>
          </Header>
        </Hide>
      </TitleColumn>
    </>
  );
};

SummaryStatus.propTypes = {
  matter: PropTypes.shape({
    matter: PropTypes.shape({
      borrowers: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
      matterId: PropTypes.number,
      senderRef: PropTypes.string,
      lender: PropTypes.string,
      broker: PropTypes.string,
      originator: PropTypes.string,
      dateToAutoSubmit: PropTypes.string,
      isSharia: PropTypes.bool,
    }).isRequired,
    matterProgress: PropTypes.shape({
      dateToAutoSubmit: PropTypes.string,
      allStagesJustCompleted: PropTypes.bool,
      readDocuments: PropTypes.arrayOf(PropTypes.shape(
        {
          errors: PropTypes.arrayOf(PropTypes.string),
          id: PropTypes.number,
          lastCompletedBy: PropTypes.string,
          myProgress: PropTypes.string,
          name: PropTypes.string,
          summary: PropTypes.string,
          theirProgress: PropTypes.string,
        },
      )),
      signDocuments: PropTypes.arrayOf(PropTypes.shape(
        {
          errors: PropTypes.arrayOf(PropTypes.string),
          availableForDownload: PropTypes.bool,
          hasBeenRead: PropTypes.bool,
          id: PropTypes.number,
          isReadOnly: PropTypes.bool,
          lastCompletedBy: PropTypes.string,
          myProgress: PropTypes.string,
          name: PropTypes.string,
          preSignatureForm: PropTypes.number,
          requiresPrinting: PropTypes.bool,
          requiresVerification: PropTypes.bool,
          summary: PropTypes.string,
          theirProgress: PropTypes.string,
          transactionIdAdded: PropTypes.bool,
          verificationType: PropTypes.number,
          webhookCalledAndCertificateReady: PropTypes.string,
        },
      )),
      uploadDocuments: PropTypes.arrayOf(PropTypes.shape(
        {
          errors: PropTypes.arrayOf(PropTypes.string),
          fileNames: PropTypes.arrayOf(PropTypes.string),
          hasDocuments: PropTypes.bool,
          id: PropTypes.number,
          lastCompletedBy: PropTypes.string,
          myProgress: PropTypes.string,
          name: PropTypes.string,
          summary: PropTypes.string,
          theirProgress: PropTypes.string,
        },
      )),
      userId: PropTypes.string,
    }).isRequired,
    dateToAutoSubmit: PropTypes.string,
  }).isRequired,
  user: PropTypes.shape({ firstName: PropTypes.string, id: PropTypes.string }).isRequired,
  match: ReactRouterPropTypes.match.isRequired,
  onSubmitDocuments: PropTypes.func.isRequired,
  onDownloadDocuments: PropTypes.func.isRequired,
};

export default SummaryStatus;
