export const VERIFICATION_STATUS = {
  INITIALISED: 'initialised',
  SUCCEEDED: 'succeeded',
  CANCELED: 'canceled',
  ERRORED: 'errored',
};

export const getVerificationModalConfig = (currentUserVerificationStatus, allSigningPartiesCompletedVerification) => {
  switch (currentUserVerificationStatus) {
    case VERIFICATION_STATUS.SUCCEEDED:
      return {
        title: 'Thank you, your identity has been verified',
        subTitle: 'Identity Verified',
        content: allSigningPartiesCompletedVerification
          ? 'You can now proceed to download and sign the Mortgage Document.'
          : '',
      };
    case VERIFICATION_STATUS.CANCELED:
      return {
        title: 'Looks like you canceled',
        subTitle: 'VOI',
        content: 'Don’t worry, you can complete your digital VOI at anytime. Click the ‘try again’ button below to have another go.',
      };

    case VERIFICATION_STATUS.ERRORED:
      return {
        title: 'Something’s gone wrong',
        subTitle: 'ERROR',
        content: 'We have notified our engineers and they will take a look. In the mean time we can reset some things and you can try again below.',
      };
    case VERIFICATION_STATUS.INITIALISED:
    default:
      return {
        title: 'Verify your identity',
        subTitle: 'VOI',
        content: 'We are ready to verify your identity with livesign. Click the button to get started.',
      };
  }
};
