import React, { useState, useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { PageLoading } from '@galilee/lilee';
import { fetchSignData } from 'actions/Sign';
import { useAuth } from 'state/AuthProvider';
import DynamicFormProvider, { prepareDynamicFormData } from 'state/DynamicFormProvider';
import { useApplication } from 'state/ApplicationProvider';
import { useMatter } from 'state/MatterProvider';
import SignPage from './signPage';
import PrintPage from './printPage';
import InstructionsPage from './verifyPage';

const Sign = ({ match, history }) => {
  const { user, authToken } = useAuth();
  const { dispatch } = useApplication();
  const docId = Number(match.params.id);
  const [data, setData] = useState(null);
  const { state } = useMatter();

  useEffect(() => {
    async function fetchData() {
      const response = await fetchSignData(dispatch, docId, authToken);
      const dynamicFormData = prepareDynamicFormData(response, user.id);
      setData(dynamicFormData);
    }
    if (data && data.docId === docId) return;
    setData(null);
    fetchData();
  }, [authToken, data, dispatch, docId, user.id]);

  if (!authToken || !user || !data) return <PageLoading />;

  const getContent = () => {
    const { signDocuments } = state?.matterProgress;
    const {
      transactionIdAdded, requiresVerification, requiresPrinting, isReadOnly,
    } = signDocuments?.find((s) => s.id === docId);

    if (!requiresPrinting || isReadOnly) return <SignPage match={match} history={history} />;
    if (!requiresVerification) return <PrintPage match={match} history={history} />;

    let allVerificationsCompleted = true;
    state?.verificationProgress.forEach((voiUserProgress) => {
      const { requireVerification, verificationCompleted } = voiUserProgress;
      if (!requireVerification) return;
      const { voiCompleted, isReadOnly: readOnly } = verificationCompleted?.find((v) => v.id === docId);
      if (!voiCompleted && !readOnly) {
        allVerificationsCompleted = false;
      }
    });

    return allVerificationsCompleted && transactionIdAdded
      ? <PrintPage match={match} history={history} />
      : <InstructionsPage match={match} />;
  };

  return (
    <DynamicFormProvider data={data} authToken={authToken} userId={user.id} applicationDispatch={dispatch}>
      {getContent()}
    </DynamicFormProvider>
  );
};

Sign.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
  history: ReactRouterPropTypes.history.isRequired,
};

export default Sign;
