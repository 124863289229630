import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Flex, Modal, Text, Button,
} from '@galilee/lilee';
import styled from 'styled-components/macro';
import submit from 'images/SubmitPopup.png';
import AutoSubmitCountdown from './AutoSubmitCountdown';

const Popup = styled(Flex)`
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #072438ac;
`;

const SubmitBox = styled(Flex)`
  margin: auto;
  background-color: white;
  border-radius: 10px;
`;

SubmitBox.defaultProps = { maxWidth: ['80%', '80%', '750px'] };

function SubmitPopup({ status, onSubmitDocuments, dateToAutoSubmit }) {
  const [trigger, setTrigger] = useState(false);
  const [isCountdownFinished, setIsCountdownFinished] = useState(false);

  useEffect(() => setTrigger((status === 'submit')), [status]);

  useEffect(() => {
    if (!dateToAutoSubmit) return;
    const isPastDue = new Date(dateToAutoSubmit) < new Date();
    setIsCountdownFinished(isPastDue);
  }, [dateToAutoSubmit]);

  const submitNow = () => {
    onSubmitDocuments();
    setTrigger(!trigger);
  };

  return (trigger) && (
    <Popup style={{ zIndex: 1000 }}>
      <SubmitBox>
        <Modal.IllustrationBody image={submit}>
          <Modal.Body p="6">
            <Modal.Subtitle mb={[6, null, 2]}>Submit Documents</Modal.Subtitle>
            <Modal.Title fontSize="5" lineHeight="compact">Your documents are ready to submit</Modal.Title>
            <Text fontSize="2" mb="8">
              All parties have completed all the necessary documents which will be automatically submitted
              {
                !isCountdownFinished
                && (
                <>
                  {' in '}
                  <AutoSubmitCountdown dateToAutoSubmit={dateToAutoSubmit} onComplete={() => setTrigger(!trigger)} />
                </>
                )
              }
              <Text>To speed things up you can submit now.</Text>
            </Text>
            <Text fontSize="1" mb={[5, null, 3]} color="opacity60">
              Note: Not submitting your documents could result in a delay to your mortgage settlement.
            </Text>
          </Modal.Body>
          <Modal.ActionContainer width="100%" justifyContent="space-evenly">
            <Button mb={6} color="secondary" onClick={() => submitNow()}>Submit Now</Button>
            <Button mb={6} color="base" onClick={() => setTrigger(!trigger)}>Submit Later</Button>
          </Modal.ActionContainer>
        </Modal.IllustrationBody>
      </SubmitBox>
    </Popup>
  );
}

SubmitPopup.propTypes = {
  status: PropTypes.string.isRequired,
  onSubmitDocuments: PropTypes.func.isRequired,
  dateToAutoSubmit: PropTypes.string,
};

SubmitPopup.defaultProps = {
  dateToAutoSubmit: null,
};

export default SubmitPopup;
