import React, { useEffect, useState } from 'react';
import {
  Button, Text, Panel, Input, Box, H6, Icon, Flex, H1, FieldError, PageLoading,
} from '@galilee/lilee';

import { useTrackNTrace } from 'state/track/TrackNTraceProvider';

import styled from 'styled-components/macro';

const LoginPanel = styled(Panel)`
  max-width: 700px;
  margin-bottom: ${(p) => p.theme.space[8]};
  border-radius:  ${(p) => p.theme.sizes[3]};
`;

const AdditionalInfoWrapper = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  background: ${(p) => p.theme.colors.error};
  padding: ${(p) => p.theme.space[6]} ${(p) => p.theme.space[7]};
  border-radius: ${(p) => p.theme.radii[3]};
  max-width: 512px;
`;

const LoginForm = () => {
  const {
    loginPendingUser, noMattersForUser, isLoading, actions,
  } = useTrackNTrace();
  const { requireAccessCode } = actions;
  const [email, setEmail] = useState('');
  const [isInvalid, setIsInvalid] = useState(false);

  useEffect(() => {
    if (!loginPendingUser) return;
    setEmail(loginPendingUser);
  }, [loginPendingUser]);

  const handleButtonClick = () => {
    if (!email || isInvalid) return;
    requireAccessCode(email);
  };

  const handleValidation = () => {
    const emailRegex = /.+@[^@]+\.[^@]{2,}$/;
    const result = emailRegex.test(email);
    setIsInvalid(!result);
  };

  return (
    <>
      {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
      <LoginPanel mx="auto">
        {noMattersForUser && (
        <AdditionalInfoWrapper mx="auto">
          <Icon name="WarningCircle" size="36px" color="white" mr="5" />
          <H1 fontSize="3" color="white" fontWeight="medium">No matters found for this email address</H1>
        </AdditionalInfoWrapper>
        )}
        <Box px={[1, 8]} py="6">
          <Text maxWidth="500px" mb="8" mx="auto" textAlign="center">Please enter the email address you have registered with Galilee so that we can retrieve any active matters you may have.</Text>
          <H6 mt="5" mb="5">
            Email Address
          </H6>
          <Input autoFocus fontSize="2" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={handleValidation} />
          {isInvalid && <FieldError error="Invalid address" />}
          <Button mt="8" onClick={handleButtonClick} disabled={isLoading}>Continue</Button>
        </Box>
      </LoginPanel>
    </>
  );
};

export default LoginForm;
