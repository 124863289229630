import React, {
  createContext, useCallback, useContext, useEffect, useRef, useState,
} from 'react';
import { toast } from '@galilee/lilee';
import { useHistory } from 'react-router';
import { useApplication } from 'state/ApplicationProvider';
import {
  requireAccessCodeAsync, getTokenAsync, createCallbackAsync, getCallbackStatusAsync, deleteCallbacksAsync, getMattersAsync,
} from 'actions/Track';
import useLocalStorage from 'hooks/useLocalStorage';
import jwtDecode from 'jwt-decode';

const TT_LOCAL_STORAGE_PREFIX = '_kwil_track_trace';

const Role = {
  Admin: 'Admin',
  Broker: 'Broker',
  Borrower: 'Borrower',
};

const CallbackStatus = {
  Queued: 'Queued',
  Active: 'Active',
  Finalised: 'Finalised',
};

const hasExpired = (token) => {
  const decodedToken = jwtDecode(token);
  return +decodedToken.exp * 1000 < new Date().getTime();
};

const normalizeUser = (user) => {
  const normalizedUser = {
    id: user.UserId,
    email: user.EmailId,
    role: user.role || null,
  };

  return normalizedUser;
};

const TrackNTraceContext = createContext();

function useTrackNTraceHook() {
  const { state: applicationState } = useApplication();
  const [loginPendingUser, setLoginPendingUser] = useLocalStorage(`${TT_LOCAL_STORAGE_PREFIX}_login_pending_user`, null);
  const [credentials, setCredentials] = useLocalStorage(`${TT_LOCAL_STORAGE_PREFIX}_credentials`, []);
  const [callback, setCallback] = useLocalStorage(`${TT_LOCAL_STORAGE_PREFIX}_callback_request`, null);

  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [matters, setMatters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [noMattersForUser, setNoMattersForUser] = useState(false);
  const [mattersLoaded, setMattersLoaded] = useState(false);
  const [reload, setReload] = useState(0);

  const history = useHistory();

  const callbackRef = useRef();
  const setCallbackRef = useRef();
  const setCredentialsRef = useRef();

  const { trackNTraceApiUrl } = applicationState.appSettings;

  const getToken = useCallback(async (id) => {
    const credential = credentials.find((c) => c.id === id);
    if (!credential) {
      toast.error(`User ${id} doesn't exist.`);
    }
    if (hasExpired(credential.token)) {
      const activeCredentials = credentials.filter((c) => c.id !== credential.id);
      setCredentialsRef.current(activeCredentials);
      return null;
    }

    return credential.token;
  }, [credentials]);

  const getMatters = useCallback(async (email) => {
    try {
      const token = await getToken(email);
      if (!token || !trackNTraceApiUrl) return;
      setIsLoading(true);
      const { searchResults } = await getMattersAsync(token, trackNTraceApiUrl);
      const mattersWithOwner = searchResults.map((i) => ({ ...i, owner: email }));
      setMatters((prev) => [...new Map(prev.concat(mattersWithOwner).map((item) => [item.goldId, item]))].map(([, matter]) => matter));
    } catch (err) {
      toast.error(`Failed to load matters. ${err}`);
    } finally {
      setIsLoading(false);
    }
  }, [getToken, trackNTraceApiUrl]);

  useEffect(() => {
    if (!setCredentialsRef.current) {
      setCredentialsRef.current = setCredentials;
    }
  }, [setCredentials]);

  useEffect(() => {
    if (!callbackRef.current) {
      callbackRef.current = callback;
    }
  }, [callback]);

  useEffect(() => {
    if (!setCallbackRef.current) {
      setCallbackRef.current = setCallback;
    }
  }, [setCallback]);

  useEffect(() => {
    const activeCredentials = credentials.filter((c) => !hasExpired(c.token));
    if (activeCredentials.length !== credentials.length) {
      setCredentialsRef.current(activeCredentials);
    }
    const normalizedUsers = activeCredentials.map((c) => normalizeUser(jwtDecode(c.token)));
    setUsers(normalizedUsers);
  }, [credentials]);

  useEffect(() => {
    async function loadMatters() {
      const requests = users
        .filter((u) => !u.role || u.role !== Role.Admin)
        .map((u) => getMatters(u.email));

      await Promise.all(requests);
    }

    if (mattersLoaded) return;

    loadMatters();
  }, [users, getMatters, mattersLoaded, reload]);

  useEffect(() => {
    async function updateCallbackStatus(callbackId) {
      try {
        const result = await getCallbackStatusAsync(callbackId, trackNTraceApiUrl);
        if (result && result.status === CallbackStatus.Queued) {
          setCallbackRef.current(result);
          return;
        }

        setCallbackRef.current(null);
      } catch (err) {
        setCallbackRef.current(null);
      }
    }

    if (!callbackRef.current || !callbackRef.current.callBackId || !trackNTraceApiUrl) return;
    updateCallbackStatus(callbackRef.current.callBackId);
  }, [trackNTraceApiUrl, reload]);

  const requireAccessCode = async (email) => {
    try {
      setIsLoading(true);
      await requireAccessCodeAsync(email, trackNTraceApiUrl);
      setLoginPendingUser(email);
      history.push('/track/login/withCode');
    } catch (e) {
      toast.error(`Failed to get verification code.${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const login = async (email, code) => {
    try {
      setIsLoading(true);
      const result = await getTokenAsync(email, code, trackNTraceApiUrl);

      setLoginPendingUser(null);

      const newCredential = {
        id: email,
        token: result.token,
        refreshToken: result.refreshToken,
      };

      const userInfo = jwtDecode(newCredential.token);
      const currentUserIsAdmin = (userInfo.role && userInfo.role === Role.Admin);

      if (!currentUserIsAdmin) {
        const { searchResults } = await getMattersAsync(newCredential.token, trackNTraceApiUrl);
        if (!searchResults || !searchResults.length) {
          setNoMattersForUser(true);
          history.push('/track/login');
          return;
        }
        const mattersWithOwner = searchResults.map((i) => ({ ...i, owner: email }));
        setMatters((prev) => [...new Map(prev.concat(mattersWithOwner).map((item) => [item.goldId, item]))].map(([, matter]) => matter));
        setMattersLoaded(true);
      }

      const updatedCredentials = [...credentials.filter((c) => c.id !== newCredential.id), newCredential];
      setCredentials(updatedCredentials);

      if (currentUserIsAdmin) {
        history.push('/track/admin');
        return;
      }

      history.push('/track');
    } catch (e) {
      toast.error(`Failed to login. ${e}`);
    } finally {
      setIsLoading(false);
    }
  };

  const logout = async () => {
    setCredentials([]);
    setUsers([]);
    setMatters([]);
  };

  const sendCallbackRequest = async (phoneNumber, note, name, matterUrl) => {
    try {
      const callbackId = await createCallbackAsync(phoneNumber, note, name, matterUrl, trackNTraceApiUrl);
      const result = await getCallbackStatusAsync(callbackId, trackNTraceApiUrl);
      setCallback(result);
    } catch (err) {
      toast.error(err);
    }
  };

  const cancelCallbackRequest = async () => {
    if (!callback) return;

    try {
      deleteCallbacksAsync('', callback.callBackId, trackNTraceApiUrl);
      setCallback(null);
    } catch (err) {
      toast.error(err);
    }
  };

  const isAuthenticated = credentials.length && credentials.some((c) => !hasExpired(c.token));
  const isAdmin = users.some((u) => u.role === Role.Admin);

  const actions = {
    requireAccessCode,
    login,
    logout,
    getToken,
    setSelectedUser,
    sendCallbackRequest,
    cancelCallbackRequest,
    setReload,
  };

  return {
    isLoading,
    isAuthenticated,
    isAdmin,
    users,
    loginPendingUser,
    selectedUser,
    matters,
    callback,
    noMattersForUser,
    actions,
  };
}

export const useTrackNTrace = () => useContext(TrackNTraceContext);

export default function TrackNTraceProvider(props) {
  const value = useTrackNTraceHook();
  return <TrackNTraceContext.Provider value={value} {...props} />;
}
