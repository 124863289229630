import React from 'react';
import PropTypes from 'prop-types';
import {
  Input, Label, Column, FieldError,
} from '@galilee/lilee';

const TextBoxField = ({
  fieldConfig, validate, fieldError, noLabel, labelProps, ...props
}) => {
  const { name, displayName, setValue } = fieldConfig;
  return (
    <Column mr={[0, 3, 4, 5]}>
      { !noLabel && <Label {...labelProps} htmlfor={name}>{displayName}</Label> }
      <Input
        {...props}
        name={name}
        onChange={setValue}
        onBlur={validate}
      />
      <FieldError error={fieldError} />
    </Column>
  );
};

TextBoxField.defaultProps = {
  fieldError: null,
  noLabel: false,
  labelProps: null,
};

TextBoxField.propTypes = {
  noLabel: PropTypes.bool,
  fieldConfig: PropTypes.shape({
    name: PropTypes.string,
    displayName: PropTypes.string,
    value: PropTypes.func,
    setValue: PropTypes.func,
  }).isRequired,
  validate: PropTypes.func.isRequired,
  fieldError: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  labelProps: PropTypes.object,
};

export default TextBoxField;
