import {
  useEffect, useState,
} from 'react';

export default function useOnScreen(ref) {
  const [isIntersecting, setIntersecting] = useState(false);

  useEffect(() => {
    const onIntersection = (entry) => setIntersecting(entry[0].isIntersecting);

    const observer = new IntersectionObserver(onIntersection);
    if (!ref.current) return () => { };
    observer.observe(ref.current);

    return () => { observer.disconnect(); };
  }, [ref]);

  return isIntersecting;
}
