import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { Flex, Show, Hide } from '@galilee/lilee';
import Carousel from 'components/Carousel';
import { useMatter } from 'state/MatterProvider';
import ProgressGroup from 'components/ProgressGroup';
import CarouselButtons from 'components/CarouselButtons';

const NavContainer = styled(Flex)`
  margin: auto;
  width: 100%;
  max-width: 1100px;
  height: 85px;
`;

const calcActiveCorouselIndex = (pathname) => {
  if (pathname.includes('read')) return 0;
  if (pathname.includes('sign')) return 1;
  return 2;
};

const TopNav = () => {
  const { state } = useMatter();
  const { pathname } = useLocation();
  if (!state.matterProgress || !state.matter) return null;
  const { matter, matterProgress } = state;
  const carouselActiveIndex = calcActiveCorouselIndex(pathname);

  return (
    <>
      <Show breakpoints={[1]}>
        <NavContainer padding="0">
          <Carousel
            current={carouselActiveIndex}
            loop
            widgets={[CarouselButtons]}
          >
            <ProgressGroup matterId={matter.matterId} noBorder title="Read & Accept" groupName="read" progress={matterProgress.readDocuments} first />
            <ProgressGroup matterId={matter.matterId} noBorder title="Complete & Sign" groupName="sign" progress={matterProgress.signDocuments} />
            <ProgressGroup matterId={matter.matterId} noBorder title="Upload Docs" groupName="upload" progress={matterProgress.uploadDocuments} />
          </Carousel>
        </NavContainer>
      </Show>
      <Hide breakpoints={[0, 1]}>
        <NavContainer padding="0">
          <ProgressGroup matterId={matter.matterId} title="Read & Accept" groupName="read" progress={matterProgress.readDocuments} first />
          <ProgressGroup matterId={matter.matterId} title="Complete & Sign" groupName="sign" progress={matterProgress.signDocuments} />
          <ProgressGroup matterId={matter.matterId} title="Upload Docs" groupName="upload" progress={matterProgress.uploadDocuments} />
        </NavContainer>
      </Hide>
    </>
  );
};

export default TopNav;
