import React from 'react';
import propTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Absolute } from '@galilee/lilee';
import CircleIcon from 'components/CircleIcon';

const Wrapper = styled.div`
    position: absolute;
    width: 100%;
    z-index: 100;
    bottom: 0;
    text-align: center;
`;

export default function CarouselButtons(props) {
  const {
    index, total, loop, prevHandler, nextHandler,
  } = props;
  return (
    <Wrapper>
      { (loop || index !== 0) && (
        <Absolute left={['8px', '32px']} top="-64px"><CircleIcon color="white" bg="#E7EAEC" iconSize="sm" name="NavLeft" onClick={prevHandler} /></Absolute>
      )}
      { (loop || index !== total - 1) && (
        <Absolute right={['8px', '32px']} top="-64px"><CircleIcon color="white" bg="#E7EAEC" iconSize="sm" name="NavRight" onClick={nextHandler} /></Absolute>
      )}
    </Wrapper>
  );
}

CarouselButtons.defaultProps = {
  prevHandler: () => {},
  nextHandler: () => {},
  loop: false,
};

CarouselButtons.propTypes = {
  index: propTypes.number.isRequired,
  total: propTypes.number.isRequired,
  prevHandler: propTypes.func,
  nextHandler: propTypes.func,
  loop: propTypes.bool,
};
