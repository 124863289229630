import React, { } from 'react';
import {
  Flex, Text, Box, mediaQueries, Button,
} from '@galilee/lilee';
import styled from 'styled-components/macro';
import { useHistory } from 'react-router-dom';
import { useAuth } from 'state/AuthProvider';
import Error from 'components/Error';
import PropTypes from 'prop-types';

const { lessThan } = mediaQueries;

const BorrowerPanel = styled(Box)`
  border-radius: 10px;
  background-color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${lessThan[0]`
    flex-direction: column;
  `}
`;

const BorrowerContainer = styled(Flex)`
  ${lessThan[0]`
    flex-direction: column;
    width: auto;
  `}
`;

const BorrowerCard = ({ borrower }) => {
  const { support } = useAuth();
  const history = useHistory();
  const { isTenantSupport, setters } = support;

  if (!isTenantSupport) return <Error />;
  const {
    id, firstName, lastName,
  } = borrower;
  const fullName = `${firstName} ${lastName}`;
  return (
    <BorrowerPanel mb={[6, 4, 5, 6]} p={[6, 4, 5]}>
      <BorrowerContainer alignItems="center">
        <Flex flexDirection="column" mb={[6, 0, 0, 0]} mt={[6, 0, 0, 0]}>
          <Text color="primary" fontSize={[2, 3]} mr={[5, 6, 7]}>
            {fullName}
          </Text>
        </Flex>
      </BorrowerContainer>
      <Button
        mb={[3, 0, 0, 0]}
        color="secondary"
        onClick={() => {
          history.goBack();
          setters.setSupportViewUserId(id);
        }}
      >
        View
      </Button>
    </BorrowerPanel>
  );
};

BorrowerCard.propTypes = {
  borrower: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    middleName: PropTypes.string,
  }).isRequired,
};

export default BorrowerCard;
