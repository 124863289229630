import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Flex, Icon, Text } from '@galilee/lilee';
import { httpRequest, downloadFile, getSummaryStage } from 'utils';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { deleteAttachment } from 'actions/Upload';

const DownloadLink = styled(Text)`  
  overflow-wrap: anywhere;
  cursor:  ${(p) => (p.uploading ? 'default' : 'pointer')};
  &:hover {
    text-decoration: ${(p) => (p.uploading ? 'none' : 'underline')};
  }
`;

const DeleteLink = styled(Icon)`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const Wrapper = styled(Flex)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  justify-content: space-around;
  align-items: center;
  background-color: white;
  font-size: ${(p) => p.theme.fontSizes[1]};
  white-space: normal;
`;

Wrapper.defaultProps = {
  p: [3, 4],
  mt: [4, 5, 6],
  borderRadius: 4,
};

const UploadedMultiDocuments = ({
  documentsMetaData, dispatch, applicationDispatch, matterDispatch, ...rest
}) => {
  if (!documentsMetaData?.documentsUploaded) return null;
  return (
    <>
      {Object.keys(documentsMetaData.documentsUploaded).map((id) => (
        <UploadDocumentWidget
          key={id}
          document={documentsMetaData.documentsUploaded[id]}
          dispatch={dispatch}
          applicationDispatch={applicationDispatch}
          matterDispatch={matterDispatch}
          rest={rest}
          meta={documentsMetaData}
        />
      ))}
    </>
  );
};

const UploadDocumentWidget = ({
  document, dispatch, applicationDispatch, matterDispatch, rest, meta,
}) => {
  const [downloading, setDownloading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const { authToken, user, support } = useAuth();
  const { isTenantSupport } = support;
  const { state } = useMatter();
  const {
    documentName, id, uploading = false,
  } = document;
  const doDownload = () => {
    if (uploading || downloading || isTenantSupport) return;
    setDownloading(true);
    httpRequest(`/api/uploaddocuments/${meta.uploadDocumentId}/file/${id}`, 'get', null, { Authorization: `Bearer ${authToken}` })
      .then((blob) => {
        downloadFile(blob, documentName);
      })
      .catch(() => {
        throw new Error(`Unable to download file for document (${meta.uploadDocumentId}).`);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  const onClickDelete = async (event) => {
    if (deleting || isTenantSupport) return;
    event.preventDefault();
    setDeleting(true);
    const documentsMeta = meta?.documents || {};
    await deleteAttachment(dispatch, applicationDispatch, matterDispatch, meta.uploadDocumentId, id, documentsMeta, authToken);
    setDeleting(false);
  };
  const status = user.type === 1 ? getSummaryStage(state.matterProgress, user.id).stage : 'started';
  return (
    <Wrapper {...rest}>
      <Icon size="24px" name={uploading ? 'Spinner' : 'CheckCircle'} color="secondary" stepping={uploading} mr={[3, 4]} />
      { downloading && <Text>Downloading...</Text>}
      { !downloading && <DownloadLink uploading={uploading} onClick={doDownload}>{documentName}</DownloadLink>}
      {(status === 'started' || status === 'submit') && !uploading && (
        !deleting
          ? (
            <DeleteLink
              onClick={onClickDelete}
              size="24px"
              name="Cross"
              p="6px"
              ml={[3, 4]}
            />
          ) : <Icon name="loading" color="base80" spin />
      )}
    </Wrapper>
  );
};

UploadDocumentWidget.propTypes = {
  document: PropTypes.shape({
    id: PropTypes.number,
    uploading: PropTypes.bool,
    documentFileType: PropTypes.string,
    documentName: PropTypes.string,
    documentSize: PropTypes.number,
    map: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    documents: PropTypes.shape({}),
    uploadDocumentId: PropTypes.number,
  }).isRequired,
  dispatch: PropTypes.func.isRequired,
  applicationDispatch: PropTypes.func.isRequired,
  matterDispatch: PropTypes.func.isRequired,
  rest: PropTypes.shape({}).isRequired,
};

UploadedMultiDocuments.defaultProps = {
  documentsMetaData: null,
  matterDispatch: null,
};

UploadedMultiDocuments.propTypes = {
  documentsMetaData: PropTypes.shape({
    uploadDocumentId: PropTypes.number,
    documentsUploaded: PropTypes.shape({
      uploading: PropTypes.bool,
      documentFileType: PropTypes.string,
      documentName: PropTypes.string,
      documentSize: PropTypes.number,
      map: PropTypes.func,
    }),
  }),
  dispatch: PropTypes.func.isRequired,
  applicationDispatch: PropTypes.func.isRequired,
  matterDispatch: PropTypes.func,
};

export default UploadedMultiDocuments;
