import React from 'react';
import PropTypes from 'prop-types';
import { theme, CustomThemeProvider } from '@galilee/lilee';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyle from 'components/GlobalStyle';
import ErrorBoundary from 'components/ErrorBoundary';
import AuthProvider from './AuthProvider';
import ApplicationProvider from './ApplicationProvider';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');

const RootProvider = ({ children }) => (
  <ApplicationProvider>
    <AuthProvider>
      <CustomThemeProvider theme={theme}>
        <BrowserRouter basename={baseUrl}>
          <GlobalStyle />
          <ErrorBoundary>
            {children}
          </ErrorBoundary>
        </BrowserRouter>
      </CustomThemeProvider>
    </AuthProvider>
  </ApplicationProvider>
);

RootProvider.defaultProps = {
  children: null,
};

RootProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default RootProvider;
