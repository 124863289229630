import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  H5, P, Row, Column, Flex, Icon, Button, MessageBox, HR, H7,
} from '@galilee/lilee';
import styled from 'styled-components/macro';
import LinkButton from 'components/LinkButton';
import PROGRESS, { toProgressName } from 'enums/Progress';
import { useAuth } from 'state/AuthProvider';
import { doDownload } from 'utils';
import ProgressIcon from 'components/ProgressIcons';

const Hyperlink = styled.button`
  border: 0;
  margin: 0;
  font-size: inherit;
  font-family: inherit;
  font-weight: bold;
  border-width: 0;
  background-color: transparent;
  text-decoration: none;
  cursor: pointer;
  color: ${(p) => p.theme.colors.secondary};;
  &:hover {
    color: ${(p) => p.theme.colors.primary};
  }
`;

function allSigningPartiesCompletedVerification(verificationProgress, docId) {
  let result = true;
  verificationProgress.forEach((voiUserProgress) => {
    const { requireVerification, verificationCompleted } = voiUserProgress;
    if (!requireVerification) return;
    const { voiCompleted, isReadOnly } = verificationCompleted?.find((v) => v.id === docId);
    if (!voiCompleted && !isReadOnly) result = false;
  });

  return result;
}

const showDownloadButton = (progress, verificationProgress, sectionType, docId) => {
  switch (sectionType) {
    case 'read':
      return true;
    case 'sign':
      return progress.requiresPrinting
        ? (!progress.requiresVerification || allSigningPartiesCompletedVerification(verificationProgress, docId))
        : true;
    case 'upload':
      return progress.hasDocument === true;
    default:
      throw new Error('Invalid sectionType passed to SummaryRow');
  }
};

const SummaryRow = ({
  progress, routePath, sectionType, verificationProgress,
}) => {
  const { authToken } = useAuth();
  const [downloading, setDownloading] = useState(false);
  const [downloadingVOI, setDownloadingVOI] = useState(false);
  const {
    requiresVerification, id: docId, name, summary, myProgress, webhookCalledAndCertificateReady,
  } = progress;
  const notStarted = myProgress === PROGRESS.NOTSTARTED;

  return (
    <>
      <HR mb={[7]} />
      <Row collapseIndex={1} mb={[0]}>
        <Column flexDirection="row" colspan="2" flexWrap="nowrap" mb="0">
          <ProgressIcon onlyMyProgress progress={progress} />
          <Flex width="100%" flexDirection="column" ml="4">
            <H5>{name}</H5>
            <H7 mt="3">{toProgressName(myProgress)}</H7>
            <P mt="3">
              {summary}
            </P>
            { requiresVerification && !webhookCalledAndCertificateReady && (
            <MessageBox mb={4} label="Important:">
              You will need to verify your identity before signing.
            </MessageBox>
            )}
            { requiresVerification && webhookCalledAndCertificateReady && (
            <MessageBox mb={4} label="Note:">
              You have completed your VOI
              <Hyperlink onClick={() => doDownload(progress, setDownloadingVOI, sectionType, docId, authToken, true)}>
                {downloadingVOI ? 'downloading' : 'download'}
              </Hyperlink>
            </MessageBox>
            )}
          </Flex>
        </Column>
        <Column flexWrap={['wrap-reverse', 'nowrap']} flexDirection="row" alignItems="flex-start" justifyContent={['center', 'flex-end']}>
          { showDownloadButton(progress, verificationProgress, sectionType, docId) && (
          <Button
            mt={[4, 0]}
            iconSmall
            iconColor="base60"
            leftIcon={<Icon name="Download" />}
            onClick={() => doDownload(progress, setDownloading, sectionType, docId, authToken)}
            color="link"
          >
              {downloading ? 'Downloading' : 'Download'}
          </Button>
          )}
          <LinkButton
            mt={[4, 0]}
            minWidth="150px"
            to={`${routePath}/${docId}`}
            color={notStarted ? 'secondary' : 'base'}
          >
            {notStarted ? 'start' : 'read'}
          </LinkButton>
        </Column>
      </Row>
    </>
  );
};

SummaryRow.defaultProps = {
  progress: null,
  verificationProgress: [],
};

SummaryRow.propTypes = {
  sectionType: PropTypes.string.isRequired,
  progress: PropTypes.shape({
    availableForDownload: PropTypes.bool,
    requiresPrinting: PropTypes.bool,
    requiresVerification: PropTypes.bool,
    fileName: PropTypes.string,
    id: PropTypes.number,
    name: PropTypes.string,
    summary: PropTypes.string,
    myProgress: PropTypes.string,
    theirProgress: PropTypes.string,
    webhookCalledAndCertificateReady: PropTypes.string,
  }),
  verificationProgress: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      requireVerification: PropTypes.bool,
      verificationCompleted: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, voiCompleted: PropTypes.bool, isReadOnly: PropTypes.bool }),
      ),
    }),
  ),
  routePath: PropTypes.string.isRequired,
};

export default SummaryRow;
