import {
  Box, mediaQueries,
} from '@galilee/lilee';
import styled from 'styled-components/macro';

const { lessThan } = mediaQueries;

export const OuterContainer = styled(Box)`
  ${lessThan[1]`
    background-color: white;
    min-height: 100%;
    height: auto;
  `}
`;

export const ShadowContainer = styled(Box)`
  background-color: white;
  width: 100%;
  padding:${(p) => p.theme.space[7]};
  border-radius: 10px;
  justify-content: space-evenly;
  box-shadow: 0px 0px 24px 0px #0724380D;
  min-height: ${(p) => p.theme.breakpoints[1]};
  ${lessThan[1]`
    background-color: white;
    border-radius: 0px;
    box-shadow: none;
  `}
`;
