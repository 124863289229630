import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Flex, Absolute, Text,
} from '@galilee/lilee';
import CircleIcon from 'components/CircleIcon';

export const PDFNavWrapper = styled(Flex)`
  display: ${(p) => (p.show ? 'initial' : 'none')};
  position: relative;
  width: 0;
  align-items: center;
  justify-content: center;
`;

PDFNavWrapper.defaultProps = {
  show: false,
};

export const PDFNavIcon = styled(CircleIcon)` 
`;

PDFNavIcon.defaultProps = {
  bg: 'baseOpacity40',
  iconSize: 'sm',
  name: 'NavLeft',
  color: 'white',
};

export const PDFNav = styled(Absolute)`

  @keyframes blinker {
    from { background-color: transparent; }
    to { background-color: ${(p) => p.theme.colors.baseOpacity07}; }
  }

  animation-delay: 1s;
  animation-name: ${(p) => (p.pulse ? 'blinker' : 'none')};
  animation-duration: 0.7s;
  animation-iteration-count:6;
  animation-timing-function:ease-in-out;
  animation-direction: alternate;

  cursor: pointer;
  left: ${(p) => (p.leftNav ? 0 : 'initial')};
  right: ${(p) => (!p.leftNav ? 0 : 'initial')};
  top: 0;
  bottom: 0;
  display: flex;
  z-index: 1;
  width: 80px;
  &:hover {
    background-color: ${(p) => p.theme.colors.baseOpacity07};
    ${PDFNavIcon} {
      background-color: ${(p) => p.theme.colors.baseOpacity50};
    }
  }
  justify-content: center;
  align-items: center;
  border-radius: ${(p) => p.theme.radii[3]};
`;

PDFNav.defaultProps = {
  top: 0,
  bottom: 0,
  display: 'flex',
  zIndex: 1,
  width: '80px',
  leftNav: false,
};

export const PDFPageNumber = ({ pageNumber, pageCount, ...rest }) => (<Text {...rest}>{pageCount ? `Page ${pageNumber} of ${pageCount}` : null}</Text>);

PDFPageNumber.defaultProps = {
  pageNumber: null,
  pageCount: null,
  muted: true,
  fontSize: 0,
  textAlign: 'center',
};

PDFPageNumber.propTypes = {
  pageNumber: PropTypes.number,
  pageCount: PropTypes.number,
  muted: PropTypes.bool,
  fontSize: PropTypes.number,
  textAlign: PropTypes.string,
};
