// import styled from 'styled-components/macro';
import React from 'react';
import PropTypes from 'prop-types';
import { Box, H6 } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const TextBox = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { labelText } = fields[fieldName];
  return (
    <Box width="100%">
      <H6>{labelText}</H6>
    </Box>
  );
};

TextBox.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default TextBox;
