import React, { useState, useRef } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Box, P, Button, FieldError,
} from '@galilee/lilee';
import SignatureCanvas from 'react-signature-canvas';

const SigBox = styled(Box)`
  width: 100%;
  border: 1px solid ${(p) => p.theme.colors.base20};
  overflow: hidden;
`;

const SignatureField = ({
  onSignatureUpdate,
}) => {
  const sigCanvas = useRef({});
  const [hasSignature, setHasSignature] = useState(false);
  const [signErrorMessage, setSignErrorMessage] = useState(null);
  const signingTolerance = 5;

  const clear = () => {
    sigCanvas.current.clear();
    onSignatureUpdate(null);
    setHasSignature(false);
  };

  const validateSignature = () => {
    if (sigCanvas.current.isEmpty()) {
      setSignErrorMessage('Please provide your signature in the box above.');
      return false;
    }

    const signatureData = sigCanvas.current.toData();
    let validSignature = false;
    Object.keys(signatureData).forEach((id) => {
      if (signatureData[id].length > signingTolerance || (signatureData.length > signingTolerance / 2 && signatureData[id].length > 2)) {
        validSignature = true;
      }
    });

    if (!validSignature) {
      setSignErrorMessage('Signature is not complex enough');
      return false;
    }

    setSignErrorMessage(null);
    return true;
  };

  const handleOnSignatureUpdate = () => {
    if (!validateSignature()) {
      onSignatureUpdate(null);
      return;
    }
    const image = sigCanvas.current.getTrimmedCanvas().toDataURL();
    onSignatureUpdate(image);
  };

  return (
    <>
      <SigBox>
        <SignatureCanvas
          ref={sigCanvas}
          canvasProps={{
            height: 300,
            width: 900,
          }}
          onEnd={() => handleOnSignatureUpdate()}
        />
      </SigBox>
      { signErrorMessage && !hasSignature && <FieldError error={signErrorMessage} /> }
      <P mt="5">Using your mouse or finger,  sign as you normally would with a pen in the box above.</P>
      <Box>
        <Button color="base" onClick={clear}>Clear</Button>
      </Box>
    </>
  );
};

SignatureField.propTypes = {
  onSignatureUpdate: PropTypes.func.isRequired,
};

export default SignatureField;
