/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import PropTypes from 'prop-types';
import {
  ActionBar, Icon, Panel, PageLoading, H4, Label, HR, OL, toast, MessageBox, UL, Flex, Button, Modal, Text, ResponsiveGroup,
} from '@galilee/lilee';
import { useHistory } from 'react-router-dom';
import Helmet from 'react-helmet';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { useApplication } from 'state/ApplicationProvider';
import Container from 'components/Container';
import PROGRESS_STAGES from 'enums/ProgressStages';
import { VerifyStatusIcon } from 'components/ProgressIcons';
import { getPageMeta } from 'utils';
import Verify from 'images/Verify.svg';
import VerifySuccess from 'images/VerifySuccess.svg';
import VerifyError from 'images/VerifyError.svg';
import styled from 'styled-components/macro';
import { preVerifySignDocument } from 'actions/Sign';
import { VERIFICATION_STATUS, getVerificationModalConfig } from './utils';

const getParties = (matterState) => {
  console.log('LOG:  > getPartiesStatus > matterState', matterState);
  const parties = [...matterState.verificationProgress]
    .map((parti) => ({
      ...parti,
      ...matterState.matter?.borrowers?.find((borrower) => borrower.id === parti.userId),
      isCurrentUser: matterState.matterProgress.userId === parti.userId,
    }));
  console.log('LOG:  > getPartiesStatus > parties', parties);
  return parties;
};

const VerifyStatus = {
  COMPLETE: 'Complete',
  UNVERIFIED: 'Unverified',
};

export default function VerifyPage({ match }) {
  const docId = Number(match.params.id);
  const { state: applicationState } = useApplication();
  const { pauseLogout } = applicationState;
  const history = useHistory();
  const { user, authToken } = useAuth();
  const { state } = useMatter();

  const currentBorrower = state.matter.borrowers.find((b) => b.id === user.id);
  const currentDocument = state?.matterProgress?.signDocuments?.find((d) => d.id === docId);
  const isReverification = state?.matterProgress?.signDocuments?.some((d) => d.webhookCalledAndCertificateReady !== null) || !!currentBorrower.CrossPlatformVerificationTransactionId;
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [verificationStatus, setVerificationStatus] = useState(VERIFICATION_STATUS.INITIALISED);
  const [verificationSigning, setVerificationSigning] = useState(false);
  const [widgetUrlQueryString, setWidgetUrlQueryString] = useState('');
  const [verificationTransactionId, setVerificationTransactionId] = useState(state?.matter?.verificationTransactionId);
  const [gettingTransactionId, setGettingTransaction] = useState(false);

  const getTransaction = async () => {
    try {
      if (gettingTransactionId) return;
      setGettingTransaction(true);
      const result = await preVerifySignDocument(authToken, docId);
      if (!result.verificationTransactionId) throw new Error('null verificationTransactionId');
      setVerificationTransactionId(result.verificationTransactionId);
      setShowVerificationModal(true);
      setGettingTransaction(false);
    } catch {
      toast.error('Something went wrong, please reload the page and try again');
    }
  };

  useEffect(() => {
    if (!verificationTransactionId) return;
    async function InitializeLivesignModal() {
      const { cleanNumber, countryCodeId, countryCode } = user.mobile;
      try {
        window.liveSignAsyncInit = () => {
          pauseLogout(true);
          // eslint-disable-next-line no-undef
          liveSign.init({
            transactionId: verificationTransactionId,
            initialiseMobile: cleanNumber,
            initCountryCodeId: countryCodeId,
            initPhoneCode: countryCode,
            onCancel: () => {
              pauseLogout(false);
              setVerificationStatus(VERIFICATION_STATUS.CANCELED);
            },
            onError: () => {
              pauseLogout(false);
              setVerificationStatus(VERIFICATION_STATUS.ERRORED);
            },
            onSuccess: () => {
              pauseLogout(false);
              setVerificationSigning(true);
            },
          });
        };
      } catch (error) {
        toast.error('Something went wrong, please reload the page and try again');
      }
    }
    InitializeLivesignModal();
    setVerificationStatus(VERIFICATION_STATUS.INITIALISED);
  }, [pauseLogout, user.mobile, authToken, docId, verificationTransactionId]);

  useEffect(() => {
    if (currentDocument.webhookCalledAndCertificateReady === null || !verificationSigning) return;
    setVerificationSigning(false);
    setVerificationStatus(VERIFICATION_STATUS.SUCCEEDED);
  }, [currentDocument.webhookCalledAndCertificateReady, verificationSigning]);

  if (!state.matter || !state.matterProgress || !authToken || !docId) return <PageLoading />;

  const pageMeta = getPageMeta(state.matter, state.matterProgress, PROGRESS_STAGES.SIGN, docId);

  const getSigningParties = () => {
    const signingParties = [];
    const parties = getParties(state);

    parties.forEach((party) => {
      if (party.verificationCompleted.find((doc) => doc.id === docId && !doc.isReadOnly)) signingParties.push(party);
    });

    return signingParties;
  };
  const signingPartyStatus = getSigningParties();

  const handleTryAgain = () => {
    setWidgetUrlQueryString(Math.floor(Math.random() * 9999));
    setVerificationStatus(VERIFICATION_STATUS.INITIALISED);
  };
  const GetVerifyModalImage = () => {
    if (verificationStatus === VERIFICATION_STATUS.SUCCEEDED) { return VerifySuccess; }
    if (verificationStatus === VERIFICATION_STATUS.ERRORED) { return VerifyError; }
    return Verify;
  };

  let allSigningPartiesCompletedVerification = true;
  state?.verificationProgress.forEach((voiUserProgress) => {
    const { requireVerification, verificationCompleted } = voiUserProgress;
    if (!requireVerification) return;
    const { voiCompleted, isReadOnly } = verificationCompleted?.find((v) => v.id === docId);
    if (!voiCompleted && !isReadOnly) allSigningPartiesCompletedVerification = false;
  });

  const ButtonSection = () => {
    switch (verificationStatus) {
      case VERIFICATION_STATUS.CANCELED:
      case VERIFICATION_STATUS.ERRORED:
        return (
          <>
            <Button color="secondary" onClick={handleTryAgain}>Try again</Button>
            <Button ml={[0, 3]} mt={[3, 0]} color="base" onClick={() => setShowVerificationModal(false)}>Close</Button>
          </>
        );
      case VERIFICATION_STATUS.SUCCEEDED:
        return <Button color="secondary" onClick={() => setShowVerificationModal(false)}>Close</Button>;
      default:
        return null;
    }
  };

  const verificationModal = getVerificationModalConfig(verificationStatus, allSigningPartiesCompletedVerification);

  const widgetUrl = `${applicationState.appSettings.liveSignWidgetUrl}?uid=${widgetUrlQueryString}`;

  return (
    <Container>
      {showVerificationModal && (
        <Helmet>
          <script src={widgetUrl} async defer />
        </Helmet>
      )}
      <Container center>
        <Panel mb="0">
          <Panel.Subtitle>Complete &amp; Sign</Panel.Subtitle>
          <Panel.Title pb={[0]}>{pageMeta?.currentStep?.name}</Panel.Title>
          <Panel.Section>
            <H4 mb={[4, 5, 6]}>{!isReverification ? 'Verify your identity' : 'Reverify your identity'}</H4>
            <Label>Instructions to complete</Label>
            <OL mb={[5, 6, 7]}>
              {!isReverification
                ? 'In order to download and sign your mortgage you will need to verify your identity. '
                + 'You can do this by clicking the “Verify ID” button below. You will need a smartphone and two identity documents such as passport and driver\'s licence.'
                : 'In order to download and sign this document we need to quickly reverify your identity. '
                + 'You can do this by clicking the “Verify ID” button below. No identity documents are required - all we need is a selfie.'}
            </OL>
            <Label>Parties status</Label>
            <MessageBox mb={[5, 6, 7]} label="IMPORTANT:">All signing parties must complete their VOI before the Mortgage Document can be downloaded.</MessageBox>
            <UL>
              {signingPartyStatus.map((parti, idx) => (
                <Fragment key={parti.userId}>
                  <PartiStatusCard
                    {...parti}
                    onAction={() => getTransaction()}
                    docId={docId}
                    gettingTransactionId={gettingTransactionId}
                  />
                  {((idx + 1) < signingPartyStatus.length) && <HR my="4" />}
                </Fragment>
              ))}
            </UL>
          </Panel.Section>
        </Panel>

        <ActionBar
          pl={[null, null, '70px']}
          showSkip
          onNextClick={() => history.push(pageMeta.nextRoute)}
        />
      </Container>
      {showVerificationModal && (
        <Modal>
          <Modal.IllustrationBody image={GetVerifyModalImage()}>
            <Modal.Close onClose={() => { setShowVerificationModal(false); }} />
            <Modal.Body height="100%">
              <Modal.Subtitle>{verificationModal.subTitle}</Modal.Subtitle>
              <Modal.Title lineHeight="compact">{verificationModal.title}</Modal.Title>
              <Text mb={[6, null, 7]}>
                {verificationModal.content}
              </Text>
              {verificationStatus === VERIFICATION_STATUS.INITIALISED
                && (
                  <>
                    <Button id="livesign-verify" color="secondary" data-style="override">Start verification</Button>
                    <Flex flexDirection="column">
                      <H4 mt={[7, null, 8]} mb={4}>What is livesign?</H4>
                      <Text>livesign is our partner app that we use for verifying your identity. Once you have completed the process you will be directed back to KWIL.</Text>
                    </Flex>
                  </>
                )}
              <ResponsiveGroup fullWidthBreakpointIndex={0} mt={[5, 6]} mb={[5, 0]}>
                <ButtonSection />
              </ResponsiveGroup>
            </Modal.Body>
          </Modal.IllustrationBody>
        </Modal>
      )}
      {verificationSigning && (
        <Modal>
          <Modal.IllustrationBody image={Verify}>
            <Modal.Body>
              <Modal.Subtitle>Verifying your identity</Modal.Subtitle>
              <Modal.Title lineHeight="compact">Do not refresh or leave this page</Modal.Title>
              <Flex flexDirection="column">
                <Text>Please wait while we finish up.</Text>
              </Flex>
            </Modal.Body>
            <Flex alignItems="center" justifyContent="center" width="100%" height="100px">
              <Icon name="loading" color="base80" size="80px" mb="4" spin />
            </Flex>
          </Modal.IllustrationBody>
        </Modal>
      )}
    </Container>
  );
}

VerifyPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

const StyledFlex = styled(Flex)`
  flex-flow: row wrap;
`;
function PartiStatusCard({ docId, gettingTransactionId, ...props }) {
  const VOIComplete = props.verificationCompleted
    .find((v) => v.id === docId)
    .voiCompleted;

  const { support } = useAuth();

  const status = (!props.requireVerification || VOIComplete)
    ? {
      color: 'primaryDark',
      text: VerifyStatus.COMPLETE,
    }
    : {
      color: 'base40',
      text: VerifyStatus.UNVERIFIED,
    };
  return (
    <>
      <StyledFlex width="100%" justifyContent="space-between">
        <Flex alignItems="flex-start">
          <VerifyStatusIcon status={status.text} />
          <Flex flexDirection="column" ml="4">
            <Label fontSize="1" mb="0" uppercase>{`${props.firstName} ${props.lastName}`}</Label>
            <Label fontSize="0" uppercase color={status.color}>{status.text}</Label>
          </Flex>
        </Flex>
        {(status.text !== VerifyStatus.COMPLETE && props.isCurrentUser)
          && (
            <Button
              color="secondary"
              onClick={props.onAction}
              noWrap
              px={[5, '30px']}
              disabled={support.isTenantSupport}
            >
              {gettingTransactionId
                ? <Icon name="loading" color="base80" spin />
                : 'Verify ID'}
            </Button>
          )}
      </StyledFlex>
    </>
  );
}
PartiStatusCard.propTypes = {
  userId: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  requireVerification: PropTypes.bool.isRequired,
  verificationCompleted: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isCurrentUser: PropTypes.bool.isRequired,
  onAction: PropTypes.func.isRequired,
  docId: PropTypes.number.isRequired,
  gettingTransactionId: PropTypes.bool.isRequired,
};
