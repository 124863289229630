export default function applicationReducer(state, action) {
  switch (action.type) {
    case 'SET_LOADING':
      return {
        ...state,
        loading: action.payload,
      };
    case 'UPDATE_USER':
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case 'UPDATE_COLLABORATORS':
      return {
        ...state,
        collaborators: {
          ...state.collaborators,
          ...action.payload.collaborators,
        },
        collaboratingWithMe: {
          ...state.collaboratingWithMe,
          ...action.payload.collaboratingWithMe,
        },
      };
    case 'SET_COLLABORATORS':
      return {
        ...state,
        collaborators: {
          ...action.payload.collaborators,
        },
        collaboratingWithMe: {
          ...action.payload.collaboratingWithMe,
        },
      };
    default:
      return state;
  }
}
