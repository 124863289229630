import React from 'react';
import UploadProviderWrapper from 'screens/components/UploadProviderWrapper';
import UploadPage from './uploadPage';

const Upload = () => (
  <UploadProviderWrapper>
    <UploadPage />
  </UploadProviderWrapper>
);

export default Upload;
