import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  ActionBar, Button, Panel, PageLoading, H4, UL, LI, Row, Column, IconSection, ActionPanel, MessageBox, ErrorSummary, utils, Text, ResponsiveGroup, Flex, Label,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { useApplication } from 'state/ApplicationProvider';
import Container from 'components/Container';
import { useDynamicForm } from 'state/DynamicFormProvider';
import PROGRESS_STAGES from 'enums/ProgressStages';
import { setDocumentPrintAction } from 'actions/Matter';
import {
  getPageMeta, httpRequest, downloadFile,
} from 'utils';

const { useScrollToTop } = utils;

const JURISDICTION = {
  NSW: 'NSW',
  QLD: 'QLD',
  TAS: 'TAS',
  SA: 'SA',
  NT: 'NT',
  ACT: 'ACT',
  WA: 'WA',
  VIC: 'VIC',
};

const PrintPage = ({ match }) => {
  const docId = Number(match.params.id);
  const history = useHistory();
  const { authToken, support } = useAuth();
  const { state, dispatch: matterDispatch } = useMatter();
  const { dispatch } = useApplication();
  const {
    jurisdiction, printReturnAddress, downloadDetails, printGuideline,
  } = useDynamicForm();
  const [downloading, setDownloading] = useState(false);
  const { setScrollToTop } = useScrollToTop(true);
  let currentStep = { name: '' };

  useEffect(() => {
    setDownloading(false);
    setScrollToTop(true);
  }, [docId, setScrollToTop]);

  if (!state.matter || !state.matterProgress || !authToken || !docId || !printReturnAddress) return <PageLoading />;

  const doDownload = () => {
    setDocumentPrintAction(dispatch, matterDispatch, docId, authToken);
    setDownloading(true);
    httpRequest(`/api/signdocuments/${docId}/file`, 'get', null, { Authorization: `Bearer ${authToken}` })
      .then((blob) => {
        downloadFile(blob, `${currentStep.name}.pdf`);
      })
      .catch(() => {
        throw new Error(`Unable to download file for matter (${state.matter.matterId}) document (${docId}).`);
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  if (!state.matter || !state.matterProgress || !authToken || !docId) return <PageLoading />;

  const pageMeta = getPageMeta(state.matter, state.matterProgress, PROGRESS_STAGES.SIGN, docId);

  const {
    isCompletedByAnyone, isNotEditable, nextRoute,
  } = pageMeta;

  currentStep = pageMeta.currentStep;

  const errors = currentStep.errors ? <ErrorSummary errors={currentStep.errors} title="There were errors" mb={[5, 6]} /> : null;

  const { requireVerification, transactionIdAdded } = state?.matterProgress?.signDocuments?.find((sd) => sd.id === docId);

  let allSigningPartiesCompletedVerification = true;

  state?.verificationProgress.forEach((voiUserProgress) => {
    const { verificationCompleted } = voiUserProgress;
    if (!requireVerification) return;
    const { voiCompleted, isReadOnly } = verificationCompleted?.find((v) => v.id === docId);
    if (!voiCompleted && !isReadOnly) allSigningPartiesCompletedVerification = false;
  });

  const {
    addressLineOne, addressLineTwo, suburb, state: addressState, postcode,
  } = printReturnAddress;

  if (!printGuideline) return <PageLoading />;
  const {
    instructions, messageBox, howToPrint,
  } = printGuideline;

  return (
    <Container>
      <Container center>
        <Panel mb="0">
          <Panel.Subtitle>Complete &amp; Sign</Panel.Subtitle>
          <Panel.Title pb={[0]}>{currentStep.name}</Panel.Title>
          {errors}
          <Panel.Section>

            <>
              <H4 mb={[4, 5, 6]}>
                Guidelines for completing the
                {' '}
                {currentStep.name}
              </H4>
              <MessageBox mb={[5, 6, 7]} label="IMPORTANT:">Failure to follow the below guidelines may delay settlement</MessageBox>
              {instructions?.map((instruction) => {
                const { title, bulletPoints, subBullets } = instruction;
                return (
                  <>
                    <Label>{title}</Label>
                    <UL mb={[5, 6, 7]}>
                      {bulletPoints && bulletPoints?.map((bullet) => <LI>{bullet}</LI>)}
                      <UL>
                        {subBullets && subBullets?.map((subBullet) => <LI>{subBullet}</LI>)}
                      </UL>
                    </UL>
                  </>
                );
              })}
              <H4 mb={[4, 5, 6]}>You must print this document</H4>
              <MessageBox my={[5, 6, 7]} label="Please note:">
                {' '}
                {messageBox}
                {' '}
              </MessageBox>
              <UL mb={[5, 6, 7]}>
                {howToPrint.map((bullet) => <LI>{bullet}</LI>)}
              </UL>
            </>
            <ActionPanel p={[5, 6, 7, 7]} mb={[5, 6, 7]}>
              <Row collapseIndex="0">
                <Column mb="0">
                  <IconSection
                    size="7"
                    width="100%"
                    iconColor="base60"
                    titleColor="primary"
                    titleFont="body"
                    titleSize="1"
                    bg="transparent"
                    iconName="Printer"
                    title="Download, print and Sign"
                  >
                    <ResponsiveGroup fullWidthBreakpointIndex={0} mt={[5, 6]} mb={[5, 0]}>
                      <Flex flexDirection="column" alignItems="center">
                        <Button
                          ml={[0, 3]}
                          mt={[3, 0]}
                          width="150px"
                          color="secondary"
                          onClick={doDownload}
                          disabled={(requireVerification && !allSigningPartiesCompletedVerification && transactionIdAdded) || support.isTenantSupport}
                        >
                          {downloading ? 'Downloading' : 'Download'}
                        </Button>
                        {downloadDetails && <Text mt="4">{`${downloadDetails.extension.toUpperCase()} - ${Math.ceil(downloadDetails.fileSize / 1024)}kb`}</Text>}
                      </Flex>
                    </ResponsiveGroup>
                  </IconSection>
                </Column>
                <Column mb="0" ml={[0, 6]}>
                  <IconSection
                    size="7"
                    width="100%"
                    iconColor="base60"
                    titleColor="primary"
                    titleFont="body"
                    titleSize="1"
                    iconName="Letter"
                    title="Return to the following address"
                  >
                    <UL fontSize="1" nobullets>
                      <LI>{addressLineOne}</LI>
                      <LI>{addressLineTwo}</LI>
                      <LI>{suburb}</LI>
                      <LI>
                        {addressState}
                        {' '}
                        {postcode}
                      </LI>
                    </UL>
                  </IconSection>
                </Column>
              </Row>
            </ActionPanel>

          </Panel.Section>
        </Panel>

        <ActionBar
          pl={[null, null, '70px']}
          showNext={isCompletedByAnyone || isNotEditable}
          onNextClick={() => history.push(nextRoute)}
        >
          { jurisdiction === JURISDICTION.QLD && <Text color="base60" fontSize="1">This document must be printed and signed on paper in the state of QLD.</Text> }
        </ActionBar>
      </Container>
    </Container>
  );
};

PrintPage.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default PrintPage;
