import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Flex, H4, H6, Box, Popover, Text, mediaQueries,
} from '@galilee/lilee';
import ProgressLink from 'components/ProgressLink';
import GroupSummaryLink from 'components/GroupSummaryLink';
import PROGRESS from 'enums/Progress';

const { lessThan } = mediaQueries;

const ProgressContainer = styled(Flex)`
  flex-direction: column;
  flex: 1;
  user-select: none;
  ${lessThan[1]`
    border-bottom: 1px solid ${(p) => (p.noBorder ? 'transparent' : p.theme.colors.base10)};
    width: 90%;
    margin: auto;
  `}
`;

const Title = styled(H6)`
  letter-spacing: ${(p) => p.theme.letterSpacings.small};
  text-transform: uppercase;
  margin: 16px 0 0 0;
  text-align: center;
  color: ${(p) => p.theme.colors.base40};
`;

Title.defaultProps = {
  fontWeight: 'medium',
};

const ProgressIconContainer = styled(Box)`
  border-left: 1px solid ${(p) => (p.first || p.noBorder ? 'transparent' : p.theme.colors.base10)};
  display: flex;
  flex-direction: column;
  flex: 1;
  ${lessThan[1]`
    border-left: none;
  `}
`;

const getProgressMessage = (p) => {
  if (p.errors && p.errors.length) {
    return "Uh Oh! There's a problem with this document. Please correct any issues and then re-submit.";
  }
  if ((p.myProgress === PROGRESS.COMPLETED || p.myProgress === PROGRESS.SUBMITTED) && (p.theirProgress === PROGRESS.COMPLETED || p.theirProgress === PROGRESS.SUBMITTED)) {
    return 'Everyone has completed this document';
  }
  if (p.myProgress === PROGRESS.WARN) {
    return 'Someone has edited this document that you already signed. Please re-sign to complete your application.';
  }
  if (p.myProgress !== PROGRESS.COMPLETED && p.myProgress !== PROGRESS.SUBMITTED) {
    return p.optional ? 'This document is optional' : 'You need to complete this document';
  }
  return "You're all done, we're just waiting for someone else to complete this document";
};

const ProgressGroup = ({
  title, progress, groupName, first, noBorder, matterId,
}) => {
  const routePath = `/matter/${matterId}/${groupName}`;

  return (
    <ProgressContainer noBorder={noBorder}>
      <Title>{title}</Title>
      <ProgressIconContainer first={first} noBorder={noBorder}>
        <Flex justifyContent="center">
          <GroupSummaryLink to={routePath} />
          {progress && progress.map((p) => (
            <Popover
              key={p.id}
              position="bottom"
              bg="white"
              offsetPosition={{ left: '-105px' }}
              contentMargin="0"
            >
              <ProgressLink m="3" to={`${routePath}/${p.id}`} progress={p} key={p.id} />
              <Popover.Content bg="white" width="250px" p="6" boxShadow="0px 0px 40px rgba(7,36,56,0.15)">
                <H4>{p.name}</H4>
                <Text fontSize="1">{getProgressMessage(p)}</Text>
              </Popover.Content>
            </Popover>
          ))}
        </Flex>
      </ProgressIconContainer>
    </ProgressContainer>
  );
};

ProgressGroup.defaultProps = {
  noBorder: false,
  progress: null,
  title: '',
  first: false,
};

ProgressGroup.propTypes = {
  noBorder: PropTypes.bool,
  progress: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    myProgress: PropTypes.string,
    theirProgress: PropTypes.string,
  })),
  title: PropTypes.string,
  first: PropTypes.bool,
  groupName: PropTypes.string.isRequired,
  matterId: PropTypes.number.isRequired,
};

export default ProgressGroup;
