import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Flex, Image, H3 } from '@galilee/lilee';
import Container from 'components/Container';
import Logo from 'images/logo.png';

const BodyContainer = styled(Flex)`
  z-index: 2;
`;

const Body = ({ children, ...props }) => (
  <BodyContainer {...props}>
    <Container center maxWidth="768px">
      <Flex flexDirection="column" alignItems="center">
        <Image maxWidth="85px" src={Logo} alt="logo" />
        <H3 mt="5" color="white">Orion</H3>
        {children}
      </Flex>
    </Container>
  </BodyContainer>
);

Body.defaultProps = {
  children: null,
};

Body.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Body;
