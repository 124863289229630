import styled from 'styled-components/macro';
import { Flex } from '@galilee/lilee';

const Indent = styled(Flex)``;

Indent.defaultProps = {
  mx: [1, 5, 6, 7],
  mb: [6, 7],
};

export default Indent;
