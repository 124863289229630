import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { TR, TD } from '@galilee/lilee';

const Cell = styled(TD)`
  text-align: center !important;
`;

Cell.defaultProps = {
  p: 8,
};

const Loader = ({ colspan }) => <TR><Cell colSpan={colspan}>Loading ...</Cell></TR>;

Loader.propTypes = {
  colspan: PropTypes.number.isRequired,
};

export default Loader;
