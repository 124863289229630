import React from 'react';
import { Route } from 'react-router';
import ReactRouterPropTypes from 'react-router-prop-types';
import TopNav from 'components/TopNav';
import Upload from './upload';
import UploadSummary from './uploadSummary';

const UploadRouter = ({ match }) => (
  <>
    <TopNav />
    <Route path={`${match.path}/:id`} component={Upload} />
    <Route exact path={match.path} component={UploadSummary} />
  </>
);

UploadRouter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default UploadRouter;
