import PROGRESS from '../enums/Progress';
import { httpRequest } from '../utils';
import { errorDispatcher } from './actionUtils';

export const fetchUploadData = async (applicationDispatch, docId, token) => httpRequest(`/api/uploaddocuments/${docId}`, 'get', null, { Authorization: `Bearer ${token}` })
  .catch(() => errorDispatcher(applicationDispatch, "We couldn't retrieve these upload document details from the server, please reload the page to try again."));

export const deleteAttachment = async (dispatch, applicationDispatch, matterDispatch = null, uploadDocumentId, documentId, meta, token) => {
  const response = await fetch(`/api/uploadDocuments/${uploadDocumentId}/document/${documentId}`, {
    method: 'delete',
    credentials: 'omit',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
  });

  const documentMeta = Object.keys(meta).filter((key) => meta[key].id !== documentId);

  dispatch({
    type: 'SET_UPLOAD_DOCUMENTS',
    payload: {
      documentsUploaded: { ...documentMeta }, uploadDocumentId,
    },
  });

  const res = await response.json();

  if (!res.succeeded) {
    errorDispatcher(applicationDispatch, "We couldn't delete this attachment right now, please reload the page to try again.");
    return;
  }

  if (!res.result.isEmpty) {
    dispatch({
      type: 'SET_UPLOAD_DOCUMENTS',
      payload: {
        documentsUploaded: { ...res.result.documentStatus.documentsUploaded },
        uploadDocumentId,
      },
    });
    return;
  }

  dispatch({ type: 'SET_EMPTY_ATTACHMENT', payload: { uploadDocumentMeta: null } });

  if (matterDispatch) {
    matterDispatch({
      type: 'SET_OPTIMISTIC_PROGRESS',
      payload: {
        theirProgress: PROGRESS.NOTSTARTED,
        documentId: uploadDocumentId,
        stage: 'upload',
        progress: {
          myProgress: PROGRESS.NOTSTARTED,
        },
      },
    });
  }
};

export const setUploadRejectionReason = async (dispatch, applicationDispatch, matterDispatch = null, reasonId, uploadDocumentId, token) => {
  if (matterDispatch) {
    matterDispatch({
      type: 'SET_OPTIMISTIC_PROGRESS',
      payload: {
        theirProgress: PROGRESS.COMPLETED,
        documentId: uploadDocumentId,
        stage: 'upload',
        progress: {
          myProgress: PROGRESS.COMPLETED,
        },
      },
    });
  }
  dispatch({ type: 'SET_REJECTION_REASON', payload: reasonId });
  await httpRequest(`/api/uploadDocuments/${uploadDocumentId}/rejectUpload`, 'post', { RejectionReasonId: reasonId }, { Authorization: `Bearer ${token}` })
    .catch(() => errorDispatcher(applicationDispatch, "We couldn't save your upload reason at the moment, please reload the page to try again."));
};

export const setUploadMultipleDocuments = async (dispatch, applicationDispatch, matterDispatch = null, uploadDocumentId, token, newDocuments, uploadedDocuments) => {
  if (matterDispatch) {
    matterDispatch({
      type: 'SET_OPTIMISTIC_PROGRESS',
      payload: {
        theirProgress: PROGRESS.COMPLETED,
        documentId: uploadDocumentId,
        stage: 'upload',
        progress: {
          myProgress: PROGRESS.COMPLETED,
        },
      },
    });
  }

  const abbreviateFileName = (fileName) => {
    if (fileName.length < 100) return fileName;
    const fileType = fileName.substring(fileName.lastIndexOf('.'), fileName.length);
    return fileName.substring(0, 100 - fileType.length) + fileType;
  };

  const documentMeta = [];
  newDocuments.forEach((file) => documentMeta.push(
    {
      documentFileType: file.type,
      documentName: abbreviateFileName(file.name),
      documentSize: file.size,
      uploading: true,
    },
  ));
  Object.keys(uploadedDocuments).forEach((id) => documentMeta.push(uploadedDocuments[id]));

  dispatch({
    type: 'SET_UPLOAD_DOCUMENTS',
    payload: {
      documentsUploaded: { ...documentMeta }, uploadDocumentId,
    },
  });

  const formData = new FormData();
  newDocuments.forEach((file) => {
    const renamedFile = new File([file], abbreviateFileName(file.name));
    formData.append('file', renamedFile);
  });

  const response = await fetch(`/api/uploadDocuments/${uploadDocumentId}/multiFiles`, {
    body: formData,
    method: 'POST',
    credentials: 'omit',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  const res = await response.json();

  if (res.succeeded) {
    dispatch({ type: 'SET_UPLOAD_DOCUMENTS', payload: { ...res.result.documentStatus, uploadDocumentId } });
  } else {
    errorDispatcher(applicationDispatch, 'We were unable to upload your document at the moment');
  }
};
