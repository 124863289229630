import React, {
  useEffect, createContext, useContext, useReducer, useState,
} from 'react';
import { base64EncodeUnicode } from 'utils';
import { toast } from '@galilee/lilee';
import { fetchAppSettings } from 'actions/AppSettings';

const applicationContext = createContext();

const initialState = {
  appSettings: {},
  disconnectionErrors: {},
  socketConnectionErrors: {},
  logoutPaused: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOGOUT_PAUSED': {
      return { ...state, logoutPaused: action.payload };
    }
    case 'SET_APP_SETTINGS': {
      return { ...state, appSettings: action.payload };
    }
    case 'SET_APPLICATION_ERROR': return toast.error(action.payload);
    case 'SET_WEBSOCKET_DISCONNECTION_ERROR': {
      const id = base64EncodeUnicode(action.payload);
      return { ...state, disconnectionErrors: { ...state.disconnectionErrors, [id]: action.payload } };
    }
    case 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR': {
      const payloadId = base64EncodeUnicode(action.payload);
      const disconnectionErrors = {};
      Object.keys(state.disconnectionErrors).forEach((id) => { if (id !== payloadId) disconnectionErrors[id] = state.disconnectionErrors[id]; });
      return { ...state, disconnectionErrors };
    }
    case 'SET_WEBSOCKET_ERROR': {
      const id = base64EncodeUnicode(action.payload);
      return { ...state, socketConnectionErrors: { ...state.socketConnectionErrors, [id]: action.payload } };
    }
    case 'REMOVE_WEBSOCKET_ERROR': {
      const payloadId = base64EncodeUnicode(action.payload);
      const socketConnectionErrors = {};
      Object.keys(state.socketConnectionErrors).forEach((id) => { if (id !== payloadId) socketConnectionErrors[id] = state.socketConnectionErrors[id]; });
      return { ...state, socketConnectionErrors };
    }
    default:
      return state;
  }
};

const useApplicationHook = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = {
    ...state,
    pauseLogout: (isPaused) => {
      dispatch({ type: 'SET_LOGOUT_PAUSED', payload: isPaused });
    },
  };
  return { state: value, dispatch };
};

export const useApplication = () => useContext(applicationContext);

const ApplicationProvider = (props) => {
  const value = useApplicationHook();
  const [appSettingsInitialised, setAppSettingsInitialised] = useState(false);

  useEffect(() => {
    async function initialiseState() {
      setAppSettingsInitialised(true);
      const appSettings = await fetchAppSettings();
      value.dispatch({ type: 'SET_APP_SETTINGS', payload: appSettings });
    }
    if (appSettingsInitialised === false) initialiseState();
  }, [appSettingsInitialised, value]);

  return <applicationContext.Provider value={value} {...props} />;
};

export default ApplicationProvider;
