import React from 'react';
import PropTypes from 'prop-types';
import { useDynamicForm, accessTypes } from 'state/DynamicFormProvider';
import Concurrency from 'images/Concurrency.png';
import {
  Modal, P, ResponsiveGroup, Button, Row, Column, utils,
} from '@galilee/lilee';
import DynamicField from './DynamicField';

const { isNumeric } = utils;

const byField = (field, a, b) => (a[field] < b[field] ? -1 : 1);
const by = (a, b) => (a < b ? -1 : 1);

const groupFields = (fields, hiddenFields, isCompletedByMe, isSignedByAnyone, isNotEditable) => {
  const rows = {};
  if (!fields) return [];
  Object.keys(fields).forEach((f) => {
    const field = fields[f];
    if (field.accessType === accessTypes.theirs || hiddenFields[f] === true) return;
    const { rowNumber, columnNumber } = field;
    const isReadOnly = isNotEditable
      || (field.accessType === accessTypes.mine && isCompletedByMe && isSignedByAnyone)
      || (field.accessType === accessTypes.shared && (isCompletedByMe || isSignedByAnyone));
    const newField = {
      fieldName: f,
      componentType: field.componentType,
      readOnly: isReadOnly,
      row: rowNumber,
      column: columnNumber,
      fullWidthBreakpointIndex: field.fullWidthBreakpointIndex,
    };
    let currentRow = rows[rowNumber];
    if (currentRow) {
      currentRow = [...currentRow, newField];
      rows[rowNumber] = currentRow;
    } else {
      rows[rowNumber] = [newField];
    }
  });
  const sortedRows = Object.keys(rows).sort((a, b) => by(a, b));
  return sortedRows.map((f) => rows[f]);
};

const Form = ({
  onSubmit, isCompletedByMe, isSignedByAnyone, isNotEditable,
}) => {
  const {
    fields, hiddenFields, getFormProps, concurrencyFieldErrors, acknowledgeConcurrencyError,
  } = useDynamicForm();

  const fieldRows = groupFields(fields, hiddenFields, isCompletedByMe, isSignedByAnyone, isNotEditable);
  return (
    <>
      <form {...getFormProps({ onSubmit })}>
        {
            fieldRows.map((row) => {
              const flexDirection = ['row', 'row', 'row', 'row', 'row'];
              const { fullWidthBreakpointIndex } = row[0];
              if (fullWidthBreakpointIndex !== 'undefined' && Array.isArray(fullWidthBreakpointIndex)) {
                fullWidthBreakpointIndex.forEach((index) => {
                  if (isNumeric(index) && index >= 0 && index <= 4) { flexDirection[index] = 'column'; }
                });
              }
              return (
                <Row key={row[0].row} flexDirection={flexDirection}>
                  {
                  row.sort((a, b) => byField('column', a, b)).map((column) => (
                    <Column mr={[0, 5, 6, 7]} key={column.fieldName}>
                      <DynamicField fieldName={column.fieldName} componentType={column.componentType} readOnly={column.readOnly} />
                    </Column>
                  ))
                }
                </Row>
              );
            })
         }
      </form>
      {
          concurrencyFieldErrors.map((c) => (
            <Modal key={`concurrency-${c.fieldTitle}`}>
              <Modal.IllustrationBody image={Concurrency}>
                <Modal.Subtitle>Document Updated</Modal.Subtitle>
                <Modal.Title>{`${c.editorFirstName} has just edited '${c.fieldTitle}'`}</Modal.Title>
                <P>
                  click confirm to see the change
                </P>
                <Modal.ActionContainer>
                  <ResponsiveGroup>
                    <Button color="secondary" onClick={() => acknowledgeConcurrencyError(c.fieldName)}>Confirm</Button>
                  </ResponsiveGroup>
                </Modal.ActionContainer>
              </Modal.IllustrationBody>
            </Modal>
          ))
        }
    </>
  );
};

Form.defaultProps = {
  onSubmit: () => { },
};

Form.propTypes = {
  onSubmit: PropTypes.func,
  isCompletedByMe: PropTypes.bool.isRequired,
  isSignedByAnyone: PropTypes.bool.isRequired,
  isNotEditable: PropTypes.bool.isRequired,
};

export default Form;
