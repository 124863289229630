import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Button, Modal, P, Label, Row, Column, ErrorSummary,
} from '@galilee/lilee';
import { setDocumentSignWitnessAction } from 'actions/Matter';
import { useApplication } from 'state/ApplicationProvider';
import { useAuth } from 'state/AuthProvider';
import SignatureField from 'components/SignatureCanvas';
import TextBoxField from 'components/TextBoxField';
import CheckBoxField from 'components/CheckBoxField';
import { validateField, validateForm } from 'utils';

const FormRow = styled(Row)`
  max-width: 400px;
`;

const WitnessModal = ({ closeAction, docId }) => {
  const { user, authToken } = useAuth();
  const { dispatch } = useApplication();
  const [signatureUrl, setSignatureUrl] = useState(false);
  const [witnessFor, setWitnessFor] = useState(false);
  const [fullName, setFullName] = useState('');
  const [address, setAddress] = useState('');
  const [suburb, setSuburb] = useState('');
  const [postalState, setPostalState] = useState('');
  const [postcode, setPostcode] = useState('');
  const [country, setCountry] = useState('');
  const [acceptRequirements, setAcceptRequirements] = useState(false);
  const [errors, setErrors] = useState({});
  const errorList = Object.values(errors).filter((error) => error !== null);
  const [triggerSignatureDownload, setTriggerSignatureDownload] = useState(false);

  const fields = {
    witnessFor: {
      name: 'witnessFor',
      displayName: `${user.firstName} ${user.lastName}`,
      getValue: () => witnessFor,
      setValue: () => setWitnessFor(!witnessFor),
      rules: {
        isEqual: { value: true, message: 'Please acknowledge who you are witnessing for' },
      },
    },
    fullName: {
      name: 'fullName',
      displayName: 'Full Name',
      getValue: () => fullName,
      setValue: (e) => setFullName(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your full name' },
      },
    },
    address: {
      name: 'address',
      displayName: 'Address',
      getValue: () => address,
      setValue: (e) => setAddress(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your address' },
      },
    },
    suburb: {
      name: 'suburb',
      displayName: 'suburb',
      getValue: () => suburb,
      setValue: (e) => setSuburb(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your suburb' },
      },
    },
    postalState: {
      name: 'postalState',
      displayName: 'State',
      getValue: () => postalState,
      setValue: (e) => setPostalState(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your state' },
      },
    },
    postcode: {
      name: 'postcode',
      displayName: 'postcode',
      getValue: () => postcode,
      setValue: (e) => setPostcode(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your postcode' },
        isExactLength: { message: 'Postcode must be 4 characters long', length: 4 },
        isNumber: { message: 'Postcode must be a number' },
      },
    },
    country: {
      name: 'country',
      displayName: 'country',
      getValue: () => country,
      setValue: (e) => setCountry(e.target.value),
      rules: {
        isRequired: { message: 'Please provide your country' },
      },
    },
    acceptRequirements: {
      name: 'acceptRequirements',
      displayName: 'I have read and understood the witness requirements',
      getValue: () => acceptRequirements,
      setValue: () => setAcceptRequirements(!acceptRequirements),
      rules: {
        isEqual: { value: true, message: 'Please acknowledge that you have understood the witness requirements' },
      },
    },
  };

  useEffect(() => {
    if (signatureUrl && triggerSignatureDownload) {
      setTriggerSignatureDownload(false);
      const witness = {
        WitnessBase64Image: signatureUrl,
        WitnessFullname: fullName,
        WitnessAddress: address,
        WitnessSuburb: suburb,
        WitnessState: postalState,
        WitnessPostcode: postcode,
        WitnessCountry: country,
        WitnessUnderstoodCheckbox: acceptRequirements,
      };
      setDocumentSignWitnessAction(dispatch, docId, witness, authToken);
      closeAction();
    }
  }, [triggerSignatureDownload, dispatch, authToken, signatureUrl, fullName, address, suburb, postalState, postcode, country, acceptRequirements, docId, closeAction]);

  const submitWitnessDocument = () => {
    const validationErrors = validateForm(fields);
    const validationIsValid = Object.values(validationErrors).every((error) => error === null);
    setErrors(validationErrors);
    if (validationIsValid && signatureUrl) {
      setTriggerSignatureDownload(true);
    }
  };

  const validateElement = (fieldName) => {
    const error = validateField(fieldName, fields);
    setErrors({ ...errors, [fieldName]: error });
  };

  return (
    <Modal>
      <Modal.Close onClose={closeAction} />
      <Modal.Body>
        <Modal.Subtitle>Witnessing</Modal.Subtitle>
        <Modal.Title lineHeight="compact">Witness Signing</Modal.Title>
        <P mb={[5, 6]}>It is important someone witnesses you sign this document. Please get this person to sign and add their details here.</P>

        <ErrorSummary errors={errorList} title="There were errors" mb={[5, 6]} />

        <Label>I acknowledge that I am the witness for:</Label>

        <FormRow>
          <CheckBoxField
            fieldConfig={fields.witnessFor}
            fieldError={errors[fields.witnessFor.name]}
          />
        </FormRow>

        <FormRow>
          <TextBoxField
            fieldConfig={fields.fullName}
            validate={() => validateElement(fields.fullName.name)}
            fieldError={errors[fields.fullName.name]}
          />
        </FormRow>

        <FormRow>
          <TextBoxField
            fieldConfig={fields.address}
            validate={() => validateElement(fields.address.name)}
            fieldError={errors[fields.address.name]}
          />
        </FormRow>

        <FormRow>
          <TextBoxField
            fieldConfig={fields.suburb}
            validate={() => validateElement(fields.suburb.name)}
            fieldError={errors[fields.suburb.name]}
          />
        </FormRow>

        <FormRow>
          <TextBoxField
            fieldConfig={fields.postalState}
            validate={() => validateElement(fields.postalState.name)}
            fieldError={errors[fields.postalState.name]}
          />
          <TextBoxField
            fieldConfig={fields.postcode}
            validate={() => validateElement(fields.postcode.name)}
            fieldError={errors[fields.postcode.name]}
          />
        </FormRow>

        <FormRow>
          <TextBoxField
            fieldConfig={fields.country}
            validate={() => validateElement(fields.country.name)}
            fieldError={errors[fields.country.name]}
          />
        </FormRow>

        <FormRow>
          <CheckBoxField
            fieldConfig={fields.acceptRequirements}
            fieldError={errors[fields.acceptRequirements.name]}
          />
        </FormRow>

        <FormRow>
          <Column>
            <SignatureField
              setSignatureUrl={(sigUrl) => setSignatureUrl(sigUrl)}
              triggerDownload={triggerSignatureDownload}
              resetTriggerDownload={() => setTriggerSignatureDownload(false)}
            />
          </Column>
        </FormRow>

        <Modal.ActionContainer width="100%" justifyContent="space-between">

          <Button color="base" onClick={closeAction}>Cancel</Button>
          <Button color="secondary" onClick={() => submitWitnessDocument()}>Sign</Button>

        </Modal.ActionContainer>
      </Modal.Body>
    </Modal>
  );
};

WitnessModal.propTypes = {
  docId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  closeAction: PropTypes.func.isRequired,
};

export default WitnessModal;

// Witnessing Props
