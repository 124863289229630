import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import IdleTimer from 'react-idle-timer';
import LogoutTimer from 'components/LogoutTimer';

const IdleLogout = ({ children, isAuthenticated, isPaused }) => {
  const [userIsIdle, setUserIsIdle] = useState(false);
  const idleTimer = useRef(null);

  const resetTimer = () => {
    idleTimer.current.reset();
    setUserIsIdle(false);
  };

  return (
    <>
      { isAuthenticated && !isPaused
        && (
        <IdleTimer
          stopOnIdle
          ref={idleTimer}
          element={document}
          onIdle={() => setUserIsIdle(true)}
          debounce={250}
          timeout={1000 * 60 * 10}
        />
        )}
      { isAuthenticated && userIsIdle && !isPaused && <LogoutTimer onReset={() => resetTimer()} /> }
      {children}
    </>
  );
};

IdleLogout.defaultProps = {
  isAuthenticated: false,
  isPaused: false,
};

IdleLogout.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  isAuthenticated: PropTypes.bool,
  isPaused: PropTypes.bool,
};

export default IdleLogout;
