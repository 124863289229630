import React, {
  createContext, useContext, useEffect, useReducer,
} from 'react';
import PropTypes from 'prop-types';
import PROGRESS from 'enums/Progress';
import { HubConnectionBuilder } from '@microsoft/signalr';

const matterContext = createContext('');

const initialState = {
  matter: null,
  matterProgress: null,
  theirProgresses: [],
  verificationProgress: [],
  dateToAutoSubmit: null,
};

const allCompleted = (progress) => progress.every((p) => (p.myProgress === PROGRESS.COMPLETED));

const isCompleted = (matterProgress) => {
  const readCompleted = allCompleted(matterProgress.readDocuments);
  const signCompleted = allCompleted(matterProgress.signDocuments);
  const uploadCompleted = allCompleted(matterProgress.uploadDocuments);
  return readCompleted && signCompleted && uploadCompleted;
};

const calcAllStagesJustCompleted = (oldState, newState) => {
  if (!oldState || !oldState.matterProgress) return false;
  const oldStateCompleted = isCompleted(oldState.matterProgress);
  const newStateIsCompleted = isCompleted(newState);
  return oldStateCompleted === false && newStateIsCompleted === true;
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'RESET_ALLSTAGESJUSTCOMPLETED':
    {
      if (!state.matterProgress) return state;
      return {
        ...state,
        matterProgress: { ...state.matterProgress, allStagesJustCompleted: false },
      };
    }
    case 'SET_BORROWER_SIGNATURE':
    {
      const borrowers = state.matter.borrowers.map((b) => ((b.id === action.payload.userId) ? { ...b, hasSignature: action.payload.hasSignature } : b));
      return {
        ...state,
        matter: { ...state.matter, borrowers },
      };
    }
    case 'SET_MATTER':
      return {
        ...state,
        matter: action.payload,
      };
    case 'SET_OPTIMISTIC_PROGRESS':
    {
      const {
        documentId, stage, progress, theirProgress,
      } = action.payload;
      const documentProgress = ((state.matter.borrowers.length > 1) && theirProgress) ? { ...progress, theirProgress } : progress;
      const stageName = `${stage}Documents`;
      const progressStage = state.matterProgress[stageName].map((s) => (s.id !== documentId ? s : { ...s, ...documentProgress }));
      const matterProgress = { ...state.matterProgress, [stageName]: progressStage };
      const allStagesJustCompleted = matterProgress.allStagesJustCompleted === true || calcAllStagesJustCompleted(state, matterProgress);
      return {
        ...state,
        matterProgress: { ...matterProgress, allStagesJustCompleted },
      };
    }
    case 'SET_MATTER_PROGRESS':
    {
      const { dateToAutoSubmit } = action.payload;
      const currentCompletedState = state.matterProgress && state.matterProgress.allStagesJustCompleted === true;
      const allStagesJustCompleted = currentCompletedState || calcAllStagesJustCompleted(state, action.payload.matterProgress);

      const progress = [...(action.payload.theirProgresses || []), action.payload.matterProgress].filter(Boolean);
      const verificationProgress = progress.map((i) => (
        {
          userId: i.userId,
          requireVerification: i.signDocuments.some((d) => d.requiresVerification),
          verificationCompleted: i.signDocuments
            .filter((s) => s.requiresVerification === true)
            .map((s) => ({
              id: s.id,
              voiCompleted: s.webhookCalledAndCertificateReady != null,
              isReadOnly: s.isReadOnly,
            })),
        }));

      return {
        ...state,
        matterProgress: { ...action.payload.matterProgress, allStagesJustCompleted },
        theirProgresses: [...action.payload.theirProgresses],
        verificationProgress,
        dateToAutoSubmit,
      };
    }
    case 'SET_CONSENT':
    {
      const borrowers = state.matter.borrowers.map((b) => ((b.id === action.payload.userId) ? { ...b, consented: action.payload.consented } : b));
      return {
        ...state,
        matter: { ...state.matter, borrowers },
      };
    }
    case 'SET_WELCOME_SCREEN':
    {
      const borrowers = state.matter.borrowers.map((b) => ((b.id === action.payload.userId) ? { ...b, shownWelcomeScreen: action.payload.shownWelcomeScreen } : b));
      return {
        ...state,
        matter: { ...state.matter, borrowers },
      };
    }
    case 'ACKNOWLEDGE_NOTES':
    {
      const borrowers = state.matter.borrowers.map((b) => ((b.id === action.payload.userId) ? { ...b, matterNotesAcknowledged: action.payload.matterNotesAcknowledged } : b));
      return {
        ...state,
        matter: { ...state.matter, borrowers },
      };
    }
    default:
      return state;
  }
};

const createHubConnection = (matterId, authToken) => new HubConnectionBuilder()
  .withUrl(`/matterhub?matterId=${matterId}`, { accessTokenFactory: () => authToken })
  .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 120000, 180000])
  .build();

const useMatterHook = (matterId, userId, authToken, applicationDispatch) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const connection = createHubConnection(matterId, authToken);

    connection.onreconnecting(() => applicationDispatch({ type: 'SET_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter' }));
    connection.onreconnected(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter' }));

    connection
      .on('MatterProgress', (updatedMatterProgress) => {
        const { matterProgressPerUser, dateToAutoSubmit } = updatedMatterProgress;
        const currentUserProgress = matterProgressPerUser.find((mp) => mp.userId === userId);
        const theirProgresses = matterProgressPerUser.filter((mp) => mp.userId !== userId);
        dispatch({
          type: 'SET_MATTER_PROGRESS',
          payload: {
            matterProgress: currentUserProgress,
            theirProgresses,
            dateToAutoSubmit,
          },
        });
      });

    connection.start()
      .then(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_ERROR', payload: 'Matter' }))
      .catch(() => applicationDispatch({ type: 'SET_WEBSOCKET_ERROR', payload: 'Matter' }));

    return () => {
      connection.stop();
    };
  }, [dispatch, applicationDispatch, authToken, matterId, userId]);

  return { state, dispatch };
};

export const useMatter = () => useContext(matterContext);

const MatterProvider = ({
  matterId, userId, authToken, applicationDispatch, ...rest
}) => {
  const value = useMatterHook(matterId, userId, authToken, applicationDispatch);
  return <matterContext.Provider value={value} {...rest} />;
};

MatterProvider.propTypes = {
  matterId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  userId: PropTypes.string.isRequired,
  authToken: PropTypes.string.isRequired,
  applicationDispatch: PropTypes.func.isRequired,
};

export default MatterProvider;
