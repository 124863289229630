import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Flex, Icon, Show, Hide, Box, mediaQueries,
} from '@galilee/lilee';

const { lessThan } = mediaQueries;

export const sortPropTypes = PropTypes.shape({
  sortId: PropTypes.string,
  ascending: PropTypes.bool,
  filters: PropTypes.shape({

  }),
});

export const headerPropTypes = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  sortable: PropTypes.bool,
  width: PropTypes.number,
  mobileWidth: PropTypes.number,
  desktopTitle: PropTypes.string,
});

const HeaderContainer = styled(Flex)`
  justify-content: flex-start;
  align-items: center;
  cursor: ${(p) => (p.header.sortable ? 'pointer' : 'default')};
  flex: ${(p) => p.header.width};
  ${lessThan[1]`
    flex: ${(p) => p.header.mobileWidth};
  `}
`;

const FilterText = styled(Box)`
  border-top: 3px solid transparent;
  border-bottom-width: 3px;
  border-bottom-style: solid;
  border-bottom-color: ${(p) => (p.selected ? p.theme.colors.tertiary : 'transparent')};
  padding-bottom: ${(p) => p.theme.space[2]};
  padding-top: ${(p) => p.theme.space[2]};
  ${lessThan[1]`
    padding-bottom: ${(p) => p.theme.space[4]};
    padding-top: ${(p) => p.theme.space[4]};
  `}
`;

const HeaderIcon = styled(Icon)`
  width: 12px;
  height: 12px;
  margin-left: 8px;
  position: relative;
  top: 1px;
  color: ${(p) => (p.selected ? p.theme.colors.secondary : p.theme.colors.base20)};
  ${lessThan[1]`
    color: ${(p) => p.theme.colors.base20};
  `}
`;

const Header = ({ header, sort, onSort }) => {
  const isSelected = sort.sortId === header.id;

  const headerClick = async () => {
    if (header.sortable) {
      const newSort = { sortId: header.id, ascending: isSelected ? !sort.ascending : false, filters: sort.filters };
      onSort(newSort);
    }
  };

  return (
    <HeaderContainer
      header={header}
      selected={isSelected}
      onClick={headerClick}
    >
      <Show breakpoints={[0, 1]}>
        <FilterText selected={isSelected}>
          {header.title}
        </FilterText>
      </Show>
      <Hide breakpoints={[0, 1]}>
        <FilterText selected={isSelected}>
          {header.desktopTitle === null || header.desktopTitle === undefined ? header.title : header.desktopTitle}
        </FilterText>
      </Hide>
      {header.sortable && (
        <HeaderIcon
          selected={isSelected}
          name={isSelected && sort.ascending ? 'NavUp' : 'NavDown'}
        />
      )}
    </HeaderContainer>
  );
};

Header.propTypes = {
  header: headerPropTypes.isRequired,
  sort: sortPropTypes.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default Header;
