import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pdfjs, Document, Page } from 'react-pdf';
import {
  ActionBar, PageButton, Panel, Show, utils, ZoomButton,
} from '@galilee/lilee';
import Container from 'components/Container';
import {
  PDFNavWrapper, PDFNav, PDFNavIcon, PDFPageNumber,
} from 'components/PDFNav';
import PdfLoading from 'components/PdfLoading';
import PDFDocumentWrapper from 'components/PDFDocumentWrapper';

const { useScrollToTop } = utils;

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Read = ({ pdfPath, title, subtitle }) => {
  const [pageCount, setPageCount] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoomed, setZoomed] = useState(false);
  useScrollToTop(true);

  return (
    <Container>
      <Container center>
        <Panel mb="0">
          <Panel.Subtitle>{subtitle}</Panel.Subtitle>
          <Panel.Title pb={[0]}>{title}</Panel.Title>
          <Panel.Section userSelect={false} p="0">
            <PDFPageNumber pageNumber={pageNumber} pageCount={pageCount} />
            <PDFDocumentWrapper zoomed={zoomed}>
              <PDFNavWrapper show={pageNumber > 1}>
                <PDFNav leftNav onClick={() => setPageNumber((pageNumber - 1))}><PDFNavIcon name="NavLeft" /></PDFNav>
              </PDFNavWrapper>
              <Document
                loading={<PdfLoading />}
                file={pdfPath}
                onLoadSuccess={({ numPages }) => setPageCount(numPages)}
              >
                <Page
                  width={1034}
                  loading={<PdfLoading />}
                  pageNumber={pageNumber}
                />
              </Document>
              <PDFNavWrapper show={pageCount && pageNumber !== pageCount}>
                <PDFNav pulse={pageNumber === 1} onClick={() => setPageNumber((pageNumber + 1))}><PDFNavIcon name="NavRight" /></PDFNav>
              </PDFNavWrapper>
            </PDFDocumentWrapper>
          </Panel.Section>
        </Panel>
        <Show breakpoints={[0, 1]}>
          <ZoomButton.Wrapper bottom={zoomed ? '18px' : '80px'}>
            <ZoomButton onClick={() => setZoomed(!zoomed)} zoomed={zoomed} />
          </ZoomButton.Wrapper>
        </Show>
        <ActionBar
          showSkip={false}
          showNext={false}
        >
          <PageButton
            totalPages={pageCount}
            currentPage={pageNumber}
            nextPageClick={() => setPageNumber(pageNumber + 1)}
            previousPageClick={() => setPageNumber(pageNumber - 1)}
          />
        </ActionBar>
      </Container>
    </Container>
  );
};

Read.propTypes = {
  pdfPath: PropTypes.shape({}).isRequired,
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
};

export default Read;
