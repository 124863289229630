import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { PageLoading } from '@galilee/lilee';
import authService, { AuthenticationResultStatus } from './AuthorizeService';
import { QueryParameterNames, LogoutActions, ApplicationPaths } from './ApiAuthorizationConstants';

class Logout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      message: undefined,
      isReady: false,
    };
  }

  componentDidMount() {
    const { action } = this.props;
    switch (action) {
      case LogoutActions.Logout:
        if (window.history.state.state.local) {
          this.logout(this.getReturnUrl());
        } else {
          // This prevents regular links to <app>/authentication/logout from triggering a logout
          this.setState({ isReady: true, message: 'The logout was not initiated from within the page.' });
        }
        break;
      case LogoutActions.LogoutCallback:
        this.processLogoutCallback();
        break;
      case LogoutActions.LoggedOut:
        // this.setState({ isReady: true, message: 'You successfully logged out!' });
        window.location.replace(`${window.location.origin}/Identity/Account/Logout`);
        break;
      default:
        throw new Error(`Invalid action '${action}'`);
    }
    this.setState({ isReady: true });
  }

  getReturnUrl = (state) => {
    const params = new URLSearchParams(window.location.search);
    const fromQuery = params.get(QueryParameterNames.ReturnUrl);
    if (fromQuery && !fromQuery.startsWith(`${window.location.origin}/`)) {
      // This is an extra check to prevent open redirects.
      throw new Error('Invalid return url. The return url needs to have the same origin as the current page.');
    }
    return (state && state.returnUrl)
            || fromQuery
            || `${window.location.origin}${ApplicationPaths.LoggedOut}`;
  }

  navigateToReturnUrl = (returnUrl) => window.location.replace(returnUrl)

  async processLogoutCallback() {
    const url = window.location.href;
    const result = await authService.completeSignOut(url);
    switch (result.status) {
      case AuthenticationResultStatus.Redirect:
        // There should not be any redirects as the only time completeAuthentication finishes
        // is when we are doing a redirect sign in flow.
        throw new Error('Should not redirect.');
      case AuthenticationResultStatus.Success:
        await this.navigateToReturnUrl(this.getReturnUrl(result.state));
        break;
      case AuthenticationResultStatus.Fail:
        this.setState({ message: result.message });
        break;
      default:
        throw new Error('Invalid authentication result status.');
    }
  }

  async logout(returnUrl) {
    const state = { returnUrl };
    const isAuthenticated = await authService.isAuthenticated();
    if (isAuthenticated) {
      const result = await authService.signOut(state);
      switch (result.status) {
        case AuthenticationResultStatus.Redirect:
          break;
        case AuthenticationResultStatus.Success:
          await this.navigateToReturnUrl(returnUrl);
          break;
        case AuthenticationResultStatus.Fail:
          this.setState({ message: result.message });
          break;
        default:
          throw new Error('Invalid authentication result status.');
      }
    }
  }

  render() {
    const { isReady, message } = this.state;
    if (!isReady) return (<PageLoading />);
    if (message) {
      return (<div>{message}</div>);
    }
    const { action } = this.props;
    switch (action) {
      case LogoutActions.Logout:
        return (<PageLoading />);
      case LogoutActions.LogoutCallback:
        return (<PageLoading />);
      case LogoutActions.LoggedOut:
        return (<PageLoading />);
      default:
        throw new Error(`Invalid action '${action}'`);
    }
  }
}

Logout.defaultProps = {
  action: null,
};

Logout.propTypes = {
  action: PropTypes.string,
};

export default Logout;
