import React from 'react';
import UpdateBorrowerProvider from 'state/updateBorrower/UpdateBorrowerProvider';
import UpdateBorrower from './UpdateUser';

export default function UpdateBorrowerRoute() {
  return (
    <UpdateBorrowerProvider>
      <UpdateBorrower />
    </UpdateBorrowerProvider>
  );
}
