import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, Route } from 'react-router-dom';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';

function ProtectedRoute({ component: Component, ...rest }) {
  const { isAuthenticated } = useTrackNTrace();
  return (
    <Route
      {...rest}
      render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to="/track/login" />)}
    />
  );
}

ProtectedRoute.propTypes = {
  component: PropTypes.func.isRequired,
};

export default React.memo(ProtectedRoute);
