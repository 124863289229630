import React from 'react';
import Countdown from 'react-countdown-now';
import PropTypes from 'prop-types';

const AutoSubmitCountdown = ({ dateToAutoSubmit, onComplete }) => {
  if (!dateToAutoSubmit) return 'the next hour.';

  return (
    <Countdown
      date={new Date(dateToAutoSubmit)}
      onComplete={() => onComplete()}
      renderer={({
        days, hours, minutes, seconds,
      }) => {
        const displayHours = (days * 24) + hours;
        const hourString = displayHours > 0 ? `${displayHours} hour${displayHours === 1 ? '' : 's'} and ` : '';
        const minuteString = minutes > 0 ? `${minutes} minute${minutes === 1 ? '' : 's'} and ` : '';
        const secondString = `${seconds} second${seconds === 1 ? '' : 's'}`;
        return `${hourString}${minuteString}${secondString}`;
      }}
    />
  );
};

export default AutoSubmitCountdown;

AutoSubmitCountdown.propTypes = {
  dateToAutoSubmit: PropTypes.string,
  onComplete: PropTypes.func,
};

AutoSubmitCountdown.defaultProps = {
  dateToAutoSubmit: null,
  onComplete: () => {},
};
