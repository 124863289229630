import React from 'react';
import PropTypes from 'prop-types';
import { OL, LI } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupOL = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data } = fields[fieldName];
  return <OL m={0}>{data.map((value) => (<LI key={fieldName + value}>{value}</LI>))}</OL>;
};

MarkupOL.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupOL;
