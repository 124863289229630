import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useInterval from 'utils/useInterval';
import { ApplicationPaths } from 'components/api-authorization/ApiAuthorizationConstants';

const HeartbeatLogout = ({ children, isAuthenticated }) => {
  const [initialised, setInitialised] = useState(false);
  const history = useHistory();

  const badoom = () => {
    localStorage.setItem('kwil_heartbeat', (new Date(Date.now())).toISOString());
  };

  const logout = () => {
    history.push({ pathname: `${ApplicationPaths.LogOut}`, state: { local: true } });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setInitialised(true);
      badoom();
    }
  }, [isAuthenticated]);

  useInterval(() => {
    if (initialised) {
      const heartbeat = localStorage.getItem('kwil_heartbeat') || '';
      if (!heartbeat) {
        logout();
      }
      try {
        const parsedHeartbeat = Date.parse(heartbeat);
        const tenMinutesAgo = Date.now() - (1000 * 60 * 10);
        if (parsedHeartbeat < tenMinutesAgo) {
          logout();
        } else {
          badoom();
        }
      } catch (error) {
        logout();
      }
    }
  }, 1000 * 60);

  return children;
};

export default HeartbeatLogout;
