import React from 'react';
import PropTypes from 'prop-types';
import HiddenBox from './components/HiddenBox';
import TextBox from './components/TextBox';
import ReadOnlyText from './components/ReadOnlyText';
import RadioList from './components/RadioList';
import MarkupTitle from './components/MarkupTitle';
import MarkupDL from './components/MarkupDL';
import MarkupTL from './components/MarkupTL';
import MarkupTable from './components/MarkupTable';
import MarkupOL from './components/MarkupOL';
import MarkupUL from './components/MarkupUL';
import MarkupP from './components/MarkupP';
import MarkupHelp from './components/MarkupHelp';
import MarkupHR from './components/MarkupHR';
import CheckBox from './components/CheckBox';
import MarkupLabel from './components/MarkupLabel';

const FormItem = ({ componentType, fieldName, readOnly }) => {
  switch (componentType) {
    case 'TextBox':
      return <TextBox fieldName={fieldName} readOnly={readOnly} />;
    case 'RadioList':
      return <RadioList fieldName={fieldName} readOnly={readOnly} />;
    case 'MarkupTitle':
      return <MarkupTitle fieldName={fieldName} />;
    case 'MarkupDL':
      return <MarkupDL fieldName={fieldName} />;
    case 'MarkupTL':
      return <MarkupTL fieldName={fieldName} />;
    case 'MarkupTable':
      return <MarkupTable fieldName={fieldName} />;
    case 'MarkupOL':
      return <MarkupOL fieldName={fieldName} />;
    case 'MarkupUL':
      return <MarkupUL fieldName={fieldName} />;
    case 'MarkupP':
      return <MarkupP fieldName={fieldName} />;
    case 'MarkupHelp':
      return <MarkupHelp fieldName={fieldName} />;
    case 'MarkupHR':
      return <MarkupHR fieldName={fieldName} />;
    case 'CheckBox':
      return <CheckBox fieldName={fieldName} readOnly={readOnly} />;
    case 'MarkupLabel':
      return <MarkupLabel fieldName={fieldName} />;
    case 'ReadonlyText':
      return <ReadOnlyText fieldName={fieldName} />;
    case 'HiddenBox':
      return <HiddenBox fieldName={fieldName} readOnly={readOnly} />;
    default:
      return null;
  }
};

FormItem.defaultProps = {
  readOnly: false,
};

FormItem.propTypes = {
  componentType: PropTypes.string.isRequired,
  fieldName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
};

export default FormItem;
