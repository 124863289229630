import React from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { H1, H4, Box } from '@galilee/lilee';
import Indent from './Indent';

const TitleWrapper = styled(Indent)`
  max-width: 550px;
`;

TitleWrapper.defaultProps = {
  mt: [5, 5, 0],
};

const TitleContainer = ({
  children, title, sectionTitle, ...rest
}) => (
  <TitleWrapper flexDirection="column" {...rest}>
    <H4 mb="2">{sectionTitle}</H4>
    <H1 mb="0">{title}</H1>
    {children}
  </TitleWrapper>
);

TitleContainer.defaultProps = {
  title: '',
  sectionTitle: '',
  children: null,
};

TitleContainer.propTypes = {
  title: PropTypes.string,
  sectionTitle: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

TitleContainer.WelcomeMessage = styled(Box)`
  max-width: 550px;
  margin-top: ${(p) => p.theme.space[6]};
  margin-bottom: ${(p) => p.theme.space[7]};
`;

export default TitleContainer;
