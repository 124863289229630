import React from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox, Box, Flex, FieldError,
} from '@galilee/lilee';
import useDynamicFieldProps from './useDynamicFieldProps';

const CheckBox = ({ fieldName, readOnly }) => {
  const { errors, fieldProps, field } = useDynamicFieldProps(fieldName);
  const {
    id, name, value, ...props
  } = fieldProps;
  return (
    <Box width="100%">
      <Flex flexDirection="column">
        <Checkbox
          disabled={readOnly}
          label={field.title}
          checked={value === 'true'}
          {...props}
        />
      </Flex>
      <FieldError error={errors[fieldName]} />
    </Box>
  );
};

CheckBox.propTypes = {
  fieldName: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
};

export default CheckBox;
