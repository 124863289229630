import React, { useEffect } from 'react';
import { Route } from 'react-router';
import PropTypes from 'prop-types';
import ReactRouterPropTypes from 'react-router-prop-types';
import Layout from 'components/Layout';
import { PageLoading } from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useApplication } from 'state/ApplicationProvider';
import ManageMatterProvider, { useManageMatter } from 'state/ManageMatterProvider';

import { fetchManageMatterAction } from 'actions/Manage';
import Security from 'screens/matter/security';
import ManageMatterSummary from './summary';
import Upload from './upload';
import Read from './read';
import Sign from './sign';

const ManageMatter = ({ match, ...props }) => {
  const { ready } = useAuth();
  const { dispatch } = useApplication();
  if (!ready) return <PageLoading />;
  return (
    <ManageMatterProvider matterId={match.params.id} applicationDispatch={dispatch}>
      <ManageMatterRouter path={match.path} matterId={match.params.id} {...props} />
    </ManageMatterProvider>
  );
};

ManageMatter.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default ManageMatter;

const ManageMatterRouter = ({
  matterId, path,
}) => {
  const { authToken } = useAuth();
  const { state, dispatch } = useManageMatter();
  const { dispatch: applicationDispatch } = useApplication();
  const matterLoaded = !!state && !!state.matter;

  useEffect(() => {
    if (dispatch && !matterLoaded) {
      fetchManageMatterAction(dispatch, applicationDispatch, matterId, authToken);
    }
  }, [authToken, matterId, dispatch, applicationDispatch, matterLoaded]);

  return (
    <Layout matter={state.matter}>
      {!matterLoaded && <PageLoading />}
      {matterLoaded && (
      <>
        <Route exact path={path} component={ManageMatterSummary} />
        <Route path={`${path}/upload/:id`} component={Upload} />
        <Route path={`${path}/read/:id`} component={Read} />
        <Route path={`${path}/sign/:id`} component={Sign} />
        <Route path={`${path}/security`} component={Security} />
      </>
      )}
    </Layout>
  );
};

ManageMatterRouter.propTypes = {
  matterId: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
};
