import React from 'react';
import PropTypes from 'prop-types';
import {
  Modal, Image, Text, Button, Flex,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import styled from 'styled-components/macro';
import FiveStepsToComplete from 'images/FiveStepsToComplete.png';
import FourStepsToComplete from 'images/FourStepsToComplete.png';
import { useMatter } from 'state/MatterProvider';

const InlineBoldText = styled.span`
  font-weight:bold;
`;

export default function WelcomeModal({ requireVerification, onConfirm }) {
  const { support } = useAuth();
  const { state } = useMatter();
  return (
    <Modal>
      <Modal.Body>
        <Flex p="8" flexDirection="column">
          <Modal.Subtitle>Get Started</Modal.Subtitle>
          <Modal.Title m="0" mt="3">Welcome</Modal.Title>
          <Text mb="8">
            {`We are just a few steps away from settling your home ${state.matter.isSharia ? 'finance' : 'loan'}. `
                          + `There are ${requireVerification ? 'five' : 'four'} simple steps we need you to complete in order to finalise your ${state.matter.isSharia ? 'finance' : 'loan'}.`}
          </Text>
          <Image src={requireVerification ? FiveStepsToComplete : FourStepsToComplete} />
          {requireVerification
          && (
          <>
            <Text mt="8" mb="6">
              Before you get started you will need at least two forms of ID, we recommend you use your
              {' '}
              <InlineBoldText>passport</InlineBoldText>
              {' '}
              and
              {' '}
              <InlineBoldText>driver&apos;s licence</InlineBoldText>
              .
              {' '}
              Alternatively, you can use either of those combined with two other government-issued identity documents, such as a medicare card, a centrelink card, or a birth certificate.
            </Text>
            <Text>If your name has changed recently we also suggest you find your marriage or change of name certificate.</Text>
          </>
          )}
          <Flex mt="8">
            <Button disabled={support.isTenantSupport} noBorder color="secondary" onClick={onConfirm}>Ok</Button>
          </Flex>
        </Flex>
      </Modal.Body>
    </Modal>
  );
}
WelcomeModal.propTypes = {
  requireVerification: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};
