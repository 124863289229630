import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';

const MarkupHelp = ({ fieldName }) => {
  const { fields } = useDynamicForm();
  const { data } = fields[fieldName];
  return <Text muted fontSize={[0]}>{data}</Text>;
};

MarkupHelp.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default MarkupHelp;
