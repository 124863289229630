import React from 'react';
import styled from 'styled-components/macro';
import {
  Flex, Panel, Box, Text, HR, H2, P, H6,
} from '@galilee/lilee';
import { useTrackNTraceMatter } from 'state/track/TrackNTraceMatterProvider';
import format from 'date-fns/format';
import StepHeader from './MatterStepHeader';

const Wrapper = styled(Panel)`
  max-width: 700px;
  margin-bottom: ${(p) => p.theme.space[6]};
`;

const getLatest = (matter) => {
  // the step with outstanding issues goes at the top. if no step has outstanding issues, use blow logic to determine which step goes at the top: if re-instructed and it's current step. we want to display the step, otherwise sort steps by order and show last step.
  const sectionHasOutstandingIssue = matter.sections.filter((s) => s.steps.some((step) => step.hasOutstandingIssues))[0];

  const stepHasOutstandingIssues = sectionHasOutstandingIssue ? { ...sectionHasOutstandingIssue.steps.find((s) => s.hasOutstandingIssues), link: sectionHasOutstandingIssue.link } : null;

  const reinstructedCurrentStep = matter.sections.flatMap((s) => s.steps).find((s) => s.name === 'Re-instructed' && s.isCurrentStep);

  const sectionsHaveSteps = matter.sections.filter((s) => s.steps && s.steps.length > 0);
  const latestSection = sectionsHaveSteps.sort((a, b) => b.order - a.order)[0];
  const latestStep = stepHasOutstandingIssues || reinstructedCurrentStep || latestSection.steps.sort((a, b) => b.order - a.order)[0];

  return latestStep;
};

const MatterCurrentStep = () => {
  const { matter } = useTrackNTraceMatter();

  if (!matter) return null;

  const latestStep = getLatest(matter);

  return (
    <Wrapper mx="auto">
      <Box>
        <Text>
          Last Updated&nbsp;
          {format(new Date(latestStep.date), 'dd/MM/yyyy')}
        </Text>
        <HR mb="7" />
        <H2 mb="7">
          {latestStep.name}
        </H2>
        <Flex flexDirection="column">
          <StepHeader step={latestStep} />
          {(!latestStep.hasOutstandingIssues && latestStep.guide) && (
          <>
            <H6 mb="4">Next Step </H6>
            <P>
              {latestStep.guide}
            </P>
          </>
          )}
        </Flex>
      </Box>
    </Wrapper>
  );
};

export default MatterCurrentStep;
