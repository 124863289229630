import React, {
  createContext,
  useContext,
  useMemo,
  useReducer,
  useRef,
  useEffect,
} from 'react';
import { useAuth } from 'state/AuthProvider';
import PropTypes from 'prop-types';
import { renameBroker, getMe } from 'actions/User';
import { toast } from '@galilee/lilee';
import reducer from './reducer';

const BrokerProfileContext = createContext(null);

const initialState = {
  loading: false,
  user: {
    firstName: null,
    lastName: null,
  },
};

function useBrokerProfileHook() {
  const { authToken } = useAuth();
  const [state, dispatch] = useReducer(reducer, initialState);
  const getAuthTokenRef = useRef(authToken);

  useEffect(() => {
    async function fetch() {
      try {
        dispatch({ type: 'SET_LOADING', payload: true });
        const { firstName, lastName } = await getMe(getAuthTokenRef.current);
        dispatch({ type: 'UPDATE_USER', payload: { firstName, lastName } });
      } catch (error) {
        toast.error('We couldn\'t get your profile, please reload the page and try again');
      } finally {
        dispatch({ type: 'SET_LOADING', payload: false });
      }
    }
    fetch();
  }, []);

  const actions = {
    renameBroker: async (payload) => {
      try {
        await renameBroker(getAuthTokenRef.current, payload);
        toast.success('Your profile has been saved');
      } catch (error) {
        toast.error("We couldn't change your name, please reload the page and try again");
      }
    },
    setUser: (payload) => {
      dispatch({ type: 'UPDATE_USER', payload });
    },
  };

  return {
    state: { ...state },
    actions,
  };
}

export const useBrokerProfile = () => {
  const contextValue = useContext(BrokerProfileContext);
  if (contextValue === null) throw Error('BrokerProfileContext has not been Provided!');
  return contextValue;
};

export default function BrokerProfileProvider({ children }) {
  const hook = useBrokerProfileHook();
  const value = useMemo(() => ({ ...hook }), [hook]);
  return (
    <BrokerProfileContext.Provider value={value}>
      {children}
    </BrokerProfileContext.Provider>
  );
}

BrokerProfileProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
