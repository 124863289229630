/* eslint-disable react/forbid-prop-types */
import React from 'react';
import styled, { createGlobalStyle } from 'styled-components/macro';
import {
  PageLoading, H1, Box, Text,
} from '@galilee/lilee';
import PropTypes from 'prop-types';

const GlobalStyle = createGlobalStyle`
  body {
    overflow-y: hidden;  
  }
`;

const ScrollContainer = styled(Box)`
  max-width: 660px;
  overflow-y: auto;
  padding: ${(p) => p.theme.space[6]};
`;

const ApplicationError = ({ disconnectionErrors, socketConnectionErrors }) => {
  const socketConnectionErrorKeys = Object.keys(socketConnectionErrors);
  if (socketConnectionErrorKeys.length > 0) {
    return (
      <PageLoading>
        <GlobalStyle />
        <ScrollContainer>
          <H1 mb="8">
            Oh no, it looks like we can&apos;t connect to Kwil right now.
          </H1>
          <Text lg mb="7">
            You might have lost connection to the internet for a moment or we might be deploying
            the latest and greatest version of Kwil, If that&apos;s the case then sit tight and
            we&apos;ll try and reconnect.
          </Text>
          <Text lg mb="9">
            If your connection doesn&apos;t return then you may be behind a VPN or Firewall.
          </Text>
        </ScrollContainer>
      </PageLoading>
    );
  }

  // You can test this by restarting the running process in Visual Studio: Ctrl+Shift+F5
  const disconnectionErrorKeys = Object.keys(disconnectionErrors);
  if (disconnectionErrorKeys.length > 0) {
    return (
      <PageLoading>
        <GlobalStyle />
        <ScrollContainer>
          <H1 mb="8">
            Oh no, it looks like we lost our connection!
          </H1>
          <Text lg mb="7">
            We&apos;re trying to reconnect...
          </Text>
        </ScrollContainer>
      </PageLoading>
    );
  }

  return null;
};

ApplicationError.defaultProps = {
  disconnectionErrors: {},
  socketConnectionErrors: {},
};

ApplicationError.propTypes = {
  disconnectionErrors: PropTypes.object,
  socketConnectionErrors: PropTypes.object,
};

export default ApplicationError;
