/* eslint-disable react/forbid-prop-types */

import React, {
  useReducer, useMemo, createContext, useContext, useEffect,
} from 'react';
import { validators, utils } from '@galilee/lilee';
import PropTypes from 'prop-types';
import { HubConnectionBuilder } from '@microsoft/signalr';
import useDeepCompareEffect from 'use-deep-compare-effect';

const { isNumeric } = utils;

export const accessTypes = {
  shared: 1,
  mine: 2,
  theirs: 3,
};

const hideField = (field, fieldValues) => {
  if (field.componentType === 'HiddenBox') return true;
  if (field.accessType === accessTypes.theirs || (!field.showIf && !field.showIfAny && !field.showIfAll)) return false;
  const { showIf, showIfAny, showIfAll } = field;

  if (showIf) {
    const { fieldRef, condition } = showIf;
    let { conditionValue } = showIf;
    let fieldValue = fieldValues[fieldRef];
    switch (condition) {
      case 'eq':
        return fieldValue?.toString() !== conditionValue.toString();
      case 'neq':
        return fieldValue?.toString() === conditionValue.toString();
      case 'gt':
      {
        if (isNumeric(fieldValue)) fieldValue = Number(fieldValue);
        if (isNumeric(conditionValue)) conditionValue = Number(conditionValue);
        return !(fieldValue > conditionValue);
      }
      case 'lt':
      {
        if (isNumeric(fieldValue)) fieldValue = Number(fieldValue);
        if (isNumeric(conditionValue)) conditionValue = Number(conditionValue);
        return !(fieldValue < conditionValue);
      }
      default:
        return false;
    }
  }

  if (showIfAny) {
    const { fields } = showIfAny;
    const showIfAnyCondition = showIfAny.condition;
    const showIfAnyConditionValue = showIfAny.conditionValue;

    const values = fields.map((fieldRef) => fieldValues[fieldRef]);

    switch (showIfAnyCondition) {
      case 'eq':
        return !values.some((v) => v?.toString() === showIfAnyConditionValue.toString());
      case 'gt':
      {
        return !values.some((fieldValue) => {
          let convertedValue = fieldValue;
          let convertedConditionValue = showIfAnyConditionValue;
          if (isNumeric(fieldValue)) convertedValue = Number(fieldValue);
          if (isNumeric(showIfAnyConditionValue)) convertedConditionValue = Number(showIfAnyConditionValue);
          return convertedValue > convertedConditionValue;
        });
      }
      case 'lt':
      {
        return !values.some((fieldValue) => {
          let convertedValue = fieldValue;
          let convertedConditionValue = showIfAnyConditionValue;
          if (isNumeric(fieldValue)) convertedValue = Number(fieldValue);
          if (isNumeric(showIfAnyConditionValue)) convertedConditionValue = Number(showIfAnyConditionValue);
          return convertedValue < convertedConditionValue;
        });
      }
      default:
        return false;
    }
  }

  if (showIfAll) {
    const fieldsConfig = showIfAll.fields;

    const testResult = fieldsConfig.every((config) => {
      const { fieldRef, condition, conditionValue } = config;
      const value = fieldValues[fieldRef];
      switch (condition) {
        case 'eq':
          return value?.toString() === conditionValue.toString();
        case 'neq':
          return value?.toString() !== conditionValue.toString();
        case 'gt':
        {
          let convertedValue = value;
          let convertedConditionValue = conditionValue;
          if (isNumeric(value)) convertedValue = Number(value);
          if (isNumeric(conditionValue)) convertedConditionValue = Number(conditionValue);
          return convertedValue > convertedConditionValue;
        }
        case 'lt':
        {
          let convertedValue = value;
          let convertedConditionValue = conditionValue;
          if (isNumeric(value)) convertedValue = Number(value);
          if (isNumeric(conditionValue)) convertedConditionValue = Number(conditionValue);
          return convertedValue < convertedConditionValue;
        }
        default:
          return false;
      }
    });
    return !testResult;
  }

  return false;
};

function validateField(fieldValues, fieldValue = '', fieldConfig) {
  const specialProps = ['initialValue'];
  let errorMessage = null;
  Object.keys(fieldConfig).find((validatorName) => {
    if (!specialProps.includes(validatorName)) {
      const validatorConfig = fieldConfig[validatorName];
      if (validatorConfig.fieldRef) {
        validatorConfig.value = fieldValues[validatorConfig.fieldRef];
      }

      if (validatorConfig.fields && Array.isArray(validatorConfig.fields)) {
        validatorConfig.fieldValues = validatorConfig.fields.reduce((acc, item) => {
          const propName = item.fieldRef || item;
          acc[propName] = fieldValues[propName];
          return acc;
        }, {});
      }

      errorMessage = validators[validatorName](validatorConfig)(fieldValue);
      return typeof errorMessage === 'string'; // First error message found, stop validating anything else.
    }
    return false;
  });
  return errorMessage;
}

function validateFields({
  fieldValues, validationConfig, fieldNames, userId, fields, hiddenFields,
}) {
  const errors = {};
  fieldNames.forEach((fieldName) => {
    if (hiddenFields[fieldName]) return;
    const { borrowerId } = fields[fieldName];
    let doValidation = true;
    if (borrowerId) doValidation = borrowerId === userId;
    const fieldConfig = validationConfig.fields[fieldName];
    const fieldValue = fieldValues[fieldName];
    if (fieldConfig && doValidation) {
      errors[fieldName] = validateField(fieldValues, fieldValue, fieldConfig);
    }
  });

  return errors;
}

function deselectFields(fieldValues, deselectionConfig, excludeField) {
  const deselections = {};
  Object.keys(deselectionConfig).forEach((fieldName) => {
    if (fieldName === excludeField) return;
    const { fields, logicalConjunction, deselectValue } = deselectionConfig[fieldName];
    const currentDeselections = [];
    fields.forEach(({ validator, validatorValue, fieldRef }) => {
      const value = fieldValues[fieldRef];
      const validatorConfig = { validator, validatorValue, message: 'deselect' };
      const errorMessage = validators.deselectIf(validatorConfig)(value);
      currentDeselections.push(errorMessage !== 'deselect');
    });
    if ((logicalConjunction.toLowerCase() === 'or' && currentDeselections.some((e) => e === true))
      || (logicalConjunction.toLowerCase() === 'and' && currentDeselections.every((e) => e === true))
    ) {
      deselections[fieldName] = deselectValue;
    }
  });

  return deselections;
}

export function prepareDynamicFormData(data, userId) {
  const {
    validationConfig,
    deselectionConfig,
    formConfig,
    formValues,
    showErrors,
    docId,
    fieldIds,
    signFlowType,
    requiresWitnessing,
    requiresPrinting,
    requireAllPartiesToReSignOnEdit,
    preSignatureForm,
    printReturnAddress,
    printGuideline,
    jurisdiction,
    downloadDetails,
  } = data;

  const blurred = {};
  const dirtyFields = {};
  const hiddenFields = {};
  const fieldNames = Object.keys(formConfig);
  fieldNames.forEach((fieldName) => {
    blurred[fieldName] = false;
    dirtyFields[fieldName] = false;
    const field = formConfig[fieldName];
    hiddenFields[fieldName] = hideField(field, formValues);
  });
  // Set the accessType for each field (shared, mine, theirs)
  for (let i = 0; i < fieldNames.length; i++) {
    const fieldName = fieldNames[i];
    const field = formConfig[fieldName];
    if (field.borrowerId) {
      field.accessType = field.borrowerId === userId ? accessTypes.mine : accessTypes.theirs;
    } else {
      field.accessType = accessTypes.shared;
    }
  }
  const state = {
    docId,
    validationConfig, // < passed in
    deselectionConfig, // < passed in
    fieldValues: formValues, // < passed in
    fieldIds, // < passed in
    fields: formConfig, // < passed in
    showErrors, // < passed in
    hiddenFields,
    dirtyFields,
    concurrencyFieldErrors: [],
    fieldNames,
    blurred,
    submitted: false,
    signFlowType,
    requiresWitnessing,
    requiresPrinting,
    requireAllPartiesToReSignOnEdit,
    printReturnAddress,
    printGuideline: JSON.parse(printGuideline),
    preSignatureForm,
    jurisdiction,
    fieldResets: {},
    userId,
    downloadDetails,
    debouncing: false,
    debounceTime: 1000,
  };

  const errors = validateFields(state);
  return { ...state, errors };
}

function dynamicFormReducer(state, action) {
  switch (action.type) {
    case 'change': {
      const { fieldName, value } = action.payload;
      const newState = {
        ...state,
        fieldValues: { ...state.fieldValues, [fieldName]: value },
        dirtyFields: { ...state.dirtyFields, [fieldName]: true },
      };
      const deselections = deselectFields(newState.fieldValues, state.deselectionConfig, fieldName);
      const deselectionKeys = Object.keys(deselections);
      if (deselectionKeys.length) {
        let deselectionState = { ...newState };
        deselectionKeys.forEach((key) => {
          deselectionState = {
            ...deselectionState,
            fieldValues: { ...deselectionState.fieldValues, [key]: deselections[key] },
          };
        });
        return { ...deselectionState, fieldResets: { ...deselectionState.fieldResets, ...deselections } };
      }
      return newState;
    }
    case 'submit':
      return { ...state, submitted: true };
    case 'validate':
      return { ...state, errors: action.payload };
    case 'deselect':
    {
      if (action.payload && action.payload.length) {
        let deselectionState = { ...state };
        for (let i = 0; i < action.payload.length; i++) {
          const deselection = action.payload[i];
          deselectionState = {
            ...deselectionState,
            fieldValues: { ...deselectionState.fieldValues, [deselection.fieldName]: deselection.deselectValue },
          };
        }
        return deselectionState;
      }
      return state;
    }
    case 'update_field': {
      const { fieldName } = action.payload;
      const isDirtyField = state.dirtyFields[fieldName];
      let concurrencyFieldErrors = [...state.concurrencyFieldErrors];
      if (isDirtyField && action.payload.isEditedBySomeoneElse) {
        concurrencyFieldErrors = [
          ...concurrencyFieldErrors,
          {
            fieldName,
            fieldTitle: state.fields[fieldName].labelText,
            editorFirstName: action.payload.editorFirstName,
            editorLastName: action.payload.editorLastName,
          },
        ];
      }

      const fieldValues = { ...state.fieldValues, [fieldName]: action.payload.fieldValue };
      const hiddenFields = {};
      state.fieldNames.forEach((f) => {
        const field = state.fields[f];
        hiddenFields[f] = hideField(field, fieldValues);
      });

      const preValidationState = {
        ...state,
        fieldValues,
        hiddenFields,
        dirtyFields: { ...state.dirtyFields, [fieldName]: false },
        concurrencyFieldErrors,
      };

      const errors = validateFields(preValidationState);
      return { ...preValidationState, errors };
    }
    case 'blur':
      return {
        ...state,
        blurred: { ...state.blurred, [action.payload]: true },
      };
    case 'ACKNOWLEDGE_FIELD_RESET': {
      const { [action.payload]: removed, ...fieldResets } = state.fieldResets;
      return { ...state, fieldResets };
    }
    case 'ACKNOWLEDGE_CONCURRENCY_ERROR': {
      const concurrencyFieldErrors = state.concurrencyFieldErrors.filter((c) => c.fieldName !== action.payload);
      return { ...state, concurrencyFieldErrors };
    }
    case 'SET_DEBOUNCE': {
      return { ...state, debouncing: action.payload };
    }
    default:
      throw new Error('Unknown action type');
  }
}

function getErrors(state) {
  if (state.showErrors === 'always' || state.submitted) {
    return state.errors;
  }
  if (state.showErrors === 'blur') {
    return Object.entries(state.blurred)
      .filter(([, blurred]) => blurred)
      .reduce((acc, [name]) => ({ ...acc, [name]: state.errors[name] }), {});
  }
  return {};
}

const createHubConnection = (docId, authToken) => new HubConnectionBuilder()
  .withUrl(`/signdocshub?signDocId=${docId}`, { accessTokenFactory: () => authToken })
  .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000, 120000, 180000])
  .build();

const DynamicFormContext = createContext({});

export const useDynamicForm = () => useContext(DynamicFormContext);

const DynamicFormProvider = ({
  data, children, authToken, userId, applicationDispatch,
}) => {
  const { docId } = data;
  const [state, dispatch] = useReducer(dynamicFormReducer, data);

  useEffect(() => {
    const connection = createHubConnection(docId, authToken);

    connection.onreconnecting(() => applicationDispatch({ type: 'SET_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter' }));
    connection.onreconnected(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_DISCONNECTION_ERROR', payload: 'Matter' }));

    connection
      .on('SignDocFieldUpdate', (updatedSignField) => {
        const payload = {
          ...updatedSignField,
          isEditedBySomeoneElse: updatedSignField.updatedByUserId !== userId,
          editorFirstName: updatedSignField.updatedByFirstname,
          editorLastName: updatedSignField.updatedByLastname,
        };
        dispatch({
          type: 'update_field',
          payload,
        });
      });

    connection.start()
      .then(() => applicationDispatch({ type: 'REMOVE_WEBSOCKET_ERROR', payload: 'Matter' }))
      .catch(() => applicationDispatch({ type: 'SET_WEBSOCKET_ERROR', payload: 'Matter' }));

    return () => {
      connection.stop();
    };
  }, [dispatch, authToken, docId, userId, applicationDispatch]);

  useDeepCompareEffect(() => {
    const errors = validateFields(state);
    dispatch({ type: 'validate', payload: errors });
  }, [state.blurred]);

  const errors = useMemo(() => getErrors(state), [state]);

  const isFormValid = () => Object.values(state.errors).every((error) => error === null) && !state.debouncing;

  const errorSummary = state.submitted ? Object.values(state.errors).filter((error) => error !== null) : [];

  const context = {
    ...state,
    errors,
    errorSummary,
    isFormValid,
    getFormProps: (overrides = {}) => ({
      onSubmit: (e) => {
        e.preventDefault();
        dispatch({ type: 'submit' });
        if (overrides.onSubmit) {
          overrides.onSubmit({ ...state, isFormValid });
        }
      },
    }),
    getFieldProps: (fieldName, overrides = {}) => ({
      onChange: (e) => {
        if (!state.fields[fieldName] || state.concurrencyFieldErrors.length > 0) return;
        dispatch({
          type: 'change',
          payload: {
            fieldName,
            value: e.target.type === 'checkbox' ? e.target.checked.toString() : e.target.value,
          },
        });
        if (overrides.onChange) {
          overrides.onChange(e);
        }
      },
      onBlur: (e) => {
        dispatch({ type: 'blur', payload: fieldName });
        if (overrides.onBlur) {
          overrides.onBlur(e);
        }
      },
    }),
    acknowledgeConcurrencyError: (fieldName) => {
      dispatch({ type: 'ACKNOWLEDGE_CONCURRENCY_ERROR', payload: fieldName });
    },
    showAllErrors: () => {
      dispatch({ type: 'submit' });
    },
    acknowledgeReset: (fieldName) => {
      dispatch({ type: 'ACKNOWLEDGE_FIELD_RESET', payload: fieldName });
    },
    setDebounce: (debouncing) => {
      dispatch({ type: 'SET_DEBOUNCE', payload: debouncing });
    },
  };

  const memoizedContext = useMemo(() => context, [context]);

  return (
    <DynamicFormContext.Provider value={memoizedContext}>
      {children}
    </DynamicFormContext.Provider>
  );
};

DynamicFormProvider.defaultProps = {};

DynamicFormProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  data: PropTypes.shape({
    docId: PropTypes.number.isRequired,
  }).isRequired,
  authToken: PropTypes.string.isRequired,
  userId: PropTypes.string.isRequired,
  applicationDispatch: PropTypes.func.isRequired,
};

export default DynamicFormProvider;
