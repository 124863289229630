const MatterStatus = {
  Draft: 1,
  NotStarted: 2,
  InProgress: 3,
  Submitted: 4,
  SubmittedAndReady: 5,
  UnderReview: 6,
  RequiresResign: 7,
  Errored: 8,
  Finalised: 9,
  Deleted: 10,
  ElectronicConsentRejected: 11,
};

export default MatterStatus;
