import React from 'react';
import InfiniteScroll from 'components/InfiniteScroll';
import PropTypes from 'prop-types';
import { Table, TBody } from '@galilee/lilee';
import SortableHeader from './components/SortableHeader';
import Loader from './components/Loader';

const DataGrid = ({
  loadMore, hasMore, headers, children, onSort, sort,
}) => (
  <Table>
    <SortableHeader headers={headers} onSort={onSort} sort={sort} />
    <InfiniteScroll
      element={TBody}
      loadMore={(page) => loadMore(page)}
      hasMore={hasMore}
      loader={<Loader key={0} colspan={headers.length} />}
      threshold={500}
    >
      {children}
    </InfiniteScroll>
  </Table>
);

DataGrid.defaultProps = {
  hasMore: false,
  children: null,
};

DataGrid.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  ...SortableHeader.propTypes,
};

export default DataGrid;
