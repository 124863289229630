import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Image, Relative } from '@galilee/lilee';

const ColinWrapper = styled(Relative)`
  height: 120px;
  text-align: right;
`;

const ColinImage = styled(Image)`
  position: absolute;

  top: -55px;
  right: -20px;
`;

const Colin = ({ image }) => (
  <ColinWrapper>
    <ColinImage src={image} />
  </ColinWrapper>
);

Colin.propTypes = {
  image: PropTypes.string.isRequired,
};

export default Colin;
