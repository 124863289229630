import React from 'react';
import { P } from '@galilee/lilee';
import { Link } from 'react-router-dom';
import ColinNotFound from 'images/ColinNotAvailable.png';
import ErrorPage from 'components/ErrorPage';

const Matter = () => (
  <ErrorPage image={ColinNotFound} title="This page is no longer available">
    <P>There are a number of reasons why this page may have either moved or expired.</P>
    <P>
      From here your best bet is to login to your case via our
      {' '}
      <Link to="/authentication/login">Login Page</Link>
      {' '}
      page or go to our
      {' '}
      <Link to="/">Home page</Link>
      {' '}
      to learn more about Kwill.
    </P>
  </ErrorPage>
);

export default Matter;
