import React from 'react';
import PropTypes from 'prop-types';
import {
  Select, Icon, H4,
} from '@galilee/lilee';
import styled from 'styled-components/macro';

const SelectContainer = styled.div`
  position: relative;
`;

const AdminSelect = ({ title, options, onChange }) => (
  <SelectContainer>
    <H4 mt="5" mb="6">
      {title}
    </H4>
    <Select
      defaultOption={options[0]}
      enableSearch
      options={options}
      onChange={(option) => onChange(option.value || null)}
      rightIcon={<Icon color="base40" name="NavDown" size="14px" />}
      type="default"
    />
  </SelectContainer>
);

AdminSelect.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes
    .arrayOf(PropTypes.shape({}))
    .isRequired,
  onChange: PropTypes.func.isRequired,
};

export default AdminSelect;
