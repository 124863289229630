import React, { useState } from 'react';
import PropTypes, { shape } from 'prop-types';
import styled from 'styled-components/macro';
import {
  Select, Icon, H7, H4, Text, Flex, Checkbox, mediaQueries,
} from '@galilee/lilee';
import { useMatterMaker } from 'state/matterMaker/MatterMakerProvider';

const { lessThan } = mediaQueries;

const BorrowerContainer = styled(Flex)`
  background-color: white;
  width: 100%;
  padding:${(p) => p.theme.space[7]};
  border-radius: 15px;
  justify-content: space-evenly;
  ${lessThan[0]`
    flex-direction: column;
  `}
`;

const ColumnContainer = styled(Flex)`
  flex-direction: column;
`;

export const BorrowerSelect = () => {
  const { state, actions } = useMatterMaker();
  const { guarantorOneId, guarantorTwoId } = state.createMatter;
  const [checked, setChecked] = useState(false);
  const { borrowers } = state.settings.options;
  const [selectedBorrowerOne, setSelectedBorrowOne] = useState(-1);
  const [selectedBorrowerTwo, setSelectedBorrowTwo] = useState(-1);

  const borrowersOnChange = async (borrowerSelection) => {
    if (!borrowerSelection) return;

    const request = {
      createMatter: borrowerSelection,
    };
    actions.setBorrower(request);
  };

  const borrower1Options = state.createMatter.isCommercial ? borrowers.filter((o) => o.value !== selectedBorrowerTwo.value) : borrowers.filter((o) => o.value !== selectedBorrowerTwo.value
    && o.mobile !== selectedBorrowerTwo.mobile
    && o.value !== guarantorOneId
    && o.value !== guarantorTwoId);

  const borrower2Options = state.createMatter.isCommercial ? borrowers.filter((o) => o.value !== selectedBorrowerOne.value) : borrowers.filter((o) => o.value !== selectedBorrowerOne.value
    && o.mobile !== selectedBorrowerOne.mobile
    && o.value !== guarantorOneId
    && o.value !== guarantorTwoId);

  return (
    <ColumnContainer
      mb="5"
      mt="5"
    >
      <H4 mt="5">Select Borrowers</H4>
      <H7
        mb="5"
        mt="5"
      >
        Borrower one
      </H7>
      <Select
        mb="2"
        enableSearch
        options={borrower1Options}
        onChange={(borrower) => {
          setSelectedBorrowOne({ value: borrower.value, mobile: borrower.mobile });
          borrowersOnChange({ borrowerOneId: borrower.value });
        }}
        rightIcon={<Icon color="base40" name="NavDown" size="14px" />}
        type="default"
      />
      <Checkbox
        mt="5"
        mb="2"
        label="(Optional) Second borrower?"
        checked={checked}
        onChange={() => {
          if (checked) borrowersOnChange({ borrowerTwoId: -1 });
          setChecked(!checked);
        }}
      />
      {(checked)
        && (
          <>
            <H7
              mb="5"
              mt="5"
            >
              Borrower two
            </H7>
            <Select
              mb="5"
              enableSearch
              options={borrower2Options}
              onChange={(borrower) => {
                setSelectedBorrowTwo({ value: borrower.value, mobile: borrower.mobile });
                borrowersOnChange({ borrowerTwoId: borrower.value });
              }}
              rightIcon={<Icon color="base40" name="NavDown" size="14px" />}
              type="default"
            />
          </>
        )}
    </ColumnContainer>
  );
};

export const BorrowersDisplay = () => {
  const { state } = useMatterMaker();
  const { borrowerOneId, borrowerTwoId } = state.createMatter;
  const { borrowers } = state.settings;

  const borrowerOne = borrowers.find((b) => b.id === borrowerOneId);
  const borrowerTwo = borrowers.find((b) => b.id === borrowerTwoId);

  const toggle = borrowerOne || borrowerTwo;

  return toggle ? (
    <BorrowerContainer>
      {borrowerOne && <Borrower borrower={borrowerOne} />}
      {borrowerTwo && <Borrower borrower={borrowerTwo} />}
    </BorrowerContainer>
  ) : null;
};

const Borrower = ({ borrower }) => {
  if (!borrower) return null;
  const {
    email, mobile, firstName, lastName,
  } = borrower;
  return (
    <ColumnContainer>
      <H4 mb="5" mt="5">
        {`${firstName}`}
        &nbsp;
        {`${lastName}`}
      </H4>
      <Text>{email}</Text>
      <Text>{mobile}</Text>
    </ColumnContainer>
  );
};

Borrower.propTypes = {
  borrower: shape({
    id: PropTypes.number.isRequired,
  }).isRequired,
};
