import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import { P, PageLoading, utils } from '@galilee/lilee';
import { useMatter } from 'state/MatterProvider';
import SectionSummary from 'components/SectionSummary';

const { useScrollToTop } = utils;

const SignSummary = ({ match }) => {
  const { state } = useMatter();
  useScrollToTop(true);

  if (!state.matterProgress) return <PageLoading />;
  return (
    <SectionSummary videoId="390661356" sectionType="read" match={match} title="Read & Accept" progress={state.matterProgress.readDocuments}>
      <P fontSize={2} color="white">It is important you carefully read and understand the documents before you accept them, as you will be legally bound by the terms and conditions within them.</P>
      <P fontSize={2} color="white">You can download these forms in PDF format so that you may read them in your own time.</P>
      <P fontSize={2} color="white">Need help navigating this section? Check out the introductory video.</P>
    </SectionSummary>
  );
};

SignSummary.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default SignSummary;
