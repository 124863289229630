import React from 'react';
import { Route, Switch } from 'react-router';
import FourOhFour from 'screens/fourOhFour';
import {
  utils,
} from '@galilee/lilee';
import TrackNTraceProvider from 'state/track/TrackNTraceProvider';
import ProtectedRoute from './components/ProtectedRoute';
import Login from './screens/login';
import MatterList from './screens/matterList';
import Admin from './screens/admin';
import Matter from './screens/matter';

const { useScrollToTop } = utils;

const Tracker = () => {
  useScrollToTop(true);
  return (
    <TrackNTraceProvider>
      <Switch>
        <ProtectedRoute
          exact
          path="/track"
          component={MatterList}
        />
        <Route path="/track/login" component={Login} />
        <ProtectedRoute exact path="/track/admin" component={Admin} />
        <ProtectedRoute exact path="/track/:id" component={Matter} />
        <Route component={FourOhFour} />
      </Switch>
    </TrackNTraceProvider>
  );
};

export default Tracker;
