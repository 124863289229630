import React from 'react';
import MatterMakerProvider from 'state/matterMaker/MatterMakerProvider';
import CreateMatter from './MatterMaker';

export default function MatterMaker() {
  return (
    <MatterMakerProvider>
      <CreateMatter />
    </MatterMakerProvider>
  );
}
