const UserType = {
  Borrower: 1,
  Broker: 2,
  Admin: 3,
  Lender: 4,
  TenantAdmin: 5,
  TenantSupport: 6,
};

export default UserType;
