import React from 'react';
import styled from 'styled-components/macro';
import { HR } from '@galilee/lilee';

const StyledRule = styled(HR)`
  width: 100%;
  color: transparent;
  border-top: 1px solid ${(p) => p.theme.colors.base20}
`;

const MarkupHR = () => <StyledRule />;

export default MarkupHR;
