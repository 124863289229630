import React from 'react';
import PropTypes from 'prop-types';
import TimeAgo from 'react-timeago';
import { Text, Flex } from '@galilee/lilee';
import Indent from 'components/Indent';
import Link from 'components/Link';
import CircleIcon from 'components/CircleIcon';

const LastAction = ({ lastAction, path }) => {
  if (!lastAction) return null;
  const { isoDate, by, action } = lastAction;
  if (!isoDate || !by || !action) return null;

  return (
    <Indent mt={[7, 8]} alignItems="center" flexWrap="nowrap">
      <CircleIcon color="base80" name="Cctv" mr="5" />
      <Flex flexDirection="column">
        <Text muted fontSize={[0, 1]}>
          {'Last Action - '}
          <TimeAgo date={isoDate} />
          {` ${by} ${action}`}
        </Text>
        <Link to={path} fontSize={[0, 1]}>View all security and tracking</Link>
      </Flex>
    </Indent>
  );
};

LastAction.defaultProps = {
  lastAction: null,
};

LastAction.propTypes = {
  lastAction: PropTypes.shape({
    isoDate: PropTypes.string,
    by: PropTypes.string,
    action: PropTypes.string,
  }),
  path: PropTypes.string.isRequired,
};

export default LastAction;
