import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { Flex, mediaQueries } from '@galilee/lilee';
import Header, { headerPropTypes, sortPropTypes } from './Header';

const { lessThan } = mediaQueries;

const Container = styled(Flex)`
  background-color: transparent;
  padding: ${(p) => p.theme.space[5]} ${(p) => p.theme.space[6]};
  ${lessThan[1]`
    padding: 0 ${(p) => p.theme.space[6]};
    background-color: ${(p) => p.theme.colors.base60};
    color: ${(p) => p.theme.colors.white};
  `}
`;

const SortableHeader = ({ onSort, headers, sort }) => {
  if (!headers) return null;
  return (
    <Container>
      {headers.map((h) => <Header key={h.id} onSort={onSort} header={h} sort={sort} />)}
    </Container>
  );
};

SortableHeader.defaultProps = {
  headers: null,
};

SortableHeader.propTypes = {
  headers: PropTypes.arrayOf(headerPropTypes),
  sort: sortPropTypes.isRequired,
  onSort: PropTypes.func.isRequired,
};

export default SortableHeader;
