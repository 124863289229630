import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import {
  Flex, H5, P,
} from '@galilee/lilee';

const preSignatureForms = {
  form6: 1,
  form7: 2,
};

const InfoContainer = styled(Flex)``;
InfoContainer.defaultProps = {
  width: '100%',
  flexDirection: ['column', 'row'],
};

const InfoColumn = styled(Flex)``;
InfoColumn.defaultProps = {
  flexDirection: 'column',
};

const InfoItems = styled(Flex)``;
InfoItems.defaultProps = {
  flexDirection: 'column',
  justifyContent: 'space-between',
  mb: 6,
};

const InfoItem = styled(P)``;
InfoItem.defaultProps = {
  mb: 6,
};

const PreSignatureForm = ({ type }) => {
  const form6 = (
    <>
      <InfoContainer>
        <InfoColumn width={['100%', '36%']} pr={[0, 7]}>
          <H5 mb="6">BEFORE YOU SIGN</H5>
          <InfoItems>
            <InfoItem>READ THIS CONTRACT DOCUMENT so that you know exactly what contract you are entering into and what you will have to do under the contract.</InfoItem>
            <InfoItem>You should also read the information statement: &apos;THINGS YOU SHOULD KNOW ABOUT YOUR PROPOSED CREDIT CONTRACT&apos;.</InfoItem>
            <InfoItem>Fill in or cross out any blank spaces.</InfoItem>
            <InfoItem>Get a copy of this contract document.</InfoItem>
            <InfoItem>
              <b>Do not sign </b>
              this contract document if there is anything you do not understand.
            </InfoItem>
          </InfoItems>

        </InfoColumn>
        <InfoColumn width={['100%', '64%']}>
          <H5 mb="6">THINGS YOU MUST KNOW</H5>
          <InfoItems>
            <InfoItem>
              You can withdraw this offer at any time before the credit provider accepts it.
              When the credit provider does accept it, you are bound by it. However, you may end the contract before you obtain credit,
              or a card or other means is used to obtain goods or services for which credit is to be provided under the contract,
              by telling the credit provider in writing, but you will still be liable for any fees or charges already incurred.
            </InfoItem>
            <InfoItem>
              You
              <b> do not </b>
              have to take out consumer credit insurance unless you want to. However, if this contract document says so,
              you must take out insurance over any mortgaged property that is used as security, such as a house or car.
            </InfoItem>
            <InfoItem>If you take out insurance, the credit provider can not insist on any particular insurance company.</InfoItem>
            <InfoItem>
              If this contract document says so, the credit provider can vary the annual percentage rate (the interest rate),
              the repayments and the fees and charges and can add new fees and charges without your consent.
            </InfoItem>
            <InfoItem>If this contract document says so, the credit provider can charge a fee if you pay out your contract early.</InfoItem>
          </InfoItems>
        </InfoColumn>
      </InfoContainer>
    </>
  );

  const form7 = (
    <>
      <InfoContainer>
        <InfoColumn width={['100%', '36%']} pr={[0, 7]}>
          <H5 mb="6">BEFORE YOU SIGN</H5>
          <InfoItems>
            <InfoItem>READ THIS CONTRACT DOCUMENT so that you know exactly what contract you are entering into and what you will have to do under the contract.</InfoItem>
            <InfoItem>You should also read the information statement: &apos;THINGS YOU SHOULD KNOW ABOUT YOUR PROPOSED CREDIT CONTRACT&apos;.</InfoItem>
            <InfoItem>Fill in or cross out any blank spaces.</InfoItem>
            <InfoItem>Get a copy of this contract document.</InfoItem>
            <InfoItem>
              <b>Do not sign </b>
              this contract document if there is anything you do not understand.
            </InfoItem>
          </InfoItems>

        </InfoColumn>
        <InfoColumn width={['100%', '64%']}>
          <H5 mb="6">THINGS YOU MUST KNOW</H5>
          <InfoItems>
            <InfoItem>
              Once you sign this contract document, you will be bound by it. However,
              you may end the contract before you obtain credit, or a card or other means is used to obtain goods or services for which credit is to be provided under the contract,
              by telling the credit provider in writing, but you will still be liable for any fees or charges already incurred.
            </InfoItem>
            <InfoItem>
              You
              <b> do not </b>
              have to take out consumer credit insurance unless you want to. However, if this contract document says so,
              you must take out insurance over any mortgaged property that is used as security, such as a house or car.
            </InfoItem>
            <InfoItem>If you take out insurance, the credit provider can not insist on any particular insurance company.</InfoItem>
            <InfoItem>
              If this contract document says so, the credit provider can vary the annual percentage rate (the interest rate),
              the repayments and the fees and charges and can add new fees and charges without your consent.
            </InfoItem>
            <InfoItem>If this contract document says so, the credit provider can charge a fee if you pay out your contract early.</InfoItem>
          </InfoItems>
        </InfoColumn>
      </InfoContainer>
    </>
  );

  switch (type) {
    case preSignatureForms.form6:
      return form6;
    case preSignatureForms.form7:
      return form7;
    default:
      return null;
  }
};

PreSignatureForm.defaultProps = {
  type: null,
};

PreSignatureForm.propTypes = {
  type: PropTypes.number,
};

export default PreSignatureForm;
