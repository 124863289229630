import React from 'react';
import PropTypes from 'prop-types';
import PROGRESS from 'enums/Progress';
import {
  Completed, Locked, Error, Warn, Started, NotStarted, InProgress, UnderReview, Finalised,
} from './components/Icons';

// FilterMatterStatus in Filter.cs
export const MatterFilterStatusIcon = ({ matterFilterStatus, ...rest }) => {
  switch (matterFilterStatus) {
    case 1:
      return <NotStarted {...rest} />;
    case 2:
      return <InProgress {...rest} />;
    case 3:
      return <Completed {...rest} />;
    case 4:
      return <Warn {...rest} />;
    case 5:
      return <Error {...rest} />;
    case 6:
      return <Finalised {...rest} />;
    case 7:
      return <Error {...rest} />;
    default:
      return null;
  }
};

MatterFilterStatusIcon.propTypes = {
  matterFilterStatus: PropTypes.number.isRequired,
};

export const DocStatusIcon = ({ documentStatus, ...rest }) => {
  switch (documentStatus) {
    case 1:
      return <NotStarted {...rest} />;
    case 2:
      return <InProgress {...rest} />;
    case 3:
      return <Completed {...rest} />;
    case 4:
      return <Warn {...rest} />;
    case 5:
      return <UnderReview {...rest} />;
    case 6:
      return <Finalised {...rest} />;
    case 7:
      return <Error {...rest} />;
    default:
      return null;
  }
};

DocStatusIcon.propTypes = {
  documentStatus: PropTypes.number.isRequired,
};

const ProgressIcon = ({ progress, onlyMyProgress, ...props }) => {
  const me = progress.myProgress;
  const them = progress.theirProgress;
  const hasErrors = progress.errors && progress.errors.length;

  if (me === PROGRESS.ERROR || them === PROGRESS.ERROR || hasErrors) {
    return <Error {...props} />;
  }
  if (me === PROGRESS.COMPLETED && them === PROGRESS.COMPLETED) {
    return <Completed {...props} />;
  }
  if (me === PROGRESS.SUBMITTED || me === PROGRESS.LOCKED) {
    return <Locked {...props} />;
  }
  if (me === PROGRESS.WARN) {
    return <Warn {...props} />;
  }
  if (onlyMyProgress) {
    if (me === PROGRESS.NOTSTARTED) {
      return <NotStarted {...props} />;
    }
    return <Completed {...props} />;
  }
  return <Started me={me} them={them} {...props} />;
};

ProgressIcon.defaultProps = {
  progress: null,
  onlyMyProgress: false,
};

ProgressIcon.propTypes = {
  onlyMyProgress: PropTypes.bool,
  progress: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    myProgress: PropTypes.string,
    theirProgress: PropTypes.string,
    errors: PropTypes.arrayOf(PropTypes.string),
  }),
};
export default ProgressIcon;

export const VerifyStatusIcon = ({ status }) => {
  switch (status) {
    case 'Complete':
      return <Completed />;
    case 'Unverified':
    default:
      return <NotStarted />;
  }
};

VerifyStatusIcon.defaultProps = {
  status: null,
};

VerifyStatusIcon.propTypes = {
  status: PropTypes.string,
};
