import React, { useState } from 'react';
import {
  Flex, Modal, Text, Button, H6, FieldError,
} from '@galilee/lilee';
import styled from 'styled-components/macro';
import { useApplication } from 'state/ApplicationProvider';
import { useUpdateBorrower } from 'state/updateBorrower/UpdateBorrowerProvider';
import DigIdVerify from 'images/DigIdVerify.png';
import { errorDispatcher } from 'actions/actionUtils';

const Popup = styled(Flex)`
  position: fixed;
  top:0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(7, 36, 56, 0.75);
`;

const SubmitBox = styled(Flex)`
  margin: auto;
  background-color: white;
  border-radius: 10px;
`;

SubmitBox.defaultProps = { maxWidth: ['80%', '80%', '750px'] };

const SubmitPopup = () => {
  const { dispatch: applicationDispatch } = useApplication();
  const { state, actions } = useUpdateBorrower();
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const { errors } = state?.settings;

  async function submitNow() {
    setLoading(true);
    state.setSettings({ errors: null });
    try {
      const {
        phoneNumber, email, firstName, middleName, lastName, id, mobile,
      } = state.user;
      const payload = {
        PhoneNumber: phoneNumber ?? mobile.number,
        Email: email,
        FirstName: firstName,
        MiddleName: middleName,
        LastName: lastName,
      };
      await actions.updateBorrower({
        id, payload,
      });
      setSubmitted(true);
    } catch (err) {
      errorDispatcher(applicationDispatch, "We couldn't update the user, please reload the page and try again");
    }
  }
  async function close() {
    setSubmitted(false);
    setLoading(false);
    state.setSettings({ readyToSubmit: false });
  }

  return (state.settings.readyToSubmit) ? (
    <Popup style={{ zIndex: 1000 }}>
      <SubmitBox>
        <Modal.IllustrationBody image={DigIdVerify}>
          <Modal.Body>
            <Modal.Subtitle mb={[6, null, 2]}>Update User details</Modal.Subtitle>
            {submitted ? (
              <>
                {errors ? (
                  <FieldError error={errors} />
                ) : (
                  <Text fontSize="2" mb={[6, null, 3]}>
                    User has been updated successfully
                  </Text>
                )}
              </>
            ) : (
              <Text fontSize="2" mb={[6, null, 3]}>
                Are you sure you want to update this user?
              </Text>
            )}
          </Modal.Body>
          {!submitted
            ? (
              <Modal.ActionContainer width="100%" justifyContent="space-evenly">
                {(!loading) && <Button mr="5" disabled={loading} mb={6} color="secondary" onClick={() => submitNow()}>Update</Button>}
                {(!loading) ? <Button mb={20} color="base" onClick={() => close()}> Exit</Button>
                  : (
                    <H6
                      mb="5"
                      mt="5"
                    >
                      Updating User...
                    </H6>
                  )}
              </Modal.ActionContainer>
            )
            : (
              <Modal.ActionContainer width="100%" justifyContent="space-evenly">
                <Button mb={6} color="secondary" onClick={() => close()}>Exit</Button>
              </Modal.ActionContainer>
            ) }
        </Modal.IllustrationBody>
      </SubmitBox>
    </Popup>
  ) : null;
};

export default SubmitPopup;
