import { useState, useEffect, useRef } from 'react';
import { utils } from '@galilee/lilee';
import { useDynamicForm } from 'state/DynamicFormProvider';
import { useApplication } from 'state/ApplicationProvider';
import { setDocumentSignField } from 'actions/Sign';
import { useAuth } from 'state/AuthProvider';

const { useDebounce } = utils;

const useValidationProps = (fieldName) => {
  const {
    getFieldProps, errors, fields, docId, fieldIds, fieldValues, fieldResets, acknowledgeReset, setDebounce, debounceTime,
  } = useDynamicForm();
  const { dispatch } = useApplication();
  const { authToken, support } = useAuth();
  const field = fields[fieldName];
  const fieldResetValue = fieldResets[fieldName];
  const fieldId = fieldIds[fieldName];
  const [changedValue, setChangedValue] = useState({ value: null, debounce: true });

  const setDebounceRef = useRef(setDebounce);

  const fieldProps = getFieldProps(fieldName, {
    onChange: (e) => setChangedValue({
      value: e.target.type === 'checkbox' ? e.target.checked.toString() : e.target.value,
      debounce: e.target.type !== 'checkbox' && e.target.type !== 'radio', // Don't debounce checkboxes or radios
    }),
  });
  fieldProps.name = fieldName;
  fieldProps.id = fieldName;
  fieldProps.value = fieldValues[fieldName];

  const debouncedValue = useDebounce(changedValue.value, changedValue.debounce ? debounceTime : 0);

  useEffect(() => {
    if (changedValue.debounce) setDebounceRef.current(true);
  }, [changedValue.value, changedValue.debounce]);

  useEffect(() => setDebounceRef.current(false), [debouncedValue]);

  useEffect(() => {
    if (debouncedValue !== null && debouncedValue === fieldProps.value && !support.isTenantSupport) {
      setDocumentSignField(dispatch, docId, fieldId, authToken, debouncedValue);
    }
  }, [changedValue.debounce, debouncedValue, authToken, fieldId, docId, fieldProps.value, dispatch, support]);
  useEffect(() => {
    if (fieldResetValue !== undefined && !support.isTenantSupport) {
      setDocumentSignField(dispatch, docId, fieldId, authToken, fieldResetValue);
      acknowledgeReset(fieldName);
    }
  }, [acknowledgeReset, authToken, dispatch, docId, fieldId, fieldName, fieldResetValue, support]);

  return { fieldProps, errors, field };
};

export default useValidationProps;
