import React from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  P, PageLoading, Modal, ResponsiveGroup, Button, UL, LI, utils,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { useApplication } from 'state/ApplicationProvider';
import SectionSummary from 'components/SectionSummary';
import { acknowledgeNotesAction } from 'actions/Matter';

const { useScrollToTop } = utils;

const SignSummary = ({ match }) => {
  const { state, dispatch } = useMatter();
  const { user, authToken } = useAuth();
  const { dispatch: applicationDispatch } = useApplication();
  const { notes } = state.matter;
  const me = (state.matter && user) ? state.matter.borrowers.find((borrower) => borrower.id === user.id) : null;
  const showNotes = me && !me.matterNotesAcknowledged && notes && notes.length > 0;
  useScrollToTop(true);

  if (!state.matterProgress) return <PageLoading />;
  return (
    <>
      <SectionSummary videoId="390661377" sectionType="sign" match={match} title="Complete &amp; Sign" progress={state.matterProgress.signDocuments} verificationProgress={state.verificationProgress}>
        <P fontSize={2} color="white">
          This is where you sign your&nbsp;
          {state.matter.isSharia ? 'finance' : 'loan'}
                  &nbsp;documents, and will also need to provide us some additional information for settlement.
        </P>
        <P fontSize={2} color="white">
          Please make sure you carefully read and understand all documents before signing, as you will be legally bound by your digital signature.
        </P>
        <P fontSize={2} color="white">
          Depending on which state your property is in, you may also need to print off the mortgage,&nbsp;
          sign it the old fashioned way, and post it back to us. (Don&apos;t worry, you will be prompted to do this if it is required).
        </P>
        <P fontSize={2} color="white">
          Navigating this section is similar to &apos;Read &amp; Accept&apos;, but with a few differences.&nbsp;
          Check out the video, it should help point you in the right direction.
        </P>
      </SectionSummary>

      {showNotes && (
      <Modal>
        <Modal.Body>
          <Modal.Subtitle>Complete &amp; Sign</Modal.Subtitle>
          <Modal.Title lineHeight="compact">Please Note</Modal.Title>
          <UL>
            {notes.map((n) => <LI><P>{n}</P></LI>)}
          </UL>
          <Modal.ActionContainer width="100%" justifyContent="flex-end">
            <ResponsiveGroup>
              <Button color="secondary" onClick={() => acknowledgeNotesAction(dispatch, applicationDispatch, state.matter.matterId, authToken, user.id)}>I Understand</Button>
            </ResponsiveGroup>
          </Modal.ActionContainer>
        </Modal.Body>
      </Modal>
      )}
    </>
  );
};

SignSummary.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default SignSummary;
