import React from 'react';
import {
  Switch, Route, useRouteMatch,
} from 'react-router-dom';
import Layout from '../components/Layout';
import LoginForm from '../components/LoginForm';
import AccessCodeForm from '../components/AccessCodeForm';

const Login = () => {
  const match = useRouteMatch();

  return (
    <Layout title="Instantly track progress updates for loan matters">
      <Switch>
        <Route exact path={match.path} component={LoginForm} />
        <Route path={`${match.path}/withCode`} component={AccessCodeForm} />
      </Switch>
    </Layout>

  );
};
export default Login;
