import React, { useState } from 'react';
import {
  Button, Icon,
} from '@galilee/lilee';
import { doDownload } from 'utils';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';

const StyledButton = styled(Button)`
  &:hover {
    padding-left: 0;
  }
`;

function allSigningPartiesCompletedVerification(verificationProgress, docId) {
  let result = true;
  verificationProgress.forEach((voiUserProgress) => {
    const { requireVerification, verificationCompleted } = voiUserProgress;
    if (!requireVerification) return;
    const { voiCompleted, isReadOnly } = verificationCompleted?.find((v) => v.id === docId);
    if (!voiCompleted && !isReadOnly) result = false;
  });

  return result;
}

const showDownloadButton = (progress, verificationProgress, sectionType, docId) => {
  switch (sectionType) {
    case 'read':
      return true;
    case 'sign':
      return progress.requiresPrinting
        ? (!progress.requiresVerification || allSigningPartiesCompletedVerification(verificationProgress, docId))
        : true;
    case 'upload':
      return progress.hasDocument === true;
    default:
      throw new Error('Invalid sectionType passed to SummaryRow');
  }
};

const DownloadButton = ({
  progress, sectionType, docId, authToken, verificationProgress,
}) => {
  const [downloading, setDownloading] = useState(false);
  return (
    showDownloadButton(progress, verificationProgress, sectionType, docId)
      ? (
        <StyledButton
          iconSmall
          iconColor="base60"
          leftIcon={<Icon name="Download" />}
          onClick={() => doDownload(progress, setDownloading, sectionType, docId, authToken)}
          color="link"
          pl="0"
        >
          {downloading ? 'Downloading' : 'Download'}
        </StyledButton>
      ) : null);
};

DownloadButton.defaultProps = {
  verificationProgress: [],
};

DownloadButton.propTypes = {
  progress: PropTypes.shape({}).isRequired,
  sectionType: PropTypes.string.isRequired,
  docId: PropTypes.number.isRequired,
  authToken: PropTypes.string.isRequired,
  verificationProgress: PropTypes.arrayOf(
    PropTypes.shape({
      userId: PropTypes.string,
      requireVerification: PropTypes.bool,
      verificationCompleted: PropTypes.arrayOf(
        PropTypes.shape({ id: PropTypes.number, voiCompleted: PropTypes.bool, isReadOnly: PropTypes.bool }),
      ),
    }),
  ),
};

export default DownloadButton;
