import React from 'react';
import InfiniteScroll from 'components/InfiniteScroll';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import {
  Flex, Column, H2, Box, mediaQueries,
} from '@galilee/lilee';
import SortableHeader from './components/SortableHeader';
import Loader from './components/Loader';

const { lessThan } = mediaQueries;

const mediaQueriesOverride = (p) => {
  if (!p.responsiveHide) return '(min-width: 0) and (max-width: 0)';
  const breakpointList = p.responsiveHide.map((b) => `(min-width: ${p.theme.breakpointsMin[b]}) and (max-width: ${p.theme.breakpointsMax[b]})`);
  return breakpointList.join(', ');
};

const NoResultsWrapper = styled(Flex)`
  padding: ${(p) => p.theme.space[9]} 0;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

const NoResultsText = styled(Box)`
  max-width: 440px;
`;

const ScrollContainer = styled(Flex)`
  flex-direction: column;
  background-color: ${(p) => p.theme.colors.white};
  border-radius: ${(p) => p.theme.radii[3]};
  padding: ${(p) => p.theme.space[6]} ${(p) => p.theme.space[9]};
  ${lessThan[3]`
    padding: ${(p) => p.theme.space[5]} ${(p) => p.theme.space[7]};
  `}
    ${lessThan[1]`
    padding: ${(p) => p.theme.space[4]} ${(p) => p.theme.space[6]};
  `}
`;

const DataList = ({
  loadMore, hasMore, displayNoResult, children,
}) => (
  <InfiniteScroll
    element={ScrollContainer}
    loadMore={(page) => loadMore(page)}
    hasMore={hasMore}
    loader={<Loader key={0} />}
    threshold={500}
  >
    {displayNoResult && (
      <NoResultsWrapper>
        <H2 mb="7">Nothing to see here!</H2>
        <NoResultsText>Try removing any filters you might have applied, or contact us if you think you should be able to see some results here.</NoResultsText>
      </NoResultsWrapper>
    )}
    {children}
  </InfiniteScroll>
);

const DataListItem = styled(Flex)`
  cursor: pointer;
  width: 100%;
  border-bottom: 1px solid ${(p) => p.theme.colors.opacity07};
`;

const DataListColumn = styled(Column)`
  display: flex;
  flex-direction: ${(p) => p.direction};
  flex: ${(p) => p.width};
  @media ${(p) => mediaQueriesOverride(p)} {
    display: none;
  }
  overflow: hidden;
  overflow-wrap: anywhere;
`;

DataListColumn.defaultProps = {
  direction: 'column',
  width: 1,
  responsiveHide: null,
  mb: [4, 4, 5, 7],
  mt: [4, 4, 5, 7],
  mr: [4],
};

DataListColumn.propTypes = {
  direction: PropTypes.string,
  width: PropTypes.number,
  responsiveHide: PropTypes.arrayOf(PropTypes.number),
};

DataList.Item = DataListItem;

DataList.Column = DataListColumn;

DataList.SortableHeader = SortableHeader;

DataList.defaultProps = {
  hasMore: false,
  children: null,
  displayNoResult: false,
};

DataList.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  loadMore: PropTypes.func.isRequired,
  hasMore: PropTypes.bool,
  displayNoResult: PropTypes.bool,
};

export default DataList;
