import React, { useState } from 'react';
import {
  H7, Checkbox, Input,
} from '@galilee/lilee';
import { useMatterMaker } from 'state/matterMaker/MatterMakerProvider';

const Broker = () => {
  const { actions } = useMatterMaker();
  const [checked, setChecked] = useState(false);

  const onChangeBroker = (broker) => {
    actions.updatePayload(broker);
  };

  const OnChangeCheckBox = () => {
    setChecked(!checked);
    onChangeBroker({
      brokerEmail: null,
    });
  };

  return (
    <>
      <Checkbox
        mb="5"
        label="(Optional) Do the matters belong to a broker?"
        checked={checked}
        onChange={() => OnChangeCheckBox()}
      />
      {checked
          && (
          <>
            <H7 mt="5" mb="5">
              Email
            </H7>
            <Input
              maxWidth="100%"
              id="email"
              name="email"
              type="text"
              onChange={(val) => onChangeBroker({ brokerEmail: val.target.value })}
            />
          </>
          )}
    </>
  );
};

export default Broker;
