import React, { useEffect, useState } from 'react';
import ReactRouterPropTypes from 'react-router-prop-types';
import {
  Box, Panel, Row, PageLoading, toast,
} from '@galilee/lilee';
import { useAuth } from 'state/AuthProvider';
import { useMatter } from 'state/MatterProvider';
import { useApplication } from 'state/ApplicationProvider';
import Container from 'components/Container';
import MatterSummary from 'components/MatterSummary';
import StepsToCompletion from 'components/StepsToCompletion';
import { welcomeReadAction, setSubmitMatterAction } from 'actions/Matter';
import LastAction from 'components/LastAction';
import { httpRequest, downloadFile, getSummaryStage } from 'utils';
import styled from 'styled-components/macro';
import SummaryStatus from '../components/SummaryStatus';
import SubmitPopup from '../components/SubmitPopup';
import WelcomeModal from './components/WelcomeModal';

const StyledPanel = styled(Panel)`
  margin-bottom: 24px;
  border-radius: 10px;
  box-shadow: 0px 14px 34px rgb(7 36 56 / 10%);
  padding: 0;
`;

const Summary = ({ match }) => {
  const { state, dispatch } = useMatter();
  const { dispatch: applicationDispatch } = useApplication();
  const { user, authToken, support } = useAuth();
  const [downloading, setDownloading] = useState(false);
  const allStagesJustCompleted = state.matterProgress && state.matterProgress.allStagesJustCompleted;

  useEffect(() => {
    if (allStagesJustCompleted === true) {
      dispatch({ type: 'RESET_ALLSTAGESJUSTCOMPLETED' });
    }
  }, [allStagesJustCompleted, dispatch]);

  const doDownload = () => {
    if (downloading) return;
    setDownloading(true);
    httpRequest(`/api/matters/${state.matter.matterId}/submitteddocuments`, 'get', null, { Authorization: `Bearer ${authToken}` })
      .then((blob) => {
        downloadFile(blob, 'LoanApplication.zip');
      })
      .catch(() => {
        throw new Error('Unable to download your documents at the moment, please contact support for assistance.');
      })
      .finally(() => {
        setDownloading(false);
      });
  };

  if (!state || !state.matter || !state.matterProgress) return <PageLoading />;

  const onSubmitDocuments = async () => {
    const { matterId } = state.matter;

    try {
      await setSubmitMatterAction(matterId, authToken);
      toast.success('Matter submitted successfully.');
    } catch (error) {
      toast.error('We couldn\'t submit your documents at the moment, please reload the page to try again.');
    }
  };

  const onWelcomeModalConfirm = () => {
    welcomeReadAction(dispatch, applicationDispatch, state.matter.matterId, authToken, user.id);
  };

  const showWelcomeModal = (state.matter?.borrowers?.some((b) => b.id === user.id && b.shownWelcomeScreen === false)) && !support.isTenantSupport;
  const requireVerification = state.matter?.requiresVerification;

  return (
    <Container center>
      <SubmitPopup status={getSummaryStage(state.matterProgress, user.id).stage} onSubmitDocuments={onSubmitDocuments} dateToAutoSubmit={state.dateToAutoSubmit} />
      <Box p={[3, 0]}>
        <Row collapseIndex="0">
          <SummaryStatus matter={state} match={match} user={user} onSubmitDocuments={onSubmitDocuments} onDownloadDocuments={doDownload} />
        </Row>
        <StyledPanel>
          <Box p={[7, 7, 8]}>
            <MatterSummary matter={state.matter} />
          </Box>
        </StyledPanel>
        <StyledPanel>
          <Box p={[7, 7, 8]}>
            <StepsToCompletion matchUrl={match.url} />
          </Box>
        </StyledPanel>
        {showWelcomeModal && <WelcomeModal requireVerification={requireVerification} onConfirm={onWelcomeModalConfirm} />}
        <LastAction lastAction={state.matter.lastAction} path={`/matter/${state.matter.matterId}/security`} />
      </Box>
    </Container>
  );
};

Summary.propTypes = {
  match: ReactRouterPropTypes.match.isRequired,
};

export default Summary;
