import React, { useEffect, useState } from 'react';
import { PageLoading, Panel, SearchBar } from '@galilee/lilee';
import styled from 'styled-components/macro';
import { useTrackNTrace } from 'state/track/TrackNTraceProvider';
import { useHistory } from 'react-router';
import Layout from '../components/Layout';
import MatterCard from '../components/MatterCard';
import UserSelect from '../components/UserSelect';

const SearchBox = styled(Panel)`
    max-width: 700px;
    padding: 0 ${(p) => p.theme.space[6]} 0 ${(p) => p.theme.space[6]};
    border-radius: ${(p) => p.theme.space[3]};
    margin-bottom: ${(p) => p.theme.space[4]};
`;

const MatterList = () => {
  const [search, setSearch] = useState('');
  const {
    isLoading, matters, isAdmin, selectedUser,
  } = useTrackNTrace();

  const history = useHistory();

  useEffect(() => {
    if (isAdmin) {
      history.push('/track/admin');
    }
  }, [isAdmin, history]);

  const handleClick = (id) => {
    history.push(`/track/${id}`);
  };

  let mattersToDisplay = [...matters];

  if (selectedUser) {
    mattersToDisplay = mattersToDisplay.filter((m) => m.owner === selectedUser);
  }

  mattersToDisplay = mattersToDisplay.filter((i) => i.names.some((n) => n.toLowerCase().includes(search)) || i.suburbs.some((s) => s.toLowerCase().includes(search)));

  return (
    <Layout title={`${mattersToDisplay?.length} Matters Found for`} top={<UserSelect />}>
      <SearchBox mx="auto">
        <SearchBar onChange={(val) => setSearch(val.toLowerCase())} position="relative" placeHolder="Find a particular matter" />
      </SearchBox>
      {isLoading && <PageLoading backgroundColor="baseOpacity60" />}
      {
        mattersToDisplay.map((matter) => <MatterCard key={matter.goldId} {...matter} onClick={() => handleClick(matter.goldId)} />)
      }
    </Layout>
  );
};

export default MatterList;
