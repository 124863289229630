import React from 'react';
import {
  Flex, Box, Text, utils, H6,
} from '@galilee/lilee';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import DataList from 'components/DataList';
import TimeAgo from 'react-timeago';
import { MatterFilterStatusIcon } from 'components/ProgressIcons';

const Time = styled(TimeAgo)`
  display: inline-block;
`;

export default function MatterItem({ matter, orderBy }) {
  const { toAUD } = utils;
  const history = useHistory();

  const getMatterFilterStatus = (item) => {
    if (!item.matterFilterStatus) return null;
    return (
      <Flex alignItems="center" mb={3}>
        <MatterFilterStatusIcon matterFilterStatus={item.matterFilterStatus} mr={5} />
        <H6 color="base40">{item.matterFilterTitle}</H6>
      </Flex>
    );
  };

  return (
    <DataList.Item clickable onClick={() => history.push(`/matter/${matter.matterId}`)}>
      <DataList.Column width={3}>
        <Text fontWeight="medium" mb={3}>
          {matter.borrowers.join(' and ')}
        </Text>
        {getMatterFilterStatus(matter)}
        { matter.securityProperties && matter.securityProperties.map((s) => (
          <Text key={s.addressOne + matter.matterId} mb={3}>
            {`${s.addressOne} ${s.addressTwo ? s.addressTwo : ''} ${s.suburb} ${s.state} ${s.postcode}`}
          </Text>
        )) }
        {matter.reference && <Text fontSize={0}>{matter.reference}</Text>}
      </DataList.Column>
      <DataList.Column width={1} responsiveHide={orderBy === 'loanAmount' ? null : [0, 1]}>
        <Text fontWeight="medium" mb={3}>
          {toAUD(matter.loanAmount)}
        </Text>
        <Box>{matter.lender}</Box>
      </DataList.Column>
      <DataList.Column width={1} responsiveHide={orderBy === 'creationDate' ? null : [0, 1]}>
        <Text fontWeight="medium" mb={3}>
          <Time minPeriod={30} date={matter.creationDate} />
        </Text>
      </DataList.Column>
      <DataList.Column width={1} responsiveHide={orderBy === 'borrowerLastActionDate' ? null : [0, 1]}>
        <Text fontWeight="medium" mb={3}>
          { matter.borrowerLastActionDate ? <Time minPeriod={30} date={matter.borrowerLastActionDate} /> : '--' }
        </Text>
      </DataList.Column>
    </DataList.Item>
  );
}

MatterItem.propTypes = {
  matter: PropTypes.shape({
    matterId: PropTypes.number,
    lender: PropTypes.string,
    loanAmount: PropTypes.number,
    borrowerLastActionDate: PropTypes.string,
    reference: PropTypes.string,
    securityProperties: PropTypes.arrayOf(PropTypes.shape({})),
    borrowers: PropTypes.arrayOf(PropTypes.string),
    creationDate: PropTypes.string,
  }).isRequired,
  orderBy: PropTypes.string.isRequired,
};
