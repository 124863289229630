import React from 'react';
import {
  Flex, H1, Box,
} from '@galilee/lilee';
import Container from 'components/Container';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import Body from './Body';
import Gradient from './Gradient';

const ChildrenContainer = styled(Box)`
  margin: -${(p) => p.theme.space[8]} ${(p) => p.theme.space[3]} ${(p) => p.theme.space[8]} ${(p) => p.theme.space[3]};
`;

const Layout = ({
  top, children, bottom, kicker, title, subtitle,
}) => (
  <Flex flexDirection="column">
    <Gradient minHeight="442px">
      <Body pb="8" mx="4">
        <Flex flexDirection="column" alignItems="center" mt="9">
          {!!kicker && (
          <H1 fontSize="4" textAlign="center" mb="6" color="white">
            {kicker}
          </H1>
          )}
          <H1 maxWidth="700px" textAlign="center" mb="6" color="white">
            {title}
          </H1>
          {!!subtitle && (
          <H1 fontSize="3" textAlign="center" mb="6" color="white">
            {subtitle}
          </H1>
          )}
          {top}
        </Flex>
      </Body>
    </Gradient>
    <ChildrenContainer>
      {children}
    </ChildrenContainer>
    <Container bg="white">
      {bottom}
    </Container>
  </Flex>
);

Layout.propTypes = {
  top: PropTypes.element,
  children: PropTypes.node,
  bottom: PropTypes.element,
  kicker: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

Layout.defaultProps = {
  top: null,
  children: null,
  bottom: null,
  kicker: null,
  title: null,
  subtitle: null,
};

export default Layout;
